import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Backdrop, IconButton, Grid, Divider, Button, TextField, TableCell, Dialog, DialogContent, TableBody, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useNotify } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { BASE_URL } from "../../../config";

interface Props {
    status: any;
    screens: any;
    subscription_id: string;
}

type Inputs = {
    quantity: string
};

const Change: FC<Props> = ({ status, screens, subscription_id }) => {

    const notify = useNotify();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const { handleSubmit, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {

        setOpenBackDrop(true);

        const formData: any = new FormData();

        data.quantity = screenQuantity;

        formData.append("quantity", data.quantity);
        formData.append("subscription_id", subscription_id);
        // formData.append("never_expires", data.neverExpires);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        setOpen(false);

        fetch(`${BASE_URL}/api/v1/subscriptions/zoho/updateSubscription`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                if (response.code === 0) {
                    window.open(response.hostedpage.url);
                    setOpenBackDrop(false);
                } else {

                    if (response.type) {
                        notify(response.message, { type: "info" });
                    } else {
                        notify(response.message, { type: "warning" });
                    }

                    setOpenBackDrop(false);
                    setOpen(false);
                }
            });

    }

    const [screenQuantity, setScreenQuantity] = React.useState<any>();

    return (
        <>
            <Box>
                {status == "live" &&
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        type="submit"
                        onClick={handleClickOpen}
                    >
                        Change
                    </Button>
                }
            </Box>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                fullScreen
            >
                <DialogContent >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box>
                            <Box>
                                <IconButton onClick={handleClose} >
                                    <KeyboardBackspaceOutlinedIcon />
                                </IconButton>
                            </Box>
                            <Box sx={{ backgroundColor: "#222", padding: 50, borderRadius: 40 }}>
                                <Typography variant="subtitle1" component="h2">
                                    Standard Plan - Monthly
                                </Typography>
                                <Typography color="textSecondary">
                                    Select the number of screens (TVs)
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={9}>
                                        <Typography component="p">Number of screens</Typography>
                                        <Typography sx={{ marginTop: 8 }} component="span">x</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <TextField
                                            id="quantity"
                                            InputLabelProps={{ shrink: true }}
                                            placeholder="How many screens ?"
                                            type="number"
                                            onChange={(e) => { setScreenQuantity(e.target.value) }}
                                            label="Screens"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item md={9}>
                                        <Typography component="span">Price/Screen</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography component="span">1 Euro</Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{ paddingTop: 15, paddingBottom: 15 }}>
                                    <Divider />
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item md={9}>
                                        <Typography component="span">Total</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography component="span">{screenQuantity ? Number(screenQuantity) * 1 : 0} Euro (s)</Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{ paddingTop: 15, paddingBottom: 15 }}>
                                    <Divider />
                                </Box>
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        type="submit"
                                        disabled={!screenQuantity || screenQuantity < 0 || screens == screenQuantity}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Proceed To Payment
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog >
            <Backdrop open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Change;