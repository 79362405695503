import * as React from 'react';
import { FC, useState } from 'react';
import {
    useListContext,
    DatagridProps,
    Identifier,
    useDataProvider, useNotify, useRefresh
} from 'react-admin';
import { Typography, CircularProgress, Button, Card, Divider, CardActions, CardContent, Backdrop,Box } from '@mui/material';
import DeleteDeviceMenu from './dialogs/DeleteDeviceMenudialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BASE_ASSETS_URL } from '../../config';

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: any) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 5;
};

const LoadedGridList: FC<DatagridProps> = () => {
    const { data,resource } = useListContext();

    const [state, setState] = useState<any>(data);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const onDragEnd = (result: any) => {
        const formData = new FormData();

        formData.append("oldOrder", result.source.index);
        formData.append("order", result.destination.index);

        dataProvider.update<any>(
            'device-menu',
            {
                id: result.draggableId,
                data: formData,
                previousData: {
                    id: result.draggableId
                }
            },
            ).then((response: any) => {
                notify('Order updated', { type: "info" });
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    if (!data) return null;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided:any, snapshot:any) => (
                    <Box
                        sx={{ display: 'flex'}}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {data.map((record:any, index:any) => (
                            <Box style={{ marginRight: 10 }}>
                                <Draggable key={record['_id']} draggableId={record['_id']} index={index}>
                                    {(provided:any, snapshot:any) => (
                                        <Card
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{ textAlign: "center",borderRadius: 20,width: "30vh"}}
                                        >
                                            <CardContent>
                                                <img alt="Device Menu's" src={`${BASE_ASSETS_URL}${record.image}`} style={{width: 150,height: 150,borderRadius: 6}} />
                                                <Typography sx={{paddingTop: 20,textAlign: "center",textTransform: "uppercase",fontWeight: "bold"}} color="textSecondary" gutterBottom>
                                                    {record.name}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{ padding: 4, paddingLeft: 6, paddingRight: 5 }}>
                                                <Divider />
                                            </Box>
                                            <CardActions style={{ alignItems: "center", justifyContent: "center" }}>
                                                <Button href={`/#/device-menu/${record._id}`} size="small" color="primary">Edit</Button>
                                                <DeleteDeviceMenu record={record} index={record._id} />
                                            </CardActions>
                                        </Card>
                                    )}
                                </Draggable>
                            </Box>
                        ))}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const GridList = () => {
    return <LoadedGridList />
};

export default (GridList);