import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, DialogContent, Divider, Button, List, TextField, Dialog, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout, useRefresh, usePermissions, useDataProvider, useNotify, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMediaQuery, Theme } from '@mui/material';

const AccountSettingsProperty: FC = () => {

    const navigate = useNavigate();
    const logout = useLogout();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    let id = decode['property_operator_id'];

    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor: 'right', open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const accountUsersRoles = () => {
        navigate('/users/property');
        setState({ right: false });
    }

    const changePassword = () => {
        navigate('/change-password');
        setState({ right: false });
    }

    const accountDetails = () => {
        navigate('/account-details');
        setState({ right: false });
    }

    const changeHotelName = () => {
        navigate('/change-name');
        setState({ right: false });
    }

    const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
)

    return (
        <Box>
            <IconButton size="large" onClick={toggleDrawer('right', true)} >
                <SettingsIcon />
            </IconButton>
            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                <Box sx={{ width: isXSmall ? 300 : 400}}>
                    <Box sx={{ p: 5, textAlign: "center" }}>
                        <IconButton>
                            <SettingsIcon sx={{ fontSize: 70 }} />
                        </IconButton>
                        <Typography sx={{ color: "gray", fontSize: 25 }}>Settings</Typography>
                    </Box>
                    <List>
                        <List>
                            <ListItem button onClick={accountUsersRoles}>
                                <ListItemIcon><GroupAddIcon /></ListItemIcon>
                                <ListItemText primary="Users & Roles" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={accountDetails}>
                                <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                                <ListItemText primary="Account Details" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={changeHotelName}>
                                <ListItemIcon><EditIcon /></ListItemIcon>
                                <ListItemText primary="Change Property Name" />
                            </ListItem>
                        </List>
                        <Divider />
                        <ListItem button onClick={changePassword}>
                            <ListItemIcon><LockIcon /></ListItemIcon>
                            <ListItemText primary="Change Password" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={logout}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText primary="Log Out" />
                        </ListItem>
                    </List>
                </Box>
                {/* {!cd_id ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <SignUpAsCD />
                    </div>
                    :
                    null} */}
            </Drawer>
        </Box>
    )
}

export default AccountSettingsProperty;