import React, { FC, useState, useEffect, useCallback } from "react";
import {
  useNotify,
  useRefresh,
  useGetList,
  useDataProvider,
  useCreate,
  Title,
} from "react-admin";
import {
  Grid,
  Typography,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Avatar,
  Autocomplete,
} from "@mui/material";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import components
import { EditChannelStreams } from "../../types/Streams";

// import EditStreams from './EditStreams';
import CreateGenre from "./dialogs/CreateGenre";

// import components
import EditStreams from "./components/EditStreams";
import CreateStreamSource from "./dialogs/CreateStreamSource";

type Inputs = {
  epg_map_id: string;
  title: string;
  channel_number: string;
  channel_description: string;
  pin_protected: boolean;
  packages: any;
  is_available: boolean;
  streams: any;
  icon_path: object;
  genre: any;
  rendering: number;
};

const Create: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const [changeimage, setChangeImage] = useState({});

  const [streams, setStreams] = useState([]);
  const [streamDetails, setStreamDetails] = React.useState<any>([]);

  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);

  const [disabled, setDisabled] = useState<boolean>(true);

  const [pinProtected, setPinProtected] = useState(false);

  const handleChangePinProtected = (event: any) => {
    setPinProtected(event.target.checked);
  };

  const [isAvailable, setIsAvailable] = useState(false);

  const handleChangeIsAvailable = (event: any) => {
    setIsAvailable(event.target.checked);
  };

  const getStateStreams: EditChannelStreams = (data: any) => {
    setStreams(data);
    // setStreamDetails(data);
  };

  const [disabledImg, setDisabledImg] = useState<boolean>(true);

  const handleImage = (e: any) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setImageUrl(url);
    setChangeImage(e.target.files[0]);
    setDisabledImg(false);
  };

  const {
    data: packages,
    total: totalPackages,
    isLoading: loadingPackage,
    error: errorPackage,
  } = useGetList<any>("packages", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  const {
    data: genres,
    total: totalGenres,
    isLoading: loadingGenres,
    error: errorGenres,
  } = useGetList<any>("genres", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  console.log(genres);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Inputs>();
  const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
    const formData: any = new FormData();

    data.packages = selectedPackages;
    data.genre = selectedGenres;
    data.streams = streams;
    data.icon_path = changeimage;

    // formData.append("title", data.title);
    // formData.append("channel_number", data.channel_number);
    // formData.append("epg_map_id", data.epg_map_id);
    // formData.append("channel_description", data.channel_description);
    // formData.append("rendering", data.rendering);

    // if (
    //   Object.keys(data.icon_path).length === 0 &&
    //   (data.icon_path.constructor === Object) === false
    // ) {
    //   formData.append("icon_path", data.icon_path);
    // }
    // formData.append("pin_protected", data.pin_protected);
    // formData.append("is_available", data.is_available);
    // formData.append("genre", JSON.stringify(data.genre));
    // formData.append("packages", JSON.stringify(data.packages));
    // formData.append("streams", JSON.stringify(data.streams));

    const channelsData = {
      title: data.title,
      channel_number: data.channel_number,
      epg_map_id: data.epg_map_id,
      channel_description: data.channel_description,
      icon_path: data.icon_path,
      pin_protected: data.pin_protected,
      is_available: data.is_available,
      genre: data.genre,
      packages: data.packages,
      streams: data.streams,
      rendering: JSON.parse(data.rendering),
    };

    dataProvider
      .create("channels", {
        data: channelsData,
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your channel was successfully created");
        navigate("/channels");
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };
  return (
    <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
      <Title title="Create Channel" />
      <form onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={6}>
          <Grid item md={7}>
            <Grid container spacing={2}>
              <Grid item xs={9} md={5}>
                <TextField
                  id="title"
                  placeholder="Channel Title"
                  label="Channel Title*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("title", { required: true })}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} md={2}>
                <TextField
                  id="channel_number"
                  type="number"
                  placeholder="Channel Nr"
                  label="Channel Nr*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("channel_number", {
                    required: true,
                    pattern: /^\d+$/,
                    min: {
                      value: 1,
                      message: "Value must be greater than 0",
                    },
                  })}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.channel_number
                      ? `${errors.channel_number.message}`
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={9} md={3}>
                <TextField
                  id="epg_map_id"
                  placeholder="EPG Map ID"
                  label="EPG Map ID*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("epg_map_id", { required: true })}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} md={2}>
                <TextField
                  id="rendering"
                  type="number"
                  placeholder="Rendering Nr"
                  label="Rendering*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("rendering", {
                    required: false,
                    pattern: /^\d+$/,
                    min: {
                      value: 1,
                      message: "Value must be greater than 0",
                    },

                  })}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.rendering
                      ? `${errors.rendering.message}`
                      : ""
                  }


                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 1 }}></Box>
            <Grid container spacing={2}>
              <Grid item xs={11} md={10}>
                <TextField
                  id="channel_description"
                  placeholder="Enter Channel Description"
                  label="Channel Description*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("channel_description", { required: true })}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box sx={{ display: "flex", mt: 1, mb: 0 }}>
                  {imageUrl ? (
                    <img width={200}
                      src={imageUrl}
                      style={{  height: 150, marginRight: 8, objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      style={{
                        width: 200,
                        height: 150,
                        marginRight: 8,
                        backgroundColor: 'darkgrey',
                        color: 'grey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      No Image
                    </Box>
                  )}
                  <Box>
                    <Button
                      size="medium"
                      color="primary"
                      component="label"
                      style={{ marginTop: 50, marginLeft: 8 }}
                    >
                      Upload
                      <input
                        type="file"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Box sx={{ display: "flex", mt: 0, paddingBlock: 2 }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pinProtected}
                          {...register("pin_protected")}
                          onChange={handleChangePinProtected}
                          name="pin_protected"
                        />
                      }
                      label="Pin Protected"
                    />
                  </Box>
                  <Box sx={{ marginLeft: 5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAvailable}
                          {...register("is_available")}
                          onChange={handleChangeIsAvailable}
                          name="is_available"
                        />
                      }
                      label="Is Available"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Divider />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CreateGenre />
              </Grid>
              <Grid item xs={6} md={5}>
                {genres && (
                  <Autocomplete
                    multiple
                    id="genres-tags"
                    options={genres as any}
                    getOptionLabel={(option: any) => option["name"]}
                    onChange={(event, value: any) => {
                      setSelectedGenres(value);
                      setDisabled(false);
                    }}
                    filterSelectedOptions
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Genre"
                        label="Select Genre*"
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={5}>
                {packages && (
                  <Autocomplete
                    multiple
                    id="packages-tags"
                    options={packages as any}
                    getOptionLabel={(option: any) => option["package_name"]}
                    onChange={(event, value: any) => setSelectedPackages(value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Package"
                        label="Select Package*"
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Box sx={{ pb: 4 }}></Box>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <Box onClick={() => setDisabled(false)}>
                  <EditStreams
                    editChannelStreams={getStateStreams}
                    initStreams={streamDetails}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Divider />
            </Box>
            <Button
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              disabled={disabled || disabledImg}
              onClick={handleSubmit(OnSubmit)}
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={5}></Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Create;
