import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Backdrop, IconButton, Grid, Divider, Button, TextField, TableCell, Dialog, DialogContent, TableBody, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { BASE_URL } from "../../../config";

interface Props {
    subscription_id: any;
}

const CancelSubscription: FC<Props> = ({ subscription_id }) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    //dialog open/close

    const [open, setOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);

    const handleClickOpen = async () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        refresh();
    };

    // cancel subscription

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<any>();
    const onSubmit: SubmitHandler<any> = (data) => {

        setOpen(false);
        setOpenBackDrop(true);

        dataProvider.create<any>(
            `subscriptions/zoho/cancelSubscription/${subscription_id}`,
            {
                data
            }
        ).then((response: any) => {
            notify("Your subscription was successfully canceled");
            handleClose();
            refresh();
            setOpenBackDrop(false);
        }).catch((error) => {
            notify(error.message, { type: 'warning' });
            handleClose();
            setOpenBackDrop(false);
        })
    }

    return (
        <Box>
            <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#ff000085", color: "white" }}
                type="submit"
                onClick={handleClickOpen}
            >
                Cancel
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}

                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ height: 150, padding: 50 }}>
                    <Typography component="span">Are you sure you want to cancel your subscription?</Typography>
                    <Typography component="span" sx={{ fontSize: 14, color: "grey", marginTop: 10 }}>{`Your subscription will remain active until the end of ${moment().format('MMMM YYYY')}`}</Typography>
                    <Box sx={{ marginTop: 20, textAlign: "center" }}>
                        <Button color="primary"
                            onClick={handleClose}
                        >
                            Back
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
                            Cancel Subscription
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Backdrop open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default CancelSubscription;