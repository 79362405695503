import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { Link } from "react-router-dom"

// import components
import TvPackages from '../../../../assets/images/iconsMenu/tvPackages.png';

interface Props {
    value?: number;
}

const Packages: FC<Props> = ({ value }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Link to="/packages" style={{ textDecoration: "none" }}>
            <Card sx={{ borderRadius: 10 }}>
                <Box sx={{ p: 4 }}>
                    <Box>
                        <img width={isXSmall ? 40 : 60} src={TvPackages} />
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: isXSmall ? 12 : 25 }}>Packages</Typography>
                        <Typography sx={{ fontSize: isXSmall ? 20 : 30 }}>{value !== 0 ? value : '0'}</Typography>
                    </Box>
                </Box>
            </Card>
        </Link>
    );
};

export default Packages;