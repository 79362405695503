import React, { FC, useState, useEffect } from 'react';
import { useNotify, useRefresh, useGetList, useDataProvider, TextInput, useListFilterContext } from 'react-admin';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { makeStyles, Theme, createStyles, Button, Dialog, Checkbox, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, Pagination, DialogActions, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { Box } from '@mui/system';

interface Props {
    selectedServices: []
}

const screenFilters = [
    <TextInput sx={{ mb: 2 }} label="Search" source="q" alwaysOn />,
];

const AssignDialog: FC<Props> = ({ selectedServices }) => {

    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const { filterValues } = useListFilterContext();

    const [properties, setProperties] = useState<any>([]);
    const [selectedProperties, setSelectedProperties] = useState<any>([]);

    const handleOpen = async () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [page, setPage] = React.useState(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    // const Property_name: string = filterValues.name;
    const [propertyName, setPropertyName] = useState("")

    const { data, total, isLoading, error, refetch } = useGetList(
        'properties/cd',
        {
            pagination: { page: page, perPage: 10 },
            sort: { field: 'title', order: 'ASC' },
            filter: { name: propertyName }
        },
    )
    useEffect(() => {
        setProperties(data);
    }, [])

    const handleSelectedProperties = (e: any, value: any) => {
        setSelectedProperties([...selectedProperties, value]);
        if (e.target.checked) {
            setSelectedProperties([...selectedProperties, value]);
        }
        else {
            setSelectedProperties(selectedProperties.filter((v: any) => v != value));
        }
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<any>();
    const onSubmit: SubmitHandler<any> = (data) => {
        const formData = new FormData();

        data.properties = selectedProperties;
        data.services = selectedServices;

        formData.append("services", JSON.stringify(data.services));
        formData.append("properties", JSON.stringify(data.properties));

        dataProvider.update(
            `services/assign/service`,
            {
                id: "111",
                data: formData,
                previousData: {
                    id: "111"
                }
            },
        ).then((response: any) => {
            notify('Your service was successfully assigned', { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box>
            <Box sx={{ pt: 1 }}>
                <Button onClick={handleOpen} variant="text" color="primary">
                    Assign
                </Button>
            </Box>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="xl"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={{ p: 6 }}>
                    <Box sx={{ pl: 2 }} width="30vh">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            fullWidth
                            variant="outlined"
                            label="Search Property"
                            type="text"
                            onChange={(e: any) => { setPropertyName(e.target.value) }}
                        />
                    </Box>
                    <List>
                        {data?.map((property: any) => (
                            <ListItem key={property["_id"]} >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(e: any) => { handleSelectedProperties(e, property["_id"]); }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={property["name"]} primary={property["name"]} />
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ display: "flex", justifyContent: 'center', alignContent: "center", marginTop: 1 }}>
                        {data?.length ?
                            <Pagination count={Math.ceil(total as number / 10)} page={page} onChange={handleChange} />
                            :
                            ''
                        }
                    </Box>
                    {data?.length ?
                        <>
                            <Box sx={{ padding: 2 }}>
                                <Divider />
                            </Box>
                            <DialogActions>
                                <Button
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!selectedProperties.length}
                                    onClick={handleSubmit(onSubmit)}
                                    sx={{ mb: -3 }}
                                >
                                    Assign
                                </Button>
                            </DialogActions>
                        </>
                        :
                        <Box sx={{ textAlign: "center" }}>
                            <HourglassEmptyRoundedIcon style={{ color: "grey" }} />
                            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any properties.</Typography>
                        </Box>
                    }
                </Box>
            </Dialog>
        </Box>
    )
}
export default AssignDialog;