import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useGetList } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar'

const SubscriptionChart = () => {

    const [chartData, setChartData] = useState<any>([]);

    var MONTHS: any = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    }

    const { data: analyticsActiveSubscription, total, isLoading, error, refetch } = useGetList(
        'analytics/active/subscriptions',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    React.useEffect(() => {
        analyticsActiveSubscription && analyticsActiveSubscription.forEach((record: any, index: number) => {
            record.month = MONTHS[index + 1];
            setChartData(analyticsActiveSubscription);
        })
    }, [analyticsActiveSubscription]);

    return (
        <>
            {analyticsActiveSubscription &&
                <Card sx={{ p: 5, height: 410, borderRadius: 10, mt: 2 }}>
                    {analyticsActiveSubscription?.length > 0 ?
                        <ResponsiveBar
                            data={chartData}
                            keys={["subscriptions"]}
                            indexBy="month"
                            colorBy='indexValue'
                            colors={{ scheme: 'accent' }}
                            theme={{
                                axis: {
                                    ticks: {
                                        text: {
                                            fill: "#ffffff"
                                        }
                                    },
                                    legend: {
                                        text: {
                                            fill: "#aaaaaa"
                                        }
                                    },
                                },
                                tooltip: {
                                    container: {
                                        background: 'white',
                                        color: 'black',
                                        fontSize: 'inherit',
                                        borderRadius: '2px',
                                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        padding: '5px 9px'
                                    }
                                },
                            }
                            }
                            margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
                            padding={0.60}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Month',
                                legendPosition: 'middle',
                                legendOffset: 32,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Total Number of Subscriptions',
                                legendPosition: 'middle',
                                legendOffset: -40,
                                format: (e: any) => Math.floor(e) === e && e
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="black"
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: "#ffffff",
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                        :
                        <Box style={{ position: 'absolute', left: '43%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
                            <Typography style={{ fontSize: 25, color: "grey" }}>No subscription data</Typography>
                        </Box>
                    }
                </Card>
            }
        </>
    );
}

export default SubscriptionChart;