import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Table, IconButton, TableContainer, TableHead, Button, TableRow, TableCell, Dialog, DialogContent, TableBody, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { BASE_URL } from "../../../config";

interface Props {
    subscription_id: string;
}

const InvoiceHistory: FC<Props> = ({ subscription_id }) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [invoices, setInvoices] = useState<any>([]);

    const getInvoices = async () => {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
        };

        fetch(`${BASE_URL}/api/v1/properties/zoho/listInvoices?subscription_id=${subscription_id}`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                setInvoices(response)
            });
    }

    const handleDownload = (id: any) => {
        setOpenBackDrop(true);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
        };

        fetch(`${BASE_URL}/api/v1/properties/zoho/retrieveInvoice/${id}`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                window.open(response.invoice.invoice_url);
                setOpenBackDrop(false);
            });
    }

    useEffect(() => {
        getInvoices();
    }, [subscription_id]);

    return (
        <Box>
            <Button
                variant="contained"
                size="small"
                color="primary"
                type="submit"
                onClick={handleClickOpen}
            >
                Invoice History
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                fullScreen
            >
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginLeft: 20,
                    marginTop: 20
                }}>
                    <IconButton onClick={handleClose} >
                        <KeyboardBackspaceOutlinedIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ height: 150, padding: 50 }}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invoice</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.length ?
                                    <>
                                        {invoices.map((invoice: any) => (
                                            <TableRow key={invoice["invoice_id"]}>
                                                <TableCell component="th" scope="row">
                                                    {invoice["number"]}
                                                </TableCell>
                                                <TableCell align="left">{moment(invoice["created_time"]).format("ll")}</TableCell>
                                                <TableCell align="left">{invoice["currency_symbol"]} {invoice["total"]}</TableCell>
                                                <TableCell align="left">{invoice["status"].toUpperCase()}</TableCell>
                                                <TableCell align="left"><Button onClick={() => handleDownload(invoice['invoice_id'])} size="small" variant="contained" color="primary">Invoice</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default InvoiceHistory;