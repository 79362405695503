export const darkTheme = {
    palette: {
        primary: {
            main: '#0db58b',
        },
        secondary: {
            main: '#FBBA72',
        },
        // background: {
        //     default: '#595959'
        // },
        mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    sidebar: {
        width: 240
    },
    components: {
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid #000',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #90caf9',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: 10
                },
                colorSecondary: {
                    color: '#ffffffb3',
                    backgroundColor: '#111',
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
              root: {
                  backgroundColor: "#222",
                  "& .RaDatagrid-headerCell": {
                      backgroundColor: "#333",
                      padding: 15
                  },
              }
           }
        }
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#4f3cc9',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
        mode: 'light' as 'light',
    },
    shape: {
        borderRadius: 0,
    },
    sidebar: {
        width: 240,
    },
    components: {
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid #fff',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #4f3cc9',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    border: '1px solid #eee',
                    backgroundClip: 'padding-box',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: 10
                },
                colorSecondary: {
                    color: '#4d4c4c',
                    backgroundColor: '#fff',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#f5f5f5',
                },
                barColorPrimary: {
                    backgroundColor: '#d7d7d7',
                },
            },
        },
    },
};


