import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, DialogContent, Divider, Button, Grid, List, Dialog, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useDataProvider, useNotify, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';

// import config
import { BASE_URL } from '../../config';

// components
import CreateProperty from './CreateProperty';

const ManageProperties: FC = () => {

    const notify = useNotify();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const { data: dataAllProperties, total, isLoading, error, refetch } = useGetList(
        'auth/all-properties',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const switchAccount: SubmitHandler<any> = (data: any) => {

        const formData: any = new FormData();

        formData.append("property_id", data['_id']);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/auth/switch-property`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                localStorage.setItem("access_token", response.accessToken);
                notify('Your property switched successfully', { type: "info" });
                handleClickClose();
            })
    }

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    const userId = decode['property_operator_id'];

    const { data: dataProperty, isLoading: loadingProperty, error: errorProperty } = useGetOne(
        'properties',
        {
            id: userId
        }
    );

    return (
        <>
            <Box sx={{ justifyContent: "flex-end", display: "flex", alignItems: "flex-end" }}>
                <Button onClick={handleClickOpen} variant="text">Manage Properties</Button>
            </Box>
            <Dialog
                onClose={handleClickClose}
                aria-labelledby="language-dialog"
                open={open}
                maxWidth={"md"}>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CreateProperty />
                    </Box>
                    {dataAllProperties?.map((property: any) => (
                        <Box>
                            <List>
                                <ListItem button onClick={() => switchAccount(property)}>
                                    <ListItemIcon><LocationCityIcon /></ListItemIcon>
                                    <ListItemText style={{ textTransform: "uppercase", }} primary={property['name']} />
                                    {dataProperty && dataProperty['id'] == property['id'] ? <span style={{ color: "gray" }}>Selected</span> : ""}
                                </ListItem>
                            </List>
                            <Divider />
                        </Box>
                    ))}
                </DialogContent>
            </Dialog >
        </>

    );
}

export default ManageProperties;