import React, { cloneElement, ReactElement, Fragment, useState } from 'react'
import {
    Datagrid,
    TopToolbar,
    List,
    FunctionField,
    DateField,
    ListProps,
    TextField,
    sanitizeListRestProps,
    useListContext,
    ExportButton,
    Button,
    ReferenceField,
    Pagination,
    ReferenceInput,
    downloadCSV,
    AutocompleteInput,
    SelectInput,
    Resource
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import CancelSubscription from './dialogs/CancelSubscription';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import BulkDelete from '../../components/BulkDelete';
import { useMediaQuery, Theme } from '@mui/material';

const choices = [
    { id: '7', name: '7 Days' },
    { id: '30', name: '30 Days' },
    { id: '90', name: '90 Days' },
    { id: '365', name: '1 Year' },
]

const subscriptionFilters = [
    <SelectInput sx={{ mb: 2 }} size="small" variant="outlined" source="days" choices={choices} alwaysOn emptyText="Lifetime" />,
    <ReferenceInput sx={{ mb: 2 }} variant="outlined" alwaysOn source="screen" reference="screens">
        <AutocompleteInput
            size="small"
            sx={{ mb: 2 }}
            optionText={(choice?: any) =>
                choice?.id
                    ? `${choice.screen_number}`
                    : ''
            }
        />
    </ReferenceInput>,
];

let dataaa = new Date();

const isSubscriptionActive = (endTime: any) => {
    if (moment() > moment(endTime)) return false
    else return true
}

const exporter = (records: any, fetchRelatedRecords: any) => {

    fetchRelatedRecords(records, "package", "packages/property/packages").then((pcg: any) => {
        fetchRelatedRecords(records, "screen", "screens").then((sc: any) => {

            let data: any = [];

            records.map((record: any) => {

                let x = moment(record?.end_time, 'YYYY-MM-DD').diff(moment(dataaa, 'YYYY-MM-DD'), 'days')

                data.push({
                    ...record,
                    package: pcg[record["package"]]["package_name"],
                    property: sc[record["screen"]]?.screen_number,
                    start_time: `${moment(record?.start_time).format("L")}`,
                    end_time: `${moment(record?.end_time).format("L")}`,
                    expires_in: (x > 0) ? x + " days" : "---",
                    status: (isSubscriptionActive(record["end_time"])) ? 'Active' : 'Expired',
                    updatedAt: `${moment(record?.updatedAt).format("L")}`,
                    screen: '',
                    property_operator_id: " ",
                    _id: " ",
                    __v: " ",
                    id: " ",
                })

            });

            return jsonExport(data, {
                headers: ['package', 'property', 'start_time', 'end_time', 'expires_in', 'status', 'createdAt', 'updatedAt',],
                rename: ["Packages Name", 'Property ', "Start Time", "End Time", 'Expires in', 'Status ', "Created At", ' Updated At', ' ', ' ', ' ', ' ', ' '],
            }, (err: any, csv: any) => {
                downloadCSV(csv, 'subscriptions');
            });

        })
    })


};

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
                selectedIds: ''
            })}
            <ExportButton />
        </TopToolbar>
    );
};

const Empty = () => {
    const { resource } = useListContext();

    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box style={{ marginTop: 10 }}>
                <Button href={"/#/subscriptions"} label="Go to Subscription"></Button>
            </Box>
        </Box>
    );
};

// const BulkActionButtons = (props: any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );
interface ResourceListProps {
    screenID: any;
  }

  const ResourceList: React.FC<ResourceListProps> = ({ screenID }) => {
    console.log('Received screenID:', screenID);
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const filter = screenID ? { screen: screenID } : {};

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    filters={subscriptionFilters}
                    title="Subscriptions"
                    perPage={25}
                    exporter={exporter}
                    // bulkActionButtons={<BulkActionButtons />}
                    empty={<Empty />}
                    actions={<ListActions />}
                    filter={{ screenID: screenID }}
                >
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Screen" source="screen" reference="screens">
                            <TextField source="screen_number" />
                        </ReferenceField>
                        <FunctionField
                            label="Status"
                            render={(record: any) => {
                                if (isSubscriptionActive(record.end_time)) {
                                    return (
                                        <Typography sx={{ color: "#83c583", fontSize: 12, p: 2 }}>Active</Typography>
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: "#cd5555", fontSize: 12, p: 2 }}>Expired</Typography>
                                    )
                                }
                            }}
                        />
                    <FunctionField
                            render={(record: any) => {
                                if (record) {
                                    return (
                                        <CancelSubscription id={record._id} />
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>
                :
                <List
                    filters={subscriptionFilters}
                    title="Subscriptions"
                    perPage={25}
                    exporter={exporter}
                    // bulkActionButtons={<BulkActionButtons />}
                    empty={<Empty />}
                    actions={<ListActions />}
                >
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Screen" source="screen" reference="screens">
                            <TextField source="screen_number" />
                        </ReferenceField>
                        <ReferenceField label="Package" source="package" reference="packages/property/packages">
                            <TextField source="package_name" />
                        </ReferenceField>
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="start_time" title="Start Time" />
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="end_time" title="End Time" />
                        <FunctionField
                            label="Status"
                            render={(record: any) => {
                                if (isSubscriptionActive(record.end_time)) {
                                    return (
                                        <Typography sx={{ color: "#83c583", fontSize: 12, p: 2 }}>Active</Typography>
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: "#cd5555", fontSize: 12, p: 2 }}>Expired</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="Expires in"
                            render={(record: any) => {
                                let x = moment(record?.end_time, 'YYYY-MM-DD').diff(moment(dataaa, 'YYYY-MM-DD'), 'days')
                                return (
                                    <Box>
                                        <Typography sx={{ color: x > 0 ? "#83c583" : "#cd5555", fontSize: 14, fontFamily: "sans-serif" }}>
                                            {(x > 0) ? x + " days" : "---"}
                                        </Typography>
                                    </Box>
                                )
                            }}
                        />
                         <FunctionField
                            render={(record: any) => {
                                if (record && isSubscriptionActive(record.end_time)) {
                                    return (
                                        <CancelSubscription id={record._id} />
                                    );
                                } else {
                                    return null; 
                                }
                            }}
                        />
                    </Datagrid>
                </List>
            }
        </Box>
    )

}


export default ResourceList;
