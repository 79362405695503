import React, { FC } from 'react';
import { useGetOne, useNotify, useRefresh } from 'react-admin';
import { Button, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { BASE_URL } from '../../config';

interface Props {
	id: string
}

const SwitchtoPropertyCd: FC<Props> = ({ id }) => {

	const notify = useNotify();
	const refresh = useRefresh();
	const navigate = useNavigate();

	const token = localStorage.getItem("access_token");

	const handleClickOpen = () => {

		const formData: any = new FormData();

		formData.append("property_id", id)

		const requestOptions = {
			method: "POST",
			headers: {
				'Authorization': `Bearer ${token}`
			},
			body: formData
		};

		fetch(`${BASE_URL}/api/v1/auth/switch-property/cd`, requestOptions)
			.then(response => response.json())
			.then((response) => {
				localStorage.setItem("access_token", response.accessToken);
				notify('Your property switched successfully', { type: "info" });
				refresh();
				navigate("/");
			})

	}

	return (
		<Box>
			<Button sx={{ ml: 2, color: "orange" }} onClick={handleClickOpen} variant="text">Login</Button>
		</Box>
	)
}

export default SwitchtoPropertyCd