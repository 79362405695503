import React, { useState, useEffect, useCallback, FC, CSSProperties, useRef } from 'react';
import { useTranslate, Title, useDataProvider, useRefresh, useNotify, useLogout, useGetOne, useGetList, useUpdate } from 'react-admin';
import { ListItem, List, ListItemAvatar, Grid, TextField, Avatar, Divider, ListItemText, Slider, Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useMediaQuery, Theme } from '@mui/material';

// import components
import PlacesAutocomplete from './PlacesAutocomplete';
import HomeScreenPreview from './HomeScreenPreview';

// configs
import { BASE_ASSETS_URL } from '../../config';

// import types
import { AddLatLong } from '../../types/LatLong';

type Inputs = {
    reception_number: string,
    wifi_password: string,
    opacity_background: number,
    property_logo_path: object,
    property_app_background_path: object,
    property_video_path: object
};

const AppPersonalization = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const { data: dataProperties, refetch } = useGetOne(
        'properties/personalization',
        {
            id: ''
        }
    );

    const [open, setOpen] = useState<boolean>(false);
    const [latLong, setLatLong] = useState<any>({ lat: 0, lng: 0 });

    const getLatLong: AddLatLong = (data: any) => {
        setLatLong(data);
        setDisabled(false);
    }

    const [imageToCrop, setImageToCrop] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<any>();

    const cropperRef = useRef<HTMLImageElement>(null);
    const onCrop = () => {
        setOpen(true);
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    const [propertyLogoImageUrl, setPropertyLogoImageUrl] = useState("");
    const [propertyLogoImage, setPropertyLogoImage] = useState({});

    const changePropertyLogoImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageToCrop(url);
        setOpen(true);
    }

    const [propertyAppBackgroundImageUrl, setPropertyAppBackgroundImageUrl] = useState("");
    const [propertyAppBackgroundImage, setPropertyAppBackgroundImage] = useState({});

    const changePropertyAppBackgroundImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setPropertyAppBackgroundImageUrl(url);
        setPropertyAppBackgroundImage(e.target.files[0]);
        setDisabled(false);
    }

    const [propertyVideoUrl, setPropertyVideoUrl] = useState("");
    const [propertyVideo, setPropertyVideo] = useState({});

    const changePropertyVideo = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setPropertyVideoUrl(url);
        setPropertyVideo(e.target.files[0]);
        setDisabled(false);
    }

    const dataURItoBlob = (dataURI: any) => {

        let byteString;

        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else
            byteString = unescape(dataURI.split(',')[1]);

        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ia = new Uint8Array(byteString.length);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const handleCrop = () => {
        const blob = dataURItoBlob(croppedImage);
        const url = URL.createObjectURL(blob);
        setPropertyLogoImageUrl(url);
        setPropertyLogoImage(blob);
        setDisabled(false);
        setOpen(false);
    }

    const [disabled, setDisabled] = useState<boolean>(true);
    const [opacity, setOpacity] = useState("");

    const opacityChange = (event: any, newValue: any) => {
        setOpacity(`${newValue}`);
        setDisabled(false);
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {

        const formData: any = new FormData();

        data.property_logo_path = propertyLogoImage;
        data.property_app_background_path = propertyAppBackgroundImage;
        data.property_video_path = propertyVideo;
        data.opacity_background = opacity;
        data.latitude = latLong['lat'];
        data.longitude = latLong['lng'];

        formData.append("reception_number", data.reception_number);
        formData.append("wifi_password", data.wifi_password);
        if (Object.keys(data.property_logo_path).length === 0 && data.property_logo_path.constructor === Object === false) {
            formData.append("property_logo_path", data.property_logo_path);
        }
        if (Object.keys(data.property_app_background_path).length === 0 && data.property_app_background_path.constructor === Object === false) {
            formData.append("property_app_background_path", data.property_app_background_path);
        }
        if (Object.keys(data.property_video_path).length === 0 && data.property_video_path.constructor === Object === false) {
            formData.append("property_video_path", data.property_video_path);
        }
        formData.append("opacity_background", data.opacity_background);
        formData.append("latitude", data.latitude);
        formData.append("longitude", data.longitude);

        dataProvider.update(
            'properties/personalization',
            {
                id: '',
                data: formData,
                previousData: {
                    id: ''
                }
            }
        ).then((response) => {
            notify('Your home screen was successfully updated', { type: "success" });
            refresh();
        }).catch((error) => {
            notify(error.message, { type: "warning" });
        })

    }

    const receptionNumber = watch("reception_number", "");
    const wifi = watch("wifi_password", "");

    React.useEffect(() => {
        if (dataProperties) {
            setOpacity(`${dataProperties.opacity_background}`);
            setValue("reception_number", dataProperties.reception_number);
            setValue("wifi_password", dataProperties.wifi_password);
        }
    }, [dataProperties])

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <Box>
            {dataProperties &&
                <Box sx={{ pt: 10, pl: 5, pr: 5 }}>
                    <Grid container spacing={6}>
                        <Grid item md={5}>
                            <form style={{ marginTop: 5 }} onSubmit={handleSubmit(OnSubmit)}>
                                {dataProperties.type == 'hotel' &&
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}>
                                                <TextField
                                                    id="reception_number"
                                                    label="Reception Number"
                                                    placeholder="Reception Number"
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText="No more than 20 characters are allowed"
                                                    inputProps={{ maxLength: 20 }}
                                                    {...register("reception_number")}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    id="wifi_password"
                                                    label="WiFi Password"
                                                    placeholder="WiFi Password"
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 20 }}
                                                    helperText="No more than 20 characters are allowed"
                                                    {...register("wifi_password")}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Box sx={{ p: 2 }}></Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={5}>
                                        <PlacesAutocomplete addLatLong={getLatLong} latitude={dataProperties.latitude ? dataProperties.latitude : 1.32754594} longitude={dataProperties.longitude ? dataProperties.longitude : 19.8186982} />
                                    </Grid>
                                </Grid>
                                <Box sx={{ p: 2 }}></Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} md={7}>
                                        {opacity &&
                                            <Slider
                                                aria-labelledby="discrete-slider"
                                                valueLabelDisplay="auto"
                                                onChange={opacityChange}
                                                defaultValue={dataProperties.opacity_background ? dataProperties.opacity_background : parseInt(`${opacity}`)}
                                                step={10}
                                                marks
                                                min={60}
                                                max={100}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Box sx={{ p: 2, mt: 1 }}>
                                    <Divider />
                                </Box>
                                <List>
                                    <ListItem
                                        secondaryAction={
                                            <Button sx={{ p: 1 }} component="label" color="primary" variant="text" size="small" startIcon={<CloudUploadIcon />}>
                                                Add Logo
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={changePropertyLogoImage}
                                                    hidden
                                                />
                                            </Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                            {propertyLogoImageUrl || dataProperties.property_logo_path ?
                                                <Avatar variant="rounded">
                                                    <img width="30" src={propertyLogoImageUrl || `${BASE_ASSETS_URL}${dataProperties.property_logo_path}`} />
                                                </Avatar>
                                                :
                                                <Avatar>L</Avatar>
                                            }
                                        </ListItemAvatar>
                                        {isXSmall
                                            ?
                                            <ListItemText
                                                primary="Upload Logo"
                                            />
                                            :
                                            <ListItemText
                                                primary="Upload Logo"
                                                secondary={
                                                    <Box>
                                                        <Typography sx={{ color: "white", fontSize: 11 }}>Upload or change your property logo</Typography>
                                                        <Typography sx={{ color: "grey", fontSize: 11 }}>Suggested size 400px x 400px</Typography>
                                                        <Typography sx={{ color: "grey", fontSize: 11, textAlign: "left", }}>Supported file types .jpg, .png </Typography>
                                                    </Box>

                                                }
                                            />
                                        }
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            <Box>
                                                <Button sx={{ p: 1 }} component="label" variant="text" size="small" color="primary" startIcon={<CloudUploadIcon />}>
                                                    Add Background
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            changePropertyAppBackgroundImage(e);
                                                        }}
                                                        hidden
                                                    />
                                                </Button>
                                                {/* <Typography sx={{ color: "grey", fontSize: 13,textAlign: "center",mt:-2 }}>Background </Typography> */}
                                            </Box>
                                        }
                                    >
                                        <ListItemAvatar>
                                            {propertyAppBackgroundImageUrl || dataProperties.property_app_background_path ?
                                                <Avatar variant="rounded">
                                                    <img width="100" src={propertyAppBackgroundImageUrl || `${BASE_ASSETS_URL}${dataProperties.property_app_background_path}`} />
                                                </Avatar>
                                                :
                                                <Avatar>B</Avatar>
                                            }
                                        </ListItemAvatar>
                                        {isXSmall
                                            ?
                                            <ListItemText
                                                primary="Upload Background"
                                            />
                                            :
                                            <ListItemText
                                                primary="Upload Background"
                                                secondary={
                                                    <Box>
                                                        <Typography sx={{ color: "white", fontSize: 11 }}>Upload or change your app background</Typography>
                                                        <Typography sx={{ color: "grey", fontSize: 11 }}>Suggested size 2000px x 1300px</Typography>
                                                        <Typography sx={{ color: "grey", fontSize: 11, textAlign: "left", }}>Supported file types .jpg, .png </Typography>
                                                    </Box>

                                                }
                                            />
                                        }

                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            <Box>
                                                <Button sx={{ p: 1 }} component="label" variant="text" size="small" color="primary" startIcon={<CloudUploadIcon />}>
                                                    Add Video
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            changePropertyVideo(e);
                                                        }}
                                                        hidden
                                                    />
                                                </Button>
                                            </Box>
                                        }
                                    >
                                        <ListItemAvatar>
                                            {propertyVideoUrl || dataProperties.property_video_path ?
                                                <Avatar variant="rounded">
                                                    <video autoPlay loop muted width="100">
                                                        <source src={propertyVideoUrl || `${BASE_ASSETS_URL}${dataProperties.property_video_path}`} type="video/mp4" />
                                                    </video>
                                                </Avatar>
                                                :
                                                <Avatar>V</Avatar>
                                            }
                                        </ListItemAvatar>
                                        {isXSmall
                                            ?
                                            <ListItemText
                                                primary="Upload Video"

                                            />
                                            :
                                            <ListItemText
                                                primary="Upload Video"
                                                secondary={
                                                    <Box>
                                                        <Typography sx={{ color: "white", fontSize: 11 }}>Upload or change your video presentation</Typography>
                                                        <Typography sx={{ color: "grey", fontSize: 11, textAlign: "left", }}>Video should not be more than 10MB </Typography>
                                                    </Box>
                                                }
                                            />
                                        }

                                    </ListItem>
                                </List>
                                <Box sx={{ padding: 4 }}>
                                    <Divider />
                                </Box>
                                <Button
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!isDirty && disabled}
                                    onClick={handleSubmit(OnSubmit)}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Grid>
                        {
                            isXSmall ?
                                ""
                                :
                                <Grid item xs={12} md={7}>
                                    <HomeScreenPreview
                                        homepageDetails={dataProperties as any}
                                        logoLive={propertyLogoImageUrl}
                                        backgroundLive={propertyAppBackgroundImageUrl}
                                        videoLive={propertyVideoUrl}
                                        background_opacity={parseInt(`${opacity}`)}
                                        wifi={wifi}
                                        phone={receptionNumber}
                                        latitude={latLong["lat"]}
                                        longitude={latLong["lng"]}
                                    />
                                </Grid>
                        }
                    </Grid>
                </Box>
            }

            {open && <Dialog open={open}>
                <DialogContent style={{ padding: 10 }}>
                    <Box>
                        <Cropper
                            src={imageToCrop}
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={16 / 9}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                        />
                    </Box>

                </DialogContent>
                <DialogActions sx={{ padding: 1 }}>
                    <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                    <Button
                        onClick={handleCrop}
                        variant="contained"
                    >
                        Crop
                    </Button>
                </DialogActions>
            </Dialog>}

        </Box>
    )
}

export default AppPersonalization;
