import React, { cloneElement, ReactElement, Fragment } from 'react';
import {
    Datagrid,
    List,
    ListProps,
    TextField,
    EditButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    Pagination,
    FunctionField,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    FilterButton,
    CreateButton,
    ExportButton,
    BooleanField,
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { Box, makeStyles, Typography } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import off from './../../assets/images/icons/off.png'
import on from './../../assets/images/icons/on.png'

const devicePropertyFilters = [
    <TextInput sx={{ mb: 2 }} source="q" variant="outlined" label="Search" alwaysOn />
];

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    const dataProvider = useDataProvider();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const Empty = () => {
    const { resource } = useListContext();

    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any devices</Typography>
        </Box>
    );
};

const BulkActionButtons = (props: any) => (
    <Fragment>
        <BulkDelete {...props} />
    </Fragment>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <Box sx={{ pl: 4, pr: 3, pt: 4 }}>
            <List
                title="Devices"
                filters={devicePropertyFilters}
                empty={<Empty />}
                pagination={<ListPagination />}
                {...props}
                bulkActionButtons={<BulkActionButtons />}
                perPage={25}
            >
                <Datagrid>
                    <FunctionField
                        label="Status"
                        render={(record: any) => {
                            const status = record.device_active;
                            // const color = record.status === 'on' ? 'green' : 'red';
                            const iconLink =
                                status
                                    ? on // url e images importuar ne krye
                                    : off;

                            return (
                                <div>
                                    {/* <Typography sx={{ p: 2, color }}>{status}</Typography> */}
                                    {/* <a href={iconLink} title={`${status} icons created by Pixel perfect - Flaticon`}> */}
                                        <img src={iconLink} width="35" height="30" />
                                    {/* </a> */}
                                </div>
                            );
                        }}
                    />
                    <FunctionField
                        label="MAC Address"
                        render={(record: any) => {
                            if (record.device_mac_address) {
                                return <Typography sx={{ p: 2 }}>{record.device_mac_address}</Typography>;
                            }
                        }}
                    />
                   <FunctionField
                        label="IP Address"
                         render={(record: any) => {
                        if (record.device_ip) {
                        return <Typography sx={{ p: 2 }}>{record.device_ip}</Typography>;
                        }
                    }}
                    />
                    <BooleanField label="Active" source="device_active" />
                    <BooleanField label="Mobile" source="activated" />
                    <TextField label="Operating System" source="os" />
                    <TextField label="Screen Resolution" source="screen_resolution" />
                    <TextField label="Device Brand" source="device_brand" />
                    <TextField label="App Version" source="app_version" />
                    <EditButton />
                </Datagrid>
            </List>
        </Box>
    );
};
export default ResourceList;