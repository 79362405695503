import * as React from 'react';
import { FC } from 'react';
import {
    useListContext,
    DatagridProps,
    Identifier,
} from 'react-admin';
import { Typography, Avatar, Chip, Button, Card, CardActions, CardContent, Grid, Box, Divider } from '@mui/material';
import RemoveUserDialog from './dialogs/RemoveUserDialog';

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: any) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 5;
};

const LoadedGridList: FC<DatagridProps> = () => {
    const { data } = useListContext();
    if (!data) return null;

    return (
        <Box sx={{ pl: 5, pt: 2 }}>
            <Grid container spacing={2}>
                {data.map((record: any, index: any) => (
                    <Grid item md={2} key={index}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", borderRadius: 10, minHeight: 200 }}>
                            <CardContent>
                                <Box sx={{ marginTop: 2, marginBottom: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Avatar alt="User" sx={{ width: 70, height: 70, color: "white", backgroundColor: "orange" }}>{record.fullName ? record.fullName.split(/\s/).reduce((response: any, word: any) => response += word.slice(0, 1), '') : record?.email?.charAt(0).toUpperCase()}</Avatar>
                                </Box>
                                <Typography sx={{ fontSize: 14, p: 1, textAlign: "center", m: 0 }} color="textSecondary" gutterBottom>
                                    {record.fullName ? record.fullName : record.email}
                                </Typography>
                                <Typography sx={{ fontSize: 10, textAlign: "center", pb: 0 }} color="textSecondary" gutterBottom>
                                    {record.email}
                                </Typography>
                                <Box sx={{ pt: 3, pb: 2 }}>
                                    <Divider />
                                </Box>
                                <Box sx={{ textAlign: "center", pt: 0, textTransform: "uppercase", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Chip size="medium" label={record.role} />
                                    <Box sx={{ pb: 2 }}></Box>
                                    {(record['role'] !== 'owner') && <RemoveUserDialog user={record} />}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const GridList = () => {
    return <LoadedGridList />
};

export default (GridList);