import React, { FC, useState, useEffect, useCallback } from 'react';
import { useUnselectAll, BulkActionProps, useNotify, useRefresh, Identifier, useUpdateMany, useDataProvider, useDeleteMany, useGetOne, DeleteButton } from 'react-admin';
import { makeStyles, createStyles, Stepper, Theme, Checkbox, Step, StepLabel, StepContent, Grid, Dialog, FormLabel, FormControlLabel, InputLabel, Divider, MenuItem, FormControl, Typography, Select, Button, DialogContent, TextField, DialogActions, DialogTitle, Box } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

const noSelection: Identifier[] = [];

interface Props {
    resource: string,
}

const BulkUpgrade: FC<BulkActionProps> = ({ selectedIds = noSelection, resource }) => {

    const notify = useNotify();
    const unselectAll = useUnselectAll(resource as any);
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    //dialog open/close

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        unselectAll();
        refresh();
    };

    const handleUpgradeMany = () => {

        const formData: any = new FormData();

        formData.append("ids", JSON.stringify(selectedIds));

        dataProvider.update(
            'devices/flag',
            {
                id: '',
                data: formData,
                previousData: {
                    id: ''
                }
            },
            ).then((response: any) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Selected devices were successfully upgraded', { type: "info" });
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    return (
        <Box sx={{paddingLeft: 0}}>
            <Button onClick={handleClickOpen} style={{ marginRight: 10 }} variant="contained" color="primary">Upgrade</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Upgrade Confirmation</DialogTitle>
                <DialogContent>
                    <Typography style={{ marginBottom: 12 }}>Are you sure you want to upgrade the selected devices?</Typography>
                </DialogContent>
                <DialogActions style={{ padding: 25 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleUpgradeMany}
                        variant="contained"
                        color="primary"
                    >
                        Upgrade
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}

export default BulkUpgrade;