import React, { FC, useState } from 'react';
import {
  useNotify,
  useRefresh,
  Title,
} from 'react-admin';
import {
  Grid,
  Divider,
  Button,
  TextField,
  Box,
} from '@mui/material';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dataProvider from '../../providers/data-provider';

type Inputs = {
  channel_name: string;
  programm_name: string;
  description: string;
  imageUrl: string;
};

const CreateEPGMetadata: FC = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm<Inputs>();

 const onSubmit: SubmitHandler<Inputs> = (data:any) => {
   
    const formData: any = new FormData();

    // data.channel_name =  channel_name;
    // data.programm_name =  programm_name ;
    // data.description =  description ;
    // data.imageUrl = imageUrl ;

    formData.append("data", JSON.stringify({
        "channel_name": data.channel_name,
        "programm_name": data.programm_name,
        "description": data.description,
        "imageUrl": data.imageUrl,
    }));

    formData.append("type", "epgmetadata")

    dataProvider.create(
        'trial-data',
        {
            data: formData,
        },
    ).then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
        notify(' successfully created');
        navigate("/dummy-data");
    }).catch((error: any) => {
      console.error("Error:", error);
        notify(error.message, { type: "warning" });
    })
  };

  return (
    <Box sx={{ pl: 5, pt: 10 }}>
      <Title title="Create EPG Metadata" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={9} md={4}>
            <TextField
              id="channel_name"
              InputLabelProps={{ shrink: true }}
              label="Channel Name*"
              placeholder="Enter Channel Name"
              {...register('channel_name', { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pl: 5, pt: 5 }}/>
        <Grid container spacing={3}>
          <Grid item xs={9} md={4}>
            <TextField
              id="programm_name"
              InputLabelProps={{ shrink: true }}
              label="Programm Name*"
              placeholder="Enter Program Name"
              {...register('programm_name', { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={11} md={8}>
            <TextField
              id="description"
              InputLabelProps={{ shrink: true }}
              label="Description*"
              placeholder="Enter EPG Description"
              {...register('description', { required: true })}
              variant="outlined"
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={11} md={8}>
            <TextField
              id="imageUrl"
              InputLabelProps={{ shrink: true }}
              label="Image URL*"
              placeholder="Enter Image URL"
              {...register('imageUrl', { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 0, pb: 4 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          // onClick={handleSubmit(onSubmit)}
        >
         Submit
        </Button>
      </form>
    </Box>
  );
};

export default CreateEPGMetadata;
