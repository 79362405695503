import { ReactElement } from 'react';
import { FC } from 'react';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    ListBase,
    Pagination,
    TextField,
    EditButton,
    TopToolbar,
    CreateButton,
    useListContext,
    Title,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { Chip, Typography, Box, Avatar, Card, CardActionArea, Grid, makeStyles } from '@mui/material';

//import types
// import theme from '../../types/Theme';

import GridList from './GridList';

const deviceMenuFilters = [
    <ReferenceInput sx={{ mb: 2, ml: 5 }} variant="outlined" alwaysOn source="property_operator_id" reference="properties/cd" helperText="hello" label="Author">
        <AutocompleteInput
            size="small"
            sx={{ mb: 2 }}
            optionText={(choice?: any) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
        />
    </ReferenceInput>
];


const ListActions: FC<any> = () => (
    <TopToolbar>
        <CreateButton resource="/device-menu" label="Create" />
    </TopToolbar>
);

const Empty = () => {
    const { resource } = useListContext();
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <div style={{ marginTop: 10 }}>
                <CreateButton resource={resource} />
            </div>
        </div>
    );
};

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <Box sx={{ pl: 5 }}>
            <Title title="Device Menu" />
            <ListBase
                title="Device Menu's"
                empty={<Empty />}
                filter={deviceMenuFilters}
                {...props}
                perPage={25}
                
            >
                <ListActions />
                <GridList />
                <Pagination rowsPerPageOptions={[25, 50, 100]} />
            </ListBase>
        </Box>
    )
}
export default ResourceList;