import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ReactElement,
} from "react";
import {
  Title,
  useNotify,
  useRefresh,
  useGetOne,
  useTranslate,
  Notification,
  useDataProvider,
  ImageInput,
  ImageField,
  ListBase,
  ListProps,
  SimpleList,
  Identifier,
  DatagridProps,
  useListContext,
  Datagrid,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  FormControl,
  Theme,
  Grid,
  Typography,
  Switch,
  InputLabel,
  TextField,
  Divider,
  Avatar,
  Button,
  FormControlLabel,
  Checkbox,
  Tab,
  Autocomplete,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import uniqid from 'uniqid';
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";

type Inputs = {
  device_active: boolean;
  mac_address: string;

  activated: boolean;
};

const Edit: FC = () => {
  const [activated, setActivated] = React.useState<boolean>(false);

  const handleChangeActivated = (event: any) => {
    setActivated(event.target.checked);
    setDisabled(!event.target.checked);
  };

  const [activate_mobile, setActivate_mobile] = React.useState<boolean>(false);
  const handleChangeActivateMobile = (event: any) => {
    setActivate_mobile(event.target.checked);
    setDisabled(!event.target.checked);
  };
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { id } = useParams();
  const [activeDevice, setActiveDevice] = useState<boolean>(false);
  const [upgradeFlag, setUpgradeFlag] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleChangeActiveDevice = (event: any) => {
    setActiveDevice(event.target.checked);
    setDisabled(false);
  };

  const handleChangeUpgradeFlag = (event: any) => {
    setUpgradeFlag(event.target.checked);
    setDisabled(false);
  };

  //fetch device details
  const {
    data: dataDevice,
    isLoading,
    error,
    refetch,
  } = useGetOne("devices", {
    id: id,
  });

  useEffect(() => {
    setActiveDevice(dataDevice?.device_active);
    setValue("device_active", dataDevice?.device_active);
    setValue("mac_address", dataDevice?.mac_address);
  }, [dataDevice]);

  const [generatedId, setGeneratedId] = useState<string>("");
  const [initialMacAddress, setInitialMacAddress] = useState<any>("");
  const [inputs, setInputs] = useState<Inputs>({
    device_active: true,
    mac_address: "",

    activated: true, // Add the activated property here
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const formData: any = new FormData();
    const devicedata = {
      device_active: data.device_active,
    };

    // formData.append("device_active", data.device_active);
    // formData.append('mac_address', data.mac_address);
    dataProvider
      .update("devices", {
        id: `${id}`,
        data: devicedata,
        previousData: {
          id: id,
        },
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your device was successfully updated", { type: "info" });
        navigate("/devices/property");
      })
      .catch((error: any) => {
        console.error("Update error:", error);
      });
  };

  return (
    <Box sx={{ p: 5 }}>
      <Title title="Edit Device" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* ...other form fields */}
        <Grid container spacing={3}>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={activeDevice}
                  {...register("device_active")}
                  onChange={handleChangeActiveDevice}
                  color="primary"
                  name="device_active"
                />
              }
              label="Active Device"
            />
          </Grid>
          {/* <div>{generatedId}</div> */}
        </Grid>
        {/* ...other form elements */}
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Edit;
