import * as React from 'react';
import { styled } from '@mui/material/styles';
import { memo, isValidElement, ReactElement } from 'react';
import {
    IconButton,
    ListItem,
    ListItemButton,
    ListItemProps,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useTranslate, useListFilterContext, shallowEqual } from 'ra-core';
import matches from 'lodash/matches';
import pickBy from 'lodash/pickBy';

const arePropsEqual = (prevProps: any, nextProps: any) =>
    prevProps.label === nextProps.label &&
    shallowEqual(prevProps.value, nextProps.value);

export const FilterListItem = memo((props: FilterListItemProps) => {
    const { label, value, ...rest } = props;
    const { filterValues, setFilters } = useListFilterContext();
    const translate = useTranslate();

    const isSelected = matches(
        pickBy(value, val => typeof val !== 'undefined')
    )(filterValues);

    const addFilter = () => {
        setFilters({ ...filterValues, ...value }, null, false);
    };

    const removeFilter = () => {
        const keysToRemove = Object.keys(value);
        const filters = Object.keys(filterValues).reduce(
            (acc, key) =>
                keysToRemove.includes(key)
                    ? acc
                    : { ...acc, [key]: filterValues[key] },
            {}
        );

        setFilters(filters, null, false);
    };

    const toggleFilter = () => (addFilter());

    return (
        <StyledListItem
            onClick={toggleFilter}
            selected={isSelected}
            disablePadding
            {...rest}
        >
            <ListItemButton
                disableGutters
                sx={{ p: 2 }}
            >
                <ListItemText
                    primary={
                        isValidElement(label)
                            ? label
                            : translate(label, { _: label })
                    }
                    className={FilterListItemClasses.listItemText}
                    data-selected={isSelected ? 'true' : 'false'}
                />
            </ListItemButton>
        </StyledListItem>
    );
}, arePropsEqual);

const PREFIX = 'RaFilterListItem';

export const FilterListItemClasses = {
    listItemButton: `${PREFIX}-listItemButton`,
    listItemText: `${PREFIX}-listItemText`,
};

const StyledListItem = styled(ListItem, {
    name: PREFIX,
    overridesResolver: (props: any, styles) => styles.root,
})(({ theme }) => ({
    [`& .${FilterListItemClasses.listItemButton}`]: {
        paddingRight: '2em',
        paddingLeft: '2em',
    },
    [`& .${FilterListItemClasses.listItemText}`]: {
        margin: 0,
    },
}));

export interface FilterListItemProps extends Omit<ListItemProps, 'value'> {
    label: string | ReactElement;
    value: any;
}
