import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning, useGetOne, useUpdate } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, FormControlLabel, Checkbox, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box, Chip } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import types
// import { AppState } from '../../types/Theme';

type Inputs = {
    email: string,
    address: string,
    phone: string,
    role: string,
    fullName: string
};

const AccountDetails: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [dataRole, setDataRole] = useState<string>("")

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);
    const id = decode['_id'];


    const { data, isLoading, error, refetch } = useGetOne(
        'users',
        {
            id: id
        },
    );

    useEffect(() => {
        if (data) {
            setDataRole(data.role)
            setValue("email", data?.email);
            setValue("address", data?.address);
            setValue("phone", data?.phone);
            setValue("fullName", data?.fullName);
            // setValue("role", data?.role);
        }
    }, [data])

    // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        formData.append("email", data.email);
        formData.append("address", data.address);
        formData.append("phone", data.phone);
        formData.append("fullName", data.fullName);

        dataProvider.update(
            'users',
            {
                id: id,
                data: formData,
                previousData: {
                    id: id
                }
            },
        ).then((response: any) => {
            notify('Account details were successfully updated', { type: "info" });
            navigate("/")
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 6, pt: 10 }}>
            <Title title="Account Details" />
            <Box sx={{ padding: 0 }}>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                id="fullName"
                                type="text"
                                label="Full Name"
                                placeholder="Enter Full Name"
                                InputLabelProps={{ shrink: true }}
                                {...register("fullName", { required: true })}
                                variant="outlined"
                                fullWidth
                            />

                        </Grid>
                    </Grid>
                    <Box sx={{ pt: 3 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                id="email"
                                type="email"
                                label="Email"
                                placeholder="Enter Email"
                                InputLabelProps={{ shrink: true }}
                                {...register("email", {
                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ pt: 3 }}></Box>
                    <Grid container spacing={2} >
                        <Grid item md={2}>
                            <TextField
                                id="address"
                                type="address"
                                label="Address"
                                placeholder="Enter Address"
                                InputLabelProps={{ shrink: true }}
                                {...register("address")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="phone"
                                type="phone"
                                label="Phone"
                                placeholder="Enter Phone Number"
                                InputLabelProps={{ shrink: true }}
                                {...register("phone")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ pt: 2 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            {/* <TextField
                                id="role"
                                type="role"
                                label="Role"
                                disabled
                                style={{ marginTop: 10 }}
                                InputLabelProps={{ shrink: true }}
                                {...register("role")}
                                variant="outlined"
                                fullWidth
                            /> */}
                            <Chip
                                id="role"
                                label={dataRole}
                                sx={{ p: 2 }}
                            />
                            <Box sx={{ pt: 3 }}>
                                <Divider />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3} style={{ marginTop: 20 }}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!isDirty}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default AccountDetails;