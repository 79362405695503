import React, { ReactElement, Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import {
  Datagrid,
  List,
  TextField,
  ListProps,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  downloadCSV,
  useListContext,
} from 'react-admin';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import jsonExport from 'jsonexport/dist';

const PropertyFilters = [
  <TextInput sx={{ mb: 2 }} source="q" variant="outlined" label="Search" alwaysOn />
];

const Empty = () => {
  const { resource } = useListContext();
  return (
    <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
      <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
      <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource} </Typography>
      <Box sx={{ mt: 3 }}>
        {/* <CreateButton  /> */}
      </Box>
    </Box>
  );
};


const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

  let data: any = [];

  records.map((record: any) => {

    data.push({
      ...record,
      _id: " ",
      id: " ",
      property_operator_id: " "

    })
  });
  return jsonExport(data, {
    headers: ['property_name', 'package_name', 'total_purchases', 'package_unit',],
    rename: ['Property Name', 'Package Name', 'Total Purchases', 'Package Unit', ' ', ' ', ' ', ' ', ' ',],
  }, (err: any, csv: any) => {
    downloadCSV(csv, 'by-properties');
  });
};


const ResourceList = (props: ListProps): ReactElement => {

  return (
    <Box sx={{ pl: 4, pr: 3, pt: 7, height: "auto" }}>
      <List
        empty={<Empty />}
        title="Subscription By Days"
        filters={PropertyFilters}
        exporter={exporter}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Property Name" source="property_name" />
          <TextField label="Package Name" source="package_name" />
          <TextField label="unit" source="package_unit" />
          <FunctionField label="Total Purchases" render={(record: any) => {
            return (
              <Typography sx={{ p: 2 }}> {record.total_purchases}</Typography>
            )
          }} />;
        </Datagrid>
      </List>
    </Box>
  )
}

export default ResourceList;