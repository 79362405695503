import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, DialogContent, Divider, Button, List, TextField, Dialog, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout, useRefresh, usePermissions, useDataProvider, useNotify, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMediaQuery, Theme } from '@mui/material';

const AccountSettings: FC = () => {

    const navigate = useNavigate();
    const logout = useLogout();
    const dataProvider = useDataProvider();

    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor: 'right', open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const accountSettings = () => {
        navigate('/settings');
        setState({ right: false });
    }

    const accountUsersRoles = () => {
        navigate('/users/systemAdmin');
        setState({ right: false });
    }

    const changePassword = () => {
        navigate('/change-password');
        setState({ right: false });
    }

    const accountDetails = () => {
        navigate('./account-details');
        setState({ right: false });
    }

    const [userDetails, setUserDetails] = useState<any>({});

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    let id = decode['_id'];

    const { data: dataUser, isLoading: loadingUser, error: errorUser } = useGetOne(
        'users',
        {
            id: id
        }
    );

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box>
            <Button onClick={toggleDrawer('right', true)}>
                Magoware - System Admin <ExpandMoreOutlinedIcon />
            </Button>
            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                <Box sx={{ width: isXSmall ? 300 : 400 }}>
                    <Box sx={{ textAlign: "center" }}>
                        <IconButton>
                            <AccountCircleIcon sx={{ fontSize: 70 }} />
                        </IconButton>
                        <Typography sx={{ color: "grey" }} variant="subtitle1">{dataUser?.email}</Typography>
                    </Box>

                    <Typography sx={{ color: "grey", textAlign: "center" }}>Account Details</Typography>
                    <List>
                        <ListItem button onClick={accountUsersRoles}>
                            <ListItemIcon><GroupAddIcon /></ListItemIcon>
                            <ListItemText primary="Users & Roles" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={accountDetails}>
                            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                            <ListItemText primary="Account Details" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={changePassword}>
                            <ListItemIcon><LockIcon /></ListItemIcon>
                            <ListItemText primary="Change Password" />
                        </ListItem>
                    </List>
                    <Divider />
                    {/* <List>
                        <ListItem button>
                            <ListItemIcon><AccountBalanceWalletOutlinedIcon /></ListItemIcon>
                            <ListItemText primary="Manage Subscription" />
                        </ListItem>
                    </List>
                    <Divider /> */}
                    <List>
                        <ListItem button onClick={logout}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText primary="Log Out" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

export default AccountSettings;