import React, { ReactElement, Fragment, cloneElement } from 'react';
import {
    BooleanField,
    Datagrid,
    TopToolbar,
    List,
    FunctionField,
    DateField,
    ListProps,
    TextField,
    sanitizeListRestProps,
    EditButton,
    useListContext,
    ExportButton,
    Button,
    CreateButton,
    ReferenceField,
    ImageField,
    Pagination
} from 'react-admin';
import { Chip, Typography, Box, makeStyles } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import moment from 'moment';
import { useMediaQuery, Theme } from '@mui/material';

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton resource={resource} />
        </TopToolbar>
    );
};

const Empty = () => {
    const { resource } = useListContext();

    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
        </div>
    );
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = (props: ListProps): ReactElement => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 4 }}>
            {isXSmall ?
                <List
                    title="Content Distributors"
                    actions={<ListActions />}
                    empty={<Empty />}
                    pagination={<ListPagination />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid>
                        <TextField label="Name" source="name" />
                        <FunctionField label="Channels Limit" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.channels_limit} </Typography>
                            )
                        }} />;
                        {/* <TextField label="Channels Limit" source="channels_limit" /> */}
                        <TextField label="VOD Limit" source="vods_limit" />
                        <TextField label="Services Limit" source="public_services_limit" />
                    </Datagrid>
                </List>
                :
                <List
                    title="Content Distributors"
                    actions={<ListActions />}
                    empty={<Empty />}
                    pagination={<ListPagination />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid>
                        <TextField label="Name" source="name" />
                        <FunctionField label="Channels Limit" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.channels_limit} </Typography>
                            )
                        }} />;
                        {/* <TextField label="Channels Limit" source="channels_limit" /> */}
                        <TextField label="VOD Limit" source="vods_limit" />
                        <TextField label="Services Limit" source="public_services_limit" />
                    </Datagrid>
                </List>
            }
        </Box>
    )

}
export default ResourceList;