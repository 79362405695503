import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, Backdrop, CircularProgress, DialogContent, Divider, Button, Grid, TextField, Dialog, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useDataProvider, useNotify, useRefresh, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// import config
import { BASE_URL } from '../../config';
import { DisabledByDefault } from '@mui/icons-material';

type Inputs = {
    name: string,
    phone: string,
    screens_limit: object,
    type: string
};

const CreateProperty: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [propertyType, setPropertyType] = useState("");
    const [isAvailable, setIsAvailable] = useState(false);
    const [nameee, setNameee] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setIsAvailable(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    let id = decode['_id'];

    const { data: dataUser, isLoading: loadingUser, error: errorUser } = useGetOne(
        'users',
        {
            id: id
        }
    );

    // useEffect(()=>{
    //     setValue("name", "");
    //     setValue("phone", "");
    //  },[open])

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {

        setIsAvailable(false)

        const formData: any = new FormData();

        data.type = "hotel";
        data.screens_limit = '1';

        formData.append("name", data.name);
        if (dataUser) {
            if (dataUser["email"] == "info@tibo.tv") {
                formData.append("content_distributor_id", "62b1d8a5c9c05b03d02952ed");
            }
        }
        formData.append("phone", data.phone);
        formData.append("screens_limit", data.screens_limit);
        formData.append("type", data.type);

        dataProvider.create(
            'properties/create',
            {
                data: formData
            }
        ).then((response: any) => {
            notify('Your property was successfully created', { type: "info" });
            refresh();
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });

        })
    }

    return (
        <>
            <Button startIcon={<AddIcon />} onClick={handleClickOpen}> Create Property</Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                fullScreen
            >
                <DialogContent>
                    <Box>
                        <IconButton onClick={handleClose} >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ alignItems: "center", justifyContent: "center", display: "flex", p: 15 }}>
                        <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <TextField
                                        id="name"
                                        label="Hotel Name"
                                        placeholder="Hotel Name"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        id="phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("phone")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid container spacing={3}>
                                    <FormControl component="fieldset" style={{ marginTop: 20, marginLeft: 25 }}>
                                        <FormLabel component="legend">Property type</FormLabel>
                                        <RadioGroup
                                            name="type"
                                            row
                                        >
                                            <FormControlLabel value="hotel" control={<Radio onChange={(e) => setPropertyType(e.target.value)} />} label="Hotel" />
                                            <FormControlLabel value="hospital" control={<Radio onChange={(e) => setPropertyType(e.target.value)} />} label="Hospital" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid> */}
                            </Grid>
                            <Box style={{ padding: 20 }}>
                                <Divider />
                            </Box>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit(onSubmit) && handleClose}
                                disabled={isAvailable ? false : true}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>

    );
}

export default CreateProperty;