import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useGetList,
  useNotify,
  useCreate,
  useRefresh,
  useGetOne,
  useDataProvider,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import jwt_decode from "jwt-decode";

type Inputs = {
  screen_number: string;
  mac_address: string;
  pin_adult: string;
  player: string;
  show_adult: boolean;
  fullName: string;
  screen_type: string;
  stream_source: string;
  activated: boolean;
  address: string;
  activate_mobile: boolean;
  app_terrestral_package_id: string;
  enabled_terrestral_tv: boolean;
  email: string;
  password: string;

};

const Create: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const [macAddress, setMacAddress] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);

  const [selectedScreenType, setSelectedScreenType] = useState<string>("room");
  const [activate_mobile, setActivate_mobile] = React.useState<boolean>(false);

  const handleChangeActivateMobile = (event: any) => {
    setActivate_mobile(event.target.checked)
    setDisabled(false);
  };


  const [activated, setActivated] = React.useState<boolean>(false);

  const handleChangeActivated = (event: any) => {
    setActivated(event.target.checked);
    setDisabled(false);
  };


  const [showAdult, setShowAdult] = React.useState<boolean>(false);

  const handleChangeShowAdult = (event: any) => {
    setShowAdult(event.target.checked);
  };

  console.log(activate_mobile, "activate_mobile");

  const [enabledTerrestral, setEnabledTerrestral] = useState<boolean>(false);
  const [changePINState, setChangePINState] = useState<boolean>(true);

  const changePIN = () => {
    setChangePINState(!changePINState);
    setValue("pin_adult", "1234");
  };

  const changeTerrestral = () => {
    setEnabledTerrestral(!enabledTerrestral);
  };

  const [selectedStreamSource, setSelectedStreamSource] = useState<any>();

  const {
    data: dataStream,
    total: totalStream,
    isLoading: loadingStream,
    error: errorStream,
  } = useGetList("stream-source/contentDistributor", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);
  let id = decode["property_operator_id"];

  const {
    data: dataProperties,
    isLoading: loadingProperties,
    error: errorProperties,
  } = useGetOne("properties", {
    id: id,
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();


  const generateUniqueId = () => {
    const randomValue = Math.floor(Math.random() * 0xFFFFFFFFFFFFFFFF).toString(16);
    const timestamp = Date.now().toString(16).slice(-5); // Use the last 5 characters of the current timestamp
    const uniqueId = randomValue.slice(0, 7) + timestamp;
    return uniqueId;
  };

  const handleGenerateId = () => {
    const generatedId = generateUniqueId();
    setMacAddress(generatedId);
    setValue("mac_address", generatedId);
    setDisabled(false);
    console.log("generatedId", generatedId);
  };

  const OnSubmit: SubmitHandler<Inputs> = (data) => {
    const formData: any = new FormData();

    if (data.pin_adult == undefined) {
      data.pin_adult = "1234";
    }

    if (data.app_terrestral_package_id == undefined) {
      data.app_terrestral_package_id = "";
    }

    data.stream_source = selectedStreamSource || "default_stream_source";
    {
      dataProperties && dataProperties["type"] === "hotel"
        ? (data.screen_type = selectedScreenType)
        : (data.screen_type = "client");
    }

    // formData.append("screen_number", data.screen_number);
    // formData.append("screen_type", data.screen_type);
    // formData.append("mac_address", data.mac_address.toUpperCase());
    // formData.append("pin_adult", data.pin_adult);
    // formData.append("show_adult", data.show_adult);
    // formData.append("fullName", data.fullName);
    // formData.append("stream_source", data.stream_source);
    // formData.append("email", data.email);
    // formData.append("password", data.password);
    // formData.append("app_terrestral_package_id", data.app_terrestral_package_id);
    // formData.append("enabled_terrestral_tv", data.enabled_terrestral_tv);

    const screensData = {
      screen_number: data.screen_number,
      screen_type: data.screen_type,
      mac_address: data.mac_address.toUpperCase(),
      pin_adult: data.pin_adult,
      show_adult: data.show_adult,
      fullName: data.fullName,
      // player: data.player,
      player: "default_player",
      activated: data.activated || true,
      stream_source: data.stream_source,
      activate_mobile: activate_mobile,
      createdAt: new Date(),
      app_terrestral_package_id: data.app_terrestral_package_id,
      enabled_terrestral_tv: data.enabled_terrestral_tv,
      ...(activate_mobile && { email: data.email, password: data.password }),

    };

console.log("Activate",activate_mobile);

    if (dataProperties && dataProperties["type"] === "residential") {
      formData.append("address", data.address);
    }

    dataProvider
      .create("screens", {
        data: screensData,
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your service was successfully created", { type: "info" });
        navigate("/screens");
      })
      // .catch((error: any) => {
      //   if (error.message.includes("Duplicate key error") && error.message.includes("email") && activate_mobile) {
      //     notify("Email should be unique", { type: "warning" });
      //   }
      //    else if (error.message.includes("Duplicate key error") && error.message.includes("screen_number") && activate_mobile) {
      //     notify("scr should be unique", { type: "warning" });
      //   }
      //    else if (error.message.includes("Duplicate key error") && error.message.includes("mac_address")) {
      //     notify("MAC address is not unique", { type: "warning" });
      //   } else {
      //     // console.error("Error during insertion:", error);
      //     notify("An error occurred while creating the service", { type: "error" });
      //     console.log("ERRORRRIII:"+ error.message);
      //   }
        
      // });
      .catch((error: any) => {
        console.log("Complete error message:", error.message);
      
        if (
          error.message.includes("Duplicate key error") &&
          error.message.includes("screen_number")
        ) {
          notify("Screen number should be unique.", {
            type: "warning",
          });
        } else if (error.message.includes("Duplicate key error") && error.message.includes("mac_address")) {
          notify("MAC address is not unique", { type: "warning" });
        } else if (error.message.includes("Duplicate key error") && error.message.includes("email") && activate_mobile) {
          notify("Email should be unique", { type: "warning" });
        } else {
          notify("An error occurred while creating the service", { type: "error" });
        }
      });
      
      
  };

  return (
    <Box sx={{ pl: 6, pt: 10, borderRadius: 10 }}>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={9} md={3}>
            <TextField
              id="screen_number"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Screen Number"
              label="Screen Number*"
              {...register("screen_number", {
                required: {
                  value: true,
                  message: "Screen number can not be empty",
                },
                min: {
                  value: 1,
                  message: "Value must be greater than 0",
                },
              })}
              variant="outlined"
              fullWidth
              helperText={
                errors.screen_number ? `${errors.screen_number.message}` : ""
              }
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                id="mac_address"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter MAC Address"
                label="MAC Address*"
                inputProps={{
                  maxLength: 12,
                  style: { textTransform: "uppercase" },
                }}
                {...register("mac_address", {
                  required: true,
                  maxLength: 12,
                  minLength: 12,
                })}
                helperText={ "Mac Address should be 12 characters long"
                }
                variant="outlined"
                fullWidth
                
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGenerateId}
                style={{ marginLeft: '9px', marginBottom: '40px', width: '80%' }}
              >
                Generate ID
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={9} md={3}>
            <TextField
              id="full_name"
              label="Full Name"
              placeholder="Enter Guest's Full Name"
              InputLabelProps={{ shrink: true }}
              {...register("fullName")}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={3}>
          <Grid item xs={9} md={2}>
            <TextField
              id="pin_adult"
              InputLabelProps={{ shrink: true }}
              {...register("pin_adult", {
                min: 1,
                pattern: /^[0-9]*$/,
                maxLength: 4,
                minLength: 4,
              })}
              helperText={"PIN should be composed of 4 numbers"}
              placeholder="1234"
              label="PIN Adult"
              variant="outlined"
              fullWidth
              disabled={changePINState}
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              style={{ marginTop: 5 }}
              control={<Checkbox onChange={changePIN} name="show_pin" />}
              label="Change PIN"
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ pb: 3 }}></Box>
                <Grid container spacing={3}>
                    {enabledTerrestral ?
                        <Grid item xs={9} md={2}>
                            <TextField
                                id="app_terrestral_package_id"
                                InputLabelProps={{ shrink: true }}
                                {...register("app_terrestral_package_id")}
                                placeholder="App Package ID"
                                label="App Package ID"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        :
                        " "
                    }
                    <Grid item md={2}>
                        <FormControlLabel
                            id="enabled_terrestral_tv"
                            style={{ marginTop: 5 }}
                            control={<Checkbox {...register("enabled_terrestral_tv")} onChange={changeTerrestral} name="enabled_terrestral_tv" />}
                            label="Enabled Terrestral"
                        />
                        <Typography sx={{ color: "#FFFFFFB3", fontSize: 12, fontFamily: " Helvetica" }}>This opsion is available for android users</Typography>
                    </Grid>
                </Grid> */}
        {dataProperties && dataProperties["type"] === "hotel" && (
          <>
            <Box sx={{ pb: 3 }}></Box>
            <Grid container spacing={6}>
              <Grid item md={2}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="screen_type"
                    name="screen_type"
                    value={selectedScreenType}
                  >
                    <FormControlLabel
                      value="room"
                      control={
                        <Radio
                          onChange={(e) =>
                            setSelectedScreenType(e.target.value)
                          }
                        />
                      }
                      label="Hotel Room"
                    />
                    <FormControlLabel
                      value="bar_restaurant"
                      control={
                        <Radio
                          onChange={(e) =>
                            setSelectedScreenType(e.target.value)
                          }
                        />
                      }
                      label="Bar & Restaurant"
                    />
                    {/* <FormControlLabel value="client" control={<Radio />} label="Residential Client" /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={6}>
          <Grid item xs={7} md={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Stream Source
              </InputLabel>
              <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      name="streamSource"
      value={selectedStreamSource}
      onChange={(e) => {
        setSelectedStreamSource(e.target.value);
      }}
      label="Stream Source"
    >
      <MenuItem value="">
        <em>Select Source</em>
      </MenuItem>
      {dataStream?.map((record: any) => (
        <MenuItem key={record.name} value={record.name}>
          {record.name}
        </MenuItem>
      ))}
    </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ pb: 2 }}></Box>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAdult}
                  {...register("show_adult")}
                  onChange={handleChangeShowAdult}
                  name="show_adult"
                />
              }
              label="Show Adult"
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activate_mobile}
                  onChange={handleChangeActivateMobile}
                  name="activate_mobile"
                />
              }
              label="Activate Mobile"
            />
          </Grid>
        </Grid>
        {activate_mobile ? (
          <Grid container spacing={3} sx={{ pt: 3.5 }}>
            <Grid item md={3}>
              <TextField
                id="email"
                type="email"
                label="Email"
                placeholder="Enter Email"
                InputLabelProps={{ shrink: true }}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                variant="outlined"
                fullWidth
                helperText={
                  errors.email
                    ? `${errors.email.message}`
                    : ""
                }
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                id="password"
                label="Password"
                placeholder="Enter Password"
                InputLabelProps={{ shrink: true }}
                {...register("password", { required: "Password is required" })}
                variant="outlined"
                type="password"
                fullWidth
                helperText={
                  errors.password
                    ? "Password is required"
                    : ""
                }
              />
            </Grid>
          </Grid>
        ) : (
          " "
        )}
        {dataProperties && dataProperties["type"] === "residential" && (
          <>
            <Box sx={{ pb: 3 }}></Box>
            <Grid container spacing={3}>
              <Grid item xs={9} md={3}>
                <TextField
                  id="address"
                  label="Address"
                  placeholder="Address"
                  InputLabelProps={{ shrink: true }}
                  {...register("address")}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        <Box sx={{ p: 5 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit(OnSubmit)}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Create;
