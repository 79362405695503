import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { Link } from "react-router-dom"

// import components
import TvMonitor from '../../../../assets/images/iconsMenu/tv-monitor.png';

interface Props {
    value?: number;
}

const Properties: FC<Props> = ({ value }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Link to="/channels" style={{ textDecoration: "none" }}>
            <Card sx={{ borderRadius: 10 }}>
                <Box sx={{ p: 4 }}>
                    <Box>
                        <img width={isXSmall ? 40 : 60} src={TvMonitor} />
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: isXSmall ? 12 : 25 }}>Channels</Typography>
                        <Typography sx={{ fontSize: isXSmall ? 20 : 30 }}>{value !== 0 ? value : '0'}</Typography>
                    </Box>
                </Box>
            </Card>
        </Link>
    );
};

export default Properties;