import React, { Fragment, cloneElement, ReactElement } from 'react'
import {
    BooleanField,
    Datagrid,
    List,
    FunctionField,
    TextField,
    useListContext,
    CreateButton,
    Pagination,
    downloadCSV,
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';
import { Typography, Chip, Box } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { useMediaQuery, Theme } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import jsonExport from 'jsonexport/dist';

import SwitchtoPropertyCd from '../../layout/components/SwitchtoPropertyCd';

const PropertyFilters = [
    <TextInput sx={{ mb: 2 }} source="name" variant="outlined" label="Search" alwaysOn />
];

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box>
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
                <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
                <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any property</Typography>
                <Box sx={{ alignSelf: "center" }}>
                    <CreateButton resource={resource} />
                </Box>
            </div>
        </Box>
    );
};

const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

    let data: any = [];

    records.map((record: any) => {

        data.push({
            ...record,
            screens: { _id: " ", updatedAt: " ", createdAt: " ", player: " ", },
            content_distributor: { updatedAt: " " },
            property_video_path: " ",
            property_app_background_path: " ",
            property_logo_path: " ",
            property_support_id: " ",
            content_distributor_id: " ",
            updatedAt: " ",
            __v: "",
            createdAt: " ",
            longitude: " ",
            latitude: " ",
            opacity_background: " ",
            wifi_password: " ",
            reception_number: " ",
            _id: " ",
            id: " ",
            phone: " ",

        })
    });
    return jsonExport(data, {
        headers: ['name', 'type', 'screens_limit', 'services_limit',],
        rename: ['Property Name', 'Type', 'Screens Limit', 'Service Limit', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
    }, (err: any, csv: any) => {
        downloadCSV(csv, 'properties/cd');
    });
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    empty={<Empty />}
                    title="Properties"
                    pagination={<ListPagination />}
                    filters={PropertyFilters}
                    perPage={25}
                    exporter={exporter}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Property Name" source="name" />
                        <TextField label="Screens Limit" source="screens_limit" />
                        <TextField label="Services Limit" source="services_limit" />
                    </Datagrid>
                </List>
                :
                <List
                    empty={<Empty />}
                    title="Properties"
                    pagination={<ListPagination />}
                    filters={PropertyFilters}
                    perPage={25}
                    exporter={exporter}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Property Name" source="name" />
                        <FunctionField
                            label=""
                            render={(record: any) => {
                                if (record.type) {
                                    return (
                                        <Chip sx={{ marginRight: 15, p: 2, m: 2, textTransform: "uppercase" }} label={record.type} />
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label=""
                            render={(record: any) => {
                                if (record.screens) {
                                    return (
                                        <Chip sx={{ marginRight: 15, p: 2, m: 2 }} label={record.screens.length + " Screens"} />
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label=""
                            render={(record: any) => {
                                if (record.id) {
                                    return (
                                        <SwitchtoPropertyCd id={record.id} />
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;