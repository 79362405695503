import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useUpdate, useGetOne, useDataProvider, useGetList, } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Grid, Typography, TextField, Avatar, Button, FormControlLabel, Checkbox, Stepper,
    Step, StepContent, StepButton, Autocomplete, createFilterOptions, Box, Divider
} from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import moment from 'moment';
import { useParams } from "react-router-dom";
import { BASE_ASSETS_URL, BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";

interface OptionType {
    label?: string;
    inputValue?: string;
}

type Inputs = {
    start_time: any,
    end_time: any,
};
const Edit: FC = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    // const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const { id } = useParams();

    const [startTime, setStartTime] = useState<any>([]);
    const [endTime, setEndTime] = useState<any>({});


    const { data, isLoading, error, refetch } = useGetOne(
        'subscriptions',
        {
            id: `${id}`
        }
    );


    useEffect(() => {
        setStartTime(data?.start_time);
        setValue("start_time", moment(data?.start_time).format('YYYY-MM-DD'));
        setEndTime(data?.end_time);
        setValue("end_time", moment(data?.end_time).format('YYYY-MM-DD'))
    }, [data]);

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        formData.append("start_time", data?.start_time);
        formData.append("end_time", data?.end_time);


        dataProvider.update(
            'subscriptions',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: `${id}`,
                }
            },
        ).then((response: any) => {
            notify('Data was successfully updated', { type: "info" });
            navigate("/subscriptions/cd");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 6, pr: 3, pt: 12 }}>
            <Title title="Edit Package" />
            <Grid container spacing={3}>
                <Grid item md={2}>
                    <TextField
                        id="start_time"
                        label="Start Date*"
                        placeholder="Start Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue=""
                        {...register("start_time")}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={2}>
                    <TextField
                        id="end_time"
                        label="End Date*"
                        placeholder="End Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue=""
                        {...register("end_time")}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box sx={{ p: 3 }}>
                <Divider />
            </Box>
            <Button
                type="button"
                onClick={handleSubmit(OnSubmit)}
                variant="contained"
                color="primary"
                disabled={!isDirty}
                size="large"
            >
                Submit
            </Button>
        </Box>
    )
}

export default Edit