import React, { FC, useEffect, useState } from 'react';
import { useNotify, useRefresh, Button, useDataProvider, useGetList, useCreate } from 'react-admin';
import { makeStyles, createStyles, Stack, TextField, Dialog, IconButton, Typography, DialogContent, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
// import IconButton from '@material-ui/core/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type Inputs = {
    movie_id: string
};

const FindFromTMDB = () => {

    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const navigate = useNavigate();

    const [movies, setMovies] = React.useState<any>([]);
    const [movieTitle, setMovieTitle] = useState<string>("");
    const [empty, setEmpty] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e: any) => {
        setMovieTitle(e.target.value);
        setEmpty(false);
    }

    const searchChange = async (e: any) => {
        setMovies([]);
        if (movieTitle) {
            await dataProvider.getList<any>(
                `tmdb/${movieTitle}`,
                {
                    pagination: { page: 1, perPage: 5 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: {}
                },
            ).then((response: any) => {
                setMovies(response.data);
                {
                }
            }
            )
        } else setEmpty(true);
    }

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && e.target.value != null) {
            searchChange(e.target.value);
        }
    }
    const ImportVOD: SubmitHandler<Inputs> = (_id: any) => {
        const formData: any = new FormData();
        formData.append("movie_id", _id);

        dataProvider.create(
            'tmdb',
            {
                data: formData
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your VOD was successfully created', { type: "info" });
            navigate("/vods");
            setOpen(false);
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
            setOpen(false);
        })
    }

    return (
        <Box sx={{ p: 0 }}>
            <Stack direction="row" spacing={1}>
                <Box>
                    <SearchIcon sx={{ fontSize: 14, mt: 0.81, mr: -1, color: "#ddd" }} />
                </Box>
                <Box>
                    <Button
                        label="Find from TMDB"
                        onClick={handleClickOpen}
                    />
                </Box>
            </Stack>
            <Dialog
                open={open}
                maxWidth={"lg"}
                onClose={handleClose}
            >
                <DialogContent sx={{ p: 5 }}>
                    <Typography sx={{ mb: 1, mt: -1, fontSize: 23, fontWeight: "bold" }}>
                        Find movies for TMDB import
                    </Typography>
                    <Typography sx={{ mb: 1, mt: -1, color: "gray" }}>
                        Import metadata and details of the movie from tmbd by selecting the exact movie from the search
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Box width="70vh">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="title"
                                fullWidth
                                onChange={handleChange}
                                variant="outlined"
                                label="Search Movies"
                                type="text"
                                onKeyDown={handleKeyDown}
                                helperText={empty ? 'Please type the movie title' : ' '}
                            />
                        </Box>
                        <Box>
                            <IconButton sx={{ mt: 2 }} onClick={searchChange} aria-label="searchoutlined">
                                <SearchOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                    <Box sx={{ p: 1 }}>
                        <List>
                            {movies?.map((movie: any) => (
                                <ListItem key={movie['id']}>
                                    <ListItemAvatar sx={{ mr: 2 }}>
                                        <img width={50} src={`https://image.tmdb.org/t/p/w200${movie['poster_path'] !== null ? movie['poster_path'] : '/vkcajIqORuKfd8uV2GYULlHut9o.jpg'}`} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={movie['title']}
                                    />
                                    <ListItemSecondaryAction>
                                        <Button onClick={() => ImportVOD(movie['id'])} label="Import" />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default FindFromTMDB;