import React from 'react';
import { makeStyles, Theme, createStyles, Fade, Button, Dialog, DialogTitle, DialogActions, DialogContent, Backdrop, DialogContentText } from '@mui/material';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

export const DeleteDeviceMenu = ((record: any, index: any) => {
    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        dataProvider.delete(
            'device-menu',
            {
                id: record.index,
                previousData: record
            },
        ).then((response: any) => {
            notify('The package was deleted succesfully', { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <div>
            <Button size="small" color="primary" onClick={handleOpen} style={{ color: "#ff0000a1" }}>
                Remove
            </Button>
            <Dialog
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                sx={{ borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DialogContent sx={{display: 'flex',flexDirection: 'column',alignItems: 'center',}}>
                        <DialogTitle>
                            Delete {record.record.name}
                        </DialogTitle>
                        <DialogContentText>
                            Are you sure you want to delete {record.record.name} ?
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="contained" style={{ borderRadius: 1 }} >Cancel</Button>
                            <Button onClick={handleDelete} variant="contained" style={{ backgroundColor: "#ff0000a1", borderRadius: 1, color: "white" }}>Delete</Button>
                        </DialogActions>
                    </DialogContent>
                </Fade>
            </Dialog>
        </div>
    );
}
);

export default DeleteDeviceMenu;