import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useGetList, useNotify, useRefresh, useUpdate, Notification, useDataProvider } from 'react-admin';
import {
    Box, Card, CardHeader, IconButton, Grid, Chip, CardActions, Divider, Button, CardMedia, Tab,
    Dialog, DialogActions, DialogContent, CardContent, Checkbox
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import { Typography, Breadcrumbs, Link, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

//import components
import ServiceDetails from './ServiceDetails';
import ServiceDelete from './ServiceDelete';
import AssignDialog from './AssignDialog';
import { BASE_ASSETS_URL } from '../../../config';

interface Props {
    menu: string,
    category: string,
}

const SeeAllServices: FC<Props> = ({ menu, category }) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        refresh();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedServices, setSelectedServices] = useState<any>([]);

    const handleChange = (event: any, id: any) => {
        if (event.target.checked === true) { setSelectedServices([...selectedServices, id['_id']]) }
        else { setSelectedServices(selectedServices.filter((v: any) => v != id["_id"])) }
    }

    const [allServices, setAllServices] = useState<any>([]);

    const { data, total, isLoading, error, refetch } = useGetList(
        `services/cd/service_items/${menu}/${category}`,
        {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
            filter: {}
        },
    );

    useEffect(() => {
        setAllServices(data);
    }, [data]);

    return (
        <>
            <Button style={{ float: "right" }} onClick={handleClickOpen} variant="text" color="primary">SEE ALL</Button>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                fullScreen
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <>
                        <Grid container sx={{ pt: 1 }}>
                            <Grid item md={6} sx={{ mb: 2 }}>
                                <IconButton onClick={handleClose} >
                                    <KeyboardBackspaceOutlinedIcon />
                                </IconButton>
                            </Grid>
                            <Grid item md={6}>
                                <Box sx={{ display: "flex", justifyContent: "right" }}>
                                    {
                                        selectedServices.length ?
                                            <Stack sx={{ float: "right" }} direction="row" spacing={2}>
                                                <AssignDialog selectedServices={selectedServices} />
                                            </Stack>
                                            :
                                            ""
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        {data?.length &&
                            <Grid container spacing={2}>
                                {data?.map((service: any) => (
                                    <Grid md={1.7}>
                                        <Card sx={{ borderRadius: 8, margin: 2 }}>
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={`${BASE_ASSETS_URL}${service.service_path}`}
                                                alt={service.service_name}
                                            />
                                            <CardContent>
                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography sx={{ fontSize: 16 }}>
                                                        {service.service_name ? service.service_name.substring(0, 15) + ' ...' : " "}
                                                    </Typography>
                                                    <Checkbox
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        onChange={(event: any) => handleChange(event, service)}
                                                    />
                                                </Box>
                                                <Typography sx={{ fontSize: 10, color: "grey" }}>
                                                    {service.short_service_description ? service.short_service_description.substring(0, 50) + ' ...' : " "}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Divider />
                                            </Box>
                                            <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
                                                <ServiceDetails record={service} />
                                                <>
                                                    <IconButton href={`/#/services/${service['_id']}`} style={{ color: "grey" }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <ServiceDelete record={service} />
                                                </>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SeeAllServices;