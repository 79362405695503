import React, { FC, useEffect, useState } from 'react';
import { useNotify, useDataProvider, useRefresh, useGetList, useGetOne } from 'react-admin';
import { makeStyles, Grid, InputLabel, MenuItem, FormControl, createStyles, Select, CircularProgress, Backdrop, Theme, Dialog, DialogContent, DialogContentText, Divider, Button, DialogActions, Box, TextField, Chip } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

// import types

interface Inputs {
    name: string;
    screens_limit: number;
    services_limit: number;
}

const EditProperty = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const { id } = useParams()

    const [properties, setProperties] = useState<any>([]);

    const { data: dataContent, isLoading, error, refetch } = useGetOne(
        'properties',
        {
            id: id,
        },
    );

    useEffect(() => {
        if (dataContent) {
            setProperties(dataContent);
            setValue("name", dataContent?.name)
            setValue("screens_limit", dataContent?.screens_limit)
            setValue("services_limit", dataContent?.services_limit)
        }
    }, [dataContent])

    const { register, handleSubmit, control, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {

        const formData: any = new FormData();

        formData.append("name", data.name);
        formData.append("screens_limit", data.screens_limit);
        formData.append("services_limit", data.services_limit);

        dataProvider.update(
            'properties',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: id
                }
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            navigate("/properties")
            notify('Your change was successfully updated', { type: "info" });
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={3}>
                                <TextField
                                    id="name"
                                    placeholder="Enter Name"
                                    label="Name"
                                    defaultValue={properties.name}
                                    InputLabelProps={{ shrink: true }}
                                    // onChange={(e: any) => { setName(e.target.value) }}
                                    {...register("name")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid container >
                                <Grid item xs={9} md={3}>
                                    <Chip label={properties?.type} variant="outlined" sx={{ p: 2, mt: 2, ml: 2, mb: 1 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 1 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={11} md={3}>
                                <TextField
                                    type="number"
                                    id="services_limit"
                                    placeholder="Enter Services Limit"
                                    label="Services Limit"
                                    defaultValue={properties.services_limit}
                                    InputLabelProps={{ shrink: true }}
                                    // onChange={(e: any) => { setServicesLimit(e.target.value) }}
                                    {...register("services_limit")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={9} md={3}>
                                <TextField
                                    type="number"
                                    id="screens_limit"
                                    placeholder="Enter Screens Limit"
                                    label="Screens Limit"
                                    defaultValue={properties.screens_limit}
                                    InputLabelProps={{ shrink: true }}
                                    // onChange={(e: any) => { setScreenLimit(e.target.value) }}
                                    {...register("screens_limit")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 4, pt: 4 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!isDirty}
                            onClick={handleSubmit(OnSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>
                    </Grid>
                </Grid>
            </form>
        </Box >
    )
}

export default EditProperty;