// import React from 'react'
import List from './List';
import Edit from './Edit';


const resource = {
    list: List,
    // create: Create,
    edit: Edit,
}

export default resource