import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useGetOne, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import jwt_decode from "jwt-decode";

// import types

type Inputs = {
    email: string,
    role: string,
    phone: string,
    address: string,
};

const Create: FC = () => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const notify = useNotify();


    const [propertyType, setPropertyType] = useState("");
    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);
    let id = decode['property_operator_id'];

    //fetch property data
    const { data, isLoading, error, refetch } = useGetOne(
        'properties',
        {
            id: id
        },
    );

    useEffect(() => {
        setPropertyType(data["type"])
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("email", data.email);
        formData.append("role", data.role);
        formData.append("phone", data.phone);
        formData.append("address", data.address);

        dataProvider.create<any>(
            'users/property/create',
            {
                data: formData
            },
        ).then((response: any) => {
            notify('User was successfully invited', { type: "info" });
            navigate("/users/property");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 5, pt: 12 }}>
            <Title title="Invite User" />
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item md={2}>
                            <TextField
                                id="email"
                                label="Email*"
                                placeholder="Enter User Email"
                                InputLabelProps={{ shrink: true }}
                                {...register("email", {
                                    required: true, minLength: 4, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="phone"
                                label="Phone*"
                                placeholder="Enter Phone"
                                InputLabelProps={{ shrink: true }}
                                {...register("phone", { required: true, pattern: /^\d+$/ })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="address"
                                label="Address*"
                                placeholder="Enter Address"
                                InputLabelProps={{ shrink: true }}
                                {...register("address", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ pb: 3 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                id="role"
                                select
                                label="Role*"
                                variant="outlined"
                                {...register("role", { required: true })}
                                fullWidth
                            >
                                <MenuItem value="it">IT</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                                {propertyType === "residential" && <MenuItem value="sale">Sales</MenuItem>}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Box sx={{ p: 4 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Invite
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Create;