import React, { Fragment } from 'react'
import {
    Datagrid,
    List,
    TextField,
    FunctionField,
    downloadCSV,
    DateInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import { useMediaQuery, Theme } from '@mui/material';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';

// const BulkActionButtons = (props: any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );
const salesFilters = [
    <DateInput sx={{ mb: 2 }} source="fromDate" variant='outlined' alwaysOn />,
    <DateInput sx={{ mb: 2 }} source="toDate" variant='outlined' alwaysOn />,
];

const date = moment().format('YYYY-MM-DD hh:mm');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');

const exporter = (records: any, fetchRelatedRecords: any) => {

    let data: any = [];

    records.map((record: any) => {
        data.push({
            ...record,
            id: " ",
            last_purchase: " "
        })

    });

    return jsonExport(data, {
        headers: ['_id', 'purchaces_total', 'duration', 'unit', 'package_price', 'total_expenses', 'id', 'last_purchase'],
        rename: ["Packages Name", 'Total Purchaces ', "Duration", "Unit", "Price", 'Total Expense ', ' ', ' '],
    }, (err: any, csv: any) => {
        downloadCSV(csv, 'activations');
    });
};

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 8, height: "auto" }}>
            {isXSmall ?
                <List
                    exporter={exporter}
                    perPage={25}
                    sort={{ field: "createdAt", order: "DESC" }}
                    filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
                    filters={salesFilters}
                // bulkActionButtons={<BulkActionButtons />}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Package Name" source="_id" />
                        <FunctionField label="Total Expenses" render={(record: any) => {
                            return (<Typography sx={{ p: 2 }}>{record.total_expenses} €</Typography>
                            )
                        }} />
                    </Datagrid>
                </List>
                :
                <List
                    perPage={25}
                    exporter={exporter}
                    filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
                    filters={salesFilters}
                // bulkActionButtons={<BulkActionButtons />}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Package Name" source="_id" />
                        <TextField label="Quantity" source="purchaces_total" />
                        <TextField label="Duration" source="duration" />
                        <TextField label="Unit" source="unit" />
                        <FunctionField label="Price" render={(record: any) => `${record.package_price} €`} />;
                        <FunctionField label="Total Expenses" render={(record: any) => {
                            return (<Typography sx={{ p: 2, fontSize: 14 }}>{record.total_expenses} €</Typography>
                            )
                        }} />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;