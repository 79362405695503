import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useNotify,
  useRefresh,
  useTranslate,
  Notification,
  useDataProvider,
  useGetOne,
  useGetList,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Avatar,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import moment from "moment";

// import types

// import components
import { BASE_ASSETS_URL } from "../../config";

type Inputs = {
  program_id: string;
  channel_name: string;
  start_time: string;
  end_time: string;
  language: string;
  type: string;
  program_name: boolean;
  icon_path: object;
  description: any;
};

const Edit: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const [hasImage, setHasImage] = useState<boolean>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [changeimage, setChangeImage] = useState({});
  // const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [selectedChannel, setSelectedChannel] = useState<{ title: string }>({
    title: "",
  });

  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [epgDetails, setEpgDetails] = React.useState<any>({});

  const [imageUrl, setImageUrl] = React.useState("");
  const [liveimageUrl, setLiveImageUrl] = useState("");


  const handleImage = (e: any) => {
    const url = URL.createObjectURL(e.target.files[0]);
    console.log(url);

    setHasImage(false);
    setImageUrl(url);
    setLiveImageUrl(url);
    setChangeImage(e.target.files[0]);
    setDisabled(false);
  };

  //fetch epg details
  const { data: dataEpg } = useGetOne("epg", {
    id: id,
  });

  //fetch channels
  const { data, total } = useGetList("channels", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  // useEffect(() => {
  //   if (dataEpg) {
  //     setEpgDetails(dataEpg);
  //     setImageUrl(dataEpg?.program_image);
  //     if (dataEpg?.program_image) setHasImage(true);
  //     setValue("channel_name", dataEpg?.channel_name);
  //     // setValue("program_id", dataEpg?.program_id);
  //     setValue(
  //       "start_time",
  //       moment(dataEpg?.start_time).utc().format("YYYY-MM-DDTHH:mm")
  //     );
  //     setValue(
  //       "end_time",
  //       moment(dataEpg?.end_time).utc().format("YYYY-MM-DDTHH:mm")
  //     );
  //     setValue("language", dataEpg?.language);
  //     setValue("type", dataEpg?.type);
  //     setValue("program_name", dataEpg?.program_name);
  //     setValue("description", dataEpg?.description);
  //     // Set default value for Autocomplete
  //     const defaultChannel = { title: dataEpg?.channel_name || "" };
  //     setSelectedChannel(defaultChannel);
  //     setIsDisabled(false);
  //   }
  // }, [dataEpg]);
  useEffect(() => {
    if (dataEpg) {
      setEpgDetails(dataEpg);
      setImageUrl(dataEpg?.program_image);

      setValue("channel_name", dataEpg?.channel_name);
      setValue("program_name", dataEpg?.program_name);
      setValue(
        "start_time",
        moment(dataEpg?.start_time).utc().format("YYYY-MM-DDTHH:mm")
      );
      setValue(
        "end_time",
        moment(dataEpg?.end_time).utc().format("YYYY-MM-DDTHH:mm")
      );
      setValue("language", dataEpg?.language);
      setValue("type", dataEpg?.type);
      setValue("description", dataEpg?.description);

      setSelectedChannel({ title: dataEpg?.channel_name || "" });
      setIsDisabled(false);
      const imageUrlFromObject = dataEpg?.icon_path?.src || '';
      setImageUrl(imageUrlFromObject);
      if (imageUrlFromObject) setHasImage(true);
    }
  }, [dataEpg, data]);

  // eslint-disable-line react-hooks/exhaustive-deps

  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   watch,
  //   setValue,
  //   formState: { errors, isDirty },
  // } = useForm<Inputs>();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    mode: "onChange", // Set the mode to 'onChange'
  });

  const channelName = watch("channel_name");
  const programName = watch("program_name");
  const description = watch("description");
  const startTime = watch("start_time");
  const endTime = watch("end_time");
  const language = watch("language");
  const type = watch("type");

  // Now you can use these variables to update isDirty
  const areFieldsDirty =
    channelName ||
    programName ||
    description ||
    startTime ||
    endTime ||
    language ||
    type;

  const isFormDirty = isDirty || areFieldsDirty;
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const formData: any = new FormData();

    data.icon_path = !liveimageUrl ? dataEpg?.icon_path:changeimage ;

    const epgData = {
      program_id: data.program_id,
      channel_name: selectedChannel.title,
      start_time: data.start_time,
      end_time: data.end_time,
      language: data.language,
      type: data.type,
      icon_path :data.icon_path,
      program_name: data.program_name,
      description: data.description,
    };
    console.log(epgData);
    

    dataProvider
      .update("epg", {
        id: `${id}`,
        data: epgData,
        previousData: {
          id: `${id}`,
        },
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your EPG was successfully updated", { type: "info" });
        navigate("/epg");
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };

  return (
    <Box sx={{ pl: 5, pt: 10 }}>
      <Title title="Create EPG" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={9} md={2}>
            <Autocomplete
              id="combo-box-demo"
              options={Array.isArray(data) ? data : []} // Ensure data is an array
              getOptionLabel={(option: any) => option["title"] || ""}
              placeholder="Channel Name*"
              value={selectedChannel}
              onChange={(event, value: any) => {
                setSelectedChannel(value);
                setIsDisabled(!isDisabled);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Channel Name*"
                  variant="outlined"
                  InputLabelProps={{ shrink: true, required: true }}
                  placeholder="Enter Channel Name"
                />
              )}
            />
          </Grid>
          <Grid item xs={9} md={2}>
            <TextField
              id="program_name"
              InputLabelProps={{ shrink: true, required: true }}
              label="Program Name*"
              placeholder="Enter Program Name"
              {...register("program_name", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={11} md={6}>
            <TextField
              id="description"
              InputLabelProps={{ shrink: true }}
              label="Description*"
              placeholder="Enter EPG Description"
              {...register("description", { required: true })}
              variant="outlined"
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={9} md={2}>
            <TextField
              id="start_time"
              placeholder="Start time*"
              InputLabelProps={{ shrink: true }}
              label="Start time*"
              type="datetime-local"
              {...register("start_time", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={9} md={2}>
            <TextField
              id="end_time"
              placeholder="End Time*"
              InputLabelProps={{ shrink: true }}
              label="End Time*"
              type="datetime-local"
              {...register("end_time", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ p: 2 }}></Box>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              id="language"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Language"
              label="Language"
              {...register("language", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box sx={{ display: "flex", mt: 1, mb: 0 }}>
              {(liveimageUrl || imageUrl)  ? (
                <img width={200}
                  src={liveimageUrl || imageUrl} 
                  style={{margin:20}}
                 
                />
              ) : (
                <Box
                style={{
                  width: 200,
                  height: 150,
                  marginRight: 8,
                  backgroundColor: 'darkgrey',
                  color: 'grey',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                No Image
              </Box>
            )}
            <Box>
                <Button
                  size="large"
                  color="primary"
                  component="label"
                  sx={{ mt: 6.5, ml: 2 }}
                >
                  Upload Image
                  <input
                    type="file"
                    onChange={(e) => {
                      handleImage(e);
                    }}
                    hidden
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ pt: 0, pb: 4 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          // disabled={!isDirty && disabled}
          disabled={!isFormDirty && disabled}
          onClick={handleSubmit(onSubmit)}
        >
          Update EPG
        </Button>
      </form>
    </Box>
  );
};

export default Edit;
