import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning, useUpdate } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, FormControlLabel, Checkbox, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import types
// import { AppState } from '../../types/Theme';
// import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    old_password: string,
    new_password: string,
    repeat_password: string
};

const ChangePassword: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        formData.append("old_password", data.old_password);
        formData.append("new_password", data.new_password);
        formData.append("repeat_password", data.repeat_password);

        dataProvider.update(
            'auth/change-password',
            {
                id: '',
                data: formData,
                previousData: {
                    id: ''
                }
            },
        ).then((response: any) => {
            notify('Password was successfully changed', { type: "info" });
            navigate("/")
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 6, pt: 10 }}>
            <Title title="Change Password" />
            <Box style={{ padding: 1 }}>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                id="old_password"
                                type="password"
                                InputLabelProps={{ shrink: true }}
                                placeholder=" Enter Current Password"
                                label="Current Password*"
                                {...register("old_password", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ pt: 1 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                style={{ marginTop: 10, marginBottom: 10 }}
                                id="new_password"
                                type="password"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Enter New Password"
                                label="New Password*"
                                {...register("new_password", { required: true, minLength: 8  })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ pt: 1 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                id="repeat_password"
                                type="password"
                                InputLabelProps={{ shrink: true }}
                                label="Repeat New Password*"
                                placeholder="Repeat New Password"
                                {...register("repeat_password", { required: true, minLength: 8  })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3} style={{ marginTop: 20 }}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default ChangePassword;