import { ReactElement,Fragment } from 'react';
import { FC } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    ListBase,
    Pagination,
    TextField,
    EditButton,
    TopToolbar,
    CreateButton,
    Title,
    useListContext,
} from 'react-admin';
import { Chip, Typography, Box, Avatar, Card, CardActionArea, Grid, makeStyles } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';

//import types
// import theme from '../../types/Theme';

import GridList from './GridList';

const ListActions: FC<any> = () => (
    <TopToolbar>
        <CreateButton resource="/users/cd" label="Invite User" />
    </TopToolbar>
);

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box style={{ padding: 20 }} textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No Users available.
            </Typography>
            <Typography variant="body1">
                Do you want to add one ?
            </Typography>
        </Box>
    );
};

// const BulkActionButtons = (props:any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <Box sx={{ pl: 4, pr: 3, pt: 1 }}>
            <Title title="Users & Roles" />
            <ListBase
                title="Users & Roles"
                empty={<Empty />}
                {...props}
                perPage={25}
            >
                <ListActions />
                <GridList />
                <Pagination rowsPerPageOptions={[25, 50, 100]} />
            </ListBase>
        </Box >
    )
}
export default ResourceList;