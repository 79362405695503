import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useNotify,
  useRefresh,
  useTranslate,
  Notification,
  useDataProvider,
  useGetList,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Avatar,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import moment from "moment";

// import types

// import components

type Inputs = {
  program_id: string;
  channel_name: string;
  start_time: string;
  end_time: string;
  language: string;
  type: string;
  program_name: boolean;
  description: any;
};

const Create: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const current = new Date();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [channels, setChannels] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>("");

  //fetch channels
  const { data, total } = useGetList("channels", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  useEffect(() => {
    setChannels(data);
  }, [data]);
  // eslint-disable-line react-hooks/exhaustive-deps

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const formData: any = new FormData();

    data.channel_name = selectedChannel;

    // // formData.append("program_id", data.program_id);
    // formData.append("channel_name", data.channel_name);
    // formData.append("start_time", data.start_time);
    // formData.append("end_time", data.end_time);
    // formData.append("language", data.language);
    // formData.append("type", data.type);
    // formData.append("program_name", data.program_name);
    // formData.append("description", data.description);


    const epgData = {
      program_id: data.program_id,
      channel_name: data.channel_name,
      start_time: data.start_time,
      end_time: data.end_time,
      language: data.language,
      type: data.type,
      program_name: data.program_name,
      description:data.description,
    };

    dataProvider
      .create("epg", {
        data: epgData,
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your EPG was successfully created");
        navigate("/epg");
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };

  return (
    <Box sx={{ pl: 5, pt: 10 }}>
      <Title title="Create EPG" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={9} md={2}>
            <Autocomplete
              id="combo-box-demo"
              options={data as any}
              getOptionLabel={(option: any) => option["title"] || ""}
              placeholder="Channel Name*"
              onChange={(event, value: any) => {
                setSelectedChannel(value["title"]);
                setIsDisabled(!isDisabled);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Channel Name*"
                  variant="outlined"
                  InputLabelProps={{ shrink: true, required: true }}
                  placeholder="Enter Channel Name"
                />
              )}
            />
          </Grid>
          <Grid item xs={9} md={2}>
            <TextField
              id="program_name"
              InputLabelProps={{ shrink: true }}
              label="Program Name*"
              placeholder="Enter Program Name"
              {...register("program_name", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={9} md={2}>
            <TextField
              id="program_id"
              InputLabelProps={{ shrink: true }}
              label="Program ID"
              placeholder="Enter Program ID"
              {...register("program_id")}
              variant="outlined"
              fullWidth
            />
          </Grid> */}
        </Grid>
        <Box sx={{ pt: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={11} md={6}>
            <TextField
              id="description"
              InputLabelProps={{ shrink: true }}
              label="Description*"
              placeholder="Enter EPG Description"
              {...register("description", { required: true })}
              variant="outlined"
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={9} md={2}>
            <TextField
              id="start_time"
              label="Start time*"
              defaultValue={moment(current).format("YYYY-MM-DDTHH:mm")}
              type="datetime-local"
              {...register("start_time")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={9} md={2}>
            <TextField
              id="end_time"
              label="End Time*"
              type="datetime-local"
              defaultValue={moment(current).format("YYYY-MM-DDTHH:mm")}
              {...register("end_time", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs={9} md={2}>
            <TextField
              id="language"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Language"
              label="Language*"
              {...register("language", { required: true })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ p: 4 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Create;
