import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useCreate } from 'react-admin';
import { makeStyles, createStyles, Dialog, DialogContent, DialogActions, Theme, Grid, Typography, TextField, Divider, Button, FormControlLabel, Checkbox, Avatar, FormHelperText, Box } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { BASE_URL } from "../../config";
import { Editor } from '@tinymce/tinymce-react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { AddBoxTwoTone, GifBox } from '@mui/icons-material';
import realmApp from '../../providers/realmConfig';
import * as Realm from "realm-web";
const {
    BSON: { ObjectId },
} = Realm;

interface OptionType {
    label?: string;
    name: string;
    inputValue?: string;
    _id?: string;
}

type Inputs = {
    service_name: string,
    short_service_description: string,
    service_description: string,
    service_menu: string,
    service_category: string,
    icon_path: object;
};

const Create: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState<boolean>(true);
    const [disabledImg, setDisabledImg] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = useState("");
    const [changeimage, setChangeImage] = useState({});
    const [imageToCrop, setImageToCrop] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);

    const handleImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setImageToCrop(url);
        setOpen(true);
        setChangeImage(e.target.files[0]);
        setDisabledImg(false)
    }

    const dataURItoBlob = (dataURI: any) => {

        let byteString;

        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const handleCrop = () => {
        const blob = dataURItoBlob(croppedImage);
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setChangeImage(blob);
        setOpen(false);
    }

    const cropperRef = useRef<HTMLImageElement>(null);
    const onCrop = () => {
        setOpen(true);
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    const filter = createFilterOptions<OptionType>();

    const [menu, setMenu] = React.useState<OptionType | any>(null);
    const [category, setCategory] = React.useState<OptionType | any>(null);
    const [allMenus, setAllMenus] = useState<OptionType | any>(null);
    const [allCategories, setAllCategories] = useState<OptionType | any>(null);

    const menu_agg = [
        {
            '$group': {
                '_id': '$service_menu',
                'count': {
                    '$sum': 1
                }
            }
        }
    ];


    const fetchServiceMenuData = async () => {
        try {
            if (realmApp.currentUser) {
                const currentUser = realmApp.currentUser
                    .mongoClient("mongodb-atlas")
                    .db(`${process.env.REACT_APP_DATABASE_TYPE}`)
                    .collection('services');

                const service_menu = await currentUser.aggregate(menu_agg);
                console.log("menu servicesssssss", service_menu);

                setAllMenus(service_menu);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const category_agg = [
        {
            '$group': {
                '_id': '$service_category',
                'count': {
                    '$sum': 1
                }
            }
        }
    ];


    const fetchServiceCategoryData = async () => {
        try {
            if (realmApp.currentUser) {
                const currentUser = realmApp.currentUser
                    .mongoClient("mongodb-atlas")
                    .db(`${process.env.REACT_APP_DATABASE_TYPE}`)
                    .collection('services');

                const service_category = await currentUser.aggregate(category_agg);
                console.log("services category log", service_category);

                setAllCategories(service_category);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const fetchMenuCategory = async () => {

    //     const requestOptions = {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem("access_token")}`
    //         },
    //     };

    //     fetch(`${BASE_URL}/api/v1/services/property/menus`, requestOptions)
    //         .then(response => response.json())
    //         .then((response) => {
    //             setAllMenus(response);
    //         });

    //     fetch(`${BASE_URL}/api/v1/services/property/categories`, requestOptions)
    //         .then(response => response.json())
    //         .then((response) => {
    //             setAllCategories(response);
    //         });

    // };

    const [longDescription, setHtmlLongDescription] = useState("");

    const handleEditorChange = (data: any) => {
        setHtmlLongDescription(`${data}`);
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {

        const formData: any = new FormData();

        data.icon_path = changeimage;
        data.service_description = longDescription;

        if (menu !== null) {
            data.service_menu = menu.name;
        }

        if (category !== null) {
            data.service_category = category.name;
        }

        // formData.append("service_name", data.service_name);
        // formData.append("short_service_description", data.short_service_description);
        // formData.append("service_description", data.service_description);
        // formData.append("service_menu", data.service_menu);
        // formData.append("service_category", data.service_category);

        const servicesdata = {
            service_name: data.service_name,
            short_service_description: data.short_service_description,
            service_description: data.service_description,
            service_menu: menu._id,
            service_category: category._id,
            icon_path: data.icon_path,
        }

        // if (Object.keys(data.service_path).length === 0 && data.service_path.constructor === Object === false) {
        //     formData.append("service_path", data.service_path);
        // }

        dataProvider.create(
            'services',
            {
                data: servicesdata
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your service was successfully created', { type: "info" });
            navigate("/services");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })

    }

    useEffect(() => {
        // fetchMenuCategory();
        fetchServiceMenuData();
        fetchServiceCategoryData();
    }, [])

    return (
        <Box sx={{ pl: 6, pt: 10 }}>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={4}>
                                <TextField sx={{ mb: 2 }}
                                    id="service_name"
                                    label="Service Name*"
                                    // placeholder="Service Name*"
                                    {...register("service_name", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={4}>
                                <Autocomplete
                                    value={menu}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string')
                                            setMenu({ name: newValue })
                                        else if (newValue && newValue.inputValue) {
                                            setMenu({
                                                name: newValue.inputValue,
                                                _id: newValue.inputValue
                                            });
                                        } else {
                                            setMenu(newValue);
                                        }
                                        setDisabled(false);
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        const isExisting = options.some((option: any) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                _id: `${params.inputValue}`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="service_menu"
                                    options={allMenus}
                                    getOptionLabel={(option: any) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        else if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        else return option._id;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option._id}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField sx={{ mb: 2 }} {...params} label="Service Menu*" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={9} md={4}>
                                <Autocomplete
                                    value={category}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setCategory({ name: newValue });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setCategory({
                                                name: newValue.inputValue,
                                                _id: newValue.inputValue
                                            });
                                        } else { setCategory(newValue); }
                                        setDisabled(false);
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option: any) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                _id: `${params.inputValue}`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="service_category"
                                    options={allCategories}
                                    getOptionLabel={(option: any) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option._id;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option._id}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Service Category*" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={12}>
                                <TextField
                                    id="short_service_description"
                                    placeholder="Short Description*"
                                    label="Short Description*"
                                    inputProps={{ maxLength: 1000 }}
                                    InputLabelProps={{ shrink: true }}
                                    {...register("short_service_description", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                />
                                <FormHelperText sx={{ fontSize: 11 }}>Description should not be more than 1000 character !</FormHelperText>
                            </Grid>
                            <Grid item xs={11} md={12}>
                                <Box sx={{ pt: 0, pb: 4 }}>
                                    <Editor
                                        apiKey="jo5ngogt8u99gz7wk0dr32waklwzwttoplcpqd473yd5s1ny"
                                        init={{
                                            height: 400,
                                            plugins: 'link image code',
                                            toolbar: 'undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright | code'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item md={5}>
                                <Box sx={{ display: 'flex', alignItems: "center", alignContent: "center" }}>
                                    <Box>
                                        {imageUrl ?
                                            <Box sx={{ pt: 3, pb: 2 }}>
                                                <img width={200} height={120} style={{ objectFit: "cover" }} src={imageUrl} />
                                            </Box>
                                            :
                                            <Avatar sx={{ mt: 5, ml: 2, mb: 3, width: 70, height: 70 }} alt="Logo" src={imageUrl}  />
                                        }
                                    </Box>
                                    <Box>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                            sx={{ ml: 2, mt: 2 }}
                                        >
                                            Upload Image
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box>
                            <FormHelperText sx={{ fontSize: 11 }}>Image should not be more than 10MB !</FormHelperText>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={(disabled || category === null || menu === null || disabledImg)}
                            onClick={handleSubmit(OnSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Box sx={{ p: 3, pb: 2 }}></Box>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>

            {open && <Dialog open={open}>
                <DialogContent sx={{ padding: 10 }}>
                    <Box>
                        <Cropper
                            src={imageToCrop}
                            style={{ height: 400, width: "100%" }}
                            // Cropper.js options
                            initialAspectRatio={16 / 9}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: 1 }}>
                    <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                    <Button
                        onClick={handleCrop}
                        variant="contained"
                    >
                        Crop
                    </Button>
                </DialogActions>
            </Dialog>}
        </Box>
    )
}

export default Create;