import React from 'react'
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import CreateChannelss from './CreateChannels';
import CreatePackagess from './CreatePackages';
import CreateVodss from './CreateVods';
import CreateEpgMetadatas from './CreateEpgMetadata';

function Create() {
	const [tabIndex, setTabIndex] = useState(0);

	const handleTabChange = (event: any, newTabIndex: any) => {
		setTabIndex(newTabIndex);
	};

	return (
		<Box>
			<Box sx={{ pt: 6, pl: 5 }}>
				<Tabs value={tabIndex} onChange={handleTabChange}>
					<Tab label="Create Packages" />
					<Tab label="Create Channels" />
					<Tab label="Create Vods" />
					<Tab label="Create EpgMetadata" />
				</Tabs>
			</Box>
			<Box sx={{ ml: 0, mt: -5, }}>
				{tabIndex === 0 && (
					<Box >
						<CreatePackagess />
					</Box>
				)}
				{tabIndex === 1 && (
					<Box>
						<CreateChannelss />
					</Box>
				)}
				{tabIndex === 2 && (
					<Box>
						<CreateVodss />
					</Box>
				)}
				{tabIndex === 3 && (
					<Box>
						<CreateEpgMetadatas />
					</Box>
				)}
			</Box>
		</Box>
	);
}


export default Create;