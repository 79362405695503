import * as React from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import {
    ListBase,
    Pagination,
    Title,
    useGetResourceLabel,
} from 'react-admin';
import GridList from './GridList';


const ResourceList = () => {
    const getResourceLabel = useGetResourceLabel();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <Box sx={{ pl: 2, pt: 6 }}>
            <ListBase perPage={25}>
                <Title defaultTitle={getResourceLabel('Packages', 2)} />
                <GridList />
                <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
            </ListBase>
        </Box>
    );

};

export default ResourceList;