import React, { useState, useEffect, useCallback, FC, CSSProperties } from 'react';
import { useTranslate, Title, useDataProvider, useRefresh, useNotify, useLogout } from 'react-admin';
import jwt_decode from "jwt-decode";
import { Typography, Card, Box } from '@mui/material';

// import components
import DashboardProvider from './provider/DashboardProvider';
import DashboardProperty from './property/DashboardProperty';
// import DashboardAdmin from './system-admin/DashboardAdmin';

const Dashboard = () => {

    const token = localStorage.getItem("access_token");

    const permissionsDashboard = () => {

        let decode: any = jwt_decode(`${token}`);

        if (decode['user'] == 'distributor') {
            return (
                <DashboardProvider />
            )
        }
        if (decode['user'] == 'property') {
            return (
                <DashboardProperty />
            )
        }
        // if (decode['user'] == 'system_admin') {
        //     return (
        //         <DashboardAdmin />
        //     )
        // }
    }

    return (
        <Box>
            {token &&
                <>
                    {permissionsDashboard()}
                </>
            }
        </Box>
    )
}

export default Dashboard;