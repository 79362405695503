import React, { FC } from 'react';
import { Typography, Card, Box, Button, ListItemSecondaryAction, List, ListItem, ListItemText } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';

// import types
import { BASE_ASSETS_URL } from '../../../config';

interface Props {
    appDetails: any
}

const CompleteAccount: FC<Props> = ({ appDetails }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    const AccountCompleted = () => {
        return (
            <>
                <Card sx={{
                    p: 4,
                    borderRadius: 10
                }}>
                    {appDetails.property_logo_path && <img style={{ marginBottom: 10 }} src={`${BASE_ASSETS_URL}${appDetails.property_logo_path}`} width={90} />}
                    <Typography style={{ color: "#ddd", fontSize: isXSmall ? 10 : 15 }}>Property ID - {appDetails.property_support_id}</Typography>
                    <Typography style={{ fontSize: isXSmall ? 18 : 25 }}>Hi, {appDetails.name}</Typography>
                    {!appDetails.latitude || !appDetails.longitude || !appDetails.wifi_password || !appDetails.reception_number || !appDetails.property_logo_path || !appDetails.property_app_background_path || !appDetails.property_video_path ?
                        <Box>
                            <Typography style={{ color: "grey", fontSize: isXSmall ? 10 : 15 }}>Your property is missing some important data</Typography>
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="App Personalization"
                                        secondary={
                                            <div style={{ width: "82%", marginTop: 10, color: "#ddd" }}>
                                                {"Missing "}
                                                {!appDetails.wifi_password ? "WiFi Password, " : null}
                                                {!appDetails.reception_number ? "Reception Number, " : null}
                                                {!appDetails.latitude ? "Latitude, " : null}
                                                {!appDetails.longitude ? "Longitude, " : null}
                                                {!appDetails.property_logo_path ? "Property Logo, " : null}
                                                {!appDetails.property_app_background_path ? "App Background, " : null}
                                                {!appDetails.property_video_path ? "Video Presentation " : null}
                                            </div>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Button size="small" color="secondary" variant="outlined" href="#/app-personalization">Continue</Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Box>
                        :
                        <Typography style={{ color: "grey" }}>Your property account is complete</Typography>
                    }
                </Card>
            </>
        )
    }

    return (
        <Box>
            {AccountCompleted()}
        </Box>
    );

}

export default CompleteAccount;