import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useGetOne } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types
import { BASE_ASSETS_URL } from '../../config';

type Inputs = {
    name: string,
    image: any
};

const Edit: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { id } = useParams();
    const [disabled, setDisabled] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = React.useState("");
    const [changeimage, setChangeImage] = useState({});

    const handleImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
        setDisabled(false);
    }

    const [menuDetails, setMenuDetails] = React.useState<any>({});

    //fetch device menus
    const { data: dataDevice, isLoading, error, refetch } = useGetOne(
        'device-menu',
        {
            id: id
        },
    );

    useEffect(() => {
        setMenuDetails(dataDevice);
        setValue("name", dataDevice?.name);
    }, [])

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        data.image = changeimage;

        formData.append("name", data.name);
        if (Object.keys(data.image).length === 0 && data.image.constructor === Object === false) {
            formData.append("image", data.image);
        }

        dataProvider.update<any>(
            'device-menu',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: id
                }
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Device menu was successfully updated', { type: "info" });
            navigate("/device-menu");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 5, pt: 10 }}>
            <Title title="Edit Device Menu" />
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={7}>
                        <Grid item md={4}>
                            <TextField
                                id="name"
                                InputLabelProps={{ shrink: true }}
                                label="Menu Name*"
                                placeholder="Enter Menu Name"
                                {...register("name")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={1}>
                            {imageUrl ?
                                <Avatar sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, width: 70, height: 70, }} alt="Logo" src={imageUrl} />
                                :
                                <Avatar sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, width: 70, height: 70, }} alt="Logo" src={`${BASE_ASSETS_URL}${menuDetails.image}`} />
                            }
                        </Grid>
                        <Grid item md={2}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                component="label"
                                style={{ marginTop: 25 }}
                            >
                                Upload Image
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        handleImage(e);
                                    }}
                                    hidden
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Box style={{ padding: 20 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty && disabled}
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Edit;