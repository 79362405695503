import React,{Fragment} from 'react'
import GridList from './GridList';
import {
  ListBase,
  CreateButton,
  Title,
  TopToolbar,
} from 'react-admin';
import { Box } from '@mui/material';
// import BulkDelete from '../../components/BulkDelete';

const ListActions = () => (
  <TopToolbar>
      <CreateButton resource="/users/property" label="Invite User" />
  </TopToolbar>
);

// const BulkActionButtons = (props:any) => (
//   <Fragment>
//       <BulkDelete {...props} />
//   </Fragment>
// );

const ResourceList = () => {
  return (
    <Box sx={{ pl: 1, pr: 3 }}>
      <Title title="Users & Roles" />
      <ListBase
        perPage={25}
        // bulkActionButtons={<BulkActionButtons />}

      >
        <ListActions />
        <GridList />
      </ListBase>
    </Box>
  )
}

export default ResourceList;