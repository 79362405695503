import React, { FC, useState, useEffect, useCallback, ReactElement } from 'react';
import { useQuery } from 'react-query';
import { Title, useNotify, useRefresh, useDataProvider, useGetList, useCreate } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Grid, Typography, TextField, Avatar, Button, FormControlLabel, Checkbox, Stepper,
    Step, StepLabel, StepContent, Paper, Autocomplete, Box, createFilterOptions
} from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import { BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";

// import types
import { EditChannelStreams } from '../../types/Streams';

// import components
import VODPreview from '../vods/components/VODPreview';
import EditStreams from '../vods/components/EditStreams';
import realmApp from '../../providers/realmConfig';
import * as Realm from "realm-web";
import DataProvider from '../../providers/data-provider';
// import VodMenuList from './VodMenuList';
// console.log("VodMenuList:", VodMenuList);
const {
    BSON: { ObjectId },
} = Realm;

interface OptionType {
    label?: string;
    name: string;
    inputValue?: string;
    _id?: string
}

type Inputs = {
    title: string,
    overview: string,
    vod_category: string,
    vod_menu: string,
    packages: any,
    adult: boolean,
    streams: object,
    icon_path: object,
    backdrop: object,
    original_title: string,
    cast: string,
    director: string,
    original_language: string,
    budget: number,
    release_date: Date,
    vote_average: number,
    vote_count: number,
    popularity: number,
    revenue: number,
    is_available: boolean,
    pin_protected: boolean,
    spoken_languages: string
};

const Create: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [imageUrl, setImageUrl] = React.useState("");
    const [changeimage, setChangeImage] = useState({});
    const [disabledImg, setDisabledImg] = useState<boolean>(true);

    const handleImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
        setDisabledImg(false);
      };
    const [backdropUrl, setBackdropUrl] = React.useState("");
    const [changeBackdrop, setChangeBackdrop] = useState({});

    const handleBackdrop = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setBackdropUrl(url);
        setChangeBackdrop(e.target.files[0]);
    }

    const [isAdult, setIsAdult] = useState(false);

    const handleChangeIsAdult = (event: any) => {
        setIsAdult(event.target.checked);
    }

    const [pinProtected, setPinProtected] = useState(false);

    const handleChangePinProtected = (event: any) => {
        setPinProtected(event.target.checked);
    }

    const [isAvailable, setIsAvailable] = useState(false);

    const handleChangeIsAvailable = (event: any) => {
        setIsAvailable(event.target.checked);
    }

    const [selectedSpokenLanguages, setSelectedSpokenLanguages] = useState([]);
    const [spokenLanguages, setSpokenLanguages] = useState([{
        "iso_639_1": "en",
        "name": "English"
    },
    {
        "iso_639_1": "sq",
        "name": "Shqip"
    },
    {
        "iso_639_1": "es",
        "name": "Español"
    },
    {
        "iso_639_1": "fr",
        "name": "Français"
    },
    {
        "iso_639_1": "de",
        "name": "Deutsch"
    },
    {
        "iso_639_1": "it",
        "name": "Italiano"
    },
    {
        "iso_639_1": "pt",
        "name": "Português"
    },
    ]
    );

    const [selectedPackages, setSelectedPackages] = useState<any>([]);
    const [allPackages, setAllPackages] = useState<any>([]);

    // fetch packages

    const { data: packages, total: totalPackages, isLoading: loadingPackage, error: errorPackage } = useGetList<any>(
        'packages',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const filter = createFilterOptions<OptionType>();
    const [menu, setMenu] = React.useState<OptionType | any>(null);
    const [category, setCategory] = React.useState<OptionType | any>(null);
    const [allMenus, setAllMenus] = useState<OptionType | any>(null);
    const [allCategories, setAllCategories] = useState<OptionType | any>(null);

    const menu_agg = [
        {
            '$group': {
                '_id': '$vod_menu',
                'count': {
                    '$sum': 1
                }
            }
        }
    ];
    
    
    const fetchVodMenuData = async () => {
        try {
            if (realmApp.currentUser) {
                const currentUser = realmApp.currentUser
                    .mongoClient("mongodb-atlas")
                    .db(`${process.env.REACT_APP_DATABASE_TYPE}`)
                    .collection('vods');
    
                const vod_menu = await currentUser.aggregate(menu_agg);
                console.log("-----------------",vod_menu);
                
                setAllMenus(vod_menu);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const category_agg = [
        {
            '$group': {
                '_id': '$vod_category',
                'count': {
                    '$sum': 1
                }
            }
        }
    ];
    
    
    const fetchVodCategoryData = async () => {
        try {
            if (realmApp.currentUser) {
                const currentUser = realmApp.currentUser
                    .mongoClient("mongodb-atlas")
                    .db(`${process.env.REACT_APP_DATABASE_TYPE}`)
                    .collection('vods');
                
                const vod_category = await currentUser.aggregate(category_agg);
    
                console.log("Vodd category: ", vod_category);
                setAllCategories(vod_category);

            }
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    

    // const fetchMenuCategory = async () => {
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem("access_token")}`
    //         },
    //     };

    //     fetch(`${BASE_URL}/api/v1/vod/cd/menus`, requestOptions)
    //         .then(response => response.json())
    //         .then((response) => {
    //             setAllMenus(response);
    //         });

    //     fetch(`${BASE_URL}/api/v1/vod/cd/categories`, requestOptions)
    //         .then(response => response.json())
    //         .then((response) => {
    //             setAllCategories(response);
    //         });
    // };

    // console.log(menu, "menu");

    useEffect(() => {
        fetchVodMenuData();
        fetchVodCategoryData();
    }, []);


    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {

        // const formData: any = new FormData();

        // if (menu !== null) {
        //     data.vod_menu = menu.name;
        // }

        // if (category !== null) {
        //     data.vod_category = category.name;
        // }

        data.packages = selectedPackages;
        data.spoken_languages = selectedSpokenLanguages;
        data.icon_path = changeimage;
        data.streams = streams;
        data.backdrop = changeBackdrop;

        // // formData.append("title", data.title);
        // // formData.append("original_title", data.original_title);
        // // formData.append("original_language", data.original_language);
        // // formData.append("budget", data.budget);
        // // formData.append("spoken_languages", JSON.stringify(data.spoken_languages));
        // // formData.append("release_date", data.release_date);
        // // formData.append("vote_count", data.vote_count);
        // // formData.append("revenue", data.revenue);
        // // formData.append("popularity", data.popularity);
        // // formData.append("vote_average", data.vote_average);
        // // formData.append("cast", data.cast);
        // // formData.append("director", data.director);
        // // formData.append("overview", data.overview);
        // // formData.append("packages", JSON.stringify(data.packages));
        // // formData.append("vod_category", data.vod_category);
        // // formData.append("adult", data.adult);
        // // formData.append("is_available", data.is_available);
        // // formData.append("pin_protected", data.pin_protected);
        // // formData.append("vod_menu", data.vod_menu);
        // // formData.append("image_path", data.image_path);
        // // formData.append("backdrop", data.backdrop);
        // // formData.append("streams", JSON.stringify(data.streams));

        const vodsdata = {
            title: data.title,
            original_title: data.original_title,
            original_language: data.original_language,
            budget: data.budget,
            spoken_languages: data.spoken_languages,
            release_date: data.release_date,
            vote_count: data.vote_count,
            revenue: data.revenue,
            popularity: data.popularity,
            vote_average: data.vote_average,
            cast: data.cast,
            director: data.director,
            overview: data.overview,
            packages: data.packages,
            vod_category: category._id,
            adult: data.adult,
            is_available: data.is_available,
            pin_protected: data.pin_protected,
            vod_menu: menu._id,
            icon_path: data.icon_path,
            backdrop: data.backdrop,
            streams: data.streams
        }

        
        dataProvider.create(
            'vods',
            {
                data: vodsdata
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your Vod was successfully created', { type: "info" });
            navigate("/vods");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    const watchTitle = watch("title", "");
    const watchOriginalTitle = watch("original_title", "");
    const watchOriginalLanguage = watch("original_language", "");
    const watchDescription = watch("overview", "");
    const watchBudget = watch("budget");
    const watchCast = watch("cast");
    const watchDirector = watch("director");
    const watchRevenue = watch("revenue");
    const watchDate = watch("release_date");
    const watchAverage = watch("vote_average");
    const watchCount = watch("release_date");
    const watchPopularity = watch("popularity");

    const [streamDetails, setStreamDetails] = React.useState<any>([]);
    const [streams, setStreams] = useState<any>([]);

    const getStateStreams: EditChannelStreams = (data: any) => {
        setStreams(data);
        setStreamDetails(data);
    }

    const getSteps = () => {
        return ['Movie Details', 'Additional Details', 'Category & Packages', 'Media Options', 'Configure Streams'];
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={{ ml: 2, mt: 5, borderRadius: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="title"
                                    label="Title*"
                                    placeholder="Enter Title"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("title", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="original_title"
                                    label="Original Title*"
                                    placeholder="Original Title"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    {...register("original_title")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="original_language"
                                    label="Original Language*"
                                    placeholder="Original Language"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    {...register("original_language")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 1 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={9}>
                                <TextField
                                    id="overview"
                                    label="Movie Overview*"
                                    placeholder="Movie Overview"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    {...register("overview")}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                />
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={isAdult} {...register("adult")} onChange={handleChangeIsAdult} name="adult" />}
                                        label="Adult Content"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={pinProtected} {...register("pin_protected")} onChange={handleChangePinProtected} name="pin_protected" />}
                                        label="Pin Protected"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={isAvailable} {...register("is_available")} onChange={handleChangeIsAvailable} name="is_available" />}
                                        label="Is Available"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )
            case 1:
                return (
                    <Box sx={{ ml: 2, mt: 5, borderRadius: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <TextField
                                    id="budget"
                                    label="Budget"
                                    placeholder="Budget"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    {...register("budget", {
                                        min: {
                                            value: 0,
                                            message: 'Value must be a positive number'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.budget ? `${errors.budget.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    id="revenue"
                                    label="Revenue"
                                    placeholder="Revenue"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    {...register("revenue", {
                                        min: {
                                            value: 0,
                                            message: 'Value must be a positive number'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.revenue ? `${errors.revenue.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="release_date"
                                    label="Release Date"
                                    placeholder="Release Date"
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    defaultValue=""
                                    {...register("release_date")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="vote_average"
                                    label="Vote Average"
                                    placeholder="Vote Average"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    {...register("vote_average", {
                                        min: {
                                            value: 0,
                                            message: 'Value must be a positive number'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.vote_average ? `${errors.vote_average.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    id="vote_count"
                                    label="Vote Count"
                                    placeholder="Vote Count"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    {...register("vote_count", {
                                        min: {
                                            value: 0,
                                            message: 'Value must be a positive number'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.vote_count ? `${errors.vote_count.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <TextField
                                    id="popularity"
                                    label="Popularity"
                                    placeholder="Popularity"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    {...register("popularity", {
                                        min: {
                                            value: 0,
                                            message: 'Value must be a positive number'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.popularity ? `${errors.popularity.message}` : ""}
                                />
                            </Grid>
                            <Grid item xs={9} md={4}>
                                <Autocomplete
                                    multiple
                                    id="spoken_languages"
                                    options={spokenLanguages}
                                    getOptionLabel={(option: any) => option['name']}
                                    onChange={(event, value: any) => setSelectedSpokenLanguages(value)}
                                    value={selectedSpokenLanguages}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Spoken Languages"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="cast"
                                    label="Cast"
                                    placeholder="Cast"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    {...register("cast")}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.cast ? `${errors.cast.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    id="director"
                                    label="Director"
                                    placeholder="Director"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    {...register("director")}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.director ? `${errors.director.message}` : ""}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )
            case 2:
                return (
                    <Box sx={{ ml: 2, mt: 5, borderRadius: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                {packages &&
                                    <Autocomplete
                                        multiple
                                        id="packages-tags"
                                        options={packages as any}
                                        getOptionLabel={(option: any) => option['package_name']}
                                        onChange={(event, value: any) => setSelectedPackages(value)}
                                        value={selectedPackages}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Package"
                                            />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    style={{ marginRight: 20 }}
                                    value={menu}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setMenu({
                                                name: newValue
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setMenu({
                                                name: newValue.inputValue,
                                                _id: newValue.inputValue
                                            });
                                        } else {
                                            setMenu(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option: any) => inputValue === option);

                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                _id: `${params.inputValue}`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="vod_menu"
                                    options={allMenus}
                                    getOptionLabel={(option: any) => {
                           
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        else if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        else if (option._id) {
                                            return option._id;
                                        }
                                        // Regular option
                                        else return option._id;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option._id}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Menu" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    value={category}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setCategory({ name: newValue });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setCategory({
                                                name: newValue.inputValue,
                                                _id: newValue.inputValue
                                            });
                                        } else {
                                            setCategory(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option: any) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                _id: `${params.inputValue}`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="vod_category"
                                    options={allCategories}
                                    getOptionLabel={(option: any) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option._id;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option._id}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Category" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )
            case 3:
                return (
                    <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                <Box sx={{ display: 'flex', alignItems: "center", alignContent: "center", justifyContent: "center", height: 200, justifyItems: "center" }}>
                                    <Box>
                                        {imageUrl ?
                                            <img width={145} height={200} style={{ objectFit: "cover", borderRadius: "5px" }} src={imageUrl} />
                                            :
                                            <Avatar sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, width: 80, height: 80, }} alt="Poster" src="/static/images/avatar/1.jpg" />
                                        }
                                    </Box>
                                    <Box>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                            style={{ marginTop: 6, marginLeft: 8, whiteSpace: "nowrap" }}
                                        >
                                            Upload Poster
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={5}>
                                <Box sx={{ display: 'flex', alignItems: "center", alignContent: "center", justifyContent: "center", justifyItems: "center", height: 200 }}>
                                    <Box>
                                        {backdropUrl ?
                                            <img width={145} height={200} style={{ objectFit: "cover", borderRadius: "5px", position: "relative", }} src={backdropUrl} />
                                            :
                                            <Avatar sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, width: 80, height: 80, }} alt="Backdrop" src="/static/images/avatar/1.jpg" />
                                        }
                                    </Box>
                                    <Box>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                            style={{ marginTop: 6, marginLeft: 8, whiteSpace: "nowrap" }}
                                        >
                                            Upload Backdrop
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleBackdrop(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )
            case 4:
                return (
                    <Box sx={{ ml: 2, mt: 5, borderRadius: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <EditStreams editChannelStreams={getStateStreams} initStreams={streamDetails} />
                            </Grid>
                        </Grid>
                    </Box>
                )
            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const nextButton = () => {

        if (activeStep === 0) {
            if (watchTitle && watchOriginalTitle && watchOriginalLanguage && watchDescription) {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={false}
                    >
                        Next
                    </Button>
                )
            } else {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={true}
                    >
                        Next
                    </Button>
                )
            }
        }
        else if (activeStep === 1) {
            if (activeStep === 1 && watchBudget && watchRevenue && watchDate && watchAverage && watchCount && watchPopularity && selectedSpokenLanguages.length) {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={false}
                    >
                        Next
                    </Button>
                )
            } else {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={true}
                    >
                        Next
                    </Button>
                )
            }
        }
        else if (activeStep === 2) {
            if (menu && category) {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={false}
                    >
                        Next
                    </Button>
                )
            } else {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={true}
                    >
                        Next
                    </Button>
                )
            }
        }
        else if (activeStep === 3) {
            if (imageUrl && backdropUrl) {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginTop: 10, marginLeft: 8 }}
                        disabled={false}
                    >
                        Next
                    </Button>
                )
            } else {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        sx={{ marginTop: 0, marginLeft: 8 }}
                        disabled={true}
                    >
                        Next
                    </Button>
                )
            }
        }
    }

    return (
        <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
            <Title title="Create Video On Demand" />
            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Stepper style={{ backgroundColor: "transparent" }} activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    <Typography>{getStepContent(index)}</Typography>
                                    <Box sx={{ marginTop: 10, marginBottom: 2, }}>
                                        <Box>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ marginTop: 1, marginLeft: 1, }}
                                            >
                                                Back
                                            </Button>

                                            {activeStep === steps.length - 1 ?
                                                <Button
                                                    sx={{ marginTop: 1, marginLeft: 1 }}
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    type="submit"
                                                    onClick={handleSubmit(OnSubmit)}
                                                >
                                                    Submit
                                                </Button>
                                                :
                                                nextButton()
                                            }
                                        </Box>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item md={4}>
                    {/* <TitleSection title="VOD Preview" description="VOD Preview" paddingBottom={10} paddingLeft={1} paddingTop={0} /> */}
                    <VODPreview
                        title={watchTitle}
                        description={watchDescription}
                        path={imageUrl}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}

export default Create;