import * as React from 'react';
import { AppBar, Logout, UserMenu, usePermissions, ToggleThemeButton, useGetList, useGetOne } from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    useMediaQuery,
    Theme,
    createTheme
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Logo from './Logo';
import { darkTheme, lightTheme } from './themes';
import jwtDecode from 'jwt-decode';
import Subscription from '../pages/account-subscription/components/Subscription';
import AccountSettingsProperty from './components/SettingsProperty';
import AccountSettingsContentProvider from './components/AccountSettingsContentProvider';
import AccountProperty from './components/AccountProperty';
import SwitchToCD from './components/SwitchToCD';
import AccountSettingsSystemAdmin from './components/AccountSettingsSystemAdmin';
import UserGuiding from './user-guiding/UserGuiding';

const CustomAppBar = (props: any) => {

    const token = localStorage.getItem("access_token");

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    if (token) {

        let decode: any = jwtDecode(`${token}`);

        console.log("decode",decode["user"]);
        

        return (
            <AppBar
                userMenu={
                    <>
                        {decode["user"] === "property" && <SwitchToCD />}
                        {decode["user"] === "property" && <AccountProperty />}
                        {decode["user"] === "distributor" && <AccountSettingsContentProvider />}
                        {decode["user"] === "distributor" && <UserGuiding />}
                        {decode["user"] === "system_admin" && <AccountSettingsSystemAdmin />}
                    </>
                }
            >

                <Box sx={{ position: "relative", mt: 0.5 }}>
                    {isLargeEnough && <Link to="/"> <Logo /> </Link>}
                </Box>

                {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}

                {decode["user"] === "property" &&
                    <>
                        {isXSmall ?
                            ""
                            :
                            <Subscription />
                        }
                        <AccountSettingsProperty />
                    </>
                }

                {/* <ToggleThemeButton
                    darkTheme={darkTheme}
                    lightTheme={lightTheme}
                /> */}

            </AppBar>
        );

    } else {

        return (
            <Box></Box>
        )

    }

};

export default CustomAppBar;