import React, { Fragment, cloneElement } from 'react'
import {
    BooleanField,
    Datagrid,
    NumberField,
    List,
    FunctionField,
    TextField,
    ExportButton,
    CreateButton,
    sanitizeListRestProps,
    useListContext,
    TopToolbar,
    TextInput,
    Pagination,
    downloadCSV,
    EditButton,
    ReferenceField,
    ChipField
} from 'react-admin';
import { Chip, Typography, Box, Stack, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { useMediaQuery, Theme } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

// import components
import { BASE_ASSETS_URL } from '../../config';
import AssignMenuCategory from './dialogs/AssignMenuCategory';
import BulkDelete from '../../components/BulkDelete';
import FindFromTMDB from './components/FindFromTMDB';
import AssignPackages from './dialogs/AssignPackages';

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <FindFromTMDB />
            <CreateButton resource={resource} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

const vodFilters = [
    <TextInput sx={{ mb: 2 }} source="q" variant="outlined" label="Search" alwaysOn />
];

const Empty = () => {
    // const {  resource } = useListContext();
    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any </Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box sx={{ mt: 3 }}>
                <CreateButton />
            </Box>
            <Box sx={{ mt: 3, pl: 5 }}>
                <Box sx={{ mt: 3, textAlign: "center" }}>
                    <FindFromTMDB />
                </Box>
            </Box>
        </Box>
    );
};

const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

    fetchRelatedRecords(records, "package", "packages").then((pcg: any) => {
        fetchRelatedRecords(records, "property_operator_id", "properties/cd").then((sc: any) => {

            let data: any = [];

            records.map((record: any) => {
                console.log(pcg[record?.packages])
                data.push({
                    ...record,

                    videos: { results: { iso_639_1: " ", iso_3166_1: " ", name: " ", key: " ", site: " ", size: " ", type: " ", official: " ", published_at: " ", id: " " } },
                    spoken_languages: { english_name: " " },
                    // genres: { id: " " },
                    production_companies: { id: " ", iso_3166_1: " ", name: " " },
                    production_countries: { iso_3166_1: " ", name: " " },
                    streams: { streamSource: " ", streamUrl: " ", streamFormat: " ", streamType: " ", token: " ", },
                    // packages: { package_name: pcg[record?.package]?.package_name, },
                    content_distributor_id: " ",
                    image_path: " ",
                    vod_menu: " ",
                    backdrop_path: " ",
                    belongs_to_collection: " ",
                    budget: " ",
                    homepage: " ",
                    imdb_id: " ",
                    original_title: " ",
                    overview: " ",
                    popularity: " ",
                    __v: " ",
                    poster_path: " ",
                    release_date: " ",
                    backdrop: " ",
                    revenue: " ",
                    _id: " ",
                    tagline: " ",
                    video: " ",
                    runtime: " ",
                    status: " ",
                    id: " ",
                    vote_average: " ",
                    vote_count: " ",
                })

            });

            return jsonExport(data, {
                headers: ['genres.id', 'title', 'genres.name', 'original_language', 'vod_category', 'packages.package_name', 'adult',],
                rename: ['Id', 'Title', "Menu", "Language", "Category", "Package Name", 'Adult', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
            }, (err: any, csv: any) => {
                downloadCSV(csv, 'vod');
            });
        })
    })

};

const BulkActionButtons = (props: any) => (
    <Fragment>
        <BulkDelete {...props} />
        <Box sx={{ mb: 1, mt: 1 }}>
            <AssignMenuCategory {...props} />
        </Box>
        <Box sx={{ mb: 1, mt: 1 }}>
            <AssignPackages {...props} />
        </Box>
    </Fragment>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    title="Video On Demand"
                    perPage={25}
                    filters={vodFilters}
                    bulkActionButtons={<BulkActionButtons />}
                    pagination={<ListPagination />}
                    actions={<ListActions />}
                    empty={<Empty />}
                    exporter={exporter}
                >
                    <Datagrid
                        optimized
                        rowClick="edit"
                    >
                        <TextField label="Title" source="title" />
                        <FunctionField
                            label="Menu"
                            render={(record: any) => {
                                if (record.vod_menu) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.vod_menu} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Menu</Typography>
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>

                :
                <List
                    title="Video On Demand"
                    perPage={25}
                    filters={vodFilters}
                    bulkActionButtons={<BulkActionButtons />}
                    pagination={<ListPagination />}
                    actions={<ListActions />}
                    empty={<Empty />}
                    exporter={exporter}
                >
                    <Datagrid
                        optimized
                    >
                        <FunctionField
                            label="Image"
                            render={(record: any) => {
                                if (record.image_path) {
                                    return (
                                        <img style={{ height: "auto", width: 50 }} src={`${BASE_ASSETS_URL}${record.image_path}`} />
                                    )
                                }
                            }}
                        />
                        <TextField label="Title" source="title" />
                        <FunctionField
                            label="Menu"
                            render={(record: any) => {
                                if (record.vod_menu) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.vod_menu} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Menu</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="Category"
                            render={(record: any) => {
                                if (record.vod_category) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.vod_category} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Category</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="Packages"
                            render={(record: any) => {
                                if (record.packages) {
                                    if (record.packages.length > 0) {
                                        if (record.packages.length > 4) {
                                            return (
                                                record.packages.map((data: any) => (
                                                    <ChipField key={data.package_name} record={data} source="package_name" />))
                                            )
                                        } else {
                                            return (
                                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                                    {record.packages.map((data: any) => (
                                                        <Box>
                                                            <ChipField record={data} source="package_name" />
                                                        </Box>
                                                    ))}
                                                </Stack>
                                            )
                                        }
                                    } else {
                                        return <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Package</Typography>
                                    }
                                }
                            }}
                        />
                        {/* <ReferenceField label="Packages" source="packages" reference="packages">
                            <ChipField source="package_name" />
                        </ReferenceField> */}

                        <FunctionField
                            label="Streams"
                            render={(record: any) => {
                                if (record.streams) {
                                    if (record.streams.length > 0) {
                                        return (
                                            <CheckCircleIcon sx={{ color: "#57e157" }} />
                                        )
                                    } else {
                                        return <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Stream</Typography>
                                    }
                                }
                            }}
                        />
                        <BooleanField label="Adult" source="adult" />
                        <BooleanField label="Available" source="is_available" />
                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;