import React, { FC, useState, useEffect, useCallback } from 'react';
import { useUnselectAll, BulkActionProps, useNotify, useRefresh, Identifier, useUpdateMany, useDataProvider, useDeleteMany, useGetOne, DeleteButton } from 'react-admin';
import { makeStyles, createStyles, Stepper, Theme, Checkbox, Step, StepLabel, StepContent, Grid, Dialog, FormLabel, FormControlLabel, InputLabel, Divider, MenuItem, FormControl, Typography, Select, Button, DialogContent, TextField, DialogActions, DialogTitle } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import jwt_decode from "jwt-decode";
import { Box } from '@mui/system';
interface Props {
    user: object,
}

const RemoveUserDialog: FC<Props> = ({ user }) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    //dialog open/close

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        refresh();
        setOpen(false);
    };

    const [confirmation, setConfirmation] = useState("");

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);

    const handleRemoveUser = () => {
        const formData = new FormData();

        formData.append("propertyId", decode['property_operator_id']);

        dataProvider.update<any>(
            `users/property`,
            {
                id: (user as any)['_id'] as any,
                data: formData,
                previousData: {
                    id: ''
                }
            },
        ).then((response: any) => {
            notify('User was successfully removed', { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ paddingLeft: 0 }}>
            <Typography variant="subtitle2" style={{ cursor: "pointer", fontSize: 10 }} onClick={handleClickOpen} >Remove User</Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography style={{ marginBottom: 12 }}>Please type <span style={{ fontWeight: "bold" }}>
                        {(user as any)["email"]}
                    </span>  to confirm deletion</Typography>
                    <TextField
                        id="channels"
                        InputLabelProps={{ shrink: true }}
                        placeholder={`Please type ${(user as any)["email"]} to confirm`}
                        variant="outlined"
                        fullWidth
                        onChange={(e: any) => setConfirmation(e.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: 5 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={(confirmation === `${(user as any)["email"]}`) ? false : true}
                        onClick={handleRemoveUser}
                        style={(confirmation === `${(user as any)["email"]}`) ? { color: "red" } : { color: "" }}
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}

export default RemoveUserDialog;