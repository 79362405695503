import List from './List';
import Create from './Create';
import Edit from './Edit';

const resource = {
    list: List,
    create: Create,
    edit: Edit
};

export default resource;