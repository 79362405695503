import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, TextField, Divider, Button, Grid, List, Dialog, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useDataProvider, useNotify, useRefresh, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { useMediaQuery, Theme } from '@mui/material';

// import config
import { BASE_URL } from '../../config';

// components
import ManageProperties from './ManageProperties';

const AccountProperty: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    let id = decode['property_operator_id'];

    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor: 'right', open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const { data: dataAllProperties, total, isLoading, error, refetch } = useGetList(
        'auth/all-properties',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const switchAccount: SubmitHandler<any> = (data: any) => {
        const formData: any = new FormData();

        formData.append("property_id", data['_id']);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/auth/switch-property`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                localStorage.setItem("access_token", response.accessToken);
                notify('Your property switched successfully', { type: "info" });
                setState({ right: false });
                refresh();
                navigate("/");
            })
    }

    const { data: dataProperty, isLoading: loadingProperty, error: errorProperty } = useGetOne(
        'properties',
        {
            id: id
        },
    );

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box>
            {
                (dataAllProperties?.length as any > 0 ) &&
                <Box>
                    {dataProperty &&
                        <>
                            <Button sx={{ ml: 1, fontSize: 12, fontWeight: "bold", paddingInline: 3 }} onClick={toggleDrawer('right', true)} variant="text" >
                                {dataProperty['name']} <ExpandMoreOutlinedIcon />
                            </Button>
                            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                                <Box sx={{ width: isXSmall ? 300 : 400 }}>
                                    <Box sx={{ mr: 1.5, mt: 2 }}>
                                        <ManageProperties />
                                    </Box>
                                    {dataAllProperties?.map((property: any, index: any) => (
                                        <div key={index}>
                                            <List>
                                                <ListItem button onClick={() => switchAccount(property)}>
                                                    <ListItemIcon><LocationCityIcon /></ListItemIcon>
                                                    <ListItemText style={{ textTransform: "uppercase", fontSize: 10 }} primary={property['name']} />
                                                    {dataProperty['id'] == property['id'] ? <span style={{ color: "gray" }}>Selected</span> : ""}
                                                </ListItem>
                                            </List>
                                            <Divider />
                                        </div>
                                    ))}
                                </Box>
                            </Drawer>
                        </>
                    }
                </Box>
            }
        </Box>
    )
}

export default AccountProperty;