import React, { FC, useState, useCallback, useEffect, cloneElement, Fragment, ReactElement } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    FunctionField,
    TextField,
    EditButton,
    useListContext,
    CreateButton,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
    ReferenceField,
    TextInput,
    Pagination,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import moment from 'moment';
import { Title, useDataProvider, useRefresh, useNotify, Button } from 'react-admin';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { makeStyles, createStyles, Typography, Box } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import UpgradeDialog from './dialogs/UpgradeDialog';
import { useMediaQuery, Theme } from '@mui/material';

const BulkActionButtons = (props: any) => (
    <Fragment>
        <UpgradeDialog {...props} />
        <BulkDelete {...props} />
    </Fragment>
);

const devicesFilters = [
    // <ReferenceInput sx={{ mb: 2, ml: 5 }} variant="outlined" alwaysOn source="property_operator_id" reference="properties">
    //     <AutocompleteInput
    //         size="small"
    //         sx={{ mb: 2 }}
    //         optionText={(choice?: any) =>
    //             choice?.id // the empty choice is { id: '' }
    //                 ? `${choice.name}`
    //                 : ''
    //         }
    //     />
    // </ReferenceInput>,
     <TextInput sx={{ mb: 2 }} source="q" variant="outlined" label="Search" alwaysOn />
];

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        // currentSort,
        resource,
        displayedFilters,
        filterValues,
        // hasCreate,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const dataProvider = useDataProvider();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const Empty = () => {
    const { resource } = useListContext();

    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
        </div>
    );
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = (props: ListProps): ReactElement => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    title="Devices"
                    filters={devicesFilters}
                    actions={<ListActions />}
                    empty={<Empty />}
                    pagination={<ListPagination />}
                    bulkActionButtons={<BulkActionButtons />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid rowClick="edit">
                        <ReferenceField label="Property" source="property_operator_id" reference="properties">
                            <TextField source="name" />
                        </ReferenceField>
                        <FunctionField label="Screen Number" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.device_screen_number}</Typography>
                            )
                        }} />;
                        {/* <TextField label="Screen Number" source="device_screen_number" /> */}
                        <EditButton />
                    </Datagrid>
                </List>
                :
                <List
                    title="Devices"
                    filters={devicesFilters}
                    actions={<ListActions />}
                    empty={<Empty />}
                    pagination={<ListPagination />}
                    bulkActionButtons={<BulkActionButtons />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid rowClick="edit">
                        <TextField label="App Name" source="app_name" />
                        <TextField label="App Version" source="app_version" />
                        <ReferenceField label="Property" source="property_operator_id" reference="properties">
                            <TextField source="name" />
                        </ReferenceField>
                        <FunctionField label="Screen Number" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.device_screen_number}</Typography>
                            )
                        }} />;
                        {/* <TextField label="Screen Number" source="device_screen_number" /> */}
                        <TextField label="MAC Address" source="device_mac_address" />
                        <BooleanField label="Active Device" source="device_active" />
                        <BooleanField label="Upgrade Available" source="upgrade_flag" />

                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}
export default ResourceList;