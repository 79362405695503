import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useListParams, useCreate } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import types

type Inputs = {
    name: string
};

const Create: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
        
        // const formData = new FormData();

        // formData.append("name", data.name);
        const streamdata ={
             name: data.name,
        }

        dataProvider.create(
            'stream-source',
            {
                data: streamdata,
            },
            ).then((response: any) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Stream Source was successfully created', { type: "info" });
                navigate("/stream-source");
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    const formName = watch('name');

    return (
        <Box sx={{ pl: 6, pr: 3, pt: 12 }}>
            <Title title="Create Stream Source" />
            <Box>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <Grid container spacing={7}>
                        <Grid item md={4}>
                            <TextField
                                id="name"
                                InputLabelProps={{ shrink: true }}
                                label="Stream Source Name*"
                                placeholder="Enter Stream Source Name"
                                helperText={"Stream Source Name cannot contain whitespace and symbols"}
                                {...register("name")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ p:3 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={(/^[A-Za-z0-9_]+$/.test(formName)) ? false : true}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Create;