import React, { FC, useState, useCallback, useEffect } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useListParams, useCreate } from 'react-admin';
import { makeStyles, createStyles, Theme, TextField, Dialog, DialogContent, Grid, Button, Divider, Box } from '@mui/material';
import { useParams } from "react-router-dom";
import { SubmitHandler, useController, Controller, useForm } from "react-hook-form";

// import types
// import { AppState } from '../../../types/Theme';

//import components
// import TitleSection from '../../../components/title/TitleSection';

type Inputs = {
    name: string
    order: number;
};

const CreateGenre: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        const genresdata = {
            name: data.name,
            order: data.order,
        }

        dataProvider.create(
            'genres',
            {
                data: genresdata,
            },
        ).then((response: any) => {
            notify('Genre was successfully created', { type: "info" });
            handleClose();
            refresh();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }


    return (
        <>
            <span onClick={handleClickOpen} style={{ cursor: "pointer", color: "#008DD9", fontWeight: "normal" }}>+ Create Genre</span>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                maxWidth={"lg"}
            >
                <DialogContent >
                    <Title title="Create Genre" />
                    <form onSubmit={handleSubmit(OnSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minWidth: 300 }}>
                        <TextField
                            id="name"
                            InputLabelProps={{ shrink: true }}
                            label="Genre Name"
                            placeholder="Enter Genre Name"
                            {...register("name")}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            id="order"
                            type="number"
                            placeholder="Order"
                            label="Order*"
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            {...register("order", {
                                required: false,
                                pattern: /^\d+$/,
                                min: {
                                    value: 1,
                                    message: "Value must be greater than 0",
                                },
                            })}
                            variant="outlined"
                            fullWidth
                            helperText={
                                errors.order ? `${errors.order.message}` : ""
                            }
                            style={{ margin: '10px 0' }} // Add margin to create space around the input box
                        />


                        <Box sx={{ p: 1 }}>
                            <Divider />
                        </Box>

                        <Button
                            type="button"
                            onClick={handleSubmit(OnSubmit)}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Submit
                        </Button>
                    </form>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default CreateGenre;
