// import React, { useState, useEffect, useCallback, FC, CSSProperties, Fragment } from 'react';
// import { Tabs, Box, Tab, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
// import BulkDelete from '../../components/BulkDelete';
// import { CreateButton, useGetList, fetchUtils } from "react-admin";
// import { Link } from "react-router-dom";
// import { useTheme } from '@mui/material/styles';
// import IconButton from '@mui/material/IconButton';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import LastPageIcon from '@mui/icons-material/LastPage';
// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import TableFooter from '@mui/material/TableFooter';
// import TablePagination from '@mui/material/TablePagination';

// const BulkActionButtons = (props: any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );
// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
// }

// interface TablePaginationActionsProps {
//     count: number;
//     page: number;
//     rowsPerPage: number;
//     onPageChange: (
//         event: React.MouseEvent<HTMLButtonElement>,
//         newPage: number,
//     ) => void;
// }

// function TablePaginationActions(props: TablePaginationActionsProps) {
//     const theme = useTheme();
//     const { count, page, rowsPerPage, onPageChange } = props;

//     const handleFirstPageButtonClick = (
//         event: React.MouseEvent<HTMLButtonElement>,
//     ) => {
//         onPageChange(event, 0);
//     };

//     const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         onPageChange(event, page - 1);
//     };

//     const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         onPageChange(event, page + 1);
//     };

//     const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//     };

//     return (
//         <Box sx={{ flexShrink: 0, ml: 2.5 }}>
//             <IconButton
//                 onClick={handleFirstPageButtonClick}
//                 disabled={page === 0}
//                 aria-label="first page"
//             >
//                 {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
//             </IconButton>
//             <IconButton
//                 onClick={handleBackButtonClick}
//                 disabled={page === 0}
//                 aria-label="previous page"
//             >
//                 {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//             </IconButton>
//             <IconButton
//                 onClick={handleNextButtonClick}
//                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//                 aria-label="next page"
//             >
//                 {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//             </IconButton>
//             <IconButton
//                 onClick={handleLastPageButtonClick}
//                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//                 aria-label="last page"
//             >
//                 {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
//             </IconButton>
//         </Box>
//     );
// }


// const ResourceList = () => {
//     const [value, setValue] = React.useState(0);

//     const { data: dataTrial, total: dataTotal, isLoading: loading, error: error } = useGetList<any>(
//         'trial-data',
//         {
//             filter: {},
//             sort: { field: 'date', order: 'DESC' },
//             pagination: { page: 1, perPage: 100 },
//         }
//     );

//     console.log(dataTrial, "dsadsa")

//     return (
//         <Box sx={{ pl: 5, pt: 4, pr: 5, pb: 5 }}>
//             <Box sx={{ pr: 7, pb: 2, justifyContent: "right", display: "flex" }}>
//                 <Link style={{ textDecoration: "none", backgroundColor: "#0db58b", color: "black", textAlign: "center", display: "inline-block", paddingTop: 10, paddingLeft: 25, paddingRight: 25, paddingBottom: 10, borderRadius: 3 }} to="/dummy-data/create">Add</Link>
//             </Box>
//             <TableContainer component={Paper}>
//                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                     <TableHead>
//                         <TableRow sx={{ bgcolor: "#333" }}>
//                             <TableCell sx={{ pl: 30, color: "#0db58b", fontWeight: "bold", fontSize: 20, letterSpacing: 0.5 }}>Type</TableCell>
//                             <TableCell sx={{ color: "#0db58b", fontWeight: "bold", fontSize: 20, letterSpacing: 0.5 }}>Data</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {dataTrial?.map((record: any) => (
//                             <TableRow
//                                 key={record.type}
//                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                             >
//                                 <TableCell sx={{ pl: 30 }} component="th" scope="row">
//                                     {record.type}
//                                 </TableCell>
//                                 <TableCell >
//                                     {record.type === "vods" && record?.data['title'] || record.type === "channels" && record?.data['aa   '] || record.type === "packages" && record?.data['package_name']}
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Box>
//     );
// }

// export default ResourceList;

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { TableBody, Box, Button } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CreateButton, useGetList, fetchUtils } from "react-admin";
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

interface Column {
    id: 'name' | 'code' | 'population' | 'size' | 'density';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

interface Data {
    name: string;
    code: string;
    population: number;
    size: number;
    density: number;
}

const ResourceList = () => {
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const { data: dataTrial, total: dataTotal, isLoading: loading, error: error } = useGetList<any>(
        'trial-data',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box sx={{ pt: 8, pl: 5, pr: 5 }}>
            <Button onClick={() => navigate("/dummy-data/create")} sx={{ float: "right" }}>Create</Button>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 740 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                hover role="checkbox" tabIndex={-1}
                            >
                                <TableCell align="left" sx={{ color: "#0db58b", pl: 35, fontSize: 14 }}>Type</TableCell>
                                <TableCell align="center" sx={{ color: "#0db58b", pr: 20, fontSize: 14 }}>Data</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataTrial
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map(records => (
                                    <TableRow
                                        key={records.type}
                                        hover role="checkbox" tabIndex={-1}
                                    >
                                        <TableCell sx={{ pl: 35 }} component="th" scope="row">
                                            {records.type}
                                        </TableCell>
                                        <TableCell sx={{ pr: 20 }} align="center" component="th" scope="row">
                                            {records.type === "vods" && `${records?.data['title'] + " / " + records?.data?.original_title + " / " + records?.data?.original_language}`}
                                            {records.type === "channels" && `${records?.data?.channel_description + " / " + records?.data?.channel_number + " /" + records?.data?.epg_map_id}`}
                                            {records.type === "packages" && `${records?.data['package_name'] + " /" + records?.data['package_duration'] + "  " + records?.data['unit'] + " /" + records?.data['price']}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={dataTotal as any}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
export default ResourceList;