import React, { FC, useEffect, useState } from 'react';
import { useNotify, useDataProvider, useRefresh, EditButton, useGetOne } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Container, Dialog, RadioGroup, FormLabel, FormControl, FormControlLabel,
    Typography, Grid, Button, Radio, IconButton, Divider, TextField, Checkbox, Box,
} from '@mui/material';
import { useParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

// import types

type Inputs = {
    email: string,
    password: string,
    confirmPassword: string,
    phoneNumber: string,
    propertyName: string,
    type: string,
    screens_limit: any,
    checkEmail: boolean,
}

const Edit: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [propertyType, setPropertyType] = useState("hotel");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [checkEmail, setCheckEmail] = useState<boolean>(false);
    const [propertiesData, setPropertiesData] = React.useState<any>({});
    const [screenLimit, setScreenLimit] = useState<any>({});


    const handleCheckEmail = (event: any) => {
        setCheckEmail(event.target.checked);
    }

    const { data: dataProp, isLoading, error, refetch } = useGetOne(
        'properties',
        {
            id: `${id}`
        },
    );

    useEffect(() => {
        setScreenLimit(dataProp?.screens_limit)
        setValue("screens_limit", dataProp?.screens_limit)
        // setPropertiesData(dataProp?.propertyName)

    }, [dataProp]);

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.type = propertyType;

        formData.append("screens_limit", data.screens_limit);
        // formData.append("email", data.email);
        // formData.append("password", data.password);
        // formData.append("confirmPassword", data.confirmPassword);
        // formData.append("phoneNumber", data.phoneNumber);
        // formData.append("propertyName", data.propertyName);
        // formData.append("type", data.type);
        // formData.append("checkEmail", data.checkEmail);

        dataProvider.update(
            'properties',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: `${id}`,
                }
            },
        ).then((response: any) => {
            notify('Data was successfully updated', { type: "info" });
            navigate("/properties/cd");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{  ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0}>
                    <Grid item md={5}>
                        <TextField
                            id="screens_limit"
                            InputLabelProps={{ shrink: true }}
                            label="Screens Limit*"
                            placeholder="Screens Limit"
                            {...register("screens_limit")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Box sx={{ pt: 10 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Box>
    );
}

export default Edit;
