import React, { FC, useState, useCallback, useEffect, cloneElement, Fragment, ReactElement } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    DateField,
    FunctionField,
    TextField,
    EditButton,
    useListContext,
    CreateButton,
    TopToolbar,
    FilterButton,
    ExportButton,
    sanitizeListRestProps,
    Pagination,
    TextInput,
    useGetOne,
    Button,
    DateInput,
    downloadCSV,
    ReferenceField,
} from 'react-admin';
import { Typography, Chip, Box, Stack, Divider, ButtonBase } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';

import moment from 'moment';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import jwt_decode from "jwt-decode";
import jsonExport from 'jsonexport/dist';

// import components
import SubscriptionPopover from './dialogs/SubscriptionsListPopover';
import AssignSubscription from './AssignSubscription';

const BulkActionButtons = (props: any) => (
    <Box>
        <Fragment>
            <AssignSubscription {...props} />
        </Fragment>
    </Box>
);

const screenFilters = [
    <TextInput sx={{ mb: 1 }} label="Search Mac Address" source="name_mac_address" alwaysOn />,
    <TextInput sx={{ mb: 1 }} label="Search Screen" source="name_screen_number" alwaysOn />,

    <DateInput sx={{ mb: 1 }} label="Registration Date" placeholder="Registration Date" source="date_createdAt" />
];

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    const [screenLimit, setScreenLimit] = useState<any>();

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);

    const { data: dataProperties, isLoading, error, refetch } = useGetOne(
        'properties',
        {
            id: decode['property_operator_id']
        }
    );

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <FilterButton filters={screenFilters} />
            <ExportButton />
            {dataProperties === total ? <Button label="Upgrade" href="#/manage-subscription" /> : <CreateButton resource={resource} />}
        </TopToolbar>
    );
};

const Empty = () => {
    const { resource } = useListContext();

    return (
        <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography sx={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography sx={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box sx={{ mt: 3 }}>
                <CreateButton resource={resource} />
            </Box>
        </Box>
    );
};

const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {
    fetchRelatedRecords(records, "subscription_start_time", "sales-report/history").then((sc: any) => {

        let data: any = [];

        records.map((record: any) => {

            data.push({
                ...record,
                packages: { _id: "" },
                subscriptions: { start_time: " " },
                streams: { streamSource: "" },
                genre: { _id: "", is_adult: "", pin_protected: "", is_available: "", content_distributor_id: "", createdAt: "" },
                epg_map_id: " ",
                content_distributor_id: " ",
                pin_adult: "",
                __v: " ",
                player: " ",
                id: " ",
                _id: " ",
                updatedAt: " ",
                show_adult: " ",
                stream_source: " ",
                property_operator_id: " ",
                hashedPassword: " ",
                greeting_message: " ",
                start_timee: record.subscriptions.map((subsc: any) => {
                    return `${moment(subsc.start_time).format("L")}`
                }),
                end_timee: record.subscriptions.map((subsc: any) => {
                    return `${moment(subsc.end_time).format("L")}`
                }),
                createdAt: `${moment(record?.createdAt).format("L")}`,
                nameeee: record.subscriptions.map((subsc: any) => {
                    return subsc.package.map((pac: any) => {
                        return pac.package_name
                    })
                }),
            })
        });
        return jsonExport(data, {
            headers: ['screen_type', 'screen_number', 'mac_address', 'fullName', 'nameeee', 'activated', 'createdAt', "start_timee", "end_timee"],
            rename: ['Screen Type', 'Screen Number', 'Mac Address', 'FullName', 'Package Name', 'Activated', 'CreatedAt', "Start Time", "End Time", ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        }, (err: any, csv: any) => {
            downloadCSV(csv, 'tv-screens');
        });
    });
};

const CustomBooleanField = ({ record, source, ...props }: any) => (
    <FunctionField
      label="Mobile"
      render={(record: any) => {
        const activateMobileValue = record[source];
  
        return (
          <Typography>
            {activateMobileValue !== undefined && activateMobileValue !== null
              ? activateMobileValue
                ? '✔' 
                : '❌' 
              : '❌'
            }
          </Typography>
        );
      }}
      {...props}
    />
  );
  const CustomActivatedField = ({ record, source, ...props }: any) => (
    <FunctionField
      label="Active"
      render={(record: any) => {
        const activatedValue = record[source];
  
        return (
          <Typography>
            {activatedValue !== undefined && activatedValue !== null
              ? activatedValue
                ? '✔'  // Display checkmark when true
                : '❌'  // Display cross when false
              : '❌'    // Display cross for undefined or null
            }
          </Typography>
        );
      }}
      {...props}
    />
);
  
  
const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    empty={<Empty />}
                    title="Our Screens"
                    filters={screenFilters}
                    perPage={25}
                    sort={{ field: "createdAt", order: "DESC" }}
                    pagination={<ListPagination />}
                    bulkActionButtons={<BulkActionButtons />}
                    actions={<ListActions />}
                    exporter={exporter}
                >
                    <Datagrid>
                        <TextField label="Screen" source="screen_number" />
                        <EditButton />
                    </Datagrid>
                </List>
                :
                <List
                    empty={<Empty />}
                    title="Our Screens"
                    filters={screenFilters}
                    perPage={25}
                    sort={{ field: "createdAt", order: "DESC" }}
                    pagination={<ListPagination />}
                    bulkActionButtons={<BulkActionButtons />}
                    actions={<ListActions />}
                    exporter={exporter}
                >
                    <Datagrid>
                        <FunctionField
                            label="Type"
                            render={(record: any) => {
                                if (record.screen_type) {
                                    return (
                                        <>

                                            {record.screen_type == "bar_restaurant" ?
                                                <Chip style={{ color: "white" }} label="Bar & Restaurant" />
                                                :
                                                null
                                            }

                                            {record.screen_type == "room" ?
                                                <Chip sx={{ color: "white" }} label="Hotel Room" />
                                                :
                                                null
                                            }

                                            {record.screen_type == "client" ?
                                                <Chip sx={{ color: "white" }} label="Residential Client" />
                                                :
                                                null
                                            }

                                        </>
                                    )
                                } else {
                                    return (
                                        <Chip sx={{ color: "white" }} label="Room" />
                                    )
                                }
                            }}
                        />
                        <TextField label="Screen" source="screen_number" />
                        <TextField label="MAC Address" source="mac_address" />
                        <FunctionField
                            label="Full Name"
                            render={(record: any) => {
                                if (record.fullName) {
                                    return (
                                        <Typography sx={{ p: 2, fontSize: 14, }}>{record.fullName}</Typography>
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 14, p: 3 }}>No Guest Name</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="IP Address"
                            render={(record: any) => {
                                if (record.device_ip) {
                                    return <Typography sx={{ p: 2 }}>{record.device_ip}</Typography>;
                                }
                            }}
                        />
                    
                       
                        <TextField label="Address" source="address" />
                        <CustomActivatedField label="Active" source="activated" />
                        <CustomBooleanField label="Mobile" source="activate_mobile" />
                        <DateField
                            options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                            label="Registration Date"
                            source="createdAt"
                        />
                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}
export default ResourceList;