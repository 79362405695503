import React, { ReactElement, Fragment } from 'react';
import {
    Datagrid,
    List,
    ListProps,
    TextField,
    useListContext,
    DateInput,
    TextInput,
    DateField,
    FunctionField,
    Pagination,
    downloadCSV,
} from 'react-admin';
import { Typography, Box } from '@mui/material';
import moment from 'moment';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import BulkDelete from '../../components/BulkDelete';
import { useMediaQuery, Theme } from '@mui/material';
import jsonExport from 'jsonexport/dist';

const salesFilters = [
    <DateInput sx={{ mb: 1 }} source="fromDate" variant='outlined' alwaysOn />,
    <DateInput sx={{ mb: 1 }} source="toDate" variant='outlined' alwaysOn />,
    <TextInput sx={{ mb: 1 }} variant='outlined' label="Name" source="name" />,
];

const date = moment().format('YYYY-MM-DD hh:mm');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');

const Empty = () => {
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any subscriptions</Typography>
        </div>
    );
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = (props: ListProps): ReactElement => {

    // const BulkActionButtons = (props: any) => (
    //     <Fragment>
    //         <BulkDelete {...props} />
    //     </Fragment>
    // );

    const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

        fetchRelatedRecords(records, "package", "packages").then((pcg: any) => {
            fetchRelatedRecords(records, "property_operator_id", "properties/cd").then((sc: any) => {

                let data: any = [];

                records.map((record: any) => {
                    console.log(pcg[record?.package]?.package_name, "sdasds")

                    data.push({
                        ...record,
                        id: " ",
                    })
                });
                return jsonExport(data, {
                    headers: ['_id', 'total_sales', 'earnigns', 'id'],
                    rename: ['Month', "Total Sales", "Earnings", " "],
                }, (err: any, csv: any) => {
                    downloadCSV(csv, 'by-months');
                });
            })
        })

    };

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 8, height: "auto" }}>
            {isXSmall ?
                <List
                    filters={salesFilters}
                    filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
                    title="Subscription By Days"
                    empty={<Empty />}
                    exporter={exporter}
                    pagination={<ListPagination />}
                    // bulkActionButtons={<BulkActionButtons />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid bulkActionButtons={false}>
                        <DateField showTime options={{ year: 'numeric', month: 'long' }} source="_id" title="Month" label={"Month"} />
                        <FunctionField label="Earnings" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}> {record.earnigns} €</Typography>
                            )
                        }} />;
                    </Datagrid>
                </List>
                :
                <List
                    filters={salesFilters}
                    filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
                    title="Subscription By Days"
                    empty={<Empty />}
                    pagination={<ListPagination />}
                    exporter={exporter}
                    // bulkActionButtons={<BulkActionButtons />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid bulkActionButtons={false}>
                        <DateField showTime options={{ year: 'numeric', month: 'long' }} source="_id" title="Month" label={"Month"} />
                        <TextField label="Total Sales" source="total_sales" />
                        <FunctionField label="Earnings" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}> {record.earnigns} €</Typography>
                            )
                        }} />;
                    </Datagrid>
                </List>}
        </Box>
    )

}
export default ResourceList;