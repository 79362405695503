import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

// import components
import SERVICES from '../../../../assets/images/iconsMenu/pin.png';

interface Props {
    value?: number;
}

const Services: FC<Props> = ({ value }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Link to="/services" style={{ textDecoration: "none" }}>
            <Card sx={{ borderRadius: 10 }}>
                <Box sx={{ p: 4 }}>
                    <Box>
                        <img width={isXSmall ? 40 : 60} src={SERVICES} />
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: isXSmall ? 12 : 25 }}>Services</Typography>
                        <Typography sx={{ fontSize: isXSmall ? 20 : 30 }}>{value !== 0 ? value : '0'}</Typography>
                    </Box>
                </Box>

            </Card>
        </Link>
    );
};

export default Services;