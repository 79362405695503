import React, { ReactElement, Fragment, cloneElement } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    FunctionField,
    TextField,
    EditButton,
    useListContext,
    CreateButton,
    ExportButton,
    TopToolbar,
    sanitizeListRestProps,
    Button,
    Pagination,
} from 'react-admin';
import { Avatar, Typography, Box, makeStyles, Chip } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { useMediaQuery, Theme } from '@mui/material';

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box style={{ marginTop: 10 }}>
                <CreateButton resource={resource} />
            </Box>
        </Box>
    );
};

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton resource={resource} />
        </TopToolbar>
    );
};

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = (props: ListProps): ReactElement => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 4 }}>
            {isXSmall ?
                <List
                    empty={<Empty />}
                    title="App Management"
                    actions={<ListActions />}
                    pagination={<ListPagination />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid>
                        <TextField label="Version" source="app_version" />
                        <EditButton />
                    </Datagrid>
                </List>
                :
                <List
                    empty={<Empty />}
                    title="App Management"
                    actions={<ListActions />}
                    pagination={<ListPagination />}
                    {...props}
                    perPage={25}
                >
                    <Datagrid>
                        <TextField label="App ID" source="appid" />
                        <TextField label="Title" source="title" />
                        <FunctionField label="Description" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.description}</Typography>
                            )
                        }} />;
                        {/* <TextField label="Description" source="description" /> */}
                        <TextField label="Version" source="app_version" />
                        <BooleanField label="Beta" source="beta_version" />
                        <BooleanField label="Available" source="isavailable" />
                        <TextField label="Upgrade Min API" source="upgrade_min_api" />
                        <TextField label="Upgrade Min App Version" source="upgrade_min_app_version" />
                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;