import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, useUpdateMany, useDataProvider, useGetList } from 'react-admin';
import { Stack, FormHelperText, FormControl, InputLabel, MenuItem, Select, IconButton, Grid, Theme, Typography, Button, TextField, Checkbox, Box, FormControlLabel, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
// import CreateStreamSource from './dialogs/CreateStreamSource';

// import states
import { EditChannelStreams } from '../../../types/Streams';

// import components
import StreamDetails from '../dialogs/StreamDetails';

interface Props {
    editChannelStreams: EditChannelStreams;
    initStreams: any;
}

const EditStreams: FC<Props> = ({ editChannelStreams, initStreams }) => {

    const [channelStreams, setChannelStreams] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);

    const [streamUrlError, setStreamUrlError] = useState<boolean>(false);
    const [streamSourceError, setStreamSourceError] = useState<boolean>(false);

    const [stateCheckboxToken, setStateCheckboxToken] = React.useState<boolean>(false);
    const [stateCheckboxEncryption, setStateCheckboxEncryption] = React.useState<boolean>(false);

    const [allValues, setAllValues] = useState({
        streamSource: '',
        streamUrl: '',
        streamFormat: 'HLS',
        streamType: ' regular',
        token: false,
        tokenUrl: '',
        encryption: false,
        encryptionUrl: ''
    });

    const { data: dataStream, total: totalStream, isLoading: loadingStream, error: errorStream, refetch } = useGetList(
        'stream-source',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    )

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStateCheckboxToken(false);
        setStateCheckboxEncryption(false);
    };

    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateCheckboxToken(event.target.checked);
        setAllValues({
            ...allValues,
            token: event.target.checked
        })
    };

    const handleChangeEncryption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateCheckboxEncryption(event.target.checked);
        setAllValues({
            ...allValues,
            token: event.target.checked
        })
    };

    const changeHandler = (e: any) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    }

    const addStreams = () => {
        if (allValues.streamUrl == "") {
            setStreamUrlError(true)
        } else if (allValues.streamSource == "") {
            setStreamSourceError(true)
        } else {
            setChannelStreams([...channelStreams, allValues]);
            editChannelStreams([...channelStreams, allValues]);
            setOpen(false);
            setStreamSourceError(false);
            setStreamUrlError(false);
        }
    }

    const handleDelete = (index: any) => {
        let newStreams = [...channelStreams];
        newStreams.splice(index, 1);
        setChannelStreams([...newStreams]);
        editChannelStreams([...newStreams]);
    }

    useEffect(() => {
        if (initStreams) {
            setChannelStreams(initStreams);
            editChannelStreams(initStreams);
        }
    }, [initStreams])

    return (
        <Box sx={{ padding: 0 }}>
            <>
                {channelStreams.map((data, index) => (
                    <Box>
                        <Tooltip title={data.streamUrl} placement="bottom-start">
                            <Stack direction="row" spacing={1}>
                                <Box>
                                    <StreamDetails initStream={data} index={index} editChannelStreams={editChannelStreams} initStreams={initStreams} />
                                </Box>
                                <Box>
                                    <IconButton sx={{ mt: -0.5, ml: -1 }} onClick={() => handleDelete(index)}>
                                        <DeleteOutlineIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Tooltip>
                    </Box>
                ))}
            </>
            {!open &&
                <Box sx={{ mt: 1 }}>
                    <Button
                        onClick={handleClickOpen}
                        color="primary"
                        style={{ marginBottom: 1, marginRight: 1 }}
                        size="small"
                        variant="contained"
                    >
                        <AddIcon />
                    </Button>
                </Box>
            }
            {open &&
                <Box>
                    <Typography sx={{ color: "gray", fontSize: 14, mb: 2 }}>Manage Streams</Typography>
                    <Box sx={{ padding: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Stream Source</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="streamSource"
                                        onChange={changeHandler}
                                        label="Stream Source"
                                    >
                                        <MenuItem value="">
                                            <em>Select Source</em>
                                        </MenuItem>
                                        <MenuItem value={"default_stream_source"}>Default Stream Source</MenuItem>
                                        {dataStream?.map((result) => (
                                            <MenuItem value={result["name"]}>{result["name"]}</MenuItem>
                                        ))}
                                    </Select>
                                    {streamSourceError ? <FormHelperText>Stream Source can not be empty</FormHelperText> : ""}
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    onChange={changeHandler}
                                    name="streamUrl"
                                    size="small"
                                    variant="outlined"
                                    id="standard-basic"
                                    label="Stream URL"
                                    placeholder="Enter Stream URL"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                {streamUrlError ? <FormHelperText>Stream URL can not be empty</FormHelperText> : ""}
                            </Grid>
                            <Grid item md={1}>
                                <FormControlLabel
                                    sx={{ ml: 2, pt: 0 }}
                                    control={<Checkbox checked={stateCheckboxToken} onChange={handleChangeToken} name="token" />}
                                    label="Token"
                                />
                            </Grid>
                            <Grid item md={1}>
                                <FormControlLabel
                                    sx={{ ml: 5, pt: 0 }}
                                    control={<Checkbox checked={stateCheckboxEncryption} onChange={handleChangeEncryption} name="encryption" />}
                                    label="Encryption"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Grid container spacing={2}>
                            {stateCheckboxToken &&
                                <Grid item md={4}>
                                    <TextField
                                        onChange={changeHandler}
                                        name="tokenUrl"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Token URL"
                                        size="small"
                                        variant="outlined"
                                        id="standard-basic"
                                        label="Token URL"
                                        fullWidth
                                    />
                                </Grid>
                            }
                            {stateCheckboxEncryption &&
                                <Grid item md={4}>
                                    <TextField
                                        onChange={changeHandler}
                                        name="encryptionUrl"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        variant="outlined"
                                        id="standard-basic"
                                        label="Encryption URL"
                                        placeholder="Enter Encryption URL"
                                        fullWidth
                                    />
                                </Grid>
                            }
                            {open &&
                                <Stack direction="row" sx={{ p: 1.5 }}>
                                    <Box>
                                        <Button color="primary" variant="outlined" sx={{ m: 1, width: 100 }} size="small" onClick={addStreams}>Add</Button>
                                    </Box>
                                    <Box>
                                        <Button size="small" color="warning" variant="outlined" sx={{ m: 1, width: 100 }} onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </Stack>
                            }
                        </Grid>
                    </Box>
                </Box>
            }
        </Box>
    )

}

export default EditStreams;