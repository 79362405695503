import React from 'react'
import { useNotify, useRefresh, useGetList, useDataProvider, useCreate, Title } from 'react-admin';
import { Box, Grid, TextField, Button, Divider, Typography } from "@mui/material";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom"

type Inputs = {
    key: string,
    iv: string,
    license_content_key: string,
    provider: string,
};

const Integrations = () => {

    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        formData.append("data", JSON.stringify({
            "key": data.key,
            "iv": data.iv,
            "license_content_key": data.license_content_key,
            "provider": data.provider,
        }));

        formData.append("type", "widevine")

        dataProvider.create(
            'configs',
            {
                data: formData,
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Successfully created');
            navigate("/");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ ml: 2, mt: 2, p: 5, borderRadius: 10 }}>
            <Title title="asasdss" />
            <form onSubmit={handleSubmit(OnSubmit)}>
                <Typography sx={{ mb: 5, color: "#ddd" }}>
                    Integrate with Widewine
                </Typography>
                <Grid container spacing={7}>
                    <Grid item xs={11} md={3}>
                        <TextField
                            id="provider"
                            label="Company Name*"
                            placeholder="Company Name"
                            InputLabelProps={{ shrink: true }}
                            {...register("provider", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ p: 2 }}></Box>
                <Grid container spacing={7}>
                    <Grid item xs={11} md={3}>
                        <TextField
                            id="key"
                            label="Key*"
                            placeholder="Key"
                            InputLabelProps={{ shrink: true }}
                            {...register("key", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ p: 2 }}></Box>
                <Grid container spacing={7}>
                    <Grid item xs={11} md={3}>
                        <TextField
                            id="iv"
                            label="Iv*"
                            placeholder="Iv"
                            InputLabelProps={{ shrink: true }}
                            {...register("iv", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ p: 2 }}></Box>
                <Grid container spacing={7}>
                    <Grid item xs={11} md={3}>
                        <TextField
                            id="license_content_key"
                            label="License Content Key*"
                            placeholder="License Content Key"
                            InputLabelProps={{ shrink: true }}
                            {...register("license_content_key", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={11} md={3}>
                        <Box sx={{ p: 5 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(OnSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box >
    )
}

export default Integrations