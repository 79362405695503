import React, { FC, useState, useEffect } from 'react';
import { useGetOne, useNotify, useDataProvider, useRefresh } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Paper, { PaperProps } from '@mui/material/Paper';
import { useMediaQuery, Theme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useNavigate, useParams } from "react-router-dom";

//import images
import logo from "../../assets/logo_hottelio_welcome.png";
import Channels from "../../assets/images/user-guiding/Channels.jpg";
import Vods from "../../assets/images/user-guiding/Vod.jpg"
import Packagess from "../../assets/images/user-guiding/Packages.jpg"
import Properties from "../../assets/images/user-guiding/Properties.jpg"

const steps = [
    {
        id: 0,
        label: 'WELCOME TO HOTTEL.IO',
        description:
            "Your 5 star in room tv cloud solution (SaaS). Explore now our user-friendly and flexible CMS/ Middleware & player applications for connected TV's. Customize & brand your screens directly  from the CMS and distribute your LIVE/ VOD content through powerful apps",
        image: logo,
    },
    {
        id: 1,
        label: 'CHANNELS',
        description:
            "Configure your channels, streams, order, protection, genres in seconds and start distributing your content",
        image: Channels,

    },
    {
        id: 2,
        label: 'VOD',
        description: "Configure your VOD's, streams, order, protection, genres, import metadata, all in seconds and start distributing your content weather combined with LINEAR TV or as a single combo.",
        image: Vods,

    },
    {
        id: 3,
        label: 'PACKAGES',
        description: "Monetize your content by combining channels, VOD, genres, and many more and assign them to single or multiple screens.",
        image: Packagess,

    },
    {
        id: 4,
        label: 'CREATE PROPERTY',
        description: "Invite or create properties (Hotel, Hospital, Cruise- ships, Residential Units), customize/brand  the screens as per company details, list your services and go live in seconds.",
        image: Properties,
    }
];

function UserGuiding() {
    const [open, setOpen] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg');
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [openDialog, setOpenDialog] = React.useState(true);

    const theme = useTheme();
    const navigate = useNavigate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const maxSteps = steps.length;
    const token = localStorage.getItem("access_token");
    let decode: any = jwtDecode(`${token}`);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const checkSteps = () => {
        if (activeStep === steps.length - 1) {
            return (
                <Box>
                    <Button
                        onClick={OnSubmit}
                    >
                        Finish
                    </Button>
                </Box>
            )
        }
        else if (activeStep < steps.length - 1) {
            return (
                <Box>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        Next
                    </Button>
                </Box>
            )
        }
    }

    useEffect(() => {
        if (dataUser?.first_login === false) {
            setOpen(false)
        }
        else if (dataUser?.first_login === true) {
            setOpen(true)
        }
    })

    const { data: dataUser, isLoading: loadingUser, error: errorUser } = useGetOne(
        'users',
        {
            id: decode["_id"]
        }
    );

    const OnSubmit = () => {

        const formData: any = new FormData();

        formData.append("first_login", false);

        dataProvider.update(
            'users',
            {
                id: `${decode['_id']}`,
                data: formData,
                previousData: {
                    id: `${decode['_id']}`
                }
            },
        ).then((response: any) => {
            refresh();
            setOpen(false)
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box>
            {isXSmall ?
                <Dialog
                    open={open}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {
                            steps?.map((record, index) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {Math.abs(activeStep - index) <= 0 ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                width: 'fit-content',
                                                maxWidth: '100%',
                                            }}
                                            src={record.image}
                                            alt={record.label}
                                        />
                                    ) : null}
                                </div>
                            ))
                        }
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                justifyContent: 'center',
                                height: 50,
                                bgcolor: 'background.default',
                                color: "#0db58b",
                                fontWeight: "bold",
                            }}
                        >
                            <Typography>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ p: 3, width: 'fit-content', display: "flex", justifyContent: "center" }}>
                            {steps[activeStep].description}
                        </Box>
                        <MobileStepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                checkSteps()
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </Dialog>
                :
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    // onClose={handleClose}
                    maxWidth={maxWidth}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Box sx={{ flexGrow: 1, p: 1, bgcolor: "#111" }}>
                        <Box sx={{ pt: 3, pb: 3, pr: 2, pl: 2 }}>
                            {
                                steps?.map((record, index) => (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {Math.abs(activeStep - index) <= 0 ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: 'fit-content',
                                                    maxWidth: '100%',
                                                }}
                                                src={record.image}
                                                alt={record.label}
                                            />
                                        ) : null}
                                    </div>
                                ))
                            }
                        </Box>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                justifyContent: 'center',
                                bgcolor: 'background.default',
                                color: "#0db58b",
                                fontWeight: "bold",
                                pt: 2,
                            }}
                        >
                            <Typography sx={{ fontSize: 29, fontWeight: "bold", }}>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ pr: 17, pl: 17, pt: 1, pb: 4, width: 'fit-content', display: "flex", justifyContent: "center", backgroundColor: "#111", textAlign: "center", }}>
                            <Typography sx={{ fontSize: 17, color: "gray" }}>{steps[activeStep].description}</Typography>
                        </Box>
                        <MobileStepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                checkSteps()
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </Dialog>
            }
        </Box >
    );
}
export default UserGuiding;