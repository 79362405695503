import React, { FC, useState } from 'react';
import { useUnselectAll, BulkActionProps, useNotify, useRefresh, Identifier, useDataProvider, useGetList, useCreate } from 'react-admin';
import { makeStyles, createStyles, Theme, Backdrop, CircularProgress, Dialog, InputLabel, MenuItem, FormControl, Typography, Select, Button, DialogContent, Box } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";

const noSelection: Identifier[] = [];

const AssignPackages: FC<BulkActionProps> = ({ selectedIds = noSelection }) => {


    const notify = useNotify();
    const unselectAll = useUnselectAll('vod');
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    //dialog open/close

    const [open, setOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [packages, setPackages] = useState([]);


    const handleClickOpen = async () => {
        // setOpenBackDrop(true);
        setOpen(true);
    }

    const { data: dataPackages, total, isLoading, error, refetch } = useGetList(
        'packages',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const handleClose = () => {
        setOpen(false);
        unselectAll();
        refresh();
    };

    const [selectedPackages, setSelectedPackages] = useState<any>({});

    // assign package

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<any>();
    const OnSubmit: SubmitHandler<any> = (data) => {
        const formData = new FormData();

        data.packages = selectedPackages;
        data.vods = selectedIds;

        formData.append("packages", JSON.stringify(data.packages));
        formData.append("vods", JSON.stringify(data.vods));

        dataProvider.create(
            'vod/assignPackage',
            {
                data: formData
            },
        ).then((response: any) => {
            notify('Your service was successfully created', { type: "info" });
            unselectAll();
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ paddingLeft: 0 }}>
            <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
                style={{ color: "white" }}
            >
                Assign Package
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent >
                    <Typography sx={{ fontSize: 18, marginLeft: 26 }} component="span">Assign Package</Typography>
                    <FormControl variant="outlined" sx={{ marginTop: 20, minWidth: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Packages</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // value={selectedPackages}
                            onChange={(e) => { setSelectedPackages(e.target.value) }}
                            label="Available Packages"
                        >
                            {dataPackages?.map((pack: any) => (
                                <MenuItem key={pack['id']} value={pack}>{pack['package_name']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{ marginTop: 20, textAlign: "end" }}>
                        <Button
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit(OnSubmit)}>
                            Activate Package
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {/* <Backdrop sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1, color: '#fff' }} open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </Box>
    )
}

export default AssignPackages;