import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useGetList } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

const SubscriptionChart = () => {

    const [chartData, setChartData] = useState<any>([]);
    const [packagesNames, setPackagesNames] = useState<any>([]);

    const { data: analyticsActiveSubscription, total, isLoading, error, refetch } = useGetList(
        'analytics/active/packages',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const { data: dataPackages, total: totalPackages, isLoading: isLoadingPackages, error: errorPackages } = useGetList(
        'packages',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const handleChartData = (datas: any) => {
        datas.forEach((record: any) => record.Month = moment.monthsShort(record.Month - 1));
        setChartData(datas);
    }

    const handlePackagesNames = () => {
        setPackagesNames(dataPackages?.map((record: any) => {
            return record["package_name"];
        }))
    }

    useEffect(() => {

        if (dataPackages) {
            handlePackagesNames();
        }

        if (analyticsActiveSubscription) {
            handleChartData(analyticsActiveSubscription);
        }

    }, [analyticsActiveSubscription, dataPackages]);

    return (
        <>
            {dataPackages && analyticsActiveSubscription &&
                <Card sx={{ p: 5, height: 410, borderRadius: 10, mt: 2 }}>
                    {packagesNames.length > 0 && chartData.length > 0 ?
                        <ResponsiveBar
                            data={chartData}
                            keys={packagesNames}
                            indexBy="Month"
                            colors={packagesNames.map((name: any) => {
                                return chartData[0][`${name}color`];
                            })}
                            theme={{
                                axis: {
                                    ticks: {
                                        text: {
                                            fill: "#ffffff"
                                        }
                                    },
                                    legend: {
                                        text: {
                                            fill: "#aaaaaa"
                                        }
                                    },
                                },
                                tooltip: {
                                    container: {
                                        background: 'white',
                                        color: 'black',
                                        fontSize: 'inherit',
                                        borderRadius: '2px',
                                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        padding: '5px 9px'
                                    }
                                },
                            }
                            }
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.50}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Month',
                                legendPosition: 'middle',
                                legendOffset: 32,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Total Number of Subscriptions',
                                legendPosition: 'middle',
                                legendOffset: -40,
                                format: e => Math.floor(e) === e && e
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="black"
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: "#ffffff",
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                        :
                        <Box style={{ position: 'absolute', left: '43%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
                            <Typography style={{ fontSize: 25, color: "grey" }}>No subscription data</Typography>
                        </Box>
                    }
                </Card>
            }
        </>
    );
}

export default SubscriptionChart;