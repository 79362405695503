import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, useUpdateMany, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Grid, Typography, Button, Avatar, Card, Box, CardActions, CardContent } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';

// import types
// import { AppState } from '../../types/Theme';

// import config
import { BASE_ASSETS_URL } from '../../../config';
import { BASE_URL } from '../../../config';

// import images
import VODPlaceholder from '../../../assets/images/vod_placeholder.jpg';

interface Props {
    title: string;
    description: string;
    path: string
}

const VODPreview: FC<Props> = ({ title, description, path }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box sx={{ padding: 0, borderRadius: 2, height: "auto" }}>
            <Grid container spacing={3}>
                {isXSmall ?
                    ""
                    :
                    <Grid item xs={12} md={6}>
                        <img style={{ borderRadius: 2, paddingLeft: 134, marginTop: 3 }} width="100%" src={path ? path : VODPlaceholder} />
                    </Grid>
                }
            </Grid>
            <Grid container spacing={3}>
                {isXSmall
                    ?
                    ""
                    :
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ fontSize: 22, marginTop: 2, pl: 3, mb: -1 }}>
                            {title ? title : "Enter Title"}
                        </Typography>
                        <Typography sx={{ mt: 3, pl: 2, mb: 3, fontSize: 16, color: "grey" }}>
                            {description ? description : "Enter Description"}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Box >
    )
}

export default VODPreview;