import * as React from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import { useGetList, CreateButton, FilterList } from 'react-admin';
// import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
// import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';

// import types
import { Menu } from '../../types/Services';
import { FilterListItem } from "./MenuListItem";
import { Divider } from '@mui/material';

const MenuServices = () => {

    const { data } = useGetList<Menu>(
        'services/cd/public/menu',
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
        }
    );


    return (
        <Box>
            <FilterList
                label=""
                icon=""
            >
                {data && data.map((record: any, index: number) => (
                    <FilterListItem
                        label={record["_id"]}
                        key={index}
                        value={{ menu_id: record.id }}
                    />
                ))}
            </FilterList>
            <Box sx={{ p: 3 }}>
                <Divider />
            </Box>
            <Box sx={{ ml: 3 }}>
                <CreateButton resource="/services/cd/public" variant="contained" color="primary" size="large" label="Add Service" />
            </Box>
        </Box>
    );
};

export default MenuServices;