import * as React from 'react';
import { Show, SimpleShowLayout, DateField, TextField, Datagrid, ReferenceManyField, useRefresh, useDataProvider, List, FunctionField, ReferenceField, } from 'react-admin';
import { Box, Typography, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import { ObjectId } from 'mongoose';



interface Props {
  screenID: any;
}


const ReferenceList: React.FC<Props> = ({ screenID }) => {
  const [value, setValue] = React.useState('1');
  const refresh = useRefresh();
 

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  let dataaa = new Date();

  const isSubscriptionActive = (endTime: any) => {
    if (moment() > moment(endTime)) return false;
    else return true;
  };

 

  return (
    <Box sx={{ width: '100%', typography: 'body1', pt: 8 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Devices" value="1" />
            <Tab label="Subscription" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box>
            <Show actions={false}>
              <SimpleShowLayout sx={{ pt: 6, pb: 3, pl: 3, pr: 3 }}>
                <ReferenceManyField reference="devices/property" target="screen_id" label="" filter={{ screen: screenID }}>
                  <Datagrid>
                    <FunctionField
                      label="Device Mac Address"
                      render={(record: any) => {
                        return (
                          <Box>
                            <Typography sx={{ p: 2 }}>
                              {record?.device_mac_address}
                            </Typography>
                          </Box>
                        )
                      }}
                    />
                    <TextField label="IP Address" source="device_ip" />
                    <TextField label="Operating System" source="os" />
                    <TextField label="Screen Resolution" source="screen_resolution" />
                    <TextField label="Device Brand" source="device_brand" />
                    <TextField label="App Version" source="app_version" />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            </Show>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box>
            <Show actions={false}>
              <SimpleShowLayout sx={{ pt: 6, pb: 3, pl: 3, pr: 3 }}>
                <ReferenceManyField reference="subscriptions" target="screen" label="" filter={{screen: screenID}}>
                  <Datagrid>
                  <ReferenceField label="Package" source="package" reference="packages/property/packages">
                    <TextField source="package_name" />
                        </ReferenceField>
                    <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="start_time" title="Start Time" />
                    <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="end_time" title="End Time" />
                    <FunctionField
                      label="Status"
                      render={(record: any) => {
                        if (isSubscriptionActive(record.end_time)) {
                          console.log("RECC",record);
                          
                          return (
                            <Typography sx={{ color: "#83c583", fontSize: 12, p: 2 }}>Active</Typography>
                          )
                        } else {
                          return (
                            <Typography sx={{ color: "#cd5555", fontSize: 12, p: 2 }}>Expired</Typography>
                          )
                        }
                      }}
                    />
                    <FunctionField
                      label="Expires in"
                      render={(record: any) => {
                        let x = moment(record?.end_time, 'YYYY-MM-DD').diff(moment(dataaa, 'YYYY-MM-DD'), 'days')
                        return (
                          <Box>
                            <Typography sx={{ color: x > 0 ? "#83c583" : "#cd5555", fontSize: 14, fontFamily: "sans-serif" }}>
                              {(x > 0) ? x + " days" : "---"}
                            </Typography>
                          </Box>
                        )
                      }}
                    />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            </Show>
          </Box>
        </TabPanel>

      </TabContext>
    </Box>
  );
}
export default ReferenceList;