import React, { FC, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Geocode from "react-geocode";
import {TextField} from '@mui/material';

import { AddLatLong } from '../../types/LatLong';

function loadScript(src: string, position: HTMLElement | null, id: string) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
	offset: number;
	length: number;
}

interface StructuredFormatting {
	main_text: string;
	secondary_text: string;
	main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
	description: string;
	structured_formatting: StructuredFormatting;
}

interface Props {
	addLatLong: AddLatLong;
	latitude: string;
	longitude: string;
}

const PlacesAutocomplete: FC<Props> = ({ addLatLong, latitude, longitude }) => {

	const [value, setValue] = React.useState<PlaceType | null>(null);
	const [placeholder, setPlaceholder] = React.useState<string>("");
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
	const loaded = React.useRef(false);

	Geocode.setApiKey("AIzaSyBektaDJrwMRj2kbGPs6lQMohERy8dPB0s");
	Geocode.setLanguage("en");

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				'https://maps.googleapis.com/maps/api/js?key=AIzaSyBektaDJrwMRj2kbGPs6lQMohERy8dPB0s&libraries=places',
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const [latLong, setLatLong] = React.useState<any>({});

	const fetch = React.useMemo(
		() =>
			throttle(
				(
					request: { input: string },
					callback: (results?: readonly PlaceType[]) => void,
				) => {
					(autocompleteService.current as any).getPlacePredictions(
						request,
						callback,
					);
				},
				200,
			),
		[],
	);

	React.useEffect(() => {

		let active = true;

		if (!autocompleteService.current && (window as any).google) {
			autocompleteService.current = new (
				window as any
			).google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
			if (active) {
				let newOptions: readonly PlaceType[] = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}

		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	let milliseconds = new Date().getTime();

	React.useEffect(() => {
		Geocode.fromLatLng(latitude, longitude).then(
			(response: any) => {
				const address = response.results[0].address_components[1].long_name;
				setPlaceholder(address);
			},
			(error: any) => {
				console.error(error);
			}
		);
	}, [milliseconds])

	return (
		<Autocomplete
			id="google-map-demo"
			sx={{ width: 300 }}
			getOptionLabel={(option) =>
				typeof option === 'string' ? option : option.description
			}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event: any, newValue: any) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
				Geocode.fromAddress(newValue?.description).then(
					(response: any) => {
						setLatLong(response.results[0].geometry.location);
						addLatLong(response.results[0].geometry.location);
					},
					(error: any) => {
						console.error(error);
					}
				);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<TextField {...params} variant="outlined" placeholder={placeholder} fullWidth />
			)}
			renderOption={(props, option) => {
				const matches = option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match: any) => [match.offset, match.offset + match.length]),
				);

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item>
								<Box
									component={LocationOnIcon}
									sx={{ color: 'primary', mr: 2 }}
								/>
							</Grid>
							<Grid item xs>
								{parts.map((part: any, index: any) => (
									<span
										key={index}
										style={{
											fontWeight: part.highlight ? 700 : 400,
										}}
									>
										{part.text}
									</span>
								))}
								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
}

export default PlacesAutocomplete;