import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useDataProvider, useGetList, useUpdate } from 'react-admin';
import {
    makeStyles, Theme, createStyles, CardHeader, Button, Dialog, Card, Checkbox,
    DialogContent, List, Grid, ListItem, Divider, ListItemIcon, ListItemText, Box
} from '@mui/material';
interface Props {
    id: string
}
const ChannelsDialog: FC<Props> = ({ id }) => {

    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [initialSelected, setInitialSelected] = useState<any>([]);

    const fetchChannels = useCallback(async () => {
        await dataProvider.getList(
            `channels/availableChannels/${id}`,
            {
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'title', order: 'ASC' },
                filter: {}
            }).then((response) => {
                setLeft(response.data);
            }).catch((error) => {
                notify(error.message, { type: "warning" });
            })
    }, [dataProvider])

    const fetchPackageChannels = useCallback(async () => {
        await dataProvider.getList(
            `channels/distributor/findByPackage/${id}`,
            {
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'title', order: 'ASC' },
                filter: {}
            }).then((response) => {
                setRight(response.data);
                setInitialSelected(response.data);
            }).catch((error) => {
                notify(error.message, { type: "warning" });
            })
    }, [dataProvider])

    useEffect(() => {
        fetchPackageChannels();
        fetchChannels();
    }, [dataProvider]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function not(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) === -1);
    }
    function intersection(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }
    function union(a: number[], b: number[]) {
        return [...a, ...not(b, a)];
    }

    const [checked, setChecked] = React.useState<number[]>([]);
    const [left, setLeft] = React.useState<any>([]);
    const [right, setRight] = React.useState<any>([]);
    const [channelsId, setChannelsId] = React.useState<any>([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items: number[]) => intersection(checked, items).length;

    const handleToggleAll = (items: number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        // handleDisabled();
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        // handleDisabled();
    };

    // const handleDisabled = () => {
    //     if (initialSelected === right) { setDisabled(false) }
    //     if (initialSelected !== right) { setDisabled(true) }
    // }

    const HandleSubmit = () => {

        right.map((channel: any) => {
            channelsId.push(channel._id);
        })

        const formData: any = new FormData();

        formData.append("selectedChannels", JSON.stringify(channelsId));

        dataProvider.update(
            'channels/availableChannels',
            {
                id: id,
                data: formData,
                previousData: {
                    id: id
                }
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            setChannelsId([]);
            notify('Your package channels were successfully updated', { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }


    const customList = (title: React.ReactNode, items: number[]) => (
        <Card sx={{ position: "relative", top: 0 }}>
            <CardHeader
                sx={{ padding: 2 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List sx={{
                overflow: "auto",
                width: "auto",
                height: 500,
                bgcolor: "background.paper",
            }} dense component="div" role="list">
                {items.map((channel: any) => {
                    const labelId = `transfer-list-all-item-${channel}-label`;
                    return (
                        <ListItem key={channel['_id']} role="listitem" button onClick={handleToggle(channel)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(channel) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${channel.title}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Box sx={{ padding: 0 }}>
            {/* <Button variant="contained" color="primary" onClick={handleOpen} size="small" >
                Edit Channels
            </Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box sx={{ display: "flex" }}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ padding: 0 }}
                        >
                            <Grid item>{customList('Available Channels', left)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        sx={{ margin: 5 }}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        sx={{ margin: 5 }}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList('Selected Channels', right)}</Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: 'flex-end', pt: 3 }}>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={HandleSubmit}
                        // disabled={disabled}
                        >
                            Submit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ChannelsDialog;