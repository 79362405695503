import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
    useListContext,
    DatagridProps,
    Identifier,
    useListFilterContext,
    useDataProvider,
    useGetList
} from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, CardActions, Card, Divider, CardContent, Stack, Grid, Grow, CardMedia, IconButton, Box, Checkbox, Pagination } from '@mui/material';

// import components
import ServiceDetails from './dialogs/ServiceDetails';
import ServiceDelete from './dialogs/ServiceDelete';
import SeeAllServices from './dialogs/SeeAllServices';
import AssignToProperties from './dialogs/AssignToProperties';
import AssignDialog from './dialogs/AssignDialog';
import { BASE_ASSETS_URL } from '../../config';

const LoadedGridList: FC<DatagridProps> = ({ width }) => {
    // const { ids, data } = useListContext();
    const { filterValues } = useListFilterContext();
    const dataProvider = useDataProvider();

    const [page, setPage] = React.useState(1);

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const menu: string = filterValues.menu_id;

    const { data: dataService, total, isLoading, error, refetch } = useGetList(
        'services/cd/public',
        {
            pagination: { page: page, perPage: 2 },
            sort: { field: 'name', order: 'ASC' },
            filter: { menu_id: menu }
        }
    );
    const [selectedServices, setSelectedServices] = useState<any>([]);

    const handleChange = (event: any, id: any) => {
        if (event.target.checked === true) { setSelectedServices([...selectedServices, id['_id']]) }
        else { setSelectedServices(selectedServices.filter((v: any) => v != id["_id"])) }
    }

    return (
        <Box sx={{ pl: 5, pr: 3, pt: 0, m: 0 }}>
            <>
                {dataService?.map((record: any, index: number) => (
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={4} md={3}>
                                <Typography sx={{ pl: 1, mt: 2, mb: 2, fontSize: 18, textTransform: "uppercase", color: "#ddd" }} key={index}>{record["_id"]}</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Stack sx={{ float: "right" }} direction="row" spacing={2}>
                                    <Box sx={{ pt: 1 }}>
                                        <SeeAllServices menu={menu} category={record["_id"]} />
                                    </Box>
                                    <Box>
                                        {
                                            selectedServices.length ?
                                                <AssignDialog selectedServices={selectedServices} />
                                                :
                                                ""
                                        }
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {record["services"].slice(0, 6)?.map((service: any, index: number) => (
                                <Grow
                                    in={true}
                                    style={{ transitionDelay: true ? '500ms' : '0ms' }}
                                    {...(true ? { timeout: ((index + 1000)) } : {})}
                                >
                                    <Grid item xs={12} md={2}>
                                        <Box>
                                            <Card sx={{ borderRadius: 6, display: "" }}>
                                                <CardMedia
                                                    component="img"
                                                    alt={service.service_name}
                                                    height="150"
                                                    image={`${BASE_ASSETS_URL}${service.service_path}`}
                                                />
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                        <Typography sx={{ fontSize: 16 }}>
                                                            {service.service_name ? service.service_name.substring(0, 10) + ' ...' : " "}
                                                        </Typography>
                                                        <Checkbox
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            onChange={(event) => handleChange(event, service)}
                                                        />
                                                    </Box>
                                                    <Typography sx={{ fontSize: 10, color: "grey" }} variant="body2" color="text.secondary">
                                                        {service.short_service_description ? service.short_service_description.substring(0, 25) + ' ...' : " "}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions style={{ alignItems: "center", justifyContent: "center" }}>
                                                    <ServiceDetails record={service} />
                                                    <>
                                                        <IconButton href={`/#/services/cd/public/${service["_id"]}`} style={{ color: "grey" }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <ServiceDelete record={service} />
                                                    </>
                                                </CardActions>
                                            </Card>
                                        </Box>
                                    </Grid>
                                </Grow>
                            ))}
                        </Grid>
                    </Box>
                ))}
                <Box sx={{ display: "flex", justifyContent: 'right', alignContent: "bottom", marginTop: 6, pr: 1 }}>
                    {dataService?.length ?
                        <Pagination count={Math.ceil(total as number / 2)} page={page} onChange={changePage} />
                        :
                        ''
                    }
                </Box>
            </>

        </Box >
    );
};

const GridList = () => {
    return <LoadedGridList />
};

export default (GridList);