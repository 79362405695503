import * as React from 'react';
import { FC } from 'react';
import {
    useListContext,
    DatagridProps,
} from 'react-admin';
import { Typography, Avatar, Chip, Button, Card, CardActions, makeStyles, CardContent, Grid, Box, Divider } from '@mui/material';

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: any) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 5;
};

// const LoadingGridList: FC<DatagridProps & { nbItems?: number }> = ({
//     width,
//     nbItems = 14,
// }) => {

//     return (
//         <GridList
//             cellHeight={180}
//             cols={getColsForWidth(width)}
//             sx={{ margin: 0 }}
//         >
//             {' '}
//             {times(nbItems, key => (
//                 <GridListTile key={key}>
//                     <Box sx={{ height: '100%' }} />
//                 </GridListTile>
//             ))}
//         </GridList>
//     );

// };

const LoadedGridList: FC<DatagridProps> = () => {
    const { data } = useListContext();
    if (!data) return null;

    return (
        <Grid container spacing={2}>
            {data?.map((record: any) => (
                <Grid item xs={11} md={2}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", borderRadius: 10, minHeight: 200 }}>
                        <CardContent>
                            <Box sx={{ marginTop: 2, marginBottom: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar alt="User" sx={{ width: 70, height: 70, color: "white", backgroundColor: "orange" }}>{record?.fullName ? record?.fullName.split(/\s/).reduce((response: any, word: any) => response += word.slice(0, 1), '') : record?.email.charAt(0).toUpperCase()}</Avatar>
                            </Box>
                            <Typography sx={{ fontSize: 14, p: 1, textAlign: "center", m: 0 }} color="textSecondary" gutterBottom>
                                {record?.fullName ? record?.fullName : record?.email}
                            </Typography>
                            <Typography sx={{ fontSize: 10, textAlign: "center", pb: 0 }} color="textSecondary" gutterBottom>
                                {record.email}
                            </Typography>
                            <Box sx={{ pt: 4, pb: 2 }}>
                                <Divider />
                            </Box>
                            <Box sx={{ textAlign: "center", pt: 0, textTransform: "uppercase", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size="medium" label={record?.role} />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );
};

const GridList = () => {
    return <LoadedGridList />
};

export default (GridList);