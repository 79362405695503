import React, { useState, useEffect, useCallback, FC, CSSProperties } from 'react';
import { makeStyles, createStyles, Theme, Grid, Typography, Avatar, Stack, Button, Box, Paper, Tab } from '@mui/material';
import { useDataProvider, useGetList, useGetOne } from 'react-admin';
import ReactPlayer from 'react-player';
// import { Player, ControlBar } from 'video-react';

// import configs
import { BASE_ASSETS_URL } from '../../config';

// import images
import Apps from '../../assets/images/services/apps.png';
import LiveTV from '../../assets/images/services/liveTv.png';
import Services from '../../assets/images/services/services.png';
import More from '../../assets/images/services/more.png';
import VOD from '../../assets/images/services/vod.png';

interface Props {
    homepageDetails: any;
    wifi: string;
    phone: string;
    logoLive?: string;
    backgroundLive?: string;
    background_opacity: number;
    videoLive?: string;
    latitude: string;
    longitude: string;
    fontSizes?: number;
    imageSizes?: number;
    logoSizes?: number;
    p?: number;
    heightVideoPlaceholder?: number;
}

const HomeScreenPreview: FC<Props> = ({ homepageDetails, wifi, phone, logoLive, backgroundLive, videoLive, background_opacity, latitude, longitude, fontSizes, imageSizes, logoSizes, p, heightVideoPlaceholder }) => {

    const Spacer = () => <span style={{ width: '4em' }} />;

    const { data: dataDeviceMenu, total, isLoading, error, refetch: refetchMenu } = useGetList(
        'device-menu/property',
        {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
            filter: {}
        }
    );

    const [weatherData, setWeatherData] = useState<any>({});
    const [city, setCity] = useState<any>({});
    const [temperature, setTemperature] = useState<any>({});

    const url = `https://api.openweathermap.org/data/2.5/forecast?units=metric&APPID=9a9c75c7275e0ff863ebc8bb5cbfdc3d&lat=${latitude}&lon=${longitude}`;

   
const fetchWeather = async () => {
    if (latitude && longitude) {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?units=metric&APPID=9a9c75c7275e0ff863ebc8bb5cbfdc3d&lat=${latitude}&lon=${longitude}`);
            const data = await response.json();
            setWeatherData(data.list[0]['weather'][0]);
            setTemperature(data.list[0]['main']);
            setCity(data.city);
        } catch (error) {
            console.error('Error fetching weather data:', error);
           
        }
    }
}


    React.useEffect(() => {
        fetchWeather();
    }, [url])

    return (
        <Box>
            <Box sx={{ width: "100%", backgroundColor: "black", p: 1 }}>
                <Box sx={{ backgroundImage: `url(${backgroundLive ? backgroundLive : BASE_ASSETS_URL + homepageDetails?.property_app_background_path})`, backgroundPosition: 'center', backgroundSize: "cover" }}>
                    <Box sx={{ backgroundColor: 'black', opacity: background_opacity ? background_opacity / 100 : 0.8, pl: 4, pr: 4, pt: 2, pb: 1 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Stack direction="row" spacing={1}>
                                    <Box>
                                        {homepageDetails?.property_logo_path ?
                                            <>
                                                {logoLive ?
                                                    <Avatar sx={{ height: logoSizes ? logoSizes : 60, width: logoSizes ? logoSizes : 60, mr: 1, backgroundColor: "transparent" }} >
                                                        <img width={logoSizes ? logoSizes : 60} src={logoLive} />
                                                    </Avatar>
                                                    :
                                                    <Avatar sx={{ height: logoSizes ? logoSizes : 60, width: logoSizes ? logoSizes : 60, mr: 1, backgroundColor: "transparent" }} >
                                                        <img width={logoSizes ? logoSizes : 60} src={`${BASE_ASSETS_URL}${homepageDetails?.property_logo_path}`} />
                                                    </Avatar>
                                                }
                                            </>
                                            :
                                            <Box>
                                                <Avatar style={{ height: logoSizes ? logoSizes : 60, width: logoSizes ? logoSizes : 60 }}>HL</Avatar>
                                            </Box>
                                        }
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: fontSizes ? fontSizes : 14, color: "white", mt: 1 }} variant="subtitle2" gutterBottom>
                                            Welcome to <strong>{homepageDetails?.name}</strong>, <br></br> Guest
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box>
                                    <Stack direction="row" spacing={0}>
                                        <Box sx={{ mt: 2 }}>
                                            <img src={`https://openweathermap.org/img/wn/${weatherData.icon ? weatherData.icon + "@2x.png" : "01n.png"}`} width={imageSizes ? imageSizes : "150"} />
                                        </Box>
                                        <Box sx={{ pt: 5, ml: -1 }}>
                                            <Typography sx={{ fontSize: fontSizes ? fontSizes : 30, fontWeight: "bold" }}>
                                                {city.name ? city.name : "Weather"}
                                            </Typography>
                                            <Typography sx={{ fontSize: fontSizes ? fontSizes : 25, ml: 0.5 }}>
                                                {Math.round(temperature.temp) ? Math.round(temperature.temp) : "10"}°C
                                            </Typography>
                                            <Typography sx={{ fontSize: fontSizes ? fontSizes : 25, ml: 0.5, color: "gray" }}>
                                                {weatherData.main}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box sx={{ mt: 1.2 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: fontSizes ? fontSizes : 14, color: 'white', textAlign: 'left' }} variant="subtitle2" gutterBottom>
                                                {homepageDetails?.type == 'hotel' ? "Room" : "Screen"}: MAGOWARE
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: fontSizes ? fontSizes : 14, color: 'white', textAlign: 'right' }} variant="subtitle2" gutterBottom>
                                                08:23 04 Sat 2020
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    {homepageDetails?.property_video_path ?
                                        <Box sx={{ width: "100%" }}>
                                            {videoLive ?
                                                <video style={{ width: "100%" }} autoPlay loop muted>
                                                    <source src={videoLive} type="video/mp4" />
                                                </video>
                                                :
                                                <video style={{ width: "100%" }} autoPlay loop muted>
                                                    <source src={`${BASE_ASSETS_URL}${homepageDetails?.property_video_path}`} type="video/mp4" />
                                                </video>
                                            }
                                        </Box>
                                        :
                                        <Box sx={{ height: heightVideoPlaceholder ? heightVideoPlaceholder : 200, backgroundColor: "#111", p: 1, width: "100%", textAlign: "center", pt: 1 }}></Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ p: p ? p : 3 }}></Box>
                        <Box sx={{ display: 'flex' }}>
                            {total as number ?
                                dataDeviceMenu?.map((menu: any) => (
                                    <>
                                        <Box>
                                            <Paper sx={{ mt: 20, textAlign: 'center', backgroundColor: '#222', borderRadius: 7, border: 0 }}>
                                                <img width={'100%'} height={'100%'} src={menu.image} />
                                            </Paper>
                                            <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 10, mt: 7 }} variant="subtitle2" gutterBottom>{menu.name}</Typography>
                                        </Box>
                                        <Spacer />
                                    </>
                                ))
                                :
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Paper sx={{ mt: 2, textAlign: 'center', backgroundColor: '#222', borderRadius: 2, border: 0 }}>
                                            <img width={'100%'} height={'100%'} src={LiveTV} />
                                        </Paper>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 20, mt: 2 }} variant="subtitle2" gutterBottom>Live TV</Typography>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Paper sx={{ mt: 2, textAlign: 'center', backgroundColor: '#222', borderRadius: 2, border: 0 }}>
                                            <img width={'100%'} height={'100%'} src={VOD} />
                                        </Paper>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 20, mt: 2 }} variant="subtitle2" gutterBottom>VOD</Typography>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Paper sx={{ mt: 2, textAlign: 'center', backgroundColor: '#222', borderRadius: 2, border: 0 }}>
                                            <img width={'100%'} height={'100%'} src={Services} />
                                        </Paper>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 20, mt: 2 }} variant="subtitle2" gutterBottom>Services</Typography>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Paper sx={{ mt: 2, textAlign: 'center', backgroundColor: '#222', borderRadius: 2, border: 0 }}>
                                            <img width={'100%'} height={'100%'} src={Apps} />
                                        </Paper>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 20, mt: 2 }} variant="subtitle2" gutterBottom>Apps</Typography>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Paper sx={{ mt: 2, textAlign: 'center', backgroundColor: '#222', borderRadius: 2, border: 0 }}>
                                            <img width={'100%'} height={'100%'} src={More} />
                                        </Paper>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: fontSizes ? fontSizes : 20, mt: 2 }} variant="subtitle2" gutterBottom>More</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Box sx={{ p: 1 }}></Box>
                        {homepageDetails?.type == 'hotel' &&
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontSize: fontSizes ? fontSizes : 16, color: 'white', textAlign: 'left', }} variant="subtitle2" gutterBottom>
                                        Reception: {phone ? phone : "Enter Reception"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontSize: fontSizes ? fontSizes : 14, mt: 1, color: 'gray', textAlign: 'center', }} variant="subtitle2" gutterBottom>
                                        Powered by Magoware
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontSize: fontSizes ? fontSizes : 16, color: 'white', textAlign: 'right' }} variant="subtitle2" gutterBottom>
                                        WiFi: {wifi ? wifi : "Enter WiFI"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{ ml: "auto", mr: "auto", mt: 1, width: "60%", p: 1, borderRadius: 6, backgroundColor: "black" }} />
            <Box sx={{ ml: "auto", mr: "auto", mt: 1, width: "40%", p: 1, borderRadius: 6, backgroundColor: "black" }} />
        </Box>
    )

}

export default HomeScreenPreview;
