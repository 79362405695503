import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useGetOne, useRefresh, useTranslate, Notification, useDataProvider, useListParams, useUpdate } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import types

type Inputs = {
    name: string;
    order: number;
};

const Edit: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { id } = useParams();

    const [genreDetails, setGenreDetails] = useState<any>({});

    //fetch genres
    const { data, isLoading, error, refetch } = useGetOne(
        'genres',
        {
            id: `${id}`
        }
    );

    useEffect(() => {
        setGenreDetails(data);
        setValue("name", data.name);
        setValue("order",data.order);
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {

        //let edit = { name: data.name };
const genresdata = {
    name: data.name,
    order: data.order,

}
        dataProvider.update(
            'genres',
            {
                id: `${id}`,
                data: genresdata,
                previousData: {
                    id: `${id}`,
                    name: genreDetails.name,
                }
            },
        ).then((response: any) => {
            notify('Genre was successfully updated', { type: "info" });
            navigate("/genres");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 6, pr: 3, pt: 12 }}>
            <Title title="Edit Package" />
            <Box>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <Grid container spacing={7}>
                        <Grid item md={4}>
                            <TextField
                                id="name"
                                InputLabelProps={{ shrink: true }}
                                label="Genre Name*"
                                placeholder="Enter Genre Name"
                                {...register("name", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={9} md={2}>
                <TextField
                  id="order"
                  type="number"
                  placeholder="Order "
                  label="Order*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("order", {
                    required: false,
                    pattern: /^\d+$/,
                    min: {
                      value: 1,
                      message: "Value must be greater than 0",
                    },
                  })}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.order ? `${errors.order.message}` : ""
                  }
                />
              </Grid>
                    </Grid>
                    <Box sx={{ p: 3 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Edit;
