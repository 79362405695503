import React, { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme, createStyles, Divider, Button, Dialog, CircularProgress, DialogActions, DialogContent, List, Backdrop, ListItem, ListItemAvatar, Avatar, ListItemText, Box } from '@mui/material';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Dropzone from 'react-dropzone';
import FileCopyIcon from '@mui/icons-material/FileCopy';


import { BASE_URL } from "../../../config";

type Inputs = {
    files: []
};

const ImportEPG: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [changeFiles, setChangeFiles] = useState<any>([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const files = changeFiles.map((file: any) => (
        <ListItem key={file.name}>
            <ListItemAvatar>
                <Avatar>
                    <FileCopyIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`${file.size} Bytes`} />
        </ListItem>
    ));

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        setOpen(false);
        setOpenBackDrop(true);

        data.files = changeFiles;

        data.files.forEach((item, i) => {
            formData.append("xmltvFile", item)
        })

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/import/epg/xmltv`, requestOptions)
            .then((data) => {
                notify('Your epg was successfully imported', { type: "info" });
                setOpenBackDrop(false);
                navigate("/epg");
            });
    }

    return (
        <Box>
            <Button variant="text" color="primary" onClick={handleOpen}>
                Import XML
            </Button>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Dialog
                    open={open}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <Dropzone onDrop={useCallback((acceptedFiles: any) => {
                            setChangeFiles(acceptedFiles);
                        }, [])}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p style={{ border: '1px dashed #ddd',padding: 30,borderRadius: 6,display: "flex",justifyContent: "center",alignItems: "center"}}>Drag and drop some files here, or click to select files</p>
                                </div>
                            )}
                        </Dropzone>
                        <Box>
                            <List>
                                {files}
                            </List>
                        </Box>
                        <Box sx={{ p: 2, mt: 2 }}>
                            <Divider />
                        </Box>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                color="primary"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </form>
        </Box>
    );
}

export default ImportEPG;