import * as React from "react";
import {
  Admin,
  CustomRoutes,
  Resource,
  combineDataProviders,
  DataProviderMatcher,
  defaultDataProvider,
  DataProvider,
} from "react-admin";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

// layout
import { Login, Layout } from "./layout";
import { lightTheme, darkTheme } from "./layout/themes";
import { Dashboard } from "./pages/dashboard";

// providers
import authProvider from "./providers/authprovider";
import provider from "./providers/data-provider";
import mongoProvider from "./providers/mongoDataProvider";

// import custom pages
import AppPersonalization from "./pages/app-personalization/AppPersonalization";
import ManageSubscription from "./pages/account-subscription/ManageSubscription";
import AccountDetails from "./pages/account-settings/AccountDetails";
import ChangeHotelName from "./pages/account-settings/ChangeHotelName";
import ChangePassword from "./pages/account-settings/ChangePassword";
import Imports from "./pages/imports/Imports";
import DummyData from "./pages/sa-dummy-data/List";

// resources
import channels from "./pages/channels";
import epgs from "./pages/epgs";
import genres from "./pages/genres";
import vods from "./pages/vods";
import reports from "./pages/property-reports";
import publicServices from "./pages/cd-services";
import cdProductSalesReport from "./pages/cd-product-sales-reports";
import cdSubscriptionByDays from "./pages/cd-subscriptionsByDays";
import cdSubscriptionByMonths from "./pages/cd-subscriptionsByMonths";
import propertyPackagesByDays from "./pages/property-packages-By-Days";
import propertyPackagesByMonths from "./pages/property-packages-By-Months";
import purchasedPackages from "./pages/property-purchased-packages";
import propertyUsers from "./pages/property-users";
import properties from "./pages/cd-properties";
import packages from "./pages/cd-packages";
import screens from "./pages/screens";
import subscriptions from "./pages/subscriptions";
import propertyPackages from "./pages/property-packages";
import users from "./pages/cd-users";
import history from "./pages/history";
import services from "./pages/services";
import streamSources from "./pages/stream-sources";
import deviceMenu from "./pages/device-menus";
import devices from "./pages/property-device";
import saProperties from "./pages/sa-properties";
import saDevices from "./pages/sa-devices";
import saDistributors from "./pages/sa-distributors";
import saUsers from "./pages/sa-users";
import appManagement from "./pages/app-management";
import Settings from "./pages/sa-settings/Settings";
import cd_Subscriptions from "./pages/cd-subscriptions";
import CreateChannels from "./pages/create-dummy-data/CreateChannels";
import CreatePackages from "./pages/create-dummy-data/CreatePackages";
import CreateVods from "./pages/create-dummy-data/CreateVods";
import Create from "./pages/create-dummy-data/Create";
import Integrations from "./layout/components/Integrations";
import subscriptionByProperties from "./pages/cd-subscriptionsByProperties";
import EditProperties from "./pages/sa-properties/EditProperties";
import epgmetadata from "./pages/epgmetadata";
import CreateEPGMetadata from "./pages/create-dummy-data/CreateEpgMetadata";

const App = () => {

  const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
      case "channels":
      case "screens":
      case "genres":
      case "epg":
      case "subscriptions":
      case "packages":
      case "vods":
      case "services":
        return mongoProvider;
      default:
        return provider;
    }
  });


  ReactGA.initialize("G-DB97RKWMHS");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });

  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      layout={Layout}
      dashboard={Dashboard}
      theme={darkTheme}
      disableTelemetry
    >
      <CustomRoutes>
        <Route path="/app-personalization" element={<AppPersonalization />} />
        <Route path="/manage-subscription" element={<ManageSubscription />} />
        <Route path="/change-name" element={<ChangeHotelName />} />
        <Route path="/account-details" element={<AccountDetails />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/imports" element={<Imports />} />
        <Route path="/createChannels" element={<CreateChannels />} />
        <Route path="/createEpgMetadata" element={<CreateEPGMetadata />} />
        <Route path="/createPackages" element={<CreatePackages />} />
        <Route path="/createVods" element={<CreateVods />} />
        <Route path="/dummy-data" element={<DummyData />} />
        <Route path="/dummy-data/create" element={<Create />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/properties/:id" element={<EditProperties />} />
      </CustomRoutes>
      <Resource name="channels" {...channels} />,
      <Resource name="vods" {...vods} />,
      <Resource name="genres" {...genres} />,
      <Resource name="packages" {...packages} />,
      <Resource name="device-menu" {...deviceMenu} />,
      <Resource name="users/cd" {...users} />,
      <Resource name="stream-source" {...streamSources} />,
      <Resource name="properties/cd" {...properties} />,
      <Resource name="epg" {...epgs} />,
      <Resource name="services/cd/public" {...publicServices} />,
      <Resource name="services/cd/public/menu" {...services} />,
      <Resource
        name="sales-report/product/sales"
        {...cdProductSalesReport}
      />
      ,
      <Resource
        name="sales-report/subscriptions/months"
        {...cdSubscriptionByMonths}
      />
      ,
      <Resource
        name="sales-report/subscriptions/days"
        {...cdSubscriptionByDays}
      />
      ,
      <Resource
        name="sales-report/properties/cd"
        {...subscriptionByProperties}
      />
      ,
      <Resource name="screens" {...screens} />,
      <Resource name="devices/property" {...devices} />,
      <Resource name="users/property" {...propertyUsers} />,
      <Resource name="packages/property/packages" {...propertyPackages} />,
      <Resource name="subscriptions" {...subscriptions} />,
      <Resource name="services" {...services} />,
      <Resource name="services/menu" {...services} />,
      <Resource name="sales-report/property" {...reports} />,
      <Resource
        name="sales-report/purchased/packages"
        {...purchasedPackages}
      />
      ,
      <Resource
        name="sales-report/purchases/days"
        {...propertyPackagesByDays}
      />
      ,
      <Resource
        name="sales-report/purchases/months"
        {...propertyPackagesByMonths}
      />
      ,
      <Resource name="sales-report/history" {...history} />,
      <Resource name="subscriptions/cd" {...cd_Subscriptions} />,
      <Resource name="properties" {...saProperties} />,
      <Resource name="devices" {...saDevices} />,
      <Resource name="content-distributor" {...saDistributors} />,
      <Resource name="users/systemAdmin" {...saUsers} />,
      <Resource name="app-management" {...appManagement} />,
    </Admin>
  );
};

export default App;
