import React, { FC, useState } from 'react';
import { useUnselectAll, BulkActionProps, useNotify, useRefresh, Identifier, useDataProvider, useUpdate } from 'react-admin';
import { makeStyles, createStyles, Theme, Backdrop, CircularProgress, Dialog, TextField, MenuItem, FormControl, Typography, Select, Button, DialogContent, DialogTitle } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { BASE_URL } from "../../../config";
import { Box } from '@mui/material';

const noSelection: Identifier[] = [];
interface OptionType {
    label?: string;
    name: string;
    inputValue?: string;
}

const AssignMenuCategory: FC<BulkActionProps> = ({ selectedIds = noSelection }) => {

    const notify = useNotify();
    const unselectAll = useUnselectAll('vod');
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const filter = createFilterOptions<OptionType>();
    const [menu, setMenu] = React.useState<OptionType | any>(null);
    const [category, setCategory] = React.useState<OptionType | any>(null);
    const [allMenus, setAllMenus] = useState<OptionType | any>(null);
    const [allCategories, setAllCategories] = useState<OptionType | any>(null);

    //dialog open/close

    const [open, setOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);

    const handleClickOpen = async () => {
        setOpenBackDrop(true);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
        };

        fetch(`${BASE_URL}/api/v1/vod/cd/menus`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                setAllMenus(response);
                setOpen(true);
                setOpenBackDrop(false);
            });

        fetch(`${BASE_URL}/api/v1/vod/cd/categories`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                setAllCategories(response);
                setOpen(true);
                setOpenBackDrop(false);
            });
    };

    const handleClose = () => {
        setOpen(false);
        unselectAll();
        refresh();
    };

    // assign menu & category

    const { register, handleSubmit, control, watch, formState: { errors } } = useForm<any>();
    const OnSubmit: SubmitHandler<any> = (data: any) => {

        const formData: any = new FormData();

        if (menu !== null) {
            data.vod_menu = menu.name;
        }

        if (category !== null) {
            data.vod_category = category.name;
        }

        data.vods = selectedIds;

        formData.append("vod_menu", data.vod_menu);
        formData.append("vod_category", data.vod_category);
        formData.append("vods", JSON.stringify(data.vods));

        dataProvider.update(
            'vod/assign/menu/category',
            {
                id: '',
                data: formData,
                previousData: {
                    id: ''
                }
            },
        ).then((response: any) => {
            notify('Vods successfully updated', { type: "info" });
            handleClose();
            // refresh();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ paddingLeft: 0 }}>
            <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
                style={{ color: "white", marginRight: 10 }}
            >
                Assign Menu & Category
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ display: "flex", flexDirection: "column", padding: 30 }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DialogTitle>Assign Menu & Category</DialogTitle>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Autocomplete
                            sx={{ marginRight: 20 }}
                            // value={menu}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setMenu({
                                        name: newValue
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setMenu({
                                        name: newValue.inputValue,
                                        label: newValue.inputValue
                                    });
                                } else {
                                    setMenu(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option: any) => inputValue === option);

                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        name: params.inputValue,
                                        label: `Add "${params.inputValue}"`
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="vod_menu"
                            options={allMenus}
                            getOptionLabel={(option: any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                else if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                else return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            style={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Menu" variant="outlined" />
                            )}
                        />

                        <Autocomplete
                            // value={category}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setCategory({ name: newValue });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setCategory({
                                        name: newValue.inputValue,
                                        label: newValue.inputValue
                                    });
                                } else {
                                    setCategory(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option: any) => inputValue === option);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        name: params.inputValue,
                                        label: `Add "${params.inputValue}"`
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="vod_category"
                            options={allCategories}
                            getOptionLabel={(option: any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Category" variant="outlined" />
                            )}
                        />
                    </Box>
                    <div style={{ marginTop: 20, textAlign: "end" }}>
                        <Button
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button disabled={menu && category ? false : true} variant="contained" color="primary" onClick={handleSubmit(OnSubmit)}>
                            Submit
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Backdrop sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1, color: '#fff', }} open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default AssignMenuCategory;