import React, { FC, useCallback, useEffect, useState } from 'react';
import { Title, useRefresh, useDataProvider, useGetOne, useNotify } from 'react-admin';
import { makeStyles, Theme, createStyles, Divider, Button, Grid, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import AndroidIcon from '@mui/icons-material/Android';
import { BASE_URL } from "../../config";

type Inputs = {
    app_version: string,
    appid: string,
    beta_version: boolean,
    description: string,
    isavailable: boolean,
    title: string,
    upgrade_min_api: string,
    upgrade_min_app_version: string,
    build: object,
};

const Edit: FC = () => {


    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const notify = useNotify();


    const [betaVersion, setBetaVersion] = useState(false);

    const handleBetaVersion = (event: any) => {
        setBetaVersion(event.target.checked);
    }

    const [isAvailable, setIsAvailable] = useState(false);

    const handleChangeIsAvailable = (event: any) => {
        setIsAvailable(event.target.checked);
    }

    const [apk, setApk] = useState<any>([]);
    const [show, setShow] = useState<boolean>(true);

    const handleAPK = (e: any) => {
        setApk(e.target.files[0]);
        setShow(true);
    }

    const { id } = useParams();

    //fetch apk data
    const { data: dataApp, isLoading, error, refetch } = useGetOne(
        'app-management',
        {
            id: id
        },
    );

    useEffect(() => {
        setValue("app_version", dataApp?.app_version);
        setValue("appid", dataApp?.appid);
        setValue("beta_version", dataApp?.beta_version);
        setValue("description", dataApp?.description);
        setValue("title", dataApp?.title);
        setValue("upgrade_min_api", dataApp?.upgrade_min_api);
        setValue("upgrade_min_app_version", dataApp?.upgrade_min_app_version);
        setIsAvailable(dataApp?.isavailable);
        setBetaVersion(dataApp?.beta_version);
        setApk(dataApp?.build)
    }, [dataApp]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        data.build = apk;

        formData.append("app_version", data.app_version);
        formData.append("appid", data.appid);
        formData.append("beta_version", data.beta_version);
        formData.append("description", data.description);
        formData.append("isavailable", data.isavailable);
        formData.append("title", data.title);
        formData.append("upgrade_min_api", data.upgrade_min_api);
        formData.append("upgrade_min_app_version", data.upgrade_min_app_version);
        if (Object.keys(data.build).length === 0 && data.build.constructor === Object === false) {
            formData.append("build", data.build);
        }

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/app-management/${id}`, requestOptions)
            .then((data) => {
                if (data.status >= 200 && data.status < 300) {
                    notify('Your app was successfully edited', { type: "info" });
                    navigate("/app-management");
                } else {
                    notify("Your app was  not successfully edited", { type: "warning" });;
                    navigate("/app-management");
                }
            });
    }

    return (
        <Box sx={{ pl: 6, pr: 3, pt: 12 }}>
            <Title title="App Management" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <TextField
                                    id="app_version"
                                    placeholder="Enter App Version"
                                    label="App Version"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("app_version")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    id="appid"
                                    placeholder="Enter App Id"
                                    label="App Id"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("appid")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="title"
                                    placeholder="Title"
                                    label="Title"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("title")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ pb: 3 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={11} md={10}>
                                <TextField
                                    id="description"
                                    placeholder="Enter Description"
                                    label="Description"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("description")}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={6}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ pb: 2 }}></Box>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ m: 2 }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={betaVersion} {...register("beta_version")} onChange={handleBetaVersion} name="beta_version" />}
                                            label="Beta Version"
                                        />
                                    </Box>
                                    <Box sx={{ m: 2 }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isAvailable} {...register("isavailable")} onChange={handleChangeIsAvailable} name="isavailable" />}
                                            label="Is Available"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ pb: 1 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <TextField
                                    id="upgrade_min_api"
                                    placeholder="Upgrade Min Api"
                                    label="Upgrade Min Api"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("upgrade_min_api")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="upgrade_min_app_version"
                                    placeholder="Upgrade Min App version"
                                    label="Upgrade Min App version"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("upgrade_min_app_version")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ pb: 3 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                {show ? <AndroidIcon /> : null}
                                <Button
                                    size="large"
                                    color="primary"
                                    component="label"
                                    style={{ marginTop: -10 }}
                                >
                                    Upload APK
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            handleAPK(e);
                                        }}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        <Box sx={{ p: 5 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default Edit;