import React, { FC, useState, useEffect, useCallback, ReactElement } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useGetOne, ImageInput, ImageField, ListBase, ListProps, SimpleList, Identifier, DatagridProps, useListContext, Datagrid, SimpleForm, FileInput, FileField, BooleanInput } from 'react-admin';
import { makeStyles, createStyles, FormControl, Theme, Grid, Typography, Switch, InputLabel, TextField, Divider, Avatar, Button, FormControlLabel, Checkbox, Tab, Box, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";


type Inputs = {
    device_active: boolean;
    upgrade_flag: boolean;
};

const Edit: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { id } = useParams();
    const [activeDevice, setActiveDevice] = useState<boolean>(false);
    const [upgradeFlag, setUpgradeFlag] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleChangeActiveDevice = (event: any) => {
        setActiveDevice(event.target.checked);
        setDisabled(false);
    }

    const handleChangeUpgradeFlag = (event: any) => {
        setUpgradeFlag(event.target.checked);
        setDisabled(false);
    }

    //fetch device details
    const { data: dataDevices, isLoading, error, refetch } = useGetOne(
        'devices',
        {
            id: id
        },
    );


    useEffect(() => {
        setActiveDevice(dataDevices["device_active"]);
        setValue('device_active', dataDevices["device_active"])
        setUpgradeFlag(dataDevices["upgrade_flag"]);
        setValue('upgrade_flag', dataDevices["upgrade_flag"])
    }, [dataDevices]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty, dirtyFields } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {

        const formData: any = new FormData();

        formData.append("device_active", data?.device_active);
        formData.append("upgrade_flag", data?.upgrade_flag);

        dataProvider.update(
            'devices',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: id
                }
            },
            ).then((response: any) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your device was successfully updated', { type: "info" });
                navigate("/screens");
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    return (
        <Box sx={{ pl: 5, pr: 3, pt: 12 }}>
            <Title title="Edit Device" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item md={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeDevice}
                                    {...register("device_active")}
                                    onChange={handleChangeActiveDevice}
                                    color="primary"
                                    name="device_active" />}
                            label="Active Device"
                        />
                    </Grid>
                    <Grid item md={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={upgradeFlag}
                                    {...register("upgrade_flag")}
                                    onChange={handleChangeUpgradeFlag}
                                    color="primary"
                                    name="upgrade_flag" />}
                            label="Upgrade Available"
                        />
                    </Grid>
                </Grid>
                <Box style={{ padding: 20 }}>
                    <Divider />
                </Box>
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={disabled}
                    onClick={handleSubmit(onSubmit)}
                >
                    Submit
                </Button>
            </form>
        </Box>
    )
}

export default Edit;