import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { Box } from '@mui/material';


type Inputs = {
    email: string,
    role: string,
    phone: string,
    address: string,
};

const Create: FC = () => {

    
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("email", data.email);
        formData.append("role", data.role);
        formData.append("phone", data.phone);
        formData.append("address", data.address);

        dataProvider.create<any>(
            'users/property/create',
            {
                data: formData
            },
            ).then((response: any) => {
                notify('User was successfully invited', { type: "info" });
                navigate("/users/property");
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    return (
        <Box sx={{padding: 30}}>
            <Title title="Invite User" />
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item md={2}>
                            <TextField
                                id="email"
                                label="Email*"
                                placeholder="Enter User Email"
                                InputLabelProps={{ shrink: true }}
                                {...register("email")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="phone"
                                label="Phone*"
                                placeholder="Enter Phone"
                                InputLabelProps={{ shrink: true }}
                                {...register("phone")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="address"
                                label="Address*"
                                placeholder="Enter Address"
                                InputLabelProps={{ shrink: true }}
                                {...register("address")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                id="role"
                                select
                                label="Role*"
                                variant="outlined"
                                {...register("role")}
                                fullWidth
                            >
                                <MenuItem value="IT">IT</MenuItem>
                                <MenuItem value="Commercial">Commercial</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <Box style={{ padding: 20 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Invite
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Create;