import React, { FC, useState, useCallback, useEffect } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useListParams,useCreate } from 'react-admin';
import { makeStyles, createStyles, Theme, TextField, Dialog, DialogContent, Grid, Button, Divider,Box } from '@mui/material';
import {  useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller, useWatch } from "react-hook-form";

// import types
// import { AppState } from '../../../types/Theme';

//import components
// import TitleSection from '../../../components/title/TitleSection';

type Inputs = {
    name: string
};

const CreateStreamSource: FC = () => {

    // const themes = useSelector((state: AppState) => state.theme);
    // const useStyles = makeStyles((theme: Theme) =>
    //     createStyles({
    //         formDiv: {
    //             display: 'flex',
    //             flexDirection: 'column',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             minWidth: 300
    //         }
    //     })
    // );
    // const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    // const history = useHistory();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("name", data.name);

        dataProvider.create(
            'stream-source',
            {
                data: formData,
            },
            ).then((response: any) => {
                notify('Stream Source was successfully created', { type: "info" });
                handleClose();
                refresh();
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    const formName = watch('name');

    return (
        <>
            <span onClick={handleClickOpen} style={{ cursor: "pointer", color: "#008DD9", fontWeight: "normal" }}>+ Create Stream Source</span>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                maxWidth={"lg"}
            >
                <DialogContent >
                    <Title title="Create Stream Source" />
                    <form onSubmit={handleSubmit(OnSubmit)} style={{ display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',minWidth: 300}}>
                        {/* <TitleSection title="Create Stream Source" description="Create Stream Source" paddingBottom={10} paddingLeft={1} paddingTop={0} /> */}
                        <TextField
                            id="name"
                            InputLabelProps={{ shrink: true }}
                            label="Stream Source Name"
                            placeholder="Enter Stream Source Name"
                            {...register("name")}
                            variant="outlined"
                            helperText={"Stream Source Name cannot contain whitespace and symbols"}
                            fullWidth
                        />
                        <Box sx={{ padding: 20 }}>
                            <Divider />
                        </Box>
                        <Button
                            type="button"
                            onClick={handleSubmit(OnSubmit)}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={(/^[A-Za-z0-9_]+$/.test(formName)) ? false : true}
                        >
                            Submit
                        </Button>
                    </form>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default CreateStreamSource;