import React, { useState, useEffect, useCallback, FC, CSSProperties } from 'react';
import { useGetOne, useTranslate, Title, useDataProvider, useRefresh, useNotify, useLogout } from 'react-admin';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles, Grid, TextField, Divider, Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";


type Inputs = {
    code: string,
    client_id: string,
    client_secret: string,
    organization_id: string,
};
const Settings = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [zoho, setZoho] = useState<any>({});

    const { data: dataSettings, isLoading, error, refetch } = useGetOne(
        'settings',
        {
            id: ''
        },
    );


    useEffect(() => {
        setValue("code", dataSettings?.code);
        setValue("client_id", dataSettings?.client_id);
        setValue("client_secret", dataSettings?.client_secret);
        setValue("organization_id", dataSettings?.organization_id);
    }, [dataSettings])

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        formData.append("code", data.code);
        formData.append("client_id", data.client_id);
        formData.append("client_secret", data.client_secret);
        formData.append("organization_id", data.organization_id);

        dataProvider.update(
            'settings',
            {
                id: "",
                data: formData,
                previousData: {
                    id: ""
                }
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your Zoho Account was successfully set up', { type: "info" });
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box>
            <Title title="Settings" />
            <Box sx={{ pl: 4, pr: 3, pt: 11 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Set Up Zoho Account</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <TextField
                                        id="client_id"
                                        InputLabelProps={{ shrink: true }}
                                        label="Client ID"
                                        placeholder="Enter Client ID"
                                        {...register("client_id")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        id="client_secret"
                                        InputLabelProps={{ shrink: true }}
                                        label="Client Secret"
                                        placeholder="Enter Client Secret"
                                        {...register("client_secret")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ marginTop: 10 }}>
                                <Grid item md={8}>
                                    <TextField
                                        id="organization_id"
                                        InputLabelProps={{ shrink: true }}
                                        label="Organization ID"
                                        placeholder="Enter Organization ID"
                                        {...register("organization_id")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        id="code"
                                        InputLabelProps={{ shrink: true }}
                                        label="Code"
                                        placeholder="Generate Code From Zoho"
                                        {...register("code")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box style={{ padding: 20 }}>
                                <Divider />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={3}>
                                    <Button
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
}

export default Settings;