import React , {Fragment} from 'react'
import {
    Datagrid,
    List,
    TextField,
    FunctionField,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';

// const BulkActionButtons = (props:any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );

const ResourceList = () => {
    return (
        <Box sx={{ pl: 1, pr: 3 }}>
            <List
                perPage={25}
                // bulkActionButtons={<BulkActionButtons />}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    bulkActionButtons={false}
                >
                    <FunctionField
                        label="Distributor"
                        render={(record: any) => {
                            if (record.cd_name) {
                                return (
                                    <Typography sx={{ p: 2 }}>{record.cd_name}</Typography>
                                )
                            }
                        }}
                    />
                    {/* <TextField label="Distributor" source="cd_name" /> */}
                    <TextField label="Screen Number" source="screen_number" />
                    <TextField label="MAC Address" source="mac_address" />
                    <TextField label="Enter Package Name" source="package_name" />
                    <TextField label="Price" source="package_price" />
                </Datagrid>
            </List>
        </Box>
    )
}

export default ResourceList;