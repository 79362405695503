import React, { FC, useState, useCallback, useEffect } from 'react';
import { Title, useDataProvider, useLogout, usePermissions, useRefresh, useNotify, useUpdate } from 'react-admin';
import { makeStyles, createStyles, Theme, Dialog, Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Box, Typography } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import moment from 'moment';

// import types
// import { AppState } from '../../../types/Theme';

interface Props {
    id: any;
}


const CancelSubscription: FC<Props> = ({ id }) => {

    // const dataProvider = useDataProvider();
    // const notify = useNotify();
    // const refresh = useRefresh();
    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     refresh();
    //     setOpen(false);
    // };

    // //const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    // //const OnSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        refresh();
        setOpen(false);
    };

    const OnSubmit = () => {
        const cancelData = {
          end_time: moment().toDate(),
        };

    dataProvider.update(
        'subscriptions',
        {
            id: id,
            data: cancelData,
            previousData: id
        },
    ).then((response: any) => {
        notify('Subscription was successfully canceled', { type: "info" });
        handleClose();
    }).catch((error: any) => {
        notify(error.message, { type: "warning" });
    })
}

return (
    <Box>
        <Button
            onClick={handleClickOpen}
            size="small"
        >
            Cancel
        </Button>
    {/* <form onSubmit={handleSubmit(OnSubmit)}> */}
            <Dialog
                open={open}
                maxWidth="lg"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent >
                    <DialogTitle>Cancel Subscription </DialogTitle>

                    <DialogContent>
                        <Typography sx={{ marginBottom: 0 }}>Are you sure you want to cancel this subscription?</Typography>

                    </DialogContent>
                    <DialogActions sx={{ padding: 12, pt: 3, pb: 5 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            style={{ color: 'black' }}
                            variant="contained"
                            onClick={OnSubmit}

                        >
                            Cancel Subscription
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        {/* </form> */}
    </Box>
)
}

export default CancelSubscription;