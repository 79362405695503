import * as React from 'react';
import { Box, Card, Typography, Grid, CardContent, Chip, Divider, CardActions, Button } from '@mui/material';
import { useCreatePath, useListContext } from 'react-admin';

// assets config
import { BASE_ASSETS_URL } from '../../config';

import DeletePackageDialog from './dialogs/DeletePackageDialog';
import InfoDialog from './dialogs/InfoDialog';

const GridList = () => {
    return <LoadedGridList />
};

const LoadedGridList = () => {

    const { data } = useListContext();
    if (!data) return null;

    return (
        <Box sx={{ pl: 5, pr: 4, pt: 3, m: 0 }}>
            <Grid container spacing={3}>
                {data.map(record => (
                    <Grid item xs={12} md={3}>
                        <Card sx={{ borderRadius: 10 }}>
                            <CardContent>
                                <Box sx={{ marginTop: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img height="60" src={`${BASE_ASSETS_URL}${record.icon_path}`} />
                                </Box>
                                <Typography sx={{ marginTop: 2, fontSize: 20, fontWeight: "bold", textAlign: "center" }} color="textSecondary" gutterBottom>
                                    {record.package_name}
                                </Typography>
                                <Typography sx={{ marginTop: 0, fontSize: 15, textAlign: "center" }} color="textSecondary" gutterBottom>
                                    {record.description ? record.description.substring(0, 90) + ' ...' : " "}
                                </Typography>
                                <Typography sx={{ fontSize: 15, textAlign: "center", height: 35 }} color="textSecondary" gutterBottom>
                                    <Chip label={`${record.package_duration} ${record.unit}`} style={{ marginRight: 10 }} />
                                    <Chip label={`${record.price} €`} />
                                </Typography>
                            </CardContent>
                            <Box style={{ padding: 9 }}>
                                <Divider />
                            </Box>
                            <CardActions sx={{ alignItems: "center", pt: 0, justifyContent: "center" }}>
                                <Button href={`/#/packages/${record._id}`} size="small" color="primary">Edit</Button>
                                <InfoDialog record={record} />
                                <DeletePackageDialog record={record} index={record._id} />
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default GridList;