import React, { ReactElement, Fragment } from 'react';
import {
    Datagrid,
    List,
    TextField,
    DateField,
    useGetResourceLabel,
    FunctionField,
    downloadCSV,
    useListContext,
} from 'react-admin';
import { Typography, Box } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import BulkDelete from '../../components/BulkDelete';

// const BulkActionButtons = (props:any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource} </Typography>
            <Box sx={{ mt: 3 }}>
                {/* <CreateButton  /> */}
            </Box>
        </Box>
    );
};


const exporter = (records: any, fetchRelatedRecords: any) => {
    let data: any = [];
    records.map((record: any) => {
        data.push({
            ...record,
            subscription_start_time: `${moment(record?.subscription_start_time).format("L")}`,
            subscription_end_time: `${moment(record?.subscription_end_time).format("L")}`,
            screen: " ",
            property_operator_id: " ",
            _id: " ",
            __v: " ",
            id: " ",
            createdAt: " ",
        })
    });
    return jsonExport(data, {
        headers: ['package_name', 'screen_number', 'subscription_start_time', 'subscription_end_time',],
        rename: ["Packages Name", 'Screen ', "Start Time", "End Time", ' ', ' ', ' ', ' ', ' ', ' '],
    }, (err: any, csv: any) => {
        downloadCSV(csv, 'history');
    });
};

const ResourceList = () => {

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 3 }}>
            <List
                empty={<Empty />}
                exporter={exporter}
                title="History"
                perPage={25}
                sort={{ field: "createdAt", order: "DESC" }}
            // bulkActionButtons={<BulkActionButtons />}
            >
                <Datagrid bulkActionButtons={false}>
                    <FunctionField
                        label="Package Name"
                        render={(record: any) => {
                            if (record.package_name) {
                                return (
                                    <Typography sx={{ p: 2, fontSize: 14 }}>{record.package_name}</Typography>
                                )
                            }
                        }}
                    />
                    <TextField label="Screen Number" source="screen_number" />
                    <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="subscription_start_time" title="Start Time" />
                    <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="subscription_end_time" title="End Time" />
                    <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="createdAt" title="Created At" />
                </Datagrid>
            </List>
        </Box>
    )

}
export default ResourceList;