import React, { FC, useCallback } from 'react';
import { useGetOne, useNotify, useRefresh } from 'react-admin';
import { Button } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

// import config
import { BASE_URL } from '../../config';

const SwitchToCD: FC = () => {

    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const token = localStorage.getItem("access_token");
    let decode: any = jwtDecode(`${token}`);

    const { data: dataUser, isLoading: loadingUser, error: errorUser } = useGetOne(
        'users',
        {
            id: decode["_id"]
        }
    );

    const handleClickOpen = () => {

        const formData: any = new FormData();

        formData.append("cd_id", dataUser["content_distributor_id"]);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/content-distributor/switch-cd`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                localStorage.setItem("access_token", response.accessToken);
                notify('Your CD switched successfully');
                // window.location.replace(window.location.origin);
                refresh();
                navigate("/");
            })

    };

    return (
        <>
            {dataUser && dataUser?.content_distributor_id &&
                <Button sx={{ ml: 2, color: "orange" }} onClick={handleClickOpen} variant="text">Manage Content</Button>
            }
        </>
    );
}

export default SwitchToCD;