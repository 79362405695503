import { ReactElement, FC, Fragment } from 'react';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    TextField,
    EditButton,
    useListContext,
    TopToolbar,
    CreateButton,
    Pagination
} from 'react-admin';
import { Chip, Typography, Box } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';

const ListActions: FC<any> = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box sx={{ pt: 3 }}>
                <CreateButton resource={resource} />
            </Box>
        </Box>
    );
};

const BulkActionButtons = (props: any) => (
    <Fragment>
        <BulkDelete {...props} />
    </Fragment>
);

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <Box sx={{ pl: 4, pr: 3, pt: 4 }}>
            <List
                title="Stream Source"
                empty={<Empty />}
                pagination={<ListPagination />}
                actions={<ListActions />}
                {...props}
                perPage={10}
                bulkActionButtons={<BulkActionButtons />}
            >
                <Datagrid>
                    <TextField label="Name" source="name" />
                    <EditButton />
                </Datagrid>
            </List>
        </Box>
    )
}
export default ResourceList;