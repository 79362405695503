import * as React from 'react';
import { Box, useMediaQuery, Theme, Typography } from '@mui/material';
import {
    ListBase,
    CreateButton,
    Pagination,
    Title,
    useGetResourceLabel,
    TopToolbar,
    useListContext
} from 'react-admin';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';

import GridList from './GridList';

const ListActions = () => (
    <TopToolbar>
        <Box sx={{ mt: 4 }}><CreateButton resource="/packages" /></Box>
    </TopToolbar>
);

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon sx={{ fontSize: 180, color: "grey" }} />
            <Typography sx={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography sx={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box sx={{ marginTop: 3 }}>
                <CreateButton resource={resource} />
            </Box>
        </Box>
    );
};

const ResourceList = () => {

    return (
        <Box sx={{ pl: 2, pt: 0 }}>
            <ListBase
                // empty={<Empty />}
                perPage={25}
                sort={{ field: 'reference', order: 'ASC' }}
            >
                <Title defaultTitle="Packages" />
                <ListActions />
                <GridList />
                <Pagination rowsPerPageOptions={[25, 50, 100]} />
            </ListBase>
        </Box>
    );

};

export default ResourceList;