import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useDelete, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, DialogContentText, DialogTitle, DialogContent, Dialog, IconButton, DialogActions, Divider, Button, Box, Tabs, Tab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

//import components
// import TitleSection from '../../../components/title/TitleSection';

interface Props {
    record: any;
}

const ServiceDelete: FC<Props> = ({ record }) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    // delete service state
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    // open delete dialog
    const handleClickDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    // close delete dialog
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // delete service request
    const HandleConfirmDeleteDialog = () => {
        dataProvider.delete (
            'services',
            {
                id: record["_id"],
                previousData: {
                    id: record["_id"],
                }
            },
            ).then((response: any) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your service was successfully deleted', { type: "info" });
                setOpenDeleteDialog(false);
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    
    };

    return (
        <Box style={{ padding: 0 }}>
            <IconButton onClick={handleClickDeleteDialog}>
                <DeleteIcon style={{ color: "rgb(230 63 63)" }} />
            </IconButton>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete service</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {record.service_name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        CANCEL
                    </Button>
                    <Button onClick={HandleConfirmDeleteDialog} color="primary" autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ServiceDelete;