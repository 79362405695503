import * as React from 'react';
import { FC } from 'react';
import { ListBase, ListProps, Title } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Grid, Typography, TextField, Avatar, Button, FormControlLabel, Checkbox, Stepper,
    Step, StepLabel, StepContent, Paper, Autocomplete, Box, createFilterOptions
} from '@mui/material';

// import components
import GridList from './GridList';
import Menu from './Menu';

const ResourceList = () => {
    return (
        <Box sx={{ pl: 4, pt: 6 }}>
            <ListBase
                perPage={5}
                sort={{ field: 'reference', order: 'ASC' }}
            >
                <Title defaultTitle="Services" />
                <Grid container spacing={4}>
                    {/* <Grid item xs={12} md={2}>
                        <Menu />
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                        <GridList />
                    </Grid>
                </Grid>
            </ListBase>
        </Box>
    );
};

export default ResourceList;