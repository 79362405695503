import React, { Fragment, cloneElement } from 'react'
import {
    Datagrid,
    List,
    TextField,
    FunctionField,
    TopToolbar,
    useListContext,
    CreateButton,
    Button,
    sanitizeListRestProps,
    ExportButton,
    Exporter,
    downloadCSV,
} from 'react-admin'
import { Box, Typography } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import { useMediaQuery, Theme } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    // const BulkActionButtons = (props: any) => (
    //     <Fragment>
    //         <BulkDelete {...props} />
    //     </Fragment>
    // );

    const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

        fetchRelatedRecords(records, "package", "packages").then((pcg: any) => {
            fetchRelatedRecords(records, "property_operator_id", "properties/cd").then((sc: any) => {

                let data: any = [];

                records.map((record: any) => {
                    console.log(pcg[record?.package]?.package_name, "sdasds")

                    data.push({
                        ...record,
                        id: "",
                        last_sold: ""
                    })
                });
                return jsonExport(data, {
                    headers: ['_id', 'package_price', 'sales_total', 'duration', 'unit', 'total_value', 'id', 'last_sold'],
                    rename: ['Packages Name', "Price", "Total Sales", "Duration", "Unit", "Total Value", " ", " "],
                }, (err: any, csv: any) => {
                    downloadCSV(csv, 'by-product');
                });
            })
        })

    };

    const Empty = () => {
        const {  resource } = useListContext();
        return (
            <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
                <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
                <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource} </Typography>
                <Box sx={{ mt: 3 }}>
                    {/* <CreateButton  /> */}
                </Box>
            </Box>
        );
    };


    const ListActions = (props: any) => {
        const {
            className,
            exporter,
            filters,
            maxResults,
            ...rest
        } = props;
        const {
            resource,
            displayedFilters,
            filterValues,
            selectedIds,
            showFilter,
            total,
        } = useListContext();
        return (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            </TopToolbar>
        );
    };
    return (
        <Box sx={{ pl: 4, pr: 3, pt: 3, width: "auto" }}>
            {isXSmall ?
                <List
                    perPage={25}
                    exporter={exporter}
                    empty={<Empty />}
                    // bulkActionButtons={<BulkActionButtons />}
                    actions={<ListActions />}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Package Name" source="_id" />
                        <FunctionField label="Total Value" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.total_value} €</Typography>
                            )
                        }} />
                    </Datagrid>
                </List>
                :
                <List
                    perPage={25}
                    exporter={exporter}
                    empty={<Empty />}
                    // bulkActionButtons={<BulkActionButtons />}
                    actions={<ListActions />}

                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="Package Name" source="_id" />
                        <TextField label="Duration" source="duration" />
                        <TextField label="Unit" source="unit" />
                        <FunctionField label="Price" render={(record: any) => `${record.package_price} €`} />;
                        <TextField label="Total Sales" source="sales_total" />
                        <FunctionField label="Total Value" render={(record: any) => {
                            return (
                                <Typography sx={{ p: 2 }}>{record.total_value} €</Typography>
                            )
                        }} />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;