import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useUpdate, Notification, useDataProvider } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Card, CardHeader, IconButton, Grid, Chip, CardActions, Divider, Button, Typography, CardMedia, Tab,
    Dialog, DialogActions, Box, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

//import components
import { BASE_ASSETS_URL } from '../../../config';

interface Props {
    record: any;
}

const ServiceDetails: FC<Props> = ({ record }) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box style={{ padding: 0 }}>
            <IconButton onClick={handleClickOpen} sx={{ color: "grey" }}>
                <InfoIcon />
            </IconButton>
            <Dialog
                style={{ borderRadius: 30 }}
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <Box>
                        <Grid container spacing={4}>
                            <Grid item md={6}>
                                <CardMedia
                                    sx={{ height: 0, pt: '50%' }}
                                    image={`${BASE_ASSETS_URL}${record.service_path}`}
                                    title={record["service_name"]}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Box sx={{ mt: 2 }}>
                                    <Typography sx={{ fontSize: 30, mb: 2 }}>
                                        {record['service_name']}
                                    </Typography>
                                </Box>
                                <DialogContentText>
                                    {record['short_service_description']}
                                </DialogContentText>
                                <Box sx={{ mt: 2 }}>
                                    <Chip sx={{ mr: 2, mt: 2 }} label={record['service_menu']} />
                                    <Chip sx={{ mr: 2, mt: 2 }} label={record['service_category']} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ServiceDetails;