import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Chip, Card, TextField, Divider, Button, Grid, Backdrop, Dialog, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetList, useGetOne, useNotify } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";

import { BASE_URL } from "../../config";

// components
import AddSubscription from './components/AddSubscription';
import InvoiceHistory from './components/InvoiceHistory';
import Change from './components/Change';
import CancelSubscription from './components/CancelSubscription';

type Inputs = {
    quantity: string
};

const ManageSubscription: FC = () => {

    let { search } = useLocation();
    let navigate = useNavigate();

    const notify = useNotify();

    const query = new URLSearchParams(search);
    const paramField = query.get('hostedpage_id');

    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [addSubscription, setAddSubscription] = React.useState(true);

    const [screenQuantity, setScreenQuantity] = React.useState<any>();

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);

    const { data: dataUser, isLoading: loadingUser, error: errorUser } = useGetOne(
        'users',
        {
            id: decode['_id']
        },
    );

    const { data: dataProperty, isLoading: loadingProperty, error: errorProperty } = useGetOne(
        'properties',
        {
            id: decode['property_operator_id']
        },
    );

    const { data: zohoSubscriptions, total: totalZohoSubscriptions, isLoading: isLoadingZohoSubscriptions, error: errorZohoSubscriptions } = useGetList(
        'properties/zoho/listSubscriptions',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const handleClick = (record: any) => {

        const formData: any = new FormData();

        formData.append("subscription_id", record['subscription_id']);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/properties/zoho/updateCreditCard`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                window.open(response.hostedpage.url);
            });

    }

    const { handleSubmit, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {

        const formData: any = new FormData();

        data.quantity = screenQuantity;

        formData.append("quantity", data.quantity);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/subscriptions/zoho/createSubscription`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                window.open(response.hostedpage.url);
            }).catch((error) => notify(error.message, { type: "warning" }));

    }

    return (
        <Box>
            {!openBackDrop ?
                <>
                    {dataProperty && dataUser && zohoSubscriptions && dataUser.zscid && zohoSubscriptions.length > 0 ?
                        <>
                            {addSubscription &&
                                <Box style={{ marginBottom: 20 }}>
                                    <AddSubscription screenLimit={dataProperty["screens_limit"]} />
                                </Box>
                            }
                            {zohoSubscriptions.map((record: any, index: any) => {
                                return (
                                    <Card key={index} sx={{ marginBottom: 20, padding: 30 }} variant="outlined">
                                        <Box sx={{ display: "flex", marginBottom: 20 }}>
                                            <Box style={{ marginRight: 90 }}>
                                                <Typography>Subscription Name</Typography>
                                                <Typography sx={{ fontSize: 12, marginTop: 0, marginBottom: 5, color: "orange" }}>Subscription ID: {record.subscription_id}</Typography>
                                                <Typography sx={{ fontSize: 12, marginTop: 0, marginBottom: 5, color: "orange" }}>Subscription Nr: {record.subscription_number}</Typography>
                                                <Typography sx={{ textTransform: "uppercase" }}>{record.plan_name}</Typography>
                                                <Typography sx={{ fontSize: 20, marginBottom: 10, color: "#ddd" }}>{dataProperty["screens_limit"]} Screens</Typography>
                                                {record.status == "live" &&
                                                    <Chip color='primary' label="Active" />
                                                }
                                                {record.status == "cancelled" &&
                                                    <Chip sx={{ backgroundColor: "#ff000085", color: "white" }} label="Cancelled" />
                                                }
                                            </Box>
                                            <Box sx={{ marginRight: 30 }}>
                                                <Typography>Subscription Amount</Typography>
                                                <Typography>{record.currency_symbol} {record.amount}</Typography>
                                                <Typography>{record.interval} {record.interval_unit}</Typography>
                                            </Box>
                                            <Box sx={{ marginRight: 30 }}>
                                                <Typography >Activation Date</Typography>
                                                <Typography>{moment(record.start_date).format("ll")}</Typography>
                                                <Box style={{ marginBottom: 10 }}></Box>
                                                <Typography >Next Billing Date</Typography>
                                                <Typography>{moment(record.next_billing_at).format("ll")}</Typography>
                                                <Box style={{ marginBottom: 10 }}></Box>
                                                <Typography >Last Billing Date</Typography>
                                                <Typography>{moment(record.last_billing_at).format("ll")}</Typography>
                                                <span onClick={() => handleClick(record)} style={{ cursor: "pointer", color: "#90CAF9", fontWeight: "normal", fontSize: 14 }}>Update Credit Card</span>
                                            </Box>
                                            <Box style={{ marginRight: 0 }}>
                                                {/* <Typography className={classes.text}>Renews Forever </Typography>
                                                {record.end_of_term ? <ClearOutlinedIcon /> : <CheckOutlinedIcon />} */}
                                                <Typography style={{ marginRight: 100, color: "#D7E945" }}>Term Ends</Typography> <Typography>{moment(record.current_term_ends_at).format('ll')}</Typography>
                                                <Box sx={{ marginBottom: 10 }}></Box>
                                                <Typography style={{ marginRight: 100, color: "#D7E945" }}>Scheduled Cancellation</Typography> <Typography>{moment(record.scheduled_cancellation_date).format('ll')}</Typography>
                                                <Box sx={{ marginBottom: 10 }}></Box>
                                                <Typography style={{ marginRight: 100, color: "grey" }}>Repeat Every</Typography> <Typography>1 {record.interval_unit}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", marginRight: 30, marginTop: 70 }}>
                                                <Box>
                                                    {record.subscription_id &&
                                                        <Change status={record.status} screens={`${dataProperty["screens_limit"]}`} subscription_id={record['subscription_id']} />
                                                    }
                                                </Box>
                                                <Box sx={{ marginLeft: 10 }}>
                                                    {record.status == "live" &&
                                                        <CancelSubscription subscription_id={record['subscription_id']} />
                                                    }
                                                </Box>
                                                <Box sx={{ marginLeft: 10 }}>
                                                    {record.subscription_id &&
                                                        <InvoiceHistory subscription_id={record.subscription_id} />
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Card>
                                )
                            })}
                        </>
                        :
                        <>
                            <Box>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ padding: 10, borderRadius: 30 }}>
                                        <Typography variant="subtitle1" component="h2">
                                            Standard Plan - Monthly
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Select the number of screens (TVs)
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item md={9}>
                                                <Typography component="p">Number of screens</Typography>
                                                <Typography sx={{ marginTop: 1 }} component="span">x</Typography>
                                            </Grid>
                                            <Grid item md={2}>
                                                <TextField
                                                    id="quantity"
                                                    sx={{ mb: 3 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="How many screens ?"
                                                    type="number"
                                                    onChange={(e) => { setScreenQuantity(e.target.value) }}
                                                    label="Screens"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={9}>
                                                <Typography component="span">Price/Screen</Typography>
                                            </Grid>
                                            <Grid item md={3}>
                                                <Typography component="span">1 Euro</Typography>
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                            <Divider />
                                        </Box>
                                        <Grid container spacing={3}>
                                            <Grid item md={9}>
                                                <Typography component="span">Total</Typography>
                                            </Grid>
                                            <Grid item md={3}>
                                                <Typography component="span">{screenQuantity ? Number(screenQuantity) * 1 : 0} Euro (s)</Typography>
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                            <Divider />
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                type="submit"
                                                disabled={!screenQuantity || screenQuantity < 0 || screenQuantity == dataProperty["screens_limit"]}
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Proceed To Payment
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </>
                    }
                </>
                :
                null
            }
            <Backdrop open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ textAlign: "center" }}>
                    <DialogContentText id="alert-dialog-description">
                        <img style={{ width: 250, marginTop: -40 }} src='https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif' />
                    </DialogContentText>
                    <DialogContentText style={{ marginTop: -60 }} id="alert-dialog-description">
                        Thank you for your subscription
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please wait until the process is complete. You will be notified by email.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ManageSubscription;