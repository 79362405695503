import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, DialogContent, Divider, Button, Container, TextField, Dialog, Grid, ListItemIcon, ListItemText } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout, useRefresh, usePermissions, useDataProvider, useNotify, useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';
import { useForm, SubmitHandler } from "react-hook-form";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';

import Logo from '../Logo';

type Inputs = {
    email: string
};

const ForgotPassword: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {

        const formData = new FormData();

        formData.append("email", data.email);

        dataProvider.create<any>(
            'auth/forgot-password',
            {
                data: formData,
            }
        ).then((response: any) => {
            refresh();
            notify("Please check your email.");
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }


    return (
        <>
            <Typography onClick={handleClickOpen} sx={{ cursor: "pointer", color: "#008DD9", fontWeight: "normal", fontFamily: "monospace", fontSize: 15 }}>Forgot password?</Typography>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                // fullScreen
                fullWidth={true}
                open={open}
                maxWidth="md">
                <DialogContent>
                    <Box>
                        <IconButton onClick={handleClose} >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Box>
                    <Container component="main" maxWidth="sm">
                        {/* <Logo /> */}
                        <Typography component="h1" variant="h5" style={{ marginBottom: 20 }}>
                            Enter your email
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Box style={{ textAlign: "center" }}>
                                    <TextField
                                        id="email"
                                        InputLabelProps={{ shrink: true }}
                                        label="Email"
                                        placeholder="Enter Your Email"
                                        {...register("email")}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Box>
                                <Box sx={{ padding: 5 }}>
                                    <Divider />
                                </Box>
                                <Box sx={{ textAlign: "center" }}>
                                    <Button
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default ForgotPassword;