import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";
import ViewListIcon from "@mui/icons-material/ViewList";
import MovieIcon from "@mui/icons-material/Movie";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import AppsIcon from "@mui/icons-material/Apps";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HistoryIcon from "@mui/icons-material/History";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import jwt_decode from "jwt-decode";
import DashboardCustomizeTwoToneIcon from "@mui/icons-material/DashboardCustomizeTwoTone";
import ReadMoreTwoToneIcon from "@mui/icons-material/ReadMoreTwoTone";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import DevicesIcon from "@mui/icons-material/Devices";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaceIcon from "@mui/icons-material/Place";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import TvIcon from "@mui/icons-material/Tv";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SettingsIcon from "@mui/icons-material/Settings";

// import images
import TVIconMenu from "../assets/images/icons/tv.png";
// import DashboardIconMenu from "../assets/images/icons/dashboard.png";
// import VODIconMenu from "../assets/images/icons/vod.png";
// import PackagesIconMenu from "../assets/images/icons/packages.png";
// import ServicesIconMenu from "../assets/images/icons/services.png";
// import EPGIconMenu from "../assets/images/icons/epg.png";
// import PersonalizationIconMenu from "../assets/images/icons/personalization.png";
// import History from '../assets/images/icons/history.png';
// import Activations from '../assets/images/icons/toggle.png';
// import Bydate from '../assets/images/icons/daily-calendar.png';
// import Bymonth from '../assets/images/icons/subscription.png'
// import Subscription from '../assets/images/icons/vecteezy_subscribe-bell-icon-red-button-for-website_-removebg-preview.png';

// import images b/w
import Dashboard11 from "../assets/images/iconsMenu/dashboard (11).png";
import TVscreens from "../assets/images/iconsMenu/tv-monitor.png";
import Servicess from "../assets/images/iconsMenu/pin.png";
import Personalizationn from "../assets/images/iconsMenu/monitor.png";
import TvPackages from "../assets/images/iconsMenu/tvPackages.png";
import Subscription1 from "../assets/images/iconsMenu/subscription (1).png";
import Bydatee from "../assets/images/iconsMenu/bydate.png";
import Bymonthh from "../assets/images/iconsMenu/august (3).png";
import Historyy from "../assets/images/iconsMenu/history (1).png";
import Reports from "../assets/images/iconsMenu/reports.png";
import Vodd from "../assets/images/iconsMenu/vod.png";
import Channelss from "../assets/images/iconsMenu/channels.png";
import Epgg from "../assets/images/iconsMenu/epg.png";
import Byproduct from "../assets/images/iconsMenu/byproduct.png";
import PropertyList from "../assets/images/iconsMenu/propertyList.png";
import Devicee from "../assets/images/iconsMenu/device.png";
import Distributorss from "../assets/images/iconsMenu/distributors.png";
import UsersRoles from "../assets/images/iconsMenu/users&roles.png";
import Settingss from "../assets/images/iconsMenu/setting.png";
import AppManagment from "../assets/images/iconsMenu/management.png";
import Imports from "../assets/images/iconsMenu/import.png";
import DummyData from "../assets/images/iconsMenu/dummyData.png";
import Property from "../assets/images/iconsMenu/town.png";
import VideoContent1 from "../assets/images/iconsMenu/video-editor.png";
import Mangamant from "../assets/images/iconsMenu/team.png";
import Properties from "../assets/properties.png";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import SubMenu from "./SubMenu";

const Menu = ({ dense = false }: MenuProps) => {
  const token = localStorage.getItem("access_token");

  type MenuNameSystemAdmin =
    | "analytics"
    | "properties"
    | "devices"
    | "distributors"
    | "settings";

  const [stateMenuSystemAdmin, setStatemenuSystemAdmin] = useState({
    analytics: true,
    properties: true,
    devices: true,
    distributors: true,
    settings: true,
  });

  type MenuNameContentProvider =
    | "analytics"
    | "videoContent"
    | "commercial"
    | "salesReports"
    | "management";

  const handleToggleSystemAdmin = (menu: MenuNameSystemAdmin) => {
    setStatemenuSystemAdmin((stateMenuSystemAdmin) => ({
      ...stateMenuSystemAdmin,
      [menu]: !stateMenuSystemAdmin[menu],
    }));
  };

  const [stateContentProvider, setStateContentProvider] = useState({
    analytics: true,
    videoContent: true,
    commercial: true,
    salesReports: true,
    management: true,
  });

  const handleToggleContentProvider = (menu: MenuNameContentProvider) => {
    setStateContentProvider((stateContentProvider) => ({
      ...stateContentProvider,
      [menu]: !stateContentProvider[menu],
    }));
  };

  type MenuNameProperty =
    | "analytics"
    | "screens"
    | "commercial"
    | "salesReports";

  const [stateProperty, setStateProperty] = useState({
    analytics: true,
    screens: true,
    commercial: true,
    salesReports: true,
  });

  const handleToggleProperty = (menu: MenuNameProperty) => {
    setStateProperty((stateProperty) => ({
      ...stateProperty,
      [menu]: !stateProperty[menu],
    }));
  };

  const translate = useTranslate();
  const [open] = useSidebarState();

  const renderMenu = () => {
    if (token) {
      let decode: any = jwt_decode(`${token}`);

      return (
        <Box>
          {decode["user"] == "distributor" && (
            <>
              {/* Analytics */}
              <SubMenu
                handleToggle={() => handleToggleContentProvider("analytics")}
                isOpen={stateContentProvider.analytics}
                name="Analytics"
                icon={<img src={Dashboard11} width={20} />}
                dense={dense}
              >
                <MenuItemLink
                  to="/"
                  state={{ _scrollToTop: true }}
                  primaryText={"Dashboard"}
                  leftIcon={<img src={Dashboard11} width={20} />}
                  dense={dense}
                />
              </SubMenu>

              {/* Video Content */}
              <SubMenu
                handleToggle={() => handleToggleContentProvider("videoContent")}
                isOpen={stateContentProvider.videoContent}
                name="Video Content"
                icon={<img src={VideoContent1} width={24} />}
                dense={dense}
              >
                <MenuItemLink
                  to="channels"
                  state={{ _scrollToTop: true }}
                  primaryText={"Channels"}
                  leftIcon={<img src={Channelss} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="epg"
                  state={{ _scrollToTop: true }}
                  primaryText={"Epg TV"}
                  leftIcon={<img src={Epgg} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="vods"
                  state={{ _scrollToTop: true }}
                  primaryText={"VOD"}
                  leftIcon={<img src={Vodd} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="imports"
                  state={{ _scrollToTop: true }}
                  primaryText={"Imports"}
                  leftIcon={<img src={Imports} width={20} />}
                  dense={dense}
                />
                {/* <MenuItemLink
                  to="epgmetadata"
                  state={{ _scrollToTop: true }}
                  primaryText={"Epg Metadata"}
                  leftIcon={<img src={Epgg} width={20} />}
                  dense={dense}
                /> */}
              </SubMenu>

              {/* Commercial */}
              <SubMenu
                handleToggle={() => handleToggleContentProvider("commercial")}
                isOpen={stateContentProvider.commercial}
                name="Commercial"
                icon={<AppsIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="packages"
                  state={{ _scrollToTop: true }}
                  primaryText={"Packages"}
                  leftIcon={<img src={TvPackages} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="services/cd/public"
                  state={{ _scrollToTop: true }}
                  primaryText={"Services"}
                  leftIcon={<img src={Servicess} width={22} />}
                  dense={dense}
                />
              </SubMenu>

              {/* management */}
              <SubMenu
                handleToggle={() => handleToggleContentProvider("management")}
                isOpen={stateContentProvider.management}
                name="Management"
                icon={<img src={Mangamant} width={24} />}
                dense={dense}
              >
                <MenuItemLink
                  to="properties/cd"
                  state={{ _scrollToTop: true }}
                  primaryText={"Properties"}
                  leftIcon={<img src={Property} width={20} />}
                  dense={dense}
                />
              </SubMenu>

              {/* Sales Reports */}
              <SubMenu
                handleToggle={() => handleToggleContentProvider("salesReports")}
                isOpen={stateContentProvider.salesReports}
                name="Sales Reports"
                icon={<TrendingDownIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="subscriptions/cd"
                  state={{ _scrollToTop: true }}
                  primaryText={"Subscription"}
                  leftIcon={<img src={Bydatee} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="sales-report/product/sales"
                  state={{ _scrollToTop: true }}
                  primaryText={"By Product"}
                  leftIcon={<img src={Byproduct} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="sales-report/properties/cd"
                  state={{ _scrollToTop: true }}
                  primaryText={"By Properties"}
                  leftIcon={<img src={Properties} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="sales-report/subscriptions/days"
                  state={{ _scrollToTop: true }}
                  primaryText={"By Date"}
                  leftIcon={<img src={Bydatee} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="sales-report/subscriptions/months"
                  state={{ _scrollToTop: true }}
                  primaryText={"By Months"}
                  leftIcon={<img src={Bymonthh} width={20} />}
                  dense={dense}
                />
              </SubMenu>
            </>
          )}

          {decode["user"] == "system_admin" && (
            <>
              {/* Analytics */}
              <SubMenu
                handleToggle={() => handleToggleSystemAdmin("analytics")}
                isOpen={stateMenuSystemAdmin.analytics}
                name="Analytics"
                icon={<DashboardIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="/"
                  state={{ _scrollToTop: true }}
                  primaryText={"Dashboard"}
                  leftIcon={<img src={Dashboard11} width={20} />}
                  dense={dense}
                />
              </SubMenu>

              <SubMenu
                handleToggle={() => handleToggleSystemAdmin("properties")}
                isOpen={stateMenuSystemAdmin.properties}
                name="Properties"
                icon={<ViewListIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="properties"
                  state={{ _scrollToTop: true }}
                  primaryText={"Properties"}
                  leftIcon={<img src={PropertyList} width={22} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="dummy-data"
                  state={{ _scrollToTop: true }}
                  primaryText={"Dummy Data"}
                  leftIcon={<img src={DummyData} width={23} />}
                  dense={dense}
                />
              </SubMenu>

              <SubMenu
                handleToggle={() => handleToggleSystemAdmin("devices")}
                isOpen={stateMenuSystemAdmin.devices}
                name="Devices"
                icon={<TvIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="devices"
                  state={{ _scrollToTop: true }}
                  primaryText={"Devices"}
                  leftIcon={<img src={Devicee} width={22} />}
                  dense={dense}
                />
              </SubMenu>

              <SubMenu
                handleToggle={() => handleToggleSystemAdmin("distributors")}
                isOpen={stateMenuSystemAdmin.distributors}
                name="Distributors"
                icon={<AccountTreeIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="content-distributor"
                  state={{ _scrollToTop: true }}
                  primaryText={"Distributors"}
                  leftIcon={<img src={Distributorss} width={23} />}
                  dense={dense}
                />
              </SubMenu>

              <SubMenu
                handleToggle={() => handleToggleSystemAdmin("settings")}
                isOpen={stateMenuSystemAdmin.settings}
                name="Settings"
                icon={<SettingsIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="users/systemAdmin"
                  state={{ _scrollToTop: true }}
                  primaryText={"Users & Roles"}
                  leftIcon={<img src={UsersRoles} width={22} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="settings"
                  state={{ _scrollToTop: true }}
                  primaryText={"Settings"}
                  leftIcon={<img src={Settingss} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="app-management"
                  state={{ _scrollToTop: true }}
                  primaryText={"App Management"}
                  leftIcon={<img src={AppManagment} width={23} />}
                  dense={dense}
                />
              </SubMenu>
            </>
          )}

          {decode["user"] == "property" && (
            <>
              {/* Analytics */}
              <SubMenu
                handleToggle={() => handleToggleProperty("analytics")}
                isOpen={stateProperty.analytics}
                name="Analytics"
                icon={<DashboardIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="/"
                  state={{ _scrollToTop: true }}
                  primaryText={"Dashboard"}
                  leftIcon={<img src={Dashboard11} width={20} />}
                  dense={dense}
                />
              </SubMenu>

              {/* Screens */}
              {decode["user"] == "property" &&
                decode["role"] != "commercial" && (
                  <SubMenu
                    handleToggle={() => handleToggleProperty("screens")}
                    isOpen={stateProperty.screens}
                    name="Screens"
                    icon={<TvIcon />}
                    dense={dense}
                  >
                    <MenuItemLink
                      to="screens"
                      state={{ _scrollToTop: true }}
                      primaryText={"TV Screens"}
                      leftIcon={<img src={TVscreens} width={24} />}
                      dense={dense}
                    />

                    <MenuItemLink
                      to="devices/property"
                      state={{ _scrollToTop: true }}
                      primaryText={"Devices"}
                      leftIcon={<DevicesIcon />}
                      dense={dense}
                    />
                  </SubMenu>
                )}

              {/* Commercial */}
              <SubMenu
                handleToggle={() => handleToggleProperty("commercial")}
                isOpen={stateProperty.commercial}
                name="Commercial"
                icon={<AppsIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="services"
                  state={{ _scrollToTop: true }}
                  primaryText={"Services"}
                  leftIcon={<img src={Servicess} width={22} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="app-personalization"
                  state={{ _scrollToTop: true }}
                  primaryText={"Personalization"}
                  leftIcon={<img src={Personalizationn} width={20} />}
                  dense={dense}
                />
                <MenuItemLink
                  to="packages/property/packages"
                  state={{ _scrollToTop: true }}
                  primaryText={"Tv Packages"}
                  leftIcon={<img src={TvPackages} width={20} />}
                  dense={dense}
                />
              </SubMenu>

              {/* Sales Reports */}
              {decode["user"] == "property" &&
                decode["role"] != "commercial" && (
                  <SubMenu
                    handleToggle={() => handleToggleProperty("salesReports")}
                    isOpen={stateProperty.salesReports}
                    name="Sales Reports"
                    icon={<TrendingDownIcon />}
                    dense={dense}
                  >
                    <MenuItemLink
                      to="subscriptions"
                      state={{ _scrollToTop: true }}
                      primaryText={"Subscriptions"}
                      leftIcon={<img src={Subscription1} width={20} />}
                      dense={dense}
                    />
                    <MenuItemLink
                      to="sales-report/purchased/packages"
                      state={{ _scrollToTop: true }}
                      primaryText={"Activations"}
                      leftIcon={<img src={Reports} width={18} />}
                      dense={dense}
                    />
                    <MenuItemLink
                      to="sales-report/purchases/days"
                      state={{ _scrollToTop: true }}
                      primaryText={"By Date"}
                      leftIcon={<img src={Bydatee} width={20} />}
                      dense={dense}
                    />
                    <MenuItemLink
                      to="sales-report/purchases/months"
                      state={{ _scrollToTop: true }}
                      primaryText={"By Months"}
                      leftIcon={<img src={Bymonthh} width={20} />}
                      dense={dense}
                    />
                    <MenuItemLink
                      to="sales-report/history"
                      state={{ _scrollToTop: true }}
                      primaryText={"History"}
                      leftIcon={<img src={Historyy} width={20} />}
                      dense={dense}
                    />
                  </SubMenu>
                )}
            </>
          )}
        </Box>
      );
    } else {
      return <Box></Box>;
    }
  };

  return (
    <Box
      sx={{
        width: open ? 240 : 50,
        backgroundColor: "#222",
        minHeight: "100vh",
        pt: 6,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {renderMenu()}
    </Box>
  );
};

export default Menu;
