import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Card, Box } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// import components
import SCREENS from '../../../../assets/images/iconsMenu/channels.png';

interface Props {
    value?: number;
}

const Screens: FC<Props> = ({ value }) => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    const token = localStorage.getItem("access_token")

    const decode: any = jwtDecode(`${token}`)

    return (
        <Box>
            {decode['user'] == "property" && decode['role'] != "commercial" ?
                <Box>
                    <Link to="/screens" style={{ textDecoration: "none" }}>
                        <Card sx={{ borderRadius: 10 }}>
                            <Box sx={{ p: 4 }}>
                                <Box>
                                    <img width={isXSmall ? 40 : 60} src={SCREENS} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: isXSmall ? 12 : 25 }}>Screens</Typography>
                                    <Typography sx={{ fontSize: isXSmall ? 20 : 30 }}>{value !== 0 ? value : '0'}</Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Link>
                </Box>
                :
                <Card sx={{ borderRadius: 10 }}>
                    <Box sx={{ p: 4 }}>
                        <Box>
                            <img width={isXSmall ? 40 : 60} src={SCREENS} />
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: isXSmall ? 12 : 25 }}>Screens</Typography>
                            <Typography sx={{ fontSize: isXSmall ? 20 : 30 }}>{value !== 0 ? value : '0'}</Typography>
                        </Box>
                    </Box>
                </Card>
            }
        </Box>
    );
};

export default Screens;