import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useGetList, useNotify, useRefresh, useUpdate, Notification, useDataProvider } from 'react-admin';
import {
    makeStyles, Typography, createStyles, Theme, Card, CardHeader, IconButton, Grid, Chip, CardActions, Divider, Button, CardMedia, Tab,
    Dialog, DialogActions, DialogContent, CardContent, Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
//import components
// import TitleSection from '../../../components/title/TitleSection';
import ServiceDetails from './ServiceDetails';
import ServiceDelete from './ServiceDelete';
import { BASE_ASSETS_URL } from '../../../config';

interface Props {
    menu: string,
    category: string
}

const SeeAllServices: FC<Props> = ({ menu, category }) => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        refresh();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [allServices, setAllServices] = useState<any>([]);

    const { data, total, isLoading, error, refetch } = useGetList(
        `services/service_items/${menu}/${category}`,
        {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
            filter: {}
        }
    );


    useEffect(() => {
        setAllServices(data);
    }, [data]);

    return (
        <>
            <Button style={{ float: "right" }} onClick={handleClickOpen} variant="text" color="primary">SEE ALL</Button>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                fullScreen
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <IconButton onClick={handleClose} >
                                <KeyboardBackspaceOutlinedIcon />
                            </IconButton>
                        </Box>
                        {data?.length &&
                            <Grid container spacing={2}>
                                {data?.map((service: any) => (
                                    <Grid md={1.7}>
                                        <Card sx={{ borderRadius: 8, margin: 2 }}>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`${BASE_ASSETS_URL}${service.service_path}`}
                                                alt={service.service_name}
                                            />
                                            <CardContent>
                                                <Typography sx={{ fontSize: 16 }}>
                                                    {service.service_name ? service.service_name.substring(0, 20) + ' ...' : " "}
                                                </Typography>
                                                <Typography sx={{ fontSize: 10, color: "grey" }}>
                                                    {service.short_service_description ? service.short_service_description.substring(0, 50) + ' ...' : " "}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Divider />
                                            </Box>
                                            <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
                                                <ServiceDetails record={service} />
                                                {service.is_public === false ?
                                                    <>
                                                        <IconButton href={`/#/services/${service['_id']}`} style={{ color: "grey" }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <ServiceDelete record={service} />
                                                    </>
                                                    :
                                                    null
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SeeAllServices;