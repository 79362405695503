import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

// import assets
import LogoAppBar from '../assets/applay_logo.png';

const Logo = () => {
    const theme = useTheme();
    return (
        <img style={{ marginLeft: 10 }} src={LogoAppBar} width={110} />
    );
};

export default Logo;