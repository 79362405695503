import React, { useState } from 'react';
import { Typography, TextField, Button, Dialog, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import { useDataProvider, useRefresh, useNotify, useDelete } from 'react-admin';

export const DeletePackage = ((record: any, index: any) => {
    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const [confirmation, setConfirmation] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleDelete = () => {
        dataProvider.delete(
            'packages',
            {
                id: record.index,
                previousData: record
            },
        ).then((response: any) => {
            notify('The package was deleted succesfully', { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
}


return (
    <Box>
        <Button size="small" color="primary" onClick={handleOpen} sx={{ color: "#af5d5d" }}>
            Remove
        </Button>
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Are you sure you want to delete {record.record.package_name} ?
            </DialogTitle>
            <DialogContent>
                <Typography>Please type <span style={{ fontWeight: "bold" }}> package </span>  to confirm deletion</Typography>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setConfirmation(e.target.value)}
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary" sx={{ borderRadius: 1 }} >Cancel</Button>
                    <Button onClick={handleDelete}
                        disabled={(confirmation === "package") ? false : true}
                        style={(confirmation === "package") ? { color: "#ff0000a1" } : { color: "" }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </Box>
);
}
);

export default DeletePackage;