import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, Typography, TextField, Divider, Button, Box, Tabs, Tab, Card, CardContent, Avatar, CardActions } from '@mui/material';

import ImportEpg from './dialogs/ImportEpg';
import ImportChannelCsv from './dialogs/ImportChannelCsv';
import ImportVodCsv from './dialogs/ImportVodCsv';

const Imports: FC = () => {

    return (
        <Box sx={{ pt: 10, pl: 5 }}>
            <Title title="Imports" />
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <Card sx={{ borderRadius: 5 }}>
                        <CardContent>
                            <Typography sx={{ mt: 4, textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }} component="p">
                                Import EPG
                            </Typography>
                            <Typography sx={{ textAlign: "center", fontSize: 10, color: "grey" }} component="p">
                                Import EPG for multiple or single channel
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <ImportEpg />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Card sx={{ borderRadius: 5 }}>
                        <CardContent>
                            <Typography sx={{ mt: 4, textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }} component="p">
                                Import CSV Channels
                            </Typography>
                            <Typography sx={{ textAlign: "center", fontSize: 10, color: "grey" }} component="p">
                                Import CSV for multiple or single channel
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <ImportChannelCsv />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Card sx={{ borderRadius: 5 }}>
                        <CardContent>
                            <Typography sx={{ mt: 4, textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }} component="p">
                                Import CSV VOD
                            </Typography>
                            <Typography sx={{ textAlign: "center", fontSize: 10, color: "grey" }} component="p">
                                Import CSV for multiple or single channel
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <ImportVodCsv />
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Imports;