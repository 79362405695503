import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useNotify,
  useRefresh,
  useTranslate,
  Notification,
  useDataProvider,
  useListParams,
  useGetOne,
  useUpdate,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  FormControl,
  TextField,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Avatar,
  InputAdornment,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useNavigate } from "react-router-dom";

// // import types
// import { AppState } from '../../types/Theme';
// import TitleSection from '../../components/title/TitleSection';
import ChannelsDialog from "./dialogs/ChannelsDialog";
import { BASE_ASSETS_URL } from "../../config";

type Inputs = {
  package_name: string;
  description: string;
  package_duration: string;
  icon_path: any;
  unit: string;
  color_tag: string;
  price: string;
};

const Edit: FC = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [color, setColor] = useState<string>("");
  const [colorControl, setColorControl] = useState<any>("");

  const [imageUrl, setImageUrl] = useState("");
  const [changeImage, setChangeImage] = useState({});

  const handleImage = (e: any) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setImageUrl(url);
    setChangeImage(e.target.files[0]);
    setDisabled(false);
  };

  const [unit, setUnit] = React.useState("");

  const handleUnitChange = (e: any) => {
    setUnit(e.target.value);
    setDisabled(false);
  };

  //fetch packages
  const {
    data: dataPackages,
    isLoading: loadingPackages,
    error: errorPackages,
  } = useGetOne("packages", {
    id: id,
  });

  const [packageColor, setPackageColor] = useColor(
    "hex",
    `${dataPackages?.color_tag}`
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Inputs>();
  const OnSubmit: SubmitHandler<Inputs> = (data) => {
    const formData: any = new FormData();

    data.icon_path = changeImage;
    data.unit = unit;
    data.color_tag = packageColor.hex;

    // formData.append("package_name", data.package_name);
    // formData.append("description", data.description);
    // formData.append("package_duration", data.package_duration);
    // if (
    //   Object.keys(data.icon_path).length === 0 &&
    //   (data.icon_path.constructor === Object) === false
    // ) {
    //   formData.append("icon_path", data.icon_path);
    // }
    // formData.append("unit", data.unit);
    // formData.append("color_tag", data.color_tag);
    // formData.append("price", data.price);
    const packagesdata ={
      package_name:data.package_name,
      description :data.description,
      package_duration: data.package_duration,
      icon_path:data.icon_path,
      unit:data.unit,
      color_tag:data.color_tag,
      price:data.price,

  }

    dataProvider
      .update("packages", {
        id: `${id}`,
        data: packagesdata,
        previousData: {
          id: `${id}`,
          package_name: dataPackages.package_name,
          description: dataPackages.description,
          package_duration: dataPackages.package_details,
          unit: dataPackages.unit,
        },
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Package was successfully updated", { type: "info" });
        navigate("/packages");
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };

  useEffect(() => {
    if (dataPackages) {
      setValue("package_name", dataPackages?.package_name);
      setColor(dataPackages?.color_tag);
      setColorControl(dataPackages?.color_tag);
      setValue("description", dataPackages?.description);
      setValue("package_duration", dataPackages?.package_duration);
      setUnit(dataPackages?.unit);
      setValue("price", dataPackages?.price);
    }
  }, [dataPackages]);

  return (
    <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
      <Title title="Edit Package" />
      <Box>
        <form onSubmit={handleSubmit(OnSubmit)}>
          {/* <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} /> */}
          <Grid container spacing={7}>
            <Grid item xs={11} md={3}>
              <TextField
                id="package_name"
                label="Package Name*"
                placeholder="Enter Package Name"
                InputLabelProps={{ shrink: true }}
                {...register("package_name", { required: true })}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: 1 }}></Box>
          <Grid container spacing={2}>
            <Grid item xs={11} md={5}>
              <TextField
                id="description"
                label="Description"
                placeholder="Enter Package Description*"
                InputLabelProps={{ shrink: true }}
                {...register("description", { required: true })}
                variant="outlined"
                multiline
                rows={6}
                fullWidth
              />
            </Grid>
          </Grid>
          {/* <TitleSection title="Package Duration" description="Package Duration" paddingBottom={10} paddingLeft={1} paddingTop={30} /> */}
          <Box sx={{ padding: 1 }}></Box>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <TextField
                id="package_duration"
                label="Duration*"
                placeholder="Package Duration"
                InputLabelProps={{ shrink: true }}
                type="number"
                {...register("package_duration", {
                  required: true,
                  min: {
                    value: 1,
                    message: "Value must be greater than 0",
                  },
                })}
                variant="outlined"
                helperText={
                  errors.package_duration
                    ? `${errors.package_duration.message}`
                    : ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={5} md={2}>
              <FormControl variant="outlined" sx={{ minWidth: 130 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Unit
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // {...register("unit")}
                  placeholder="Age"
                  value={unit}
                  onChange={handleUnitChange}
                >
                  {/* <MenuItem value="">
                                        <em>Unit</em>
                                    </MenuItem> */}
                  <MenuItem value={"years"}>YEAR</MenuItem>
                  <MenuItem value={"months"}>MONTH</MenuItem>
                  <MenuItem value={"weeks"}>WEEK</MenuItem>
                  <MenuItem value={"days"}>DAY</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
          {/* <TitleSection title="Pricing" description="Pricing" paddingBottom={10} paddingLeft={1} paddingTop={30} /> */}
          <Box sx={{ padding: 1 }}></Box>
          <Grid container spacing={2}>
            <Grid item xs={5} md={2}>
              <TextField
                id="price"
                label="Price*"
                placeholder="Package Pricing"
                InputLabelProps={{ shrink: true, required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{ marginTop: 3 }} position="end">
                      €
                    </InputAdornment>
                  ),
                }}
                type="number"
                {...register("price", {
                  required: true,
                  min: {
                    value: 1,
                    message: "Value must be greater than 0",
                  },
                })}
                variant="outlined"
                helperText={errors.price ? `${errors.price.message}` : ""}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: 1 }}></Box>
          <Grid container spacing={10}>
            <Grid item md={2}>
              <ColorPicker
                width={250}
                height={100}
                color={packageColor}
                onChange={setPackageColor}
                hideRGB
                hideHSV
                dark
              />
            </Grid>
            <Grid item md={8}>
              <Box sx={{ padding: 2 }}></Box>
              <Box sx={{ display: "flex" }}>
                <Box>
                  {imageUrl ? (
                    <img
                      style={{ marginTop: 25 }}
                      width={90}
                      height={90}
                      alt="Logo"
                      src={imageUrl}
                    />
                  ) : (
                    <img
                      style={{ marginTop: 25 }}
                      width={90}
                      height={90}
                      alt="Logo"
                      src={`${BASE_ASSETS_URL}${dataPackages?.icon_path}`}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    size="large"
                    color="primary"
                    component="label"
                    style={{ marginTop: 48, marginLeft: 10 }}
                  >
                    Upload Image
                    <input
                      type="file"
                      onChange={(e) => {
                        handleImage(e);
                      }}
                      hidden
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item md={4}>
              <Box sx={{ pt: 4 }} onClick={() => setDisabled(false)}>
                <ChannelsDialog id={id as any} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ padding: 4 }}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Button
                type="button"
                onClick={handleSubmit(OnSubmit)}
                variant="contained"
                color="primary"
                disabled={
                  !isDirty && disabled && colorControl === packageColor.hex
                }
                size="large"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Edit;
