import React, { useState, FC, useEffect } from 'react';
import { Box, Typography, Card, TextField, Divider, Button, Grid, IconButton, Dialog, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import { useNotify } from 'react-admin';
import { useForm, SubmitHandler } from "react-hook-form";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { BASE_URL } from "../../../config";

type Inputs = {
    quantity: string
}

interface Props {
    screenLimit: number
}

const AddSubscription: FC<Props> = ({ screenLimit }) => {

    const notify = useNotify();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        data.quantity = screenQuantity;

         //formData.append("quantity", data.quantity);
        const dataquantity ={
            quantity:data.quantity,
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body:  JSON.stringify(dataquantity), 
        };

        fetch(`${BASE_URL}/api/v1/subscriptions/zoho/createSubscription`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                window.open(response.hostedpage.url);
            }).catch((error) => notify(error.message, {
                type: "warning"
            }));
    }

    const [screenQuantity, setScreenQuantity] = React.useState<any>();

    return (
        <>
            <div>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    onClick={handleClickOpen}
                >
                    Add Subscription
                </Button>
            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                fullScreen
            >
                <DialogContent >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box>
                            <Box>
                                <IconButton onClick={handleClose} >
                                    <KeyboardBackspaceOutlinedIcon />
                                </IconButton>
                                <Box sx={{ backgroundColor: "#222", padding: 50, borderRadius: 40 }}>
                                    <Typography variant="subtitle1" component="h2">
                                        Standard Plan - Monthly
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Select the number of screens (TVs)
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item md={9}>
                                            <Typography component="p">Number of screens</Typography>
                                            <Typography sx={{ marginTop: 8 }} component="span">x</Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <TextField
                                                id="quantity"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="How many screens ?"
                                                type="number"
                                                onChange={(e) => { setScreenQuantity(e.target.value) }}
                                                label="Screens"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={9}>
                                            <Typography component="span">Price/Screen</Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography component="span">1 Euro</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ paddingTop: 15, paddingBottom: 15 }}>
                                        <Divider />
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={9}>
                                            <Typography component="span">Total</Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography component="span">{screenQuantity ? Number(screenQuantity) * 1 : 0} Euro (s)</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ paddingTop: 15, paddingBottom: 15 }}>
                                        <Divider />
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            type="submit"
                                            disabled={!screenQuantity || screenQuantity < 0}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            Proceed To Payment
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default AddSubscription;