import List from './PublicServices';
import Create from './Create';
import Edit from './Edit';

const resource = {
    list: List,
    create: Create,
    edit: Edit,
};

export default resource;