import React, { FC } from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import { useGetOne } from 'react-admin';
import jwtDecode from 'jwt-decode';

const Subscription: FC = () => {

    let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
    let id = decode['property_operator_id'];

    const { data: dataProperty, isLoading: loadingProperty, error: errorProperty } = useGetOne(
        'properties',
        {
            id: id
        },
    );

    return (
        <Box>
            {dataProperty &&
                <>
                    {dataProperty['screens_limit'] <= "1" ?
                        <Box>
                            <Stack direction="row" spacing={2}>
                                <Box sx={{ mr: 1, mt: 0.8 }}>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: 12 }}>
                                        {dataProperty['active_screens']}/{dataProperty['screens_limit']} Screens
                                    </Typography>
                                </Box>
                                <Box sx={{ pr: 2 }}>
                                    <Button
                                        href="/#/manage-subscription"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                    >
                                        Upgrade
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                        :
                        <Box sx={{ mr: 4 }}>
                            <Stack direction="row" spacing={2}>
                                <Box sx={{ marginRight: 1, mt: 0.8 }}>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: 12 }}>
                                        {dataProperty['active_screens']}/{dataProperty['screens_limit']} Screens
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button
                                        href="/#/manage-subscription"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                    >
                                        Edit Plan
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    }
                </>
            }
        </Box>
    )
}

export default Subscription;