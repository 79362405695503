import React, { FC, useState } from 'react';
import { useUnselectAll, BulkActionProps, useNotify, useRefresh, Identifier, useDataProvider, useDeleteMany } from 'react-admin';
import { makeStyles, createStyles, Theme, Dialog, Typography, Button, DialogContent, TextField, DialogActions } from '@mui/material';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';

const noSelection: Identifier[] = [];

const BulkDelete: FC<BulkActionProps> = ({ selectedIds = noSelection, resource }) => {

    const notify = useNotify();
    const unselectAll = useUnselectAll(resource as any);
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        unselectAll();
        refresh();
    };

    const [confirmation, setConfirmation] = useState("");

    const HandleDeleteMany = () => {
        dataProvider.deleteMany(
            `${resource}`,
            {
                ids: selectedIds
            },
        ).then((response: any) => {
            notify(`Selected ${resource} were successfully deleted`, { type: "info" });
            handleClose();
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ mb: 1 }}>
            <Button
                onClick={handleClickOpen}
                startIcon={<DeleteIcon />}
                sx={{ color: "white", fontWeight: "bold" }}
            >
                Delete
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography style={{ marginBottom: 12 }}>Please type <span style={{ fontWeight: "bold" }}> {resource} </span>  to confirm deletion</Typography>
                    <TextField
                        id="channels"
                        InputLabelProps={{ shrink: true }}
                        placeholder={`Please type ${resource} to confirm`}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setConfirmation(e.target.value)}
                    />
                </DialogContent>
                <DialogActions style={{ padding: 25 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={(confirmation === `${resource}`) ? false : true}
                        onClick={HandleDeleteMany}
                        style={(confirmation === `${resource}`) ? { color: "red" } : { color: "" }}
                        variant="contained"
                    // color="default"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}

export default BulkDelete;