import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useGetList, useGetOne } from 'react-admin';
import { Typography, Grid, Box, Card, ListItemSecondaryAction, List, ListItem, ListItemText } from '@mui/material';
import jwt_decode from "jwt-decode";
import { useMediaQuery, Theme } from '@mui/material';

// import components
import Properties from './cards/Properties';
import Channels from './cards/Channels';
import Vods from './cards/Vods';
import Packages from './cards/Packages';
import SubscriptionChart from './SubscriptionsChart';

const DashboardProvider = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    const { data: dataAnalyticsDistributor, isLoading: loadingAnalyticsDistributor, error: errorAnalyticsDistributor } = useGetOne(
        'analytics/distributor',
        {
            id: ""
        },
    );
    console.log(dataAnalyticsDistributor);

    return (
        <Box sx={{ pl: 3, pt: 8, pr: 2 }}>
            <Grid container spacing={2}>
                <>
                    {dataAnalyticsDistributor &&
                        <Grid item md={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}>
                                    <Channels value={dataAnalyticsDistributor['total_channels']} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Vods value={dataAnalyticsDistributor['total_vods']} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Packages value={dataAnalyticsDistributor['total_packages']} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Properties value={dataAnalyticsDistributor['total_properties']} />
                                </Grid>
                            </Grid>
                            {isXSmall ?
                                ""
                                :
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={7}>
                                        <SubscriptionChart />
                                    </Grid>
                                </Grid>
                            }

                        </Grid>
                    }
                </>
            </Grid>
        </Box>
    )
}

export default DashboardProvider;