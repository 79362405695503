import React, { FC, useState } from 'react';
import { useNotify, useDataProvider, useRefresh, CreateButton } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Box, Dialog, RadioGroup, FormLabel, FormControl, FormControlLabel,
    Typography, Grid, Button, Radio, IconButton, Divider, TextField, Checkbox
} from '@mui/material';
import { useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
// import types

type Inputs = {
    email: string,
    password: string,
    confirmPassword: string,
    phoneNumber: string,
    propertyName: string,
    type: string,
    checkEmail: boolean,
}

const Create: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [propertyType, setPropertyType] = useState("hotel");
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [checkEmail, setCheckEmail] = useState<boolean>(false);

    const handleCheckEmail = (event: any) => {
        setCheckEmail(event.target.checked);
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();
        data.type = propertyType;
        formData.append("email", data.email);
        formData.append("password", data.password);
        formData.append("confirmPassword", data.confirmPassword);
        formData.append("phoneNumber", data.phoneNumber);
        formData.append("propertyName", data.propertyName);
        formData.append("propertyType", data.type);
        formData.append("checkEmail", data.checkEmail);

        dataProvider.create(
            'properties/create/cd',
            {
                data: formData
            },
        ).then((response: any) => {
            notify("Property was successfully created");
            refresh();
            navigate("/properties/cd")
        }).catch((error: any) => {
            refresh();
            notify(error.message, { type: "warning" });
            handleClose();
        })

    }

    return (
        <>
            <Box component="main" maxWidth="sm" sx={{ margin: 5, marginTop: 10 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField
                                id="propertyName"
                                InputLabelProps={{ shrink: true }}
                                label="Name*"
                                placeholder="Name"
                                {...register("propertyName", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="email"
                                InputLabelProps={{ shrink: true }}
                                label="Email Address*"
                                placeholder="Email Address"
                                {...register("email", { required: true, minLength: 4, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                id="password"
                                InputLabelProps={{ shrink: true }}
                                label="Password*"
                                placeholder="Password"
                                type="password"
                                {...register("password", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                id="confirmPassword"
                                InputLabelProps={{ shrink: true }}
                                label="Repeat Password*"
                                type="password"
                                placeholder="Repeat Password"
                                {...register("confirmPassword", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="phoneNumber"
                                InputLabelProps={{ shrink: true }}
                                label="Phone Number*"
                                placeholder="Phone Number"
                                {...register("phoneNumber", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FormControl component="fieldset" style={{ marginTop: 10 }}>
                                <FormLabel component="legend">Property type</FormLabel>
                                <RadioGroup
                                    name="type"
                                    defaultValue="hotel"
                                    row
                                >
                                    <FormControlLabel value="hotel" control={<Radio onChange={(e) => setPropertyType(e.target.value)} />} style={{ marginRight: 80 }} label="Hotel" />
                                    <FormControlLabel value="residential" control={<Radio onChange={(e) => setPropertyType(e.target.value)} />} label="Residential" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControlLabel
                                control={<Checkbox checked={checkEmail} {...register("checkEmail")} onChange={handleCheckEmail} name="checkEmail" />}
                                label="Check Email"
                            />
                        </Grid>
                        <div style={{ padding: 10 }}>
                            <Divider />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
            </Box>
        </>
    );
}
export default Create;