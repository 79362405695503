import Edit  from './Edit';
import Create  from './Create';
import ResourceList from './List';

const resource = {
    list: ResourceList,
    create : Create,
    edit: Edit,
};

export default resource;