import React, { Fragment } from 'react'
import {
    Datagrid,
    List,
    FunctionField,
    TextField,
    ReferenceField,
    EditButton,
} from 'react-admin';
import { Box, Button } from '@mui/material';
import BulkDelete from '../../components/BulkDelete';
import AssignProvider from './EditProperties';
import { useMediaQuery, Theme } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import EditProvider from "./EditProperties"

const BulkActionButtons = (props: any) => (
    <Fragment>
        <BulkDelete {...props} />
    </Fragment>
);

const ResourceList = () => {

    const navigate = useNavigate();

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )
    
    return (
        <Box sx={{ pl: 4, pr: 3, pt: 4 }}>
            {isXSmall ?
                <List
                    perPage={25}
                    bulkActionButtons={<BulkActionButtons />}
                >
                    <Datagrid>
                        <TextField label="Name" source="name" />
                        <TextField label="Screens Limit" source="screens_limit" />
                        <TextField label="Services Limit" source="services_limit" />
                        <FunctionField
                            render={(record: any) => {
                                if (record) {

                                    return (
                                        <Box>
                                            <EditButton />
                                        </Box>
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>
                :
                <List
                    perPage={25}
                    bulkActionButtons={<BulkActionButtons />}
                >
                    <Datagrid>
                        <TextField label="Name" source="name" />
                        <TextField label="Type" source="type" />
                        <TextField label="Screens Limit" source="screens_limit" />
                        <TextField label="Services Limit" source="services_limit" />
                        <FunctionField
                            render={(record: any) => {
                                if (record) {
                                    if (record.content_distributor_id) {
                                        return (
                                            <ReferenceField label="Provider" source="content_distributor_id" reference="content-distributor">
                                                <Box sx={{ p: 2 }}><TextField source="name" /></Box>
                                            </ReferenceField>
                                        )
                                    }
                                    else {
                                        return (
                                            <div style={{ color: '#da5555', fontSize: 13 }}>No Provider</div>
                                        )
                                    }
                                }
                            }}
                        />
                        <FunctionField
                            render={(record: any) => {
                                if (record) {

                                    return (
                                        <Box>
                                            <EditButton />
                                        </Box>
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;