import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  CardActions,
  CircularProgress,
  IconButton,
  Typography,
  Link,
  useMediaQuery,
  Theme,
  Stack,
} from "@mui/material";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
  useGetOne,
} from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@leecheuk/react-google-login";
import { useMediaQuery as Media } from "react-responsive";
import { gapi } from "gapi-script";

// import components
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";

//image
import BGLogin from "../assets/applay_login.jpg";
import Logo from "../assets/applay_login_logo.png";

//mui icons
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import GoogleIcon from "@mui/icons-material/Google";

interface FormValues {
  username?: string;
  password?: string;
}

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      align="center"
      color="white"
      sx={{ mr: 6 }}
      {...props}
    >
      {}
      {"Powered by Magoware | All right reserved © 2023  \n"}
      <p>
        <Link color="inherit" href="https://magoware.tv">
          www.magoware.tv
        </Link>
      </p>{" "}
    </Typography>
  );
};

const CopyrightShort = (props: any) => {
  return (
    <Typography
      sx={{ pb: 3 }}
      variant="body2"
      align="center"
      color="white"
      {...props}
    >
      {}
      {"Powered by Magoware | All right reserved © 2023  \n"}
      {/* <p><Link align="center" color="inherit" href="https://magoware.tv">
				www.magoware.tv
			</Link></p>{' '} */}
    </Typography>
  );
};

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const navigate = useNavigate();

  const [showHidePassword, setShowHidePassword] = useState<boolean>(true);

  const handleShowHide = (e: any) => {
    setShowHidePassword(!showHidePassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/");
    }
  });

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    let emailAuth = {
      api: "/api/v1/auth/signin",
      data: auth,
    };
    login(emailAuth, "/").catch((error: Error) => {
      notify(error.message, { type: "warning" });
      setLoading(false);
    });
  };

  const handleSuccessGoogle = (response: any) => {
    setLoading(true);

    let googleAuth = {
      api: "/api/v1/auth/social-media/google",
      data: response.tokenObj,
    };

    login(googleAuth, "/").catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        { type: "warning" }
      );
    });
  };

  const handleFailedGoogle = (response: any) => {};

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const clientId =
    "23717147066-1gv3231cj7hn9u40616up05a6scmak94.apps.googleusercontent.com";

  React.useEffect(() => {
    gapi.load("client: auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  });

  const isDesktopOrLaptop = Media({
    query: "(min-height: 650px)",
  });

  const widthless1200px = Media({
    query: "(max-width: 1200px)",
  });

  return (
    <Box sx={{ bgcolor: "#222", height: "100vh" }}>
      <Grid container sx={{}}>
        <Grid item md={8.5}>
          <Box
            sx={{
              backgroundImage: `url(${BGLogin})`,
              backgroundSize: "cover",
              borderBottomRightRadius: 800,
              backgroundPosition: "center",
              minHeight: "100vh",
              minWidth: "100%",
            }}
          ></Box>
        </Grid>
        <Grid item md={3.5} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12} xs={12}>
              <Box sx={{ pt: isDesktopOrLaptop ? 9 : 6 }}>
                {/* <Typography sx={{ display: "flex", justifyContent: "center" }}>Welcome to</Typography> */}
                <img
                  src={Logo}
                  alt="logo"
                  style={{ paddingTop: 8, width:"auto", height: 70 }}
                />
              </Box>
            </Grid>
            <Grid item md={12} xs={12} sx={{ height: "100%" }}>
              <Form onSubmit={handleSubmit}>
                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: 3 }}
                >
                  <TextInput
                    source="username"
                    label="Email"
                    disabled={loading}
                    size="medium"
                    validate={required()}
                    placeholder="Email"
                    inputProps={{
                      style: { width: widthless1200px ? 230 : 300 },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{}}
                >
                  <TextInput
                    source="password"
                    label={translate("ra.auth.password")}
                    type={showHidePassword ? "password" : "text"}
                    size="medium"
                    disabled={loading}
                    validate={required()}
                    placeholder="Password"
                    inputProps={{
                      style: { width: widthless1200px ? 230 : 300 },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {showHidePassword ? (
                    <Button
                      onClick={handleShowHide}
                      sx={{ position: "relative", ml: -8.5, mt: 1.2 }}
                    >
                      <VisibilityOutlinedIcon />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleShowHide}
                      sx={{ position: "relative", ml: -8.5, mt: 1.2 }}
                    >
                      <VisibilityOffOutlinedIcon />
                    </Button>
                  )}
                  <Box
                    sx={{ display: "flex", justifyContent: "right", pt: -1 }}
                  >
                    <ForgotPassword />
                  </Box>
                </Grid>
                <CardActions
                  sx={{ justifyContent: "center", pt: isXSmall ? 1 : 2 }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ alignSelf: "center", pl: 4, pr: 4, borderRadius: 3 }}
                    color="primary"
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <GoogleLogin
                      clientId={clientId}
                      buttonText={`CONTINUE WITH GOOGLE`}
                      onSuccess={handleSuccessGoogle}
                      onFailure={handleFailedGoogle}
                      render={(renderProps: any) => (
                        <IconButton
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          sx={{ margin: 2, backgroundColor: "#DB4437" }}
                        >
                          <GoogleIcon />
                        </IconButton>
                      )}
                      icon={false}
                      isSignedIn={false}
                      autoLoad={false}
                      scope="profile"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "monospace",
                        fontSize: 16,
                      }}
                    >
                      Don't have an account ?
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "monospace",
                        fontSize: 16,
                      }}
                    >
                      <SignUp />
                    </Typography>
                  </Stack>
                  {/* </Box> */}
                </Grid>
                <Box sx={{ position: "absolute", bottom: 0 }}>
                  {isDesktopOrLaptop ? <Copyright /> : <CopyrightShort />}
                </Box>
              </Form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
