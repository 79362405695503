import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useGetList, useDataProvider, useCreate, Title } from 'react-admin';
import { Grid, Typography, Divider, Button, FormControlLabel, Checkbox, TextField, Box, Avatar, Autocomplete } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import components
// import EditStreams from './EditStreams';
// import CreateGenre from './dialogs/CreateGenre';

type Inputs = {
    epg_map_id: string,
    title: string,
    channel_number: string,
    channel_description: string,
    pin_protected: boolean,
    packages: any,
    is_available: boolean,
    streams: any,
    icon_path: object,
    genre: any
};

const CreateChannels: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [imageUrl, setImageUrl] = useState("");
    const [changeimage, setChangeImage] = useState({});

    const [streams, setStreams] = useState([]);
    const [streamDetails, setStreamDetails] = React.useState<any>([]);

    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);

    const [disabled, setDisabled] = useState<boolean>(true);

    const [pinProtected, setPinProtected] = useState(false);

    const handleChangePinProtected = (event: any) => {
        setPinProtected(event.target.checked);
    }

    const [isAvailable, setIsAvailable] = useState(false);

    const handleChangeIsAvailable = (event: any) => {
        setIsAvailable(event.target.checked);
    }

    const { data: dataTrial, total: dataTotal, isLoading: loading, error: error } = useGetList<any>(
        'trial-data',
        {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
    );

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        data.packages = selectedPackages;
        data.genre = selectedGenres;
        data.streams = streams;

        formData.append("data", JSON.stringify({
            "title": data.title,
            "channel_number": data.channel_number,
            "epg_map_id": data.epg_map_id,
            "channel_description": data.channel_description,
            "icon_path": "/dummy-data/image-placeholder.png",
            "pin_protected": data.pin_protected,
            "is_available": data.is_available,
            "genre": data.genre,
            "packages": data.packages,
            "streams": data.streams,
        }));

        formData.append("type", "channels")

        dataProvider.create(
            'trial-data',
            {
                data: formData,
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your channel was successfully created');
            navigate("/dummy-data");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
            <Title title="Create Channel" />
            <form onSubmit={handleSubmit(OnSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={5}>
                                <TextField
                                    id="title"
                                    placeholder="Channel Title"
                                    label="Channel Title*"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("title", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={9} md={2}>
                                <TextField
                                    id="channel_number"
                                    type="number"
                                    placeholder="Channel Nr"
                                    label="Channel Nr*"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("channel_number", {
                                        required: true,
                                        min: {
                                            value: 1,
                                            message: 'Value must be greater than 0'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.channel_number ? `${errors.channel_number.message}` : ""}
                                />
                            </Grid>
                            <Grid item xs={9} md={3}>
                                <TextField
                                    id="epg_map_id"
                                    placeholder="EPG Map ID"
                                    label="EPG Map ID*"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("epg_map_id", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 1 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={11} md={10}>
                                <TextField
                                    id="channel_description"
                                    placeholder="Enter Channel Description"
                                    label="Channel Description*"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("channel_description", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={6}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <Box sx={{ display: 'flex', mt: 0 }}>
                                    <Box>
                                        <FormControlLabel
                                            control={<Checkbox checked={pinProtected} {...register("pin_protected")} onChange={handleChangePinProtected} name="pin_protected" />}
                                            label="Pin Protected"
                                        />
                                    </Box>
                                    <Box sx={{ marginLeft: 5 }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isAvailable} {...register("is_available")} onChange={handleChangeIsAvailable} name="is_available" />}
                                            label="Is Available"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 3 }}>
                            <Divider />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={5}>
                                {dataTrial &&
                                    <Autocomplete
                                        multiple
                                        id="packages-tags"
                                        options={dataTrial as any}
                                        getOptionLabel={(option: any) => option['data'].package_name}
                                        onChange={(event, value: any) => setSelectedPackages(value)}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Package"
                                                label="Select Package*"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item md={12}>
                                {/* <EditStreams editChannelStreams={getStateStreams} initStreams={streamDetails} /> */}
                            </Grid>
                        </Grid>
                        <Box sx={{ padding: 3 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(OnSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default CreateChannels;