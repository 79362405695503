import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  EditButton,
  SingleFieldList,
  ReferenceArrayField,
  ChipField,
  BulkDeleteButton,
  TextInput,
  TopToolbar,
  Button,
  ListProps,
  ToolbarProps,
  ExportButton,
  FunctionField,
  ImageField,
} from "react-admin";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const GenreButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/genres'); 
  };

  return (
    <Button label="Genre" onClick={handleClick} />
  );
};
const CreateButton = () => {
  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate('/channels/create'); 
  };

  return (
    <Button label="+ Create" onClick={handleCreateClick} />
  );
};
const StreamButton =() => {
  const navigate = useNavigate();
  const handleStreamClick = () => {
    navigate('/stream-source')
  };
  
  return (
    <Button label="Stream Source" onClick={handleStreamClick} />
  );
}


const CustomTopToolbar: React.FC<ToolbarProps> = (props) => (
  <TopToolbar {...props}>
    <StreamButton/>
    <GenreButton />
    <CreateButton/>
    <ExportButton />
  </TopToolbar>
);

const ChannelList: React.FC<ListProps> = (props) => {
  const postFilters = [
    <TextInput
      sx={{ mb: 2 }}
      label="Search title"
      source="name_title"
      alwaysOn
    />,
    <TextInput
      sx={{ mb: 2 }}
      label="Channel Number"
      source="name_channel_number"
      alwaysOn
    />,
  ];

  return (
    <Box sx={{ pl: 4, pr: 3, pt: 5 }}>
      <List {...props} filters={postFilters} actions={<CustomTopToolbar />}>
        <Datagrid rowClick="edit">
        <ImageField source="icon_path.src" title="" />
        <NumberField source="channel_number" label="NR" />
          <TextField source="title" label="Name" />
          <FunctionField
                            label="Genres"
                            render={(record: any) => {
                                if (record.genre) {
                                    if (record.genre.length > 0) {
                                        if (record.genre.length > 4) {
                                            return (
                                                record.genre.map((data: any) => (
                                                    <Chip label={data.name} />
                                                ))
                                            )
                                        } else {
                                            return (
                                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                                    {record.genre.map((data: any) => (
                                                        <Box>
                                                            <Chip label={data.name} />
                                                        </Box>
                                                    ))}
                                                </Stack>
                                            )
                                        }
                                    } else {
                                        return <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Genre</Typography>
                                    }
                                }
                            }}
                        />
          <TextField source="epg_map_id" label="EPG ID" />
          <FunctionField
                            label="Packages"
                            render={(record: any) => {
                                if (record.packages) {
                                    if (record.packages.length > 0) {
                                        if (record.packages.length > 4) {
                                            return (
                                                record.packages.map((data: any) => (
                                                    <Chip label={data.package_name} />
                                                ))
                                            )
                                        } else {
                                            return (
                                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                                    {record.packages.map((data: any) => (
                                                        <Box>
                                                            <Chip label={data.package_name} />
                                                        </Box>
                                                    ))}
                                                </Stack>
                                            )
                                        }
                                    } else {
                                        return <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Package</Typography>
                                    }
                                }
                            }}
                        />
                        <FunctionField
                            label="Streams"
                            render={(record: any) => {
                                if (record.streams) {
                                    if (record.streams.length > 0) {
                                        return (
                                            <CheckCircleIcon sx={{ color: "#57e157" }} />
                                        )
                                    } else {
                                        return <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Stream</Typography>
                                    }
                                }
                            }}
                        />
          {/* <NumberField source="rendering" label="Rendering" /> */}
          {/* <TextField source="channel_description" label="Description" /> */}
          <BooleanField source="pin_protected" label="Pin" />
          <BooleanField source="is_available" label="Available" />
          <EditButton />
        </Datagrid>
      </List>
    </Box>
  );
};


export default ChannelList;
