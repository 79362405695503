import React, { Fragment, cloneElement } from 'react'
import {
    BooleanField,
    Datagrid,
    NumberField,
    List,
    FunctionField,
    TextField,
    CreateButton,
    sanitizeListRestProps,
    useListContext,
    TopToolbar,
    TextInput,
    Pagination,
    EditButton,
    useGetList,
    useListFilterContext
} from 'react-admin';
import { Chip, Typography, Box, Stack, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { useMediaQuery, Theme, Grid } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

// import components
import { BASE_ASSETS_URL } from '../../config';
import ServiceDetails from './dialogs/ServiceDetails';
import ServiceDelete from './dialogs/ServiceDelete';
import SeeAllServices from './dialogs/SeeAllServices';
import BulkDelete from '../../components/BulkDelete';
import { filter } from 'lodash';

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <CreateButton resource={resource} />
        </TopToolbar>
    );
};

const servicesFilters = [
    <TextInput sx={{ mb: 2 }} source="q" variant="outlined" label="Search" alwaysOn />
];

const Empty = () => {
    // const {  resource } = useListContext();
    return (
        <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any </Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <Box sx={{ mt: 3 }}>
                <CreateButton />
            </Box>
            <Box sx={{ mt: 3, pl: 5 }}>
                <Box sx={{ mt: 3, textAlign: "center" }}>
                </Box>
            </Box>
        </Box>
    );
};

const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

    fetchRelatedRecords(records, "package", "packages").then((pcg: any) => {
        fetchRelatedRecords(records, "property_operator_id", "properties/cd").then((sc: any) => {

            let data: any = [];

            records.map((record: any) => {
                console.log(pcg[record?.packages])
                data.push({
                    ...record,

                    // videos: { results: { iso_639_1: " ", iso_3166_1: " ", name: " ", key: " ", site: " ", size: " ", type: " ", official: " ", published_at: " ", id: " " } },
                    // spoken_languages: { english_name: " " },
                    // genres: { id: " " },
                    // production_companies: { id: " ", iso_3166_1: " ", name: " " },
                    // production_countries: { iso_3166_1: " ", name: " " },
                    // streams: { streamSource: " ", streamUrl: " ", streamFormat: " ", streamType: " ", token: " ", },
                    // packages: { package_name: pcg[record?.package]?.package_name, },
                    content_distributor_id: " ",
                    image_path: " ",
                    // vod_menu: " ",
                    service_name: " ",
                    service_menu: " ",
                    service_category: " ",
                    short_service_description: " ",
                    service_description: " ",
                    // backdrop_path: " ",
                    belongs_to_collection: " ",
                    // budget: " ",
                    // homepage: " ",
                    // imdb_id: " ",
                    // original_title: " ",
                    // overview: " ",
                    // popularity: " ",
                    // __v: " ",
                    // poster_path: " ",
                    // release_date: " ",
                    // backdrop: " ",
                    // revenue: " ",
                    _id: " ",
                    // tagline: " ",
                    // video: " ",
                    // runtime: " ",
                    // status: " ",
                    id: " ",
                    // vote_average: " ",
                    // vote_count: " ",
                })

            });

        })
    })

};

const BulkActionButtons = (props: any) => (
    <Fragment>
        <BulkDelete {...props} />
        {/* <Box sx={{ mb: 1, mt: 1 }}>
            <AssignMenuCategory {...props} />
        </Box>
        <Box sx={{ mb: 1, mt: 1 }}>
            <AssignPackages {...props} />
        </Box> */}
    </Fragment>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )
    const { filterValues } = useListFilterContext();

    const menu: string = filterValues.menu_id;
    const category: string = filterValues.category_id;
     
    const { data: dataService, isLoading, error, refetch } = useGetList(
        'services',
        {
            filter: { menu_id: menu }
        }
    )

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 3 }}>
            {isXSmall ?
                <List
                    title="Services"
                    perPage={25}
                    filters={servicesFilters}
                    bulkActionButtons={<BulkActionButtons />}
                    pagination={<ListPagination />}
                    actions={<ListActions />}
                    empty={<Empty />}
                    exporter={exporter}
                >
                    <Datagrid
                        optimized
                        rowClick=""
                    >
                        <TextField label="Title" source="title" />
                        <FunctionField
                            label="Menu"
                            render={(record: any) => {
                                if (record.service_menu) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.service_menu} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Menu</Typography>
                                    )
                                }
                            }}
                        />
                    </Datagrid>
                </List>

                :
                <List
                    title="Services"
                    perPage={25}
                    filters={servicesFilters}
                    bulkActionButtons={<BulkActionButtons />}
                    pagination={<ListPagination />}
                    actions={<ListActions />}
                    empty={<Empty />}
                    exporter={exporter}
                >
                    <Datagrid
                        optimized
                    >

                        <FunctionField
                            label="Image"
                            render={(record: any) => {
                                if (record.service_path) {
                                    return (
                                        <img style={{ height: "auto", width: 50 }} src={`${BASE_ASSETS_URL}${record.service_path}`} />
                                    )
                                }
                            }}
                        />
                        <TextField label="Service Name" source="service_name" />
                        <FunctionField
                            label="Menu"
                            render={(record: any) => {
                                if (record.service_menu) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.service_menu} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Menu</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="Category"
                            render={(record: any) => {
                                if (record.service_category) {
                                    return (
                                        <Chip sx={{ marginRight: 15 }} label={record.service_category} />
                                    )
                                } else {
                                    return (
                                        <Typography sx={{ color: '#da5555', fontSize: 13 }}>No Category</Typography>
                                    )
                                }
                            }}
                        />
                        <FunctionField
                            label="Details"
                            render={(record: any) => (
                                <ServiceDetails record={record} />
                            )}
                        />
                        <FunctionField
                            label="All Services"
                            render={(record: any) => (
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <SeeAllServices menu={menu} category={record["_id"]} />
                                    </Grid>
                                </Grid>
                            )}
                        />

                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )
}

export default ResourceList;