import { ReactElement } from 'react';
import { FC } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    ListBase,
    Pagination,
    TextField,
    EditButton,
    Title,
    TopToolbar,
    CreateButton,
    useListContext,
} from 'react-admin';
import { Chip, Typography, Box, Avatar, Card, CardActionArea, Grid, makeStyles } from '@mui/material';

//import types
// import theme from '../../types/Theme';

import GridList from './GridList';

const Empty = () => {
    const { resource } = useListContext();
    return (
        <Box style={{ padding: 20 }} textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No Users available.
            </Typography>
            <Typography variant="body1">
                Do you want to add one ?
            </Typography>
        </Box>
    );
};

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <div style={{ paddingLeft: 25 }}>
            <Title title="Users & Roles" />
            <ListBase
                title="Users"
                empty={<Empty />}
                {...props}
                perPage={25}
            >
                <GridList />
                <Pagination rowsPerPageOptions={[25, 50, 100]} />
            </ListBase>
        </div >
    )
}
export default ResourceList;