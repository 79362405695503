import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useCreate } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box, InputAdornment } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

// import types
// import { AppState } from '../../types/Theme';
// import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    package_name: string,
    description: string,
    package_duration: string,
    unit: string,
    color_tag: string,
    price: string
};

const CreatePackages: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState<boolean>(true);

    const [color, setColor] = useState<any>("");
    const [packageColor, setPackageColor] = useColor("hex", `${color}`);

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        data.color_tag = packageColor.hex;

        formData.append("data", JSON.stringify({
            "package_name": data.package_name,
            "description": data.description,
            "package_duration": data.package_duration,
            "icon_path": "/dummy-data/image-placeholder.png",
            "unit": data.unit,
            "color_tag": data.color_tag,
            "price": data.price,
        }));
        formData.append("type", "packages")
        // formData.append("package_name", data.package_name);
        // formData.append("description", data.description);
        // formData.append("package_duration", data.package_duration);
        // formData.append("icon_path", data.icon_path);
        // formData.append("unit", data.unit);
        // formData.append("color_tag", data.color_tag);
        // formData.append("price", data.price);

        dataProvider.create(
            'trial-data',
            {
                data: formData,
            },

        ).then((response: any) => {
            notify('Package was successfully created', { type: "info" });
            navigate("/dummy-data");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
            <Title title="Create Package" />
            <Box>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    {/* <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} /> */}
                    <Grid container spacing={7}>
                        <Grid item xs={11} md={3}>
                            <TextField
                                id="package_name"
                                label="Package Name*"
                                placeholder="Package Name"
                                InputLabelProps={{ shrink: true }}
                                {...register("package_name", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: 1 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item xs={11} md={5}>
                            <TextField
                                id="description"
                                label="Description*"
                                placeholder="Package Description"
                                InputLabelProps={{ shrink: true }}
                                {...register("description", { required: true })}
                                variant="outlined"
                                multiline
                                rows={6}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {/* <TitleSection title="Package Duration" description="Package Duration" paddingBottom={10} paddingLeft={1} paddingTop={30} /> */}
                    <Box sx={{ padding: 1 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <TextField
                                id="package_duration"
                                label="Duration*"
                                placeholder="Enter Package Duration"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                {...register("package_duration", {
                                    required: true,
                                    min: {
                                        value: 1,
                                        message: 'Value must be greater than 0'
                                    }
                                })}
                                variant="outlined"
                                helperText={errors.package_duration ? `${errors.package_duration.message}` : ""}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl variant="outlined" sx={{ minWidth: 130 }}>
                                <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    {...register("unit", { required: true })}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>Unit</em>
                                    </MenuItem>
                                    <MenuItem value={"years"}>YEAR</MenuItem>
                                    <MenuItem value={"months"}>MONTH</MenuItem>
                                    <MenuItem value={"weeks"}>WEEK</MenuItem>
                                    <MenuItem value={"days"}>DAY</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                        </Grid>
                    </Grid>
                    {/* <TitleSection title="Pricing" description="Pricing" paddingBottom={10} paddingLeft={1} paddingTop={30} /> */}
                    <Box sx={{ padding: 1 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item xs={5} md={2}>
                            <TextField
                                id="price"
                                label="Price*"
                                placeholder="Enter Package Pricing"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <InputAdornment style={{ marginTop: 3 }} position="end">€</InputAdornment>,
                                }}
                                type="number"
                                {...register("price", {
                                    required: true,
                                    min: {
                                        value: 1,
                                        message: 'Value must be greater than 0'
                                    }
                                })}
                                variant="outlined"
                                helperText={errors.price ? `${errors.price.message}` : ""}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item md={2}>
                            {/* <TitleSection title="Color Tag" description="Color Tag" paddingBottom={10} paddingLeft={1} paddingTop={20} /> */}
                            <Box sx={{ padding: 1 }}></Box>
                            <ColorPicker width={250} height={100} color={packageColor} onChange={setPackageColor} hideRGB hideHSV dark />
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: 5 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default CreatePackages;