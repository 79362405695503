import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useGetList,
  useGetOne,
  useNotify,
  Show,
  SimpleShowLayout,
  TextField as TextFieldRA,
  Datagrid,
  ReferenceManyField,
  useRefresh,
  useDataProvider,
  List,
} from "react-admin";
import {
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Autocomplete,
  Checkbox,
  Box,
  FormHelperText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

// import types
// import { AppState } from '../../types/Theme';

// import components
// import TitleSection from '../../components/title/TitleSection';
import ReferenceList from "./ReferenceList";

type Inputs = {
  screen_number: string;
  mac_address: string;
  pin_adult: string;
  show_adult: boolean;
  fullName: string;
  player: string;
  stream_source: object;
  screen_type: string;
  greeting_message: string;
  activate_mobile: boolean;
  activated: boolean;
  address: string;
  app_terrestral_package_id: string;
  enabled_terrestral_tv: boolean;
  email: string;
  password: string;
  generatedId: string;
};

const Edit: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [activate_mobile, setActivate_mobile] = React.useState<boolean>(false);

  const handleChangeActivateMobile = (event: any) => {
    setActivate_mobile(event.target.checked);
    setDisabled(false);
  };

  const [showAdult, setShowAdult] = React.useState<boolean>(false);
  const [initialMacAddress, setInitialMacAddress] = useState<any>("");

  const handleChangeShowAdult = (event: any) => {
    setShowAdult(event.target.checked);
    setDisabled(false);
  };

  const [activated, setActivated] = React.useState<boolean>(false);

  const handleChangeActivated = (event: any) => {
    setActivated(event.target.checked);
    setDisabled(false);
  };

  const [generatedId, setGeneratedId] = useState<string>("");

  const [changePINState, setChangePINState] = useState<boolean>(true);

  const changePIN = () => {
    setChangePINState(!changePINState);
  };

  const [selectedScreenType, setSelectedScreenType] = useState<string>("room");

  const handleScreenTypeChange = (event: any) => {
    setSelectedScreenType(event.target.value);
    setDisabled(false);
  };
  const [macAddress, setMacAddress] = useState<string>("");
  const [streamSources, setStreamSources] = useState<any>([]);
  const [selectedStreamSource, setSelectedStreamSource] = useState<any>();

  const {
    data: dataStream,
    total: totalStream,
    isLoading: loadingStream,
    error: errorStream,
  } = useGetList("stream-source/contentDistributor", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  useEffect(() => {
    setStreamSources(dataStream);
  }, [dataStream]);

  let decode: any = jwtDecode(`${localStorage.getItem("access_token")}`);
  let propertyid = decode["property_operator_id"];

  const {
    data: dataProperties,
    isLoading: loadingProperties,
    error: errorProperties,
  } = useGetOne("properties", {
    id: propertyid,
  });

  const [screenDetails, setScreenDetails] = React.useState<any>({});
  const [selectedPlayers, setSelectedPlayers] = useState<any>({});
  const [enabledTerrestral, setEnabledTerrestral] = useState<boolean>(false);

  const changeTerrestral = (event: any) => {
    setEnabledTerrestral(!enabledTerrestral);
  };

  //fetch screen details
  const {
    data: dataScreens,
    isLoading: loadingScreens,
    error: errorScreens,
  } = useGetOne("screens", {
    id: id,
  });

  useEffect(() => {
    setScreenDetails(dataScreens);
    setShowAdult(dataScreens?.show_adult);
    setEnabledTerrestral(dataScreens?.enabled_terrestral_tv);
    setValue("enabled_terrestral_tv", dataScreens?.enabled_terrestral_tv);
    setValue("screen_number", dataScreens?.screen_number);
    setValue("mac_address", dataScreens?.mac_address);
    setValue(
      "app_terrestral_package_id",
      dataScreens?.app_terrestral_package_id
    );
    setInitialMacAddress(dataScreens?.mac_address);
    if (dataProperties && dataProperties["type"] === "residential") {
      setValue("address", dataScreens?.address);
    }
    setValue("pin_adult", dataScreens?.pin_adult);
    setSelectedScreenType(dataScreens?.screen_type);
    setValue("show_adult", dataScreens?.show_adult);
    setValue("activated", dataScreens?.activated);
    setValue("activate_mobile", dataScreens?.activate_mobile);
    setValue("email", dataScreens?.email);
    setValue("password", dataScreens?.password);
    setActivated(dataScreens?.activated);
    setActivate_mobile(dataScreens?.activate_mobile);
    {
      dataScreens?.fullName
        ? setValue("fullName", dataScreens?.fullName)
        : setValue("fullName", "");
    }
    {
      dataScreens?.greeting_message
        ? setValue("greeting_message", dataScreens?.greeting_message)
        : setValue("greeting_message", "Welcome");
    }
    setSelectedPlayers({
      value: dataScreens?.player,
    });
    setSelectedStreamSource(
      dataScreens?.stream_source
        ? dataScreens?.stream_source
        : "default_stream_source"
    );
  }, [dataScreens]);

  const generateUniqueId = () => {
    const randomValue = Math.floor(Math.random() * 0xffffffffffffffff).toString(
      16
    );
    const timestamp = Date.now().toString(16).slice(-5); // Use the last 5 characters of the current timestamp
    const uniqueId = randomValue.slice(0, 7) + timestamp;
    return uniqueId;
  };

  const handleGenerateId = () => {
    const generatedId = generateUniqueId();
    setMacAddress(generatedId);
    setValue("mac_address", generatedId);
    setDisabled(false);
    console.log("generatedId", generatedId);
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<Inputs>();
  const OnSubmit: SubmitHandler<Inputs> = (data) => {
    if (!selectedPlayers) {
      // Handle the case where player is empty
      notify("Player can not be empty", { type: "warning" });
      return;
    }
    const formData: any = new FormData();

    data.player = selectedPlayers.value;
    data.stream_source = selectedStreamSource;

    // if (initialMacAddress !== data.mac_address) {
    //   formData.append("mac_address", data.mac_address.toUpperCase());
    // }

    // formData.append("screen_type", selectedScreenType);
    // formData.append("screen_number", data.screen_number);
    // formData.append("pin_adult", data.pin_adult);
    // formData.append("show_adult", data.show_adult);
    // formData.append("activated", data.activated);
    // formData.append("fullName", data.fullName);
    // formData.append("greeting_message", data.greeting_message);
    // formData.append("player", data.player);
    // formData.append("email", data.email);
    // formData.append("password", data.password);
    // // formData.append("enabled_terrestral_tv", data.enabled_terrestral_tv);
    // // formData.append("app_terrestral_package_id", data.app_terrestral_package_id);
    // formData.append("stream_source", data.stream_source);

    if (dataProperties && dataProperties["type"] === "residential") {
      formData.append("address", data.address);
    }

    const screensData = {
      screen_number: data.screen_number,
      screen_type: data.screen_type,
      mac_address: data.mac_address.toUpperCase(),
      pin_adult: data.pin_adult,
      show_adult: data.show_adult,
      activated: data.activated,
      activate_mobile: data.activate_mobile,
      player: data.player,
      fullName: data.fullName,
      greeting_message: data.greeting_message,
      stream_source: data.stream_source,
      app_terrestral_package_id: data.app_terrestral_package_id,
      enabled_terrestral_tv: data.enabled_terrestral_tv,
      ...(activate_mobile && { email: data.email, password: data.password }),
    };

    dataProvider
      .update("screens", {
        id: `${id}`,
        data: screensData,
        previousData: {
          id: `${id}`,
        },
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your screen was successfully updated", { type: "info" });
        navigate("/screens");
      })
      .catch((error: any) => {
        if (error.message.includes("email")) {
          notify("Email should be unique", { type: "warning" });
        } else if (
          error.message.includes("Duplicate key error") &&
          error.message.includes("mac_address")
        ) {
          notify("MAC address is not unique", { type: "warning" });
        } else {
          console.error("Error during insertion:", error);
          notify(
            "An error occurred while creating the service. Please check your inputs and try again.",
            { type: "error" }
          );
        }
      });
  };

  return (
    <Box sx={{ pl: 6, pt: 10, borderRadius: 10 }}>
      <Title title="Create Screen" />
      <form onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={9} md={3}>
            <TextField
              id="screen_number"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Screen Number"
              label="Screen Number*"
              {...register("screen_number", {
                required: {
                  value: true,
                  message: "Screen number can not be empty",
                },
              })}
              helperText={errors.screen_number?.message}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={9} md={4}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="mac_address"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter MAC Address"
                label="MAC Address*"
                inputProps={{
                  maxLength: 12,
                  style: { textTransform: "uppercase", width: "80%" },
                }}
                helperText={"Mac Address should be 12 characters long"}
                {...register("mac_address", {
                  required: true,
                  maxLength: 12,
                  minLength: 12,
                })}
                variant="outlined"
                fullWidth
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGenerateId}
                style={{
                  marginLeft: "9px",
                  marginBottom: "40px",
                  width: "80%",
                }}
              >
                Generate ID
              </Button>
            </div>
          </Grid>
        </Grid>
        <Box sx={{ pb: 1 }}></Box>
        <Grid container spacing={4}>
          <Grid item xs={9} md={3}>
            <TextField
              id="greeting_message"
              label="Greeting Message"
              placeholder="Enter Greeting Message"
              InputLabelProps={{ shrink: true }}
              {...register("greeting_message")}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <TextField
              id="full_name"
              label="Full Name"
              placeholder="Enter Guest's Full Name"
              InputLabelProps={{ shrink: true }}
              {...register("fullName")}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={6}>
          <Grid item xs={9} md={2}>
            <TextField
              id="pin_adult"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter PIN Adult"
              label="PIN Adult"
              {...register("pin_adult", {
                required: true,
                maxLength: 12,
                minLength: 1,
              })}
              variant="outlined"
              fullWidth
              disabled={changePINState}
              required
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              style={{ marginTop: 5 }}
              control={<Checkbox onChange={changePIN} name="show_pin" />}
              label="Change PIN"
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ pb: 3 }}></Box>
                <Grid container spacing={3}>
                    {enabledTerrestral ?
                        <Grid item xs={9} md={2}>
                            <TextField
                                id="app_package_ID"
                                InputLabelProps={{ shrink: true }}
                                {...register("app_terrestral_package_id")}
                                placeholder="App Package ID"
                                label="App Package ID"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        :
                        " "
                    }
                    <Grid item md={2}>
                        <FormControlLabel
                            id="enabled_terrestral"
                            style={{ marginTop: 5 }}
                            control={<Checkbox checked={enabledTerrestral} {...register("enabled_terrestral_tv")} onChange={changeTerrestral} name="enabled_terrestral_tv" />}
                            label="Enabled Terrestral"
                        />
                        <Typography sx={{ color: "#FFFFFFB3", fontSize: 12, fontFamily: " Helvetica" }}>This opsion is available for android users</Typography>
                    </Grid>
                </Grid> */}
        <Box sx={{ pb: 3 }}></Box>
        {(selectedScreenType === "room" ||
          selectedScreenType === "bar_restaurant") && (
          <>
            <Grid container spacing={6}>
              <Grid item md={2}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="screen_type"
                    name="screen_type"
                    value={selectedScreenType}
                  >
                    <FormControlLabel
                      value="room"
                      control={
                        <Radio
                          onChange={(e) => {
                            setSelectedScreenType(e.target.value);
                            setDisabled(false);
                          }}
                        />
                      }
                      label="Hotel Room"
                    />
                    <FormControlLabel
                      value="bar_restaurant"
                      control={
                        <Radio
                          onChange={(e) => {
                            setSelectedScreenType(e.target.value);
                            setDisabled(false);
                          }}
                        />
                      }
                      label="Bar & Restaurant"
                    />
                    {/* <FormControlLabel value="client" control={<Radio />} label="Residential Client" /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={6}>
          <Grid item xs={8} md={2}>
            <Autocomplete
              multiple={false}
              id="player"
              options={[
                {
                  value: "default_player",
                },
                {
                  value: "exo_player",
                },
              ]}
              value={selectedPlayers}
              getOptionLabel={(option: any) => (option && option.value) || ''}
              onChange={(event, value: any) => {
                setSelectedPlayers(value);
                setDisabled(false);
              }}
              limitTags={1}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Select Player"
                  fullWidth
                  error={selectedPlayers === null}
                  helperText={
                    selectedPlayers === null || selectedPlayers === undefined
                      ? "Player can not be empty"
                      : ""
                  }
                />
              )}
            />
            {/* <FormHelperText>Player can not be empty</FormHelperText> */}
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={6}>
          <Grid item xs={8} md={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Stream Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="streamSource"
                value={selectedStreamSource || ""}
                onChange={(e) => {
                  setSelectedStreamSource(e.target.value);
                  setDisabled(false);
                }}
                label="Stream Source"
              >
                <MenuItem value="">
                  <em>Select Source</em>
                </MenuItem>
                {streamSources?.map((record: any) => (
                  <MenuItem key={record["name"]} value={record["name"]}>
                    {record["name"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ pb: 3 }}></Box>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAdult}
                  {...register("show_adult")}
                  onChange={handleChangeShowAdult}
                  name="show_adult"
                />
              }
              label="Show Adult"
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activated}
                  {...register("activated")}
                  onChange={handleChangeActivated}
                  name="activated"
                />
              }
              label="Active Screen"
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activate_mobile}
                  {...register("activate_mobile")}
                  onChange={handleChangeActivateMobile}
                  name="activate_mobile"
                />
              }
              label="Activate Mobile"
            />
          </Grid>
        </Grid>
        {activate_mobile ? (
          <Grid container spacing={3} sx={{ pt: 3.5 }}>
            <Grid item md={3}>
              <TextField
                id="email"
                type="email"
                label="Email"
                placeholder="Enter Email"
                InputLabelProps={{ shrink: true }}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                variant="outlined"
                fullWidth
                helperText={errors.email ? `${errors.email.message}` : ""}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                id="password"
                label="Password"
                placeholder="Enter Password"
                InputLabelProps={{ shrink: true }}
                {...register("password", { required: "Password is required" })}
                variant="outlined"
                type="password"
                fullWidth
                helperText={errors.password ? "Password is required" : ""}
              />
            </Grid>
          </Grid>
        ) : (
          " "
        )}
        {dataProperties && dataProperties["type"] === "residential" && (
          <>
            <Box sx={{ pb: 3 }}></Box>
            <Grid container spacing={3}>
              <Grid item xs={9} md={3}>
                <TextField
                  id="address"
                  label="Address"
                  placeholder="Address"
                  InputLabelProps={{ shrink: true }}
                  {...register("address")}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!isDirty && disabled}
          onClick={handleSubmit(OnSubmit)}
        >
          Submit
        </Button>
      </form>
      <Box>
        <ReferenceList screenID={id} />
      </Box>
    </Box>
  );
};

export default Edit;
