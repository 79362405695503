import React, { FC, useCallback, useEffect, useState } from "react";
import {
  useDataProvider,
  useRefresh,
  useNotify,
  useGetList,
  useGetOne,
} from "react-admin";
import {
  Dialog,
  Button,
  makeStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Theme,
  DialogActions,
  DialogContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CreateStreamSource from "./CreateStreamSource";

// import states
import { EditChannelStreams } from "../../../types/Streams";

interface Props {
  editChannelStreams: EditChannelStreams;
  initStream: any;
  initStreams: any;
  index: number;
}

const StreamDetails: FC<Props> = ({
  initStream,
  index,
  editChannelStreams,
  initStreams,
}) => {
  const [open, setOpen] = React.useState(false);

  const [allValues, setAllValues] = useState({
    streamSource: "",
    streamUrl: "",
    streamFormat: "HLS",
    streamType: " regular",
    token: false,
    tokenUrl: "",
    encryption: false,
    encryptionUrl: "",
    stream_type: "",
  });

  const [stateCheckboxToken, setStateCheckboxToken] =
    React.useState<boolean>(false);
  const [stateCheckboxEncryption, setStateCheckboxEncryption] =
    React.useState<boolean>(false);

  const [streamUrlError, setStreamUrlError] = useState<boolean>(false);
  const [streamSourceError, setStreamSourceError] = useState<boolean>(false);

  const [channelStreams, setChannelStreams] = React.useState<any[]>([]);

  //   const [stream_type, setStream_type] = useState<any>("");

  const {
    data: dataStream,
    total,
    isLoading,
    error,
    refetch,
  } = useGetList("stream-source", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheckboxToken(event.target.checked);
    setAllValues({
      ...allValues,
      token: event.target.checked,
    });
  };

  const handleChangeEncryption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStateCheckboxEncryption(event.target.checked);
    setAllValues({
      ...allValues,
      encryption: event.target.checked,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeHandler = (e: any) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  console.log(allValues);

  const addStreams = () => {
    if (allValues.streamUrl === "") {
      setStreamUrlError(true);
    } else if (allValues.streamSource === "") {
      setStreamSourceError(true);
    } else {
      setChannelStreams([...channelStreams, allValues]);
      let newArr = [...channelStreams];
      newArr[index] = allValues;
      editChannelStreams(newArr);
      setOpen(false);
      setStreamSourceError(false);
      setStreamUrlError(false);
    }
  };

  React.useEffect(() => {
    if (initStream) {
      setAllValues({
        streamSource: initStream.streamSource,
        streamUrl: initStream.streamUrl,
        streamFormat: "HLS",
        streamType: " regular",
        token: initStream.token,
        tokenUrl: initStream.tokenUrl,
        encryption: initStream.encryption,
        encryptionUrl: initStream.encryptionUrl,
        stream_type: initStream.stream_type,
      });
      setChannelStreams(initStreams);
      setStateCheckboxToken(initStream.token);
      setStateCheckboxEncryption(initStream.encryption);
    }
  }, [initStream, open]);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        sx={{ marginBottom: 1, marginRight: 1 }}
        size="small"
      >
        {initStream.streamSource}
      </Button>
      <Dialog
        open={open}
        maxWidth="xl"
        PaperProps={{
          sx: {
            width: 800,
            p: 2,
            borderRadius: 5,
          },
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box sx={{ m: 2 }}>
            <Typography sx={{ color: "#9c9c9c", fontSize: 17, pb: 1 }}>
              Manage Streams
            </Typography>
          </Box>
          <Box sx={{ m: 2 }}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Stream Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="streamSource"
                defaultValue={allValues.streamSource}
                onChange={changeHandler}
                size="medium"
                label="Stream Source"
              >
                <MenuItem value="">
                  <em>Select Source</em>
                </MenuItem>
                <MenuItem value={"default_stream_source"}>
                  Default Stream Source
                </MenuItem>
                {dataStream?.map((streamSource) => (
                  <MenuItem value={streamSource["name"]}>
                    {streamSource["name"]}
                  </MenuItem>
                ))}
              </Select>
              {streamSourceError ? (
                <FormHelperText>Stream Source can not be empty</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
            <CreateStreamSource />
          </Box>
          {/* <Box sx={{ m: 1 }}>
                        <Typography>{initStream.streamFormat}</Typography>
                    </Box> */}
          <Box sx={{ m: 2, mt: 4 }}>
            <TextField
              onChange={changeHandler}
              name="streamUrl"
              defaultValue={allValues.streamUrl}
              size="medium"
              label="Stream URL"
              InputLabelProps={{ shrink: true }}
              placeholder="Stream Url"
              id="standard-basic"
              fullWidth
            />
            {streamUrlError ? (
              <FormHelperText>Stream URL can not be empty</FormHelperText>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateCheckboxToken}
                  onChange={handleChangeToken}
                  name="token"
                />
              }
              label="Token"
            />
          </Box>
          <Box sx={{ m: 2 }}>
            <TextField
              onChange={changeHandler}
              name="tokenUrl"
              size="medium"
              label="Token URL"
              InputLabelProps={{ shrink: true }}
              placeholder="Token Url"
              defaultValue={allValues.tokenUrl}
              id="standard-basic"
              fullWidth
              disabled={!stateCheckboxToken}
            />
          </Box>
          <Box sx={{ m: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateCheckboxEncryption}
                  onChange={handleChangeEncryption}
                  name="encryption"
                />
              }
              label="Encryption"
            />
          </Box>
          <Box sx={{ m: 2 }}>
            <TextField
              onChange={changeHandler}
              name="encryptionUrl"
              size="medium"
              id="standard-basic"
              label="Encryption URL"
              InputLabelProps={{ shrink: true }}
              placeholder="Encryption Url"
              defaultValue={allValues.encryptionUrl}
              fullWidth
              disabled={!stateCheckboxEncryption}
            />
          </Box>
          <Box sx={{ m: 2, pt: 2 }}>
            <FormControl variant="outlined" size="medium" fullWidth>
              <InputLabel id="demo-simple-select-label">Stream Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="stream_type"
                defaultValue={allValues.stream_type}
                label="Stream Type"
                // InputLabelProps={{shrink: true}}
                onChange={changeHandler}
              >
                <MenuItem value="live">Live</MenuItem>
                <MenuItem value="catchUp">CatchUp</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mr: 3 }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={addStreams}
          >
            Save Stream
          </Button>
          <Button onClick={handleClose} color="warning">
            {" "}
            Close{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StreamDetails;
