import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useGetOne } from 'react-admin';
import { makeStyles, createStyles, Dialog, DialogActions, DialogContent, Theme, Grid, Box, Typography, TextField, Divider, Button, FormControlLabel, Checkbox, Avatar, FormHelperText } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, SubmitHandler } from "react-hook-form";
import { BASE_ASSETS_URL, BASE_URL } from "../../config";
import { Editor } from '@tinymce/tinymce-react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// import types

// import components

interface OptionType {
    label?: string;
    name: string;
    inputValue?: string;
}

type Inputs = {
    service_name: string,
    short_service_description: string,
    service_description: string,
    service_menu: string,
    service_category: string,
    service_path: object,
    is_public: boolean
};

const Edit: FC = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const { id } = useParams();
    const [disabled, setDisabled] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = useState("");
    const [changeimage, setChangeImage] = useState({});
    const [hasImage, setHasImage] = useState<boolean>(false);
    const [imageToCrop, setImageToCrop] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);

    const handleImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setHasImage(false);
        setImageToCrop(url);
        setOpen(true);
    }

    function dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;

        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const handleCrop = () => {
        const blob = dataURItoBlob(croppedImage);
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setChangeImage(blob);
        setDisabled(false);
        setOpen(false);
    }

    const cropperRef = useRef<HTMLImageElement>(null);
    const onCrop = () => {
        setOpen(true);
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    const filter = createFilterOptions<OptionType>();
    const [menu, setMenu] = React.useState<OptionType | any>(null);
    const [category, setCategory] = React.useState<OptionType | any>(null);
    const [allMenus, setAllMenus] = useState<OptionType | any>(null);
    const [allCategories, setAllCategories] = useState<OptionType | any>(null);
    const [longDescriptionDetails, setLongDescriptionDetails] = React.useState("");

    const fetchMenuCategory = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
        };

        fetch(`${BASE_URL}/api/v1/services/cd/menus`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                setAllMenus(response);
            });

        fetch(`${BASE_URL}/api/v1/services/cd/categories`, requestOptions)
            .then(response => response.json())
            .then((response) => {
                setAllCategories(response);
            });
    };

    const [longDescription, setHtmlLongDescription] = useState("");

    const handleEditorChange = (data: any) => {
        setHtmlLongDescription(`${data}`);
    }

    //fetch service details
    const { data: dataServices, isLoading, error, refetch } = useGetOne(
        'services',
        {
            id: id
        }
    )

    useEffect(() => {
        fetchMenuCategory()
    }, [])

    useEffect(() => {
        if (dataServices) {
            setValue("service_name", dataServices.service_name);
            setValue("short_service_description", dataServices.short_service_description);
            setValue("service_description", dataServices.service_description);
            setMenu({ name: dataServices.service_menu });
            setCategory({ name: dataServices.service_category });
            setImageUrl(dataServices.service_path);
            if (dataServices.service_path) setHasImage(true);
            if (dataServices?.service_description == "undefined") {
                setLongDescriptionDetails('')
            } else {
                setLongDescriptionDetails(dataServices?.service_description);
            }
        }
    }, [dataServices])


    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.service_path = changeimage;
        data.service_description = longDescription;

        if (menu !== null) {
            data.service_menu = menu.name;
        }

        if (category !== null) {
            data.service_category = category.name;
        }

        formData.append("service_name", data.service_name);
        formData.append("short_service_description", data.short_service_description);
        formData.append("service_description", data?.service_description);
        formData.append("service_menu", data.service_menu);
        formData.append("service_category", data.service_category);
        if (Object.keys(data.service_path).length === 0 && data.service_path.constructor === Object === false) {
            formData.append("service_path", data.service_path);
        }

        dataProvider.update(
            'services/cd',
            {
                id: `${id}`,
                data: formData,
                previousData: {
                    id: `${id}`
                }
            },
        ).then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
            notify('Your service was successfully updated', { type: "info" });
            navigate("/services/cd/public");
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    return (
        <Box sx={{ pl: 6, pt: 10 }}>
            <Title title="Create Service" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        {/* <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} /> */}
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={4}>
                                <TextField
                                    id="service_name"
                                    placeholder="Service Name*"
                                    label="Service Name*"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("service_name", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {/* <TitleSection title="Category Details" description="Category Details" paddingBottom={10} paddingLeft={1} paddingTop={20} /> */}
                        <Box sx={{ pb: 3 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={9} md={4}>
                                <Autocomplete
                                    value={menu}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            {
                                                setMenu({
                                                    name: newValue
                                                });
                                                setDisabled(false);
                                            }
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            {
                                                setMenu({
                                                    name: newValue.inputValue,
                                                    label: newValue.inputValue
                                                });
                                                setDisabled(false);
                                            }
                                        } else {
                                            {
                                                setMenu(newValue);
                                                setDisabled(false);
                                            }
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option: any) => inputValue === option);

                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                label: `Add "${params.inputValue}"`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="service_menu"
                                    options={allMenus}
                                    getOptionLabel={(option: any) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        else if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        else return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Service Menu*" variant="outlined" placeholder='Service Menu' InputLabelProps={{ shrink: true, required: true }} />
                                    )}
                                />
                            </Grid>
                            <Grid xs={9} item md={4}>
                                <Autocomplete
                                    value={category}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            {
                                                setCategory({ name: newValue });
                                                setDisabled(false);
                                            }
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            {
                                                setCategory({
                                                    name: newValue.inputValue,
                                                    label: newValue.inputValue
                                                });
                                                setDisabled(false);
                                            }
                                        } else {
                                            {
                                                setCategory(newValue);
                                                setDisabled(false);
                                            }
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option: any) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                name: params.inputValue,
                                                label: `Add "${params.inputValue}"`
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="service_category"
                                    options={allCategories}
                                    getOptionLabel={(option: any) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Service Category*" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* <TitleSection title="Description" description="Description" paddingBottom={10} paddingLeft={1} paddingTop={20} /> */}
                        <Box sx={{ pb: 3 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={11} md={12}>
                                <TextField
                                    id="short_service_description"
                                    placeholder="Short Description*"
                                    label="Short Description*"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 1000 }}
                                    {...register("short_service_description", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                                <FormHelperText sx={{ fontSize: 11 }}>Description should not be more than 1000 character !</FormHelperText>
                            </Grid>
                            <Grid item md={12}>
                                <Box sx={{ pt: 0, pb: 4 }}>
                                    <Editor
                                        apiKey="jo5ngogt8u99gz7wk0dr32waklwzwttoplcpqd473yd5s1ny"
                                        initialValue={longDescriptionDetails}
                                        onChange={() => {
                                            setDisabled(false);
                                        }}
                                        init={{
                                            height: 500,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <TitleSection title="Media Options" description="Media Options" paddingBottom={0} paddingLeft={1} paddingTop={20} /> */}
                        <Grid container spacing={1}>
                            <Grid item md={5}>
                                <Box sx={{ display: 'flex', alignItems: "center", alignContent: "center", }}>
                                    <Box>
                                        {imageUrl ?
                                            <>
                                                {hasImage && imageUrl ?
                                                    < img width={200} height={120} style={{ objectFit: "cover" }} src={`${BASE_ASSETS_URL}${imageUrl}`} />
                                                    :
                                                    <img width={200} height={120} style={{ objectFit: "cover" }} src={imageUrl} />
                                                }
                                            </>
                                            :
                                            <Avatar sx={{ mt: 5, ml: 2, mb: 3, width: 70, height: 70 }} alt="Logo" src="/static/images/avatar/1.jpg" />

                                        }
                                    </Box>
                                    <Box>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                            sx={{ ml: 2, mt: 0 }}
                                        >
                                            Upload Image
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box>
                            <FormHelperText sx={{ fontSize: 11 }}>Image should not be more than 10MB !</FormHelperText>
                        </Box>
                        <Box sx={{ p: 3, pb: 2 }}>
                            <Divider />
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            disabled={!isDirty && disabled}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Box sx={{ p: 3, pb: 2 }}></Box>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>
            {open && <Dialog open={open}>
                <DialogContent sx={{ p: 10 }}>
                    <div>
                        <Cropper
                            src={imageToCrop}
                            style={{ height: 400, width: "100%" }}
                            // Cropper.js options
                            initialAspectRatio={16 / 9}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                        />
                    </div>

                </DialogContent>
                <DialogActions sx={{ padding: 1 }}>
                    <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                    <Button
                        onClick={handleCrop}
                        variant="contained"
                    // color="default"
                    >
                        Crop
                    </Button>
                </DialogActions>
            </Dialog>}
        </Box>
    )
}

export default Edit;