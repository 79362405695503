import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning, useUpdate, useGetOne } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, FormControlLabel, Checkbox, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import types
// import { AppState } from '../../types/Theme';
// import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    name: string,
};

const ChangeHotelName: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const OnSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        formData.append("name", data.name);

        dataProvider.update(
            'properties',
            {
                id: decode["property_operator_id"],
                data: formData,
                previousData: {
                    id: decode["property_operator_id"]
                }
            },
        ).then((response: any) => {
            notify('Hotel name was successfully changed', { type: "info" });
        }).catch((error: any) => {
            notify(error.message, { type: "warning" });
        })
    }

    const [activeProperties, setStateActiveProperties] = useState({});


    const { data: dataProp, isLoading, error, refetch } = useGetOne(
        'properties',
        {
            id: decode["property_operator_id"]
        },

    );

    useEffect(() => {
        setValue("name", dataProp?.name);
    }, [dataProp])

    return (
        <Box sx={{ pl: 6, pt: 11 }}>
            <Title title="Change Property Name" />
            <Box >
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                id="name"
                                type="text"
                                InputLabelProps={{ shrink: true }}
                                placeholder=" Enter Hotel Name"
                                label="Hotel Name*"
                                {...register("name", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3} style={{ marginTop: 20 }}>
                            <Button
                                type="button"
                                onClick={handleSubmit(OnSubmit)}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default ChangeHotelName;