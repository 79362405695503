import Create  from './Create';
import ResourceList from './List';
import Edit from './Edit';

const resource = {
    list: ResourceList,
    create: Create,
    edit: Edit
};

export default resource;