import React, { FC, useState, useCallback, useEffect } from 'react';
import { Title, useTranslate, useNotify, useLogin } from 'react-admin';
import {
    makeStyles, createStyles, Theme, Container, TextField, Dialog, RadioGroup, FormLabel, FormControl, FormControlLabel,
    Typography, Grid, CardActions, Button, Radio, IconButton, Avatar, Divider, Box
} from '@mui/material';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import DialogContent from '@mui/material/DialogContent';
import Logo from '../Logo';
import { useNavigate } from "react-router-dom";

interface FormValues {
    username?: string;
    email?: string;
    password?: string;
    repeat_password?: string;
    phone?: string;
    name?: string;
    company_type?: string;
    screens_limit?: string;
    type?: string;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        variant="outlined"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const SignUp: FC = () => {
    const { Form } = withTypes<FormValues>();

    const translate = useTranslate();
    const login = useLogin();
    const location = useLocation();
    const notify = useNotify();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companyType, setCompanyType] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [statePropertyType, setStatePropertyType] = React.useState(false);

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        if (token) {
            navigate("/")
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeCompanyType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatePropertyType(event.target.value === "property");
        setCompanyType(event.target.value)
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.name) {
            errors.name = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        if (!values.phone) {
            errors.phone = translate('ra.validation.required');
        }
        if (values.password != values.repeat_password) {
            errors.repeat_password = translate('Passwords do not match');
        }
        return errors;
    };

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);

        let signupAuth = {
            api: '/api/v1/auth/signup',
            data: {
                ...auth,
                company_type: "distributor",
            }
        }

        setOpen(false);

        login(signupAuth, '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    { type: 'warning' },
                );
            }
        );
    };

    return (
        <>
            <span onClick={handleClickOpen} style={{ cursor: "pointer", color: "#008DD9", fontWeight: "normal" }}>Sign Up</span>
            <Dialog
                onClose={handleClose}
                fullScreen
                open={open}
            >
                <DialogContent>
                    <Box>
                        <IconButton onClick={handleClose} >
                            <KeyboardBackspaceOutlinedIcon />
                        </IconButton>
                    </Box>
                    <Container component="main" maxWidth="sm" sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', justifyItems: 'center', alignContent: 'center' }}>
                        <Box sx={{ p: 5 }}>
                            <Logo />
                        </Box>
                        <Typography sx={{ color: "gray", fontSize: 22 }}>
                            Sign Up
                        </Typography>
                        <Form
                            onSubmit={handleSubmit}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item md={12}>
                                            <Field
                                                // @ts-ignore
                                                component={renderInput}
                                                margin="normal"
                                                required
                                                id="name"
                                                label="Name"
                                                name="name"
                                                fullWidth
                                                autoComplete="name"
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <Field
                                                // @ts-ignore
                                                component={renderInput}
                                                margin="normal"
                                                required
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                fullWidth
                                                autoComplete="email"
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Field
                                                // @ts-ignore
                                                component={renderInput}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Field
                                                // @ts-ignore
                                                component={renderInput}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="repeat_password"
                                                label="Repeat Password"
                                                type="password"
                                                id="repeat_password"
                                            />
                                        </Grid>
                                        <Grid item md={8}>
                                            <Field
                                                // @ts-ignore
                                                component={renderInput}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="phone"
                                                label="Phone Number"
                                                id="phone"
                                                autoComplete="phone"
                                            />
                                        </Grid>
                                        <Box sx={{ padding: 8 }}>
                                            <Divider />
                                        </Box>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                            fullWidth
                                            size="large"
                                        >
                                            Sign Up
                                        </Button>
                                    </Grid>
                                </form >
                            )}
                        />
                    </Container>
                </DialogContent>
            </Dialog >
        </>

    );
}

export default SignUp;