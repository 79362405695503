import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDataProvider, useNotify, useGetList } from 'react-admin';
import { makeStyles, Theme, createStyles, Button, Dialog, Backdrop, CircularProgress, Tooltip, DialogContent, Grid, Typography, Divider, Box, Pagination } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { BASE_ASSETS_URL } from '../../../config';
interface Props {
    record: any
}

const InfoDialog: FC<Props> = ({ record }) => {

    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const [state, setState] = useState([]);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [seeAllInfo, setSeeAllInfo] = useState<boolean>(false);

    const HandleOpen = async () => {
        setOpenBackDrop(true);
        setOpen(true);
        setOpenBackDrop(false);
    }
    const { data: dataChannels, total: totalChannels } = useGetList(
        `channels/property/findByPackage/${record['_id']}`,
        {
            pagination: { page: page, perPage: 72 },
            sort: { field: 'title', order: 'ASC' },
            filter: {}
        }
    );

    const handleClose = () => {
        setOpen(false);
    };

    const handleSeeAll = () => {
        setSeeAllInfo(!seeAllInfo);
    }

    useEffect(() => {
        { open && HandleOpen(); }
    }, [page]);
    
    // console.log(totalChannels,"sdasdsa")

    return (
        <Box sx={{ padding: -10 }}>
            <Button size="small" color="primary" onClick={HandleOpen}>
                Info
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{width: 1400}}>
                    <Box sx={{ marginBottom: 2, marginLeft: 2, marginRight: 0 }}>
                        <Grid container spacing={5}>
                            <Grid item md={4}>
                                <Box sx={{ marginTop: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={`${BASE_ASSETS_URL}${record.icon_path}`} width={120} />
                                </Box>
                                <Box sx={{ marginBottom: 1 }}>
                                    <Typography sx={{ fontSize: 26, marginBottom: 2 }} variant="body1">
                                        {record['package_name']}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: 14, color: "#C3C3C3" }}>
                                        {record['description'] ?
                                            <>
                                                {(record['description'].length > 90) ?
                                                    <>
                                                        {seeAllInfo ?
                                                            <> {record['description']} <span style={{ cursor: 'pointer', color: '#00c2b3' }} onClick={handleSeeAll}>See less</span></>
                                                            :
                                                            <>
                                                                {record['description'].substring(0, 90) + ' ...'}
                                                                <span style={{ cursor: 'pointer', color: '#00c2b3' }} onClick={handleSeeAll}>See more</span>
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>{record['description']} </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </Typography>
                                </Box>
                                <Box sx={{ pt: 1 }}>
                                    <Divider />
                                </Box>
                                <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Typography component="span" sx={{ fontSize: 14, color: "#C3C3C3", marginBottom: 2 }}>
                                        Duration: {record['package_duration']} {record['unit']}
                                    </Typography>
                                    <Typography component="span" sx={{ fontSize: 14, color: "#C3C3C3", marginBottom: 10 }}>
                                        Price: {record['price']} €
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={8}>
                                <Typography sx={{ fontSize: 18, marginBottom: 15, color: "grey" }} component="span">
                                    Available Channels
                                </Typography>
                                <Grid container spacing={1}>
                                    {totalChannels ?
                                        <>
                                            {
                                                dataChannels?.map((item) => (
                                                    <>
                                                        <Grid key={item['_id']} item md={1}>
                                                            <Tooltip title={`${item['title']}`} placement="top">
                                                                <img width={60} src={`${BASE_ASSETS_URL}${item['icon_path']}`} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: "100%", width: "100%", paddingTop: 25, pl: 30, pr: 30 ,pb:25}}>
                                            <HourglassEmptyRoundedIcon style={{ fontSize: 90, color: "grey" }} />
                                            <Typography sx={{ color: "grey" }}>No available channels</Typography>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", justifyContent: 'center', alignContent: "center", marginTop: 1 }}>
                            <Pagination count={Math.ceil(totalChannels as number / 72)} page={page} onChange={handleChange} />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default InfoDialog;