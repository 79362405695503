import * as React from "react";
import { FC } from "react";
import { Box, Grid, Typography, useMediaQuery, Theme } from "@mui/material";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import { makeStyles } from "@mui/styles";
import {
  CreateButton,
  List,
  Pagination,
  TextInput,
  TopToolbar,
  Datagrid,
  TextField,
  ListProps,
  DateField,
  EditButton,
  DateInput,
  ImageField,
  SearchInput,
  
} from "react-admin";
import { FilterButton } from 'react-admin';


import ProgramList from "./ProgramList";

const ListActions: FC<any> = () => (
  <TopToolbar>
    <CreateButton />
    <FilterButton filters={productFilters} />
  </TopToolbar>
);

const ListPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);
const productFilters = [
  <TextInput sx={{ mb: 1 }} source="name_channel_name" label="Channel Name"alwaysOn />,
  <DateInput sx={{ mb: 1 }} label="Start Time" placeholder="Start time" source="date_start_time" />,
  <DateInput sx={{ mb: 1 }} label="Registration Date" placeholder="Registration Date" source="date_createdAt" />
];



const ResourceList: FC<ListProps> = (props) => {
  return (
    <Box sx={{ pl: 3, pt: 7 }}>
      <List
        // empty={<Empty />}
        filters={productFilters}
        pagination={<ListPagination />}
        title="Live TV"
        // bulkActionButtons={<BulkActionButtons />}
        actions={<ListActions />}
        // exporter={exporter}
        perPage={25}
        // sort={{ field: "reference", order: "ASC" }}
        {...props}
      >
        <Datagrid rowClick="edit">
          <ImageField label="Image" source="icon_path.src" />
          <TextField label="Channel Name" source="channel_name" />
          <TextField label="Program Name" source="program_name" />
          <TextField label="Epg Map ID" source="emp_map_id" />
          {/* <TextField label="Epg Map ID" source="epg_map_id" /> */}
          <DateField
            // showTime
            // options={{
            //   weekday: "short",
            //   year: "numeric",
            //   month: "short",
            //   day: "numeric",
            // }}
            source="start_time"
            label="Start Time"
          />
          <DateField
            // showTime
            // options={{
            //   weekday: "short",
            //   year: "numeric",
            //   month: "short",
            //   day: "numeric",
            // }}
            source="end_time"
            label="End Time"
          />
          <EditButton />
        </Datagrid>
      </List>
    </Box>
  );
};

export default ResourceList;
