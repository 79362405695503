import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Title,
  useNotify,
  useGetList,
  useRefresh,
  useTranslate,
  Notification,
  useGetOne,
  useDataProvider,
  useUpdate,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Theme,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Avatar,
  Chip,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import types
import { EditChannelStreams } from "../../types/Streams";

// import components
import EditStreams from "./components/EditStreams";

import CreateGenre from "./dialogs/CreateGenre";

// import configs
import { BASE_ASSETS_URL } from "../../config";

type Inputs = {
  epg_map_id: string;
  title: string;
  channel_number: string;
  channel_description: string;
  pin_protected: boolean;
  packages: any[];
  is_available: boolean;
  streams: any[];
  icon_path: object;
  genre: any[];
  rendering: number;
};

const EditChannel: FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [imageUrl, setImageUrl] = useState("");
  const [liveimageUrl, setLiveImageUrl] = useState("");
  const [changeimage, setChangeImage] = useState({});
  const [hasImage, setHasImage] = useState<boolean>();

  const [selectedGenres, setSelectedGenres] = useState<any[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<any[]>([]);

  const [streams, setStreams] = useState([]);

  const [pinProtected, setPinProtected] = useState(false);

  const [channelDetails, setChannelDetails] = React.useState<any>({});
  const [streamDetails, setStreamDetails] = React.useState<any[]>([]);

  const handleChangePinProtected = (event: any) => {
    setPinProtected(event.target.checked);
    setDisabled(false);
  };

  const [isAvailable, setIsAvailable] = useState(false);

  const handleChangeIsAvailable = (event: any) => {
    setIsAvailable(event.target.checked);
    setDisabled(false);
  };

  const getStateStreams: EditChannelStreams = (data: any) => {
    setStreams(data);
    // setDisabled(false);
  };
  const handleImage = (e: any) => {
    const url = URL.createObjectURL(e.target.files[0]);
    console.log(url);

    setHasImage(false);
    setImageUrl(url);
    setLiveImageUrl(url);
    setChangeImage(e.target.files[0]);
    setDisabled(false);
  };
  console.log(changeimage, "car sjell kjo");



  const {
    data: dataPackages,
    total: totalPackages,
    isLoading: loadingPackages,
    error: errorPackages,
  } = useGetList("packages", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  const {
    data: dataGenres,
    total: totalGenres,
    isLoading: loadingGenres,
    error: errorGenres,
  } = useGetList("genres", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  //fetch channel data
  const {
    data: dataChannels,
    isLoading: loadingChannels,
    error: errorChannels,
    refetch,
  } = useGetOne("channels", {
    id: id,
  });

  useEffect(() => {
    if (dataChannels) {
      setChannelDetails(dataChannels);
      setValue("epg_map_id", dataChannels?.epg_map_id);
      setValue("title", dataChannels?.title);
      setValue("channel_number", dataChannels?.channel_number);
      setValue("rendering", dataChannels?.rendering);
      setValue("channel_description", dataChannels?.channel_description);
      setPinProtected(dataChannels?.pin_protected);
      setSelectedGenres(dataChannels?.genre);
      setSelectedPackages(dataChannels?.packages);
      setStreams(dataChannels?.streams);
      setStreamDetails(dataChannels?.streams);
      setIsAvailable(dataChannels?.is_available);
      console.log(dataChannels?.icon_path);
      const imageUrlFromObject = dataChannels?.icon_path?.src || '';
      setImageUrl(imageUrlFromObject);
      if (imageUrlFromObject) setHasImage(true);
    }
  }, [dataChannels, dataPackages, dataGenres]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Inputs>();
  const OnSubmit: SubmitHandler<Inputs> = (data: any) => {
    const formData: any = new FormData();

    data.packages = selectedPackages;
    data.genre = selectedGenres;
    data.streams = streams;
    data.pin_protected = pinProtected;
    data.is_available = isAvailable;
    data.icon_path = !liveimageUrl ? dataChannels?.icon_path : changeimage;

    const channelsData = {
      title: data.title,
      channel_number: data.channel_number,
      epg_map_id: data.epg_map_id,
      channel_description: data.channel_description,
      icon_path: data.icon_path,
      pin_protected: data.pin_protected,
      is_available: data.is_available,
      genre: data.genre,
      packages: data.packages,
      streams: data.streams,
      rendering: JSON.parse(data.rendering),
    };

    console.log(channelsData.icon_path, "MARIZAAAA");

    dataProvider
      .update("channels", {
        id: `${id}`,
        data: channelsData,
        previousData: {
          id: `${id}`,
        },
      })
      .then((response: any) => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => refresh(),
          1000
        );
        notify("Your channel was successfully updated", { type: "info" });
        navigate("/channels");
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };

  return (
    <Box sx={{ ml: 2, mt: 5, p: 5, borderRadius: 10 }}>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={6}>
          <Grid item md={7}>
            <Grid container spacing={2}>
              <Grid item xs={9} md={3}>
                <TextField
                  id="title"
                  placeholder="Channel Title"
                  label="Channel Title*"
                  InputLabelProps={{ shrink: true }}
                  {...register("title", { required: true })}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} md={3}>
                <TextField
                  id="channel_number"
                  type="number"
                  placeholder="Channel Number"
                  label="Channel Number*"
                  InputLabelProps={{ shrink: true }}
                  {...register("channel_number", {
                    required: true,
                    pattern: /^\d+$/,
                    min: {
                      value: 1,
                      message: "Value must be greater than 0",
                    },
                  })}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.channel_number
                      ? `${errors.channel_number.message}`
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={9} md={3}>
                <TextField
                  id="epg_map_id"
                  placeholder="EPG Map ID"
                  label="EPG Map ID*"
                  InputLabelProps={{ shrink: true }}
                  {...register("epg_map_id", { required: true })}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} md={2}>
                <TextField
                  id="rendering"
                  type="number"
                  placeholder="Rendering Nr"
                  label="Rendering*"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...register("rendering", {
                    required: false,
                    pattern: /^\d+$/,
                    min: {
                      value: 1,
                      message: "Value must be greater than 0",
                    },
                  })}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.rendering ? `${errors.rendering.message}` : ""
                  }
                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 1 }}></Box>
            <Grid container spacing={2}>
              <Grid item xs={11} md={9}>
                <TextField
                  id="channel_description"
                  placeholder="Enter Channel Description"
                  label="Channel Description*"
                  InputLabelProps={{ shrink: true }}
                  {...register("channel_description", { required: true })}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box sx={{ display: "flex", mt: 1, mb: 0 }}>
                  {(liveimageUrl || imageUrl) ? (
                    <img
                      src={liveimageUrl || imageUrl}
                      style={{ width: 100, height: 80, marginRight: 8, objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      style={{
                        width: 100,
                        height: 80,
                        marginRight: 8,
                        backgroundColor: 'darkgrey',
                        color: 'grey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      No Image
                    </Box>
                  )}
                  <Box>
                    <Button
                      size="medium"
                      color="primary"
                      component="label"
                      style={{ marginTop: 38, marginLeft: 8 }}
                    >
                      Upload
                      <input
                        type="file"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Box sx={{ display: "flex", mt: 0, paddingBlock: 2 }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pinProtected}
                          {...register("pin_protected")}
                          onChange={handleChangePinProtected}
                          name="pin_protected"
                        />
                      }
                      label="Pin Protected"
                    />
                  </Box>
                  <Box sx={{ marginLeft: 5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAvailable}
                          {...register("is_available")}
                          onChange={handleChangeIsAvailable}
                          name="is_available"
                        />
                      }
                      label="Is Available"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CreateGenre />
              </Grid>
              <Grid item md={6}>
                {dataGenres && (
                  <Autocomplete
                    multiple={true}
                    id="genres-standard"
                    options={dataGenres as any}
                    getOptionLabel={(option) => option.name}
                    value={selectedGenres}
                    onChange={(event, value: any) => {
                      setSelectedGenres(value);
                      setDisabled(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Genre"
                        placeholder="Select Genre"
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item md={6}>
                {dataPackages && (
                  <Autocomplete
                    multiple={true}
                    id="packages-tags"
                    options={dataPackages as any}
                    getOptionLabel={(option: any) => option.package_name}
                    onChange={(event: any, value: any) => {
                      setSelectedPackages(value);
                      setDisabled(false);
                    }}
                    value={selectedPackages}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Packages"
                        placeholder="Select Package"
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Box sx={{ pb: 4 }}></Box>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <Box onClick={() => setDisabled(false)}>
                  <EditStreams
                    editChannelStreams={getStateStreams}
                    initStreams={streamDetails}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ padding: 4, pt: 4 }}>
              <Divider />
            </Box>
            <Button
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              disabled={!isDirty && disabled}
              onClick={handleSubmit(OnSubmit)}
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={5}></Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditChannel;
