import React, { cloneElement, ReactElement, Fragment, useState, FC } from 'react'
import {
    Datagrid,
    TopToolbar,
    List,
    FunctionField,
    DateField,
    ListProps,
    TextField,
    EditButton,
    sanitizeListRestProps,
    useListContext,
    ExportButton,
    Button,
    ReferenceField,
    Pagination,
    ReferenceInput,
    downloadCSV,
    AutocompleteInput,
    TextInput,
    SelectInput
} from 'react-admin';
import { Box, Typography, Stack, Divider, Chip, Grid } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import BulkDelete from '../../components/BulkDelete';
import { useMediaQuery, Theme } from '@mui/material';
import { execFile } from 'child_process';

interface Props {
    name: any,
}

// const BulkActionButtons = (props: any) => (
//     <Fragment>
//         <BulkDelete {...props} />
//     </Fragment>
// );

const ResourceList: FC<Props> = () => {

    const [nameInput, setNameInput] = useState("");

    const handleChangeee = (e: any) => {
        setNameInput(e.target.value)
    }

    let dataaa = new Date();


    const ListActions = (props: any) => {
        const {
            className,
            exporter,
            filters,
            maxResults,
            ...rest
        } = props;
        const {
            resource,
            displayedFilters,
            filterValues,
            showFilter,
        } = useListContext();
        return (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                    selectedIds: ''
                })}
                <ExportButton />
            </TopToolbar>
        );
    };

    const Empty = () => {
        const { resource } = useListContext();

        return (
            <Box style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
                <HourglassEmptyOutlinedIcon style={{ fontSize: 180, color: "grey" }} />
                <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
                <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
                <Box style={{ marginTop: 10 }}>
                    <Button href={"/#/subscriptions/cd"} label="Go to Subscription"></Button>
                </Box>
            </Box>
        );
    };

    const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

        fetchRelatedRecords(records, "package", "packages").then((pcg: any) => {
            fetchRelatedRecords(records, "property_operator_id", "properties/cd").then((sc: any) => {

                let data: any = [];

                records.map((record: any) => {

                    let x = moment(record?.end_time, 'YYYY-MM-DD').diff(moment(dataaa, 'YYYY-MM-DD'), 'days')

                    data.push({
                        ...record,
                        package: pcg[record?.package]?.package_name,
                        name: sc[record?.property_operator_id]?.name,
                        end_time: `${moment(record?.end_time).format("L")}`,
                        start_time: `${moment(record?.start_time).format("L")}`,
                        expires_in: (x > 0) ? x + " days" : "---",
                        updatedAt: `${moment(record?.updatedAt).format("L")}`,
                        property_operator_id: " ",
                        screen: " ",
                        iddd: " ",
                        createdAt: " ",
                        __v: " ",
                        _id: " ",
                        id: " "
                    })

                });

                return jsonExport(data, {
                    headers: ['package', 'name', 'start_time', 'end_time', 'expires_in', 'updatedAt', 'screen', 'property_operator_id', 'createdAt', '__v', 'id', 'iddd', '_id'],
                    rename: ['Packages', "Property Name", "Start Time", "End Time", "Expires in", "UpdatedAt", ' ', ' ', ' ', ' ', ' ', ' ', ' '],
                }, (err: any, csv: any) => {
                    downloadCSV(csv, 'subscriptions');
                });
            })
        })
    };

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    const subscriptionFilters = [
        <ReferenceInput sx={{ mb: 1, ml: 5 }} variant="outlined" alwaysOn source="property_operator_id" reference="properties/cd" perPage={100}>
            <AutocompleteInput
                onChange={handleChangeee}
                filterToQuery={(text) => ({ name: text })}
                size="small"
                sx={{ mb: 1, width: 200 }}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.name}`
                        : ''
                }
            />
        </ReferenceInput>
    ];

    return (
        <Box sx={{ pl: 4, pr: 3, pt: 7 }}>
            {isXSmall ?
                <List
                    filters={subscriptionFilters}
                    title="Subscriptions"
                    perPage={25}
                    // bulkActionButtons={<BulkActionButtons />}
                    empty={<Empty />}
                    actions={<ListActions />}
                    exporter={exporter}
                >
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Property Name" source="property_operator_id" reference="properties/cd">
                            <TextField source="name" />
                        </ReferenceField>
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="start_time" title="Start Time" />
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="end_time" title="End Time" />
                        <EditButton />
                    </Datagrid>
                </List>
                :
                <List
                    filters={subscriptionFilters}
                    title="Subscriptions"
                    perPage={25}
                    // bulkActionButtons={<BulkActionButtons />}
                    empty={<Empty />}
                    actions={<ListActions />}
                    exporter={exporter}
                >
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Package" source="package" reference="packages">
                            <TextField source="package_name" />
                        </ReferenceField>
                        <ReferenceField label="Property Name" source="property_operator_id" reference="properties/cd">
                            <TextField source="name" />
                        </ReferenceField>
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="start_time" title="Start Time" />
                        <DateField showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} source="end_time" title="End Time" />
                        <FunctionField
                            label="Expires in"
                            render={(record: any) => {
                                let x = moment(record?.end_time, 'YYYY-MM-DD').diff(moment(dataaa, 'YYYY-MM-DD'), 'days')
                                return (
                                    <Box sx={{ p: 2 }}>
                                        <Typography sx={{ color: x > 0 ? "#83c583" : "#cd5555", fontSize: 14, fontFamily: "sans-serif" }}>
                                            {(x > 0) ? x + " days" : "---"}
                                        </Typography>
                                    </Box>
                                )
                            }}
                        />
                        <EditButton />
                    </Datagrid>
                </List>
            }
        </Box>
    )

}
export default ResourceList;