import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useGetList, useGetOne } from 'react-admin';
import { Typography, Grid, Box, Card, ListItemSecondaryAction, List, ListItem, ListItemText } from '@mui/material';
import jwt_decode from "jwt-decode";
import { useMediaQuery, Theme } from '@mui/material';

// import components
import Screens from './cards/Screens';
import Services from './cards/Services';
import Assists from './cards/Assists';
import Packages from './cards/Packages';
import ActiveSubscriptionsChart from './ActiveSubscriptionsChart';
import CompleteAccount from './CompleteAccount';
import HomeScreenPreview from '../../app-personalization/HomeScreenPreview';

const DashboardProperty = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);

    const { data: dataAnalyticsProperty, isLoading: loadingAnalyticsProperty, error: errorAnalyticsProperty } = useGetOne(
        'analytics/property',
        {
            id: ""
        },
    );

    const { data: dataProperties, isLoading: loadingProperties, error: errorProperties } = useGetOne(
        'properties',
        {
            id: decode['property_operator_id']
        },
    );

    return (
        < Box sx={{ pl: 3, pt: 8, pr: 2, display: "flex" }}>
            <Grid container spacing={2}>
                <>
                    {dataAnalyticsProperty &&
                        <Grid item md={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={4}>
                                    <Screens value={dataAnalyticsProperty['total_screens']} />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Services value={dataAnalyticsProperty['total_services']} />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Packages value={dataAnalyticsProperty['total_packages']} />
                                </Grid>
                            </Grid>
                            {isXSmall ?
                                ""
                                :
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <ActiveSubscriptionsChart />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    {dataProperties &&
                        <Grid item xs={12} md={4}>
                                {Object.keys(dataProperties).length != 0 &&
                                    <CompleteAccount appDetails={dataProperties} />
                                }
                            {isXSmall ?
                                ""
                                :
                                <Grid>
                                    <Card sx={{ p: 4, borderRadius: 10, mt: 4, pr: 5, pt: 10, pb: 10 }}>
                                        <HomeScreenPreview
                                            homepageDetails={dataProperties as any}
                                            wifi={dataProperties?.wifi_password}
                                            phone={dataProperties?.reception_number}
                                            latitude={dataProperties?.latitude}
                                            longitude={dataProperties?.longitude}
                                            background_opacity={dataProperties?.opacity_background}
                                            fontSizes={7}
                                            imageSizes={75}
                                            logoSizes={35}
                                            p={0.1}
                                            heightVideoPlaceholder={100}
                                        />
                                    </Card>
                                </Grid>
                            }

                        </Grid>
                    }
                </>
            </Grid>
        </Box >
    )
}

export default DashboardProperty;