import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types

type Inputs = {
    name: string,
    image: any
};

const Create: FC = () => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = useState("");
    const [changeImage, setChangeImage] = useState({});

    const handleImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
        setDisabled(false);
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        data.image = changeImage;

        formData.append("name", data.name);
        if (Object.keys(data.image).length === 0 && data.image.constructor === Object === false) {
            formData.append("image", data.image);
        }

        dataProvider.create<any>(
            'device-menu',
            {
                data: formData
            },
            ).then((response: any) => {
                notify('Device menu was successfully created', { type: "info" });
                navigate("/device-menu");
            }).catch((error: any) => {
                notify(error.message, { type: "warning" });
            })
    }

    return (
        <Box sx={{ pl: 5, pt: 10 }}>
            <Title title="Create Device Menu" />
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={7}>
                        <Grid item md={4}>
                            <TextField
                                id="name"
                                InputLabelProps={{ shrink: true }}
                                label="Menu Name*"
                                placeholder="Menu Name"
                                {...register("name", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={1}>
                            {imageUrl ?
                                <Avatar sx={{marginTop: 10,marginLeft: 10,marginBottom: 10,width: 70,height: 70,}} alt="Logo" src={imageUrl} />
                                :
                                <Avatar sx={{marginTop: 10,marginLeft: 10,marginBottom: 10,width: 70,height: 70,}} alt="Logo" src="/static/images/avatar/1.jpg" />
                            }
                        </Grid>
                        <Grid item md={2}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                component="label"
                                style={{ marginTop: 25 }}
                            >
                                Upload Image
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        handleImage(e);
                                    }}
                                    hidden
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: 4 }}>
                        <Divider />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={disabled}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Create;