import { AuthProvider } from "react-admin";
import jwt_decode from "jwt-decode";
import { BASE_URL, SIGN_IN } from "../config";

import realmApp from "./realmConfig";

import * as Realm from "realm-web";
import { constant } from "lodash";

const authProvider: AuthProvider = {
  login: async (data: any) => {
    const request = new Request(`${BASE_URL}${data.api}`, {
      method: "POST",
      body: JSON.stringify(data.data),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    return fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((auth) => {
        if (auth.statusCode >= 200) {
          throw new Error(auth["message"]);
        } else {
          try {
            const mongoDbLogin = async () => {
              // let accessToken = { Authorization: auth.accessToken };

              const token = {Authorization : `Bearer ${auth.accessToken}`}

              const credentials = Realm.Credentials.function(token);

              await realmApp.logIn(credentials);

              localStorage.setItem("access_token", auth.accessToken);

              return Promise.resolve();
            };

            return mongoDbLogin();
          } catch (err) {
            console.log(err);
          }
        }
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("access_token");
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("access_token")
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      id: 1,
      fullName: `TiBO TV - Content Distributor`,
    });
  },
  getPermissions: () => {
    // let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);
    const user = "user";
    // const role = decode['role'];
    return user ? Promise.resolve(user) : Promise.reject();
  },
};

export default authProvider;
