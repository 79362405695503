import React, { FC, useState, useEffect, useCallback } from "react";
import {
  useUnselectAll,
  BulkActionProps,
  useNotify,
  useRefresh,
  Identifier,
  useUpdateMany,
  useDataProvider,
  useGetList,
  useCreate,
  useGetOne,
} from "react-admin";
import {
  makeStyles,
  createStyles,
  Stepper,
  Theme,
  Checkbox,
  Step,
  StepLabel,
  StepContent,
  Grid,
  Dialog,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Divider,
  MenuItem,
  FormControl,
  Typography,
  Button,
  DialogContent,
  TextField,
  Autocomplete,
  Stack,
} from "@mui/material";
import {
  useForm,
  SubmitHandler,
  useController,
  Controller,
} from "react-hook-form";
import { Select, Box, FormGroup, SelectChangeEvent } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { ObjectId } from "mongoose";

type ScreenIDType = {
  _id: string;
  screen_number: number;

};

type Inputs = {
  package: string;
  screen: string;
  quantity_subscription: number;
  start_time: any;
  end_time: any;
};

const noSelection: Identifier[] = [];

const AssignSubscription: FC<BulkActionProps> = ({
  selectedIds = noSelection,
}) => {

  const fetchDataForScreenID = async (screenID: Identifier): Promise<ScreenIDType> => {
 
    try {
      const response = await dataProvider.getOne('screens', { id: screenID });
      return response.data as ScreenIDType;
    } catch (error) {
    
      console.error('Error fetching data for screen ID:', screenID, error);
      throw error;
    }
  };

  const notify = useNotify();
  const unselectAll = useUnselectAll("screens");
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + " " + time;

  const From1To365Numbers = Array.from({ length: 365 }, (_, index) => ({
    nr: ` ${index + 1}`,


  }));


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setActiveStep(0);
    refresh();
  };

  const handleClose = () => {
    setOpen(false);
    unselectAll();
    refresh();
  };

  const [checkStartTime, setCheckStartTime] = useState(false);

  const handleChangePinProtected = (event: any) => {
    setCheckStartTime(event.target.checked);
  };


  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState<any>({});
  const [duration, setDuration] = useState<string>("");

  const {
    data: dataPackages,
    total: totalPackages,
    isLoading,
    error,
    refetch,
  } = useGetList("packages/property/packages", {
    filter: {},
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });


  const [timee, setTimee] = React.useState<any | null>();


  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const OnSubmit: SubmitHandler<any> = async (data) => {
    const screensData = [];

    // selectedIds.forEach((screenID) => {
    for (const screenID of selectedIds) {
      try {

        const additionalData = await fetchDataForScreenID(screenID);


        // data.package = selectedPackages;
        // data.screen = selectedIds;
        // formData.append("package", JSON.stringify(data.package));
        // formData.append("screen", JSON.stringify(data.screen));
        // formData.append("quantity_subscription", duration);
        //  console.log(timee);

        const dateNow = new Date()

        const endTime = checkStartTime
          ? moment(timee).add(duration, 'months').toDate()
          : moment(dateNow).add(duration, 'months').toDate();

        const subsdata = {
          package: selectedPackages._id,
          screen: additionalData._id,
          quantity_subscription: duration,
          start_time: checkStartTime ? moment(timee).toDate() : dateNow,
          end_time: endTime,
          createdAt: dateNow,
          updatedAt: dateNow,
          screen_number: additionalData.screen_number,

        };

        console.log("screenID", screenID);


        console.log("selectedPackages", selectedPackages);

        console.log("subsdata", subsdata);



        dataProvider
          .create("subscriptions", {
            data: subsdata,
          })
          .then((response: any) => {
            const timer: ReturnType<typeof setTimeout> = setTimeout(
              () => refresh(),
              1000
            );
            notify("Subscription was successfully created", { type: "info" });
            handleClose();
          })
          .catch((error: any) => {
            notify(error.message, { type: "warning" });
          });




      } catch (error) {

        console.error('Error processing screen ID:', screenID, error);
      }
    };
  };



  const [activeStep, setActiveStep] = React.useState(0);

  const getSteps = () => {
    return ["Select Subscription", "Check Information & Confirm Subscription"];
  };

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ pt: 2, pl: 2 }}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormControl
                  variant="outlined"
                  sx={{ marginTop: 20, margin: 1, minWidth: "52%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Packages
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) => setSelectedPackages(e.target.value)}
                    label="Availalbe Packages"
                    placeholder="Enter Availalbe Packages"
                  >
                    {dataPackages?.map((pack) => (
                      <MenuItem key={pack["id"]} value={pack}>
                        {pack["package_name"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <Stack spacing={2}>
                  <Autocomplete
                    sx={{ width: 200, ml: 1 }}
                    id="free-solo-2-demo"
                    options={From1To365Numbers.map((option) => option.nr)}
                    onChange={(e, nr) => {
                      setDuration(nr as any);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Duration"
                        placeholder="Enter Duration"
                        InputProps={{
                          ...params.InputProps,

                        }}
                      // InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ pt: 2, pl: 1 }}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <FormGroup sx={{ pb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkStartTime}
                          onChange={handleChangePinProtected}
                        />
                      }
                      label="Select Start Time"
                    />
                  </FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      label="Start Time"
                      value={timee}
                      onChange={(newTimee) => {
                        const date = dayjs(newTimee).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                        setTimee(date);
                      }}
                      disabled={checkStartTime === false}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );



      case 1:
        return (
          <Box sx={{ pt: 4, pl: 2 }}>
            <Grid container spacing={3}>
              <Grid item md={9}>
                <Grid container spacing={3}>
                  <Grid item md={9}>
                    <Typography component="span">
                      {selectedPackages["package_name"]}
                    </Typography>
                    <Typography sx={{ fontSize: 12, pl: 0 }} component="p">
                      Duration:{" "}
                      {duration +
                        " " +
                        "x" +
                        " " +
                        selectedPackages["package_duration"] +
                        " " +
                        selectedPackages["unit"]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                  component="span"
                >
                  {selectedPackages["price"] + " Euro"}
                </Typography>
                <Typography
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                  component="span"
                >
                  {selectedPackages[0]}
                </Typography>
                <br />
                <Typography component="span">x</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={9}>
                <Typography component="span">Rooms</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                  component="span"
                >
                  {selectedIds.length}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
              <Divider />
            </Box>
            <Grid container spacing={3}>
              <Grid item md={9}>
                <Typography component="span">Total</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                  component="span"
                >
                  {selectedPackages["price"] *
                    (duration as any) *
                    selectedIds.length +
                    " Euro"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkSteps = () => {
    if (activeStep === steps.length - 2) {
      return (
        <Box sx={{ pl: 3, pt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={Object.keys(selectedPackages).length === 0}
          >
            Next
          </Button>
        </Box>
      );
    } else if (activeStep === steps.length - 1) {
      return (
        <Box>
          <Button onClick={handleBack} sx={{ marginTop: 0, marginRight: 8 }}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(OnSubmit)}
          >
            Activate Subscription
          </Button>
        </Box>
      );
    } else if (activeStep === 2) {
      return <Box></Box>;
    } else {
      return (
        <Box>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ paddingLeft: 0 }}>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        style={{ color: "white", margin: 8 }}
      >
        Assign Subscription
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography sx={{ fontSize: 18, marginLeft: 25 }} component="span">
            Assign Subscription
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                    {checkSteps()}
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AssignSubscription;
function fetchDataForScreenID(screenID: Identifier) {
  throw new Error("Function not implemented.");
}

