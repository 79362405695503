import React, { Fragment } from 'react'
import {
	Datagrid,
	List,
	ListProps,
	TextField,
	DateInput,
	FunctionField,
	Button,
	DateField,
	Pagination,
	downloadCSV,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import BulkDelete from '../../components/BulkDelete';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import { useMediaQuery, Theme } from '@mui/material';
import jsonExport from 'jsonexport/dist';

const salesFilters = [
	<DateInput sx={{ mb: 2 }} source="fromDate" variant='outlined' alwaysOn />,
	<DateInput sx={{ mb: 2 }} source="toDate" variant='outlined' alwaysOn />,
];

const date = moment().format('YYYY-MM-DD hh:mm');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');

const Empty = () => {
	return (
		<Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
			<HourglassBottomRoundedIcon style={{ fontSize: 180, color: "grey" }} />
			<Typography style={{ fontSize: 25, color: "grey" }}>You have not purchased any package yet.</Typography>
			<Typography style={{ fontSize: 12, color: "grey" }}>Do you want to purchase one ?</Typography>
			<Box sx={{ mt: 3 }}>
				<Button href={"/#/packages/property/packages"} label="Go to packages"></Button>
			</Box>
		</Box>
	);
};

const exporter = (records: any, fetchRelatedRecords: any, posts: any) => {

	let data: any = [];

	records.map((record: any) => {

		data.push({
			...record,
			id: " ",
		})
	});
	return jsonExport(data, {
		headers: ['_id', 'total_purchases', 'expenses', 'id'],
		rename: ['Date', "Total Purchases", "Expenses", " "],
	}, (err: any, csv: any) => {
		downloadCSV(csv, 'by-date');
	});
};

// const BulkActionButtons = (props: any) => (
// 	<Fragment>
// 		<BulkDelete {...props} />
// 	</Fragment>
// );

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ResourceList = () => {

	const isXSmall = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	)

	return (
		<Box sx={{ pl: 4, pr: 3, pt: 8, backgroundSize: "cover", height: "100%" }}>
			{isXSmall ?
				<List
					filters={salesFilters}
					exporter={exporter}
					filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
					title="Subscriptions By Date"
					empty={<Empty />}
					pagination={<ListPagination />}
					perPage={25}
				// bulkActionButtons={<BulkActionButtons />}
				>
					<Datagrid bulkActionButtons={false}>
						<DateField showTime options={{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }} source="_id" title="Date" label={"Day"} />
						<FunctionField label="Total Expenses" render={(record: any) => {
							return (
								<Typography sx={{ p: 2 }}>{record.expenses} €</Typography>
							)
						}} />
					</Datagrid>
				</List>
				:
				<List
					filters={salesFilters}
					exporter={exporter}
					filterDefaultValues={{ toDate: date, fromDate: startOfMonth }}
					title="Subscriptions By Date"
					empty={<Empty />}
					pagination={<ListPagination />}
					perPage={25}
				// bulkActionButtons={<BulkActionButtons />}
				>
					<Datagrid bulkActionButtons={false}>
						<DateField showTime options={{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }} source="_id" title="Date" label={"Day"} />
						<TextField label="Total Purchases" source="total_purchases" />
						<FunctionField label="Total Expenses" render={(record: any) => {
							return (
								<Typography sx={{ p: 2, fontSize: 14 }}>{record.expenses} €</Typography>
							)
						}} />
					</Datagrid>
				</List>}
		</Box>
	)
}

export default ResourceList;