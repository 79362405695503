import * as React from 'react';
import { Layout, LayoutProps, Admin } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools'
import AppBar from './AppBar';
import Menu from './Menu';

export default (props: LayoutProps) => {
    return (
        <>
            <Layout {...props} appBar={AppBar} menu={Menu} />
            <ReactQueryDevtools />
        </>
    )
};