import {
  fetchUtils,
  DataProvider,
  DeleteResult,
  UpdateResult,
} from "react-admin";
import { stringify } from "query-string";

import realmApp from "../realmConfig";

import * as Realm from "realm-web";
const {
  BSON: { ObjectId },
} = Realm;
// Define a type guard for File
function isFile(obj: any): obj is File {
  return obj instanceof File;
}



export default (
  api: string,
  httpClient = fetchUtils.fetchJson
): DataProvider => ({
  getList: async (resource, params) => {
    console.log(resource);
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }

    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    // Constructing MongoDB query
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const skip = (page - 1) * perPage;

    // MongoDB sort requires an integer value: 1 for ASC, -1 for DESC
    const sortOrder = order === "ASC" ? 1 : -1;
    const filterKeys = Object.keys(params.filter);

    //name
    let dateArrayWordsStartingWithName: any[] = [];

    const filteredWordsStartingWithName = filterWordsStartingWith(
      filterKeys,
      "name"
    );
    filteredWordsStartingWithName.map((name: any) =>
      dateArrayWordsStartingWithName.push(name.slice(5))
    );
    const firstFilter = dateArrayWordsStartingWithName[0];
    const firstFilter1 = dateArrayWordsStartingWithName[1];

    const nameFilter1 = {
      [firstFilter]: {
        $regex: `^${params.filter[`name_${firstFilter}`]}`,
        $options: "i",
      },
    };

    const nameFilter2 = {
      [firstFilter1]: {
        $regex: `^${params.filter[`name_${firstFilter1}`]}`,
        $options: "i",
      },
    };

    //date

    function filterWordsStartingWith(arr: any, startingWith: any) {
      return arr.filter((word: any) => word.startsWith(startingWith));
    }

    let dateArrayWordsStartingWithDate: any[] = [];

    const filteredWordsStartingWithDate = filterWordsStartingWith(
      filterKeys,
      "date"
    );
    filteredWordsStartingWithDate.map((date: any) =>
      dateArrayWordsStartingWithDate.push(date.slice(5))
    );

    const DateFilter1 = dateArrayWordsStartingWithDate[0];
    const DateFilter2 = dateArrayWordsStartingWithDate[1];

    const startTime = new Date(params.filter[`date_${DateFilter1}`]);
    const endTime = new Date(params.filter[`date_${DateFilter2}`]);

    const dateFilters1 = {
      [DateFilter1]: { $gte: startTime },
    };

    const dateFilters2 = {
      [DateFilter2]: { $lt: endTime },
    };

    const filterPipeline = nameFilter1 ? [{ $match: nameFilter1 }] : []; // Add a $match stage if there is a filter
    const filterPipeline1 = nameFilter2 ? [{ $match: nameFilter2 }] : []; // Add a $match stage if there is a filter
    const date1 = dateFilters1 ? [{ $match: dateFilters1 }] : []; // Add a $match stage if there is a filter
    const date2 = dateFilters2 ? [{ $match: dateFilters2 }] : []; // Add a $match stage if there is a filter

    const pipeline: any = [{ $count: "total" }];

    const [result] = await currentUser.collection(resource).aggregate(pipeline);

    const dataPipeline: any = [
      ...(params.filter[`name_${firstFilter}`] ? filterPipeline : []),
      ...(params.filter[`name_${firstFilter1}`] ? filterPipeline1 : []),
      ...(params.filter[`date_${DateFilter1}`] ? date1 : []),
      ...(params.filter[`date_${DateFilter2}`] ? date2 : []),
      { $sort: { [field]: sortOrder, _id: 1 } },
      { $skip: skip },
      { $limit: perPage },
    ];

    const documents = await currentUser
      .collection(resource)
      .aggregate(dataPipeline);

    const totalData = result ? result.total : 0;

    console.log(documents);
    

    return {
      data: documents.map((doc: any) => ({ ...doc, id: doc._id.toString() })),
      total: totalData,
    };
  },
  getOne: (resource, params) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }

    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);
    const nid = new ObjectId(params.id);

    //console.log("11111111111111111 found one 111111111111", nid);

    return currentUser
      .collection(resource)
      .findOne({ _id: nid })
      .then((document) => {
        if (!document) {
          throw new Error(`getOne: Document with ID ${params.id} not found.`);
        }

        // Return the transformed data
        return {
          data: {
            ...document,
            id: document._id,
          },
        };
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
        throw new Error("Failed to fetch data.", err);
      });
  },
  getMany: async (resource, params) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }
    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    const filter = { _id: { $in: params.ids.flat() } };

    const documents = await currentUser.collection(resource).find(filter);

    return { data: documents.map((doc) => ({ ...doc, id: doc._id })) };
  },
  getManyReference: async (resource, params) => {
    try {
      if (!realmApp.currentUser) {
        console.error("User needs to be logged in to fetch data.");
        throw new Error("User needs to be logged in to fetch data.");
      }

      const currentUser = realmApp.currentUser
        .mongoClient("mongodb-atlas")
        .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;


      const referenceField = Object.keys(params.filter)[0];
      const parentId = params.id;

      const filter = { [referenceField]: parentId };

      console.log('Filter:', filter);


      const sortAndPagination = [
        { $sort: { [field]: order === "ASC" ? 1 : -1, _id: 1 } },
        { $skip: (page - 1) * perPage },
        { $limit: perPage },
      ];


      const pipeline = [
        { $match: filter },
        ...sortAndPagination,
      ];

      try {
        currentUser.collection(resource);


        const documents = await currentUser.collection(resource).aggregate(pipeline);

        console.log('Fetched Documents:', documents);

        return {
          data: documents.map((doc: { _id: any; }) => ({ ...doc, id: doc._id })),
          total: documents.length,
        };
      } catch (error) {
        throw new Error(`Failed to fetch related items: ${error}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },


  update: async (resource: any, params: any) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }
    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    const nid = new ObjectId(params.id);

    const updateResult = await currentUser
      .collection(resource)
      .updateOne({ _id: nid }, { $set: params.data });

    if (updateResult.matchedCount === 0) {
      throw new Error(`update: Document with ID ${params.id} not found.`);
    }
if (params.data.icon_path && isFile(params.data.icon_path)) {
        const { icon_path, ...otherParams } = params.data;

        const filename = icon_path.name;

        const response = await fetch(
            'https://europe-west1.gcp.data.mongodb-api.com/app/live-portal-api-bsnmg/endpoint/get_signed_url?filename=' + filename,
            { method: "GET" }
        );

        const signData = await response.json();

        const url = `https://images.app.applay.io/${filename}`;
        const title = filename;

        const blob = new Blob([icon_path], { type: 'image/jpeg' });

        const uploadResponse = await fetch(signData, {
            method: "PUT",
            body: blob,
            headers: {
                'Content-Type': 'image/jpeg'
            }
        });

        if (!uploadResponse.ok) {
            console.error("Failed to upload image:", uploadResponse.statusText);
            throw new Error(`Failed to upload image: ${uploadResponse.statusText}`);
        }

        try {
            await currentUser
                .collection(resource)
                .updateOne({ _id: nid }, { $set: { ...otherParams, icon_path: { src: url, title: title } } });

            const updatedDocument = await currentUser
                .collection(resource)
                .findOne({ _id: nid });

            if (!updatedDocument) {
                throw new Error(`Failed to fetch the updated document with ID ${params.id}.`);
            }

            return {
                data: { ...updatedDocument, id: updatedDocument._id },
            };
        } catch (error) {
            console.error("Error during update:", error);
            throw error;
        }
    } else {
        const updateResult = await currentUser
            .collection(resource)
            .updateOne({ _id: nid }, { $set: params.data });

        if (updateResult.matchedCount === 0) {
            throw new Error(`update: Document with ID ${params.id} not found.`);
        }

        const updatedDocument = await currentUser
            .collection(resource)
            .findOne({ _id: nid });

        if (!updatedDocument) {
            throw new Error(`Failed to fetch the updated document with ID ${params.id}.`);
        }

        return {
            data: { ...updatedDocument, id: updatedDocument._id },
        };
    }
},

  updateMany: async (resource, params) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }
    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    const filter = { _id: { $in: params.ids.map((id) => new ObjectId(id)) } };
    const update = {
      $set: {
        /* Define your update fields and values here */
      },
    };

    try {
      const result = await currentUser
        .collection(resource)
        .updateMany(filter, update);
      // Check the matchedCount property to get the number of documents matched by the filter
      const matchedCount = result.matchedCount || 0;
      return { data: { matchedCount } } as UpdateResult; // Cast to the expected type
    } catch (error) {
      throw new Error(`Failed to update items: ${error}`);
    }
  },// ...
  create: async (resource: any, params: any) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }
  
    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);
  
    console.log('params:', params);
    console.log('params.data:', params.data);
  
    if (params.data && params.data.icon_path && params.data.icon_path instanceof File) {
      // Image data provided, proceed with image upload logic
  
      const { icon_path, ...otherParams } = params.data;
      console.log('icon_path:', icon_path);
  
      const filename = icon_path.name;
  
      const response = await fetch(
        'https://europe-west1.gcp.data.mongodb-api.com/app/live-portal-api-bsnmg/endpoint/get_signed_url?filename=' + filename,
        { method: "GET" }
      );
  
      const signData = await response.json();
  
      const url = `https://images.app.applay.io/${filename}`;
      const title = filename;
  
      console.log(params);
      console.log("mongo response =>", signData);
      console.log('Icon path type:', params.data.icon_path.type);
  
      const blob = new Blob([icon_path], { type: 'image/jpeg' });
  
      const uploadResponse = await fetch(signData, {
        method: "PUT",
        body: blob,
        headers: {
          'Content-Type': 'image/jpeg'
        }
      });
  
      console.log("uploadResponse", uploadResponse);
  
      if (!uploadResponse.ok) {
        console.error("Failed to upload image:", uploadResponse.statusText);
        throw new Error(`Failed to upload image: ${uploadResponse.statusText}`);
      }
  
      try {
        const result = await currentUser
          .collection(resource)
          .insertOne({ ...otherParams, icon_path: { src: url, title: title } });
  
        return { data: { ...otherParams, id: result.insertedId } };
      } catch (error) {
        console.error("Error during insertion:", error);
        throw error;
      }
    } else {
      console.warn("No valid image provided. Creating resource without an image.");
  
      // Continue with the alternative logic when no valid image is provided
      try {
        const result = await currentUser
          .collection(resource)
          .insertOne(params.data);
        return { data: { ...params.data, id: result.insertedId } };
      } catch (error) {
        console.error("Error during insertion:", error);
        throw error;
      }
    }
  },
  


  delete: async (resource, params) => {
    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }
    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    const query = { _id: new ObjectId(params.id) };

    try {
      const result = await currentUser.collection(resource).deleteOne(query);
      return { data: result } as DeleteResult; // Cast to the expected type
    } catch (error) {
      throw new Error(`Failed to delete item: ${error}`);
    }
  },
  deleteMany: async (resource, params) => {
    console.log('Deleting items:', params.ids);

    if (!realmApp.currentUser) {
      console.error("User needs to be logged in to fetch data.");
      throw new Error("User needs to be logged in to fetch data.");
    }

    const currentUser = realmApp.currentUser
      .mongoClient("mongodb-atlas")
      .db(`${process.env.REACT_APP_DATABASE_TYPE}`);

    const query = { _id: { $in: params.ids.map((id) => new ObjectId(id)) } };

    try {
      const result = await currentUser.collection(resource).deleteMany(query);
      console.log('Delete result:', result);

      return { data: [{ id: params.ids[0], deletedCount: result.deletedCount }] } as DeleteResult;
    } catch (error) {
      console.error(`Failed to delete items: ${error}`);
      throw new Error(`Failed to delete items: ${error}`);
    }
  },
});